import React, { useEffect, useState, useRef } from "react";
import "./adminchat.css";
import { SearchOutlined } from "@ant-design/icons";
import SearchInput from "../../Components/Common/SearchInput";
import userAvatar from "../../Assets/Images/userImage.png";
import { Spin, message, Image } from "antd";
import joompaApi from "../../utils/apis/joompaApi";
import { formatDate } from "../../utils/Constant";
import { useLocation } from "react-router-dom";
import { socket } from "./socket";

const Chatlist = ({ handleClick, setUserProfileData,listenerData ,setListenerData,setFileList, setFileForDocument,setFileForVideo}) => {
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const [inputValue, setInputValue] = useState("")
  const location=useLocation()
  const [clickedUser,SetClickedUser] = useState("");
  const {userId}=location.state || ""
  useEffect(()=>{
    if(userId){
      fetchingForRedirection();
    }
     
    
  },[])

  const fetchingForRedirection = async()=>{
    try{
          const res = await joompaApi.get(`/user?userId=${userId}`);
          const data = res.data.data;
          console.log(data);
          handleClick(userId,data.fname+data.lname,data.userImg[0].photoUrl,data.email);
    }
    catch(error){
      console.log(error);
    }
  }
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          setPageNum((no) => no + 1);
        }
      })
  );
  let timeoutId;

  const handleInputChange = async (e) => {
    const query = e.target.value.trim();
    if (!query) {
      console.log("inner");// Clear the previous timeout if there is one
      clearTimeout(timeoutId);
      // Set a new timeout to delay the API call
      timeoutId = setTimeout(async () => {
      try {
        setPageNum(0);
        // setLoading(true);
        // const res = await joompaApi(`/getAllSupportRequest?pageNumber=${pageNum}`);
        // const data = res?.data?.data;
        // setChatList(data);
        // setLoading(false);
        return;
      } catch (error) {
        setLoading(false);
        message.error("Failed To Get Chat List");
      }
    }, 1000);
      // Don't hit API if input value is empty

    } else{
      // console.log("else");
      // Clear the previous timeout if there is one
      clearTimeout(timeoutId);
      // Set a new timeout to delay the API call
      timeoutId = setTimeout(async () => {
        try {
          const res = await joompaApi.get(`/getAllSupportRequest?search=${query}`);
          const data = res.data.data;
           setChatList(data);
          
        } catch (error) {
          // console.log(error)
        }
      }, 1500);
    }
  };

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);


  useEffect(() => {
    getChatList();
  }, [pageNum,userId]);

  useEffect(()=>{ 
    console.log(listenerData)
    chatList.map((item,i)=>{
      if(item.userId===listenerData.userId){
        let newrray =[];
        if(listenerData.data.message){
          document.getElementById("LastMsg"+listenerData.userId).innerHTML=listenerData.data.message;
          chatList[i].lastMessage.message=listenerData.data.message;
          newrray = chatList;
        }
        else{ 
          document.getElementById("LastMsg"+listenerData.userId).innerHTML=listenerData.data.type==="image"?"Image":listenerData.data.type==="video"?"Video":listenerData.data.type==="doc"&&"Document";
          chatList[i].lastMessage.message=listenerData.data.type;
          newrray = chatList;
        }
        document.getElementById("LastTime"+listenerData.userId).innerHTML=formatDate(listenerData.data.date,true);
        if(clickedUser!==listenerData.userId){
          document.getElementById("chatPopup"+listenerData.userId).innerHTML=parseInt(document.getElementById("chatPopup"+listenerData.userId).innerHTML) +1
          if(document.getElementById("chatPopup"+listenerData.userId).innerHTML>=0){
            document.getElementById("chatPopup"+listenerData.userId).classList.remove("hide");
            document.getElementById("chatPopup"+listenerData.userId).classList.add("show");
          }
        }
       
        setChatList(newrray);
        
      }
    })
  },[listenerData])

  const handleSeenChat = (userId)=>{

      setFileList([]);
      setFileForDocument([]);
      setFileForVideo([])

    document.getElementById("chatPopup"+userId).innerHTML=0;

      document.getElementById("chatPopup"+userId).classList.remove("show");
          document.getElementById("chatPopup"+userId).classList.add("hide");
      SetClickedUser(userId);
  }
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     if(userId){
  //       // console.log(`chatList${userId}`)
  //      document.getElementById(`chatList${userId}`).click();
  //     }
  //   },5000)
  // },[])

  const getChatList = async () => {
    try {
      setLoading(true);
      if(pageNum===0){
        
        const res = await joompaApi(`/getAllSupportRequest?pageNumber=${pageNum}`);
        const data = res?.data?.data;
        // console.log("chatList data", chatList, data);
        // let flatdata = [...chatList, data]
        setChatList(data);
        console.log("i am here")
        
        
        // console.log("data",chatList)
        setLoading(false);
      }
      else{
        const res = await joompaApi(`/getAllSupportRequest?pageNumber=${pageNum}`);
        const data = res?.data?.data;
        // console.log("chatList data", chatList, data);
        let flatdata = [...chatList, data]
        setChatList(flatdata.flat());
        setLoading(false);
      }
     
    } catch (error) {
      setLoading(false);
      message.error("Failed To Get Chat List");
    }
  };

  useEffect(()=>{
    socket.connect();
       
        socket.on("connect", (data) => {
        })
        socket.on("join-admin", (data) => {

        })
        socket.emit("join-admin")
        socket.on("someone-send-message",(data)=>{
          // console.log(data)
          setListenerData(data);
        });

  },[chatList])
  return (
    <>
    <div className="chat-left" style={{display:userId?"none":"unset"}}>
      <div className="inbox-header">
        <div className="inbox-title">Inbox</div>
        {/* <div className="search-btn">
          <SearchOutlined />
         
        </div> */}
        <SearchInput handleSearchInput={handleInputChange} />
      </div>
      <div className="hor-line"></div>
      <div style={{ overflow: "scroll" }}>
        <Spin spinning={loading} size="large" >
          <div className="chat-list">
            {chatList &&
              chatList?.map((el,i) => 
              {

                return(
                
                <div ref={setLastElement}
                  id={`chatList${el?.userId}`}
                  className="single-chat"
                  key={el?.userId}
                  onClick={() =>{
                    handleClick(
                      el?.userId,
                      el?.userName,
                      el?.profilePhoto,
                      el?.email
                    );
                    handleSeenChat(el?.userId);
                  }
                  }
                >
                  <div>
                    <Image
                      src={el?.profilePhoto}
                      style={{ borderRadius: "50%" }}
                      width="48px"
                      height="48px"
                    />
                  </div>
                  <div style={{width:"83%"}} >
                  <div style={{marginLeft:"10px",display:"flex",flexDirection:"row",justifyContent:"space-between",height:"25px",marginBottom:"10px"}}>
                    <div>
                  <p className="chat-user-name" >{el?.userName}</p>
                  </div>
                  <div className=""  >
                    <p className="chat-user-msg-time" style={{width:"110px",whiteSpace:"nowrap"}} id={"LastTime"+el.userId}>
                      {/* {new Date(
                        Date.now() - new Date(el?.lastMessage?.date)
                      ).toLocaleTimeString([], { timeStyle: "short" })} */}
                    {formatDate(el?.lastMessage?.date,true)}
                    </p>
                    {/* {!el?.lastMessage?.user_seen && ( */}
                     
                     
                      {/* <p className={el.unreadCount===0?"chat-user-msg-count hide":"chat-user-msg-count show"} id={"chatPopup"+el.userId}>{el.unreadCount}</p> */}
                    
                    {/* )} */}
                    </div> 
                  </div>
                  <div style={{display:"flex",justifyContent:"flex-end"}}>
                  <p  className={el.unreadCount===0?"chat-user-msg-count hide":"chat-user-msg-count show"} id={"chatPopup"+el.userId}>{el.unreadCount}</p>
                </div>
                    <div className="ex">
                      
                      <p className="chat-user-last-msg" id={"LastMsg"+el?.userId}>
                      {  

                      el?.lastMessage?.message ?el?.lastMessage?.message:el?.lastMessage?.type==="image"?"Image":el?.lastMessage?.type==="video"?"Video":el?.lastMessage?.type==="doc"&&"Document"           
                        }
                      </p>
                   
                   
                  </div>
                  </div>
                </div>
              )})}
          </div>
        </Spin>
      </div>
    </div>
    </>
  );
};

export default Chatlist;


