import React, { useEffect, useState } from "react";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Loading from "./Loading";
import { useLocation } from "react-router-dom";
const SearchInput = ({handleSearchInput,loading=false}) => {
  const [timeout,settimeout] = useState();
  const [searching,setSearching] = useState("");
  const location = useLocation();
  useEffect(()=>{
    if(localStorage.getItem("search") && (location.pathname==="/usermanagement/allusers"||location.pathname==="/usermanagement/premiumusers"|| location.pathname==="/usermanagement/verificationRequest"||location.pathname==="/usermanagement/profiledeletion")){
      setSearching(localStorage.getItem("search"))
    }
  },[])
  return (
    <Input
      style={{
        width: "200px",
        border: "1px solid #E6EBF1",
        borderRadius: "8px",
      }}
      value={searching}
      onChange={(e)=>{
        clearTimeout(timeout)
        setSearching(e.target.value)
        settimeout()
        settimeout(
        setTimeout(()=>{
          handleSearchInput(e)
      },1000))
      

      }}
      placeholder="Search"
      prefix={!loading?<SearchOutlined /> :<LoadingOutlined/>}
    />
  );
};

export default SearchInput;
