import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CustomButton = ({ title = "+Add New" ,href,data,interest}) => {
  return (
    <Button
      style={{
        height: "35px",
        marginRight:"5px",
        background: "#1B75BC",
        borderRadius: "8px",
        color:"white",
        marginRight:"20px"
      }}
    >
        <Link to={href} state={{userDeatils:data,PersonalityInfo:interest}}>{title}</Link>
      
    </Button>
  );
};

export default CustomButton;
