import React, { useEffect, useState } from "react";
import SearchInput from "../../Components/Common/SearchInput";
import { Button, Image, Popconfirm, Popover, Spin, message } from "antd";
import TableComponent from "../../Components/Common/TableComponent";
import joompaApi from "../../utils/apis/joompaApi";
import { formatDate, statusActiveStyle, statusRejectedStyle } from "../../utils/Constant";
import ExportButton from "../../Components/Common/ExportButton";
import { Link } from "react-router-dom";
import { ADMIN_MANAGEMENT, EDIT_ADMIN, TRANSACTIONS, VIEW_ADMIN } from "../../utils/Urls";
import { headersForBanUser, headersForReport } from "../../utils/Columns";
import ReadMore from "../../Components/Common/ReadMore";
import Message from "../../Assets/Images/messagetext.png";
import { DeleteOutlined, EditOutlined, LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/Common/CustomButton";

function AdminManagement() {
  const [userReportData, setUserReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nAllUsers, setNAllUsers] = useState([]);
  const [visible, setVisibile] = useState(false);
  const [readMore, setReadMore] = useState({});
  const [deleteLoding, setdeleteLoding] = useState(false);

  const handleSearchInputReport = async (e) => {
    try {
      let query = e.target.value;
      let res = await joompaApi.get(`/getAllAdmins?search=${query.trim()}`);
      if(res.status===204){
        setUserReportData([])
        return;
      }
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setUserReportData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };

  const getUserReportData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/getAllAdmins`);
      console.log("Response User Report", res);
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setUserReportData(mapData);
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    await joompaApi.delete(`/delete-admin-by-id?id=${id}`).then((res)=>{
      if(res.status===200){
        message.success("Deleted Successfully")
        getUserReportData()
      }
    }).catch((err)=>{
      message.error(err.message);
  })
}

  const tabThreecolumns = [

    {
      title: "",
      dataIndex: "profilePhoto",
      className: "col-normal",
      width: "5%",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text?text:"https://api.dicebear.com/7.x/initials/svg?seed="+record.fullName}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User",
      width: "15%",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      width:"25%"
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: "15%",
      align:"center",
      render(text){
        return{
          children:(<div>{text?text:"-"}</div>)
        }
      }
    },
    {
      title:"Account Type",
      render(text){
        return {
          children:(
            <div>{text.isSuperAdmin?"Superadmin":"Admin"}</div>
          )
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      width:"7%",
      render(text) {
        return {
          children: (
            <div style={text?statusActiveStyle:statusRejectedStyle}>{text?"Active":"Inactive"}</div>
          ),
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      align: "center",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              <Popover
                placement="bottomRight"
                content={
                  <>
                    <Link
                      to={EDIT_ADMIN}
                      style={{ color: "black" }}
                      state={{ id: record._id , uid:record.uid}}
                    >
                      <EditOutlined /> Edit
                    </Link>
                    <Popconfirm
                      // title="Delete the task"
                      title="Are You Sure To Delete This User?"
                      onConfirm={()=>handleDelete(record._id)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      // placement="leftBottom"
                      placement="left"
                    >
                      <button
                         style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          color: "red",
                        }}
                      >
                       {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                       <DeleteOutlined style={{marginRight:"5px",color: "red"}}/>Delete
                      </button>
                    </Popconfirm>

                  </>
                }
                trigger="click"
                overlayInnerStyle={{
                  borderRadius: "12px",
                  textAlign: "center",
                }}
                overlayClassName="popover-menu"
                showArrow={false}
              >
                <Link>{text}</Link>
              </Popover>
            </div>
          ),
        };
      },
    },
  ];

  useEffect(() => {
    getUserReportData();
  }, []);
  return (
    <>
    <div style={{display:"flex",justifyContent:"space-between"}}>
      <div className="buttons-container">
        <SearchInput handleSearchInput={handleSearchInputReport} />
        {nAllUsers.length !== 0 && (
          <ExportButton data={nAllUsers} passing={headersForBanUser} />
        )}
      </div>
      <div style={{ float: "right" }}>
        <CustomButton title="Add New Admin" href={VIEW_ADMIN} />
      </div>
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent columns={tabThreecolumns} data={userReportData} />
      </Spin>
      <ReadMore
        visible={visible}
        setVisibile={setVisibile}
        readMore={readMore}
        remarks="Remarks"
      />
    </>
  );
}

export default AdminManagement;
