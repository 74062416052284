import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import userAvatar from "../../Assets/Images/userImage.png";
import ChatInput from "./ChatInput"
import ChatHeader from "./ChatHeader"
import Chats from './chatData'
import { io } from "socket.io-client"; 
import { socket } from "./socket";
import { formatDate } from "../../utils/Constant";
import { FileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import joompaApi from "../../utils/apis/joompaApi";





const ChatRoom = ({ chatData, setChatData, userProfileData,messagesEndRef,setListenerData,pageNum,setPageNum,hasMorePage,setHasMorePage,fileList,setFileList,fileForDocument,setFileForDocument,fileForVideo,setFileForVideo,loadings,setLoadings}) => {
  const [inputValue, setInputValue] = useState("");
  const [roomId,setRoomId] = useState("");
  const [attachments,setAttachment] = useState("");
  // const [fileList, setFileList] = useState([]);
  // const [fileForVideo,setFileForVideo] = useState([]);
  // const [fileForDocument,setFileForDocument] = useState([]);
  
  
  
  const [loading,setLoading]=useState(false)
  // const [chatData,setChatData] = useState([]);
  // const messagesEndRef = useRef(null)
  

  const { userId } = userProfileData
  useEffect(() => {
    if(!userId){
      return;
    }
    // scrollToBottom()
    socket.connect();
    // const data={userId}
    socket.on("connect", (data) => {
    
    })
    socket.emit('support-join-room',{userId: userId,type:"admin"})
    socket.emit("support-leave-room",{room_id: roomId,type:"admin"})
    socket.emit('get-all-messages',{userId: userId,pageNumber:0,limit:10})

    const supportJoinRoom = (data)=>{
      // console.log("userId for",userId)
      localStorage.setItem("roomId",userId);
      setRoomId(userId);
      // console.log(data);
    }

    const supportLeaveRoom = (data) =>{
      // console.log("userId for leave",roomId)
      // console.log(data);
    }

    const sendMessageSupport = (data) =>{
        if(!data.data){
          setHasMorePage(false);
          return;
        }
        setLoading(true)
          const data2 = data.data;
          let newrray = data2;
          setChatData(chatData => [...chatData,newrray].flat());
          setTimeout(()=>{
            setLoading(false)
          },2000)
          
      
    }


    socket.on('support-join-room',supportJoinRoom)
 
    socket.on("support-leave-room",supportLeaveRoom)

    socket.on("someone-send-message",(data)=>{
      console.log(data)
      setListenerData(data);
    });

    socket.on('update-message-support',(data)=>{
      console.log(data)
      setListenerData(data);
    });


    socket.on('get-all-messages',sendMessageSupport)
    socket.on('send-message-support',(data) =>{
      setChatData(chatData => [ data.data,...chatData])
    })

    socket.on("disconnect", (data) => {
  
    })

    return () => {
      socket.off('connect');
      socket.off('support-join-room',supportJoinRoom);
      socket.off('get-all-messages',sendMessageSupport);
      socket.off('send-message-support');
      socket.off("support-leave-room",supportLeaveRoom);
      socket.off("disconnect")
      // socket.off('get-all-messages');
      socket.disconnect();
    };
  }, [userId]);
  useEffect(() => {
    if(pageNum!==0){
      fetchData();
    }
    },[pageNum])

    // useEffect(()=>{
    //   if(endPage!==10){
    //   fetchData();
    //   }
    // },[endPage])
  const fetchData = async() => {
    setTimeout(()=>{
      socket.emit('get-all-messages',{userId: userId,pageNumber:pageNum,limit:10})
    },500)
   
    
  }


  const handleSendChat = async () => {
    setLoadings(true)
    if(inputValue){

      socket.emit('send-message-support', {
        message: inputValue,
        profile: "",
        from: "admin",
        userId: userId,
        date: new Date().toISOString(),
      })
      setInputValue("");
    }
    

    // Image //
    if(fileList.length!==0){
      if(parseFloat(fileList[0].size/1024)<=20000){
      formData.append("file", fileList[0]);
      try {
        
        const res = await joompaApi.post(
          `/uploadUserImage`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ); 
       socket.emit('send-message-support', {
        attachment:res.data.data.location,
        type:"image",
        profile: "",
        from: "admin",
        userId: userId,
        date: new Date().toISOString(),
      })
      setFileList([]);
      } catch (error) {
       
      }
      }
      else{
        setFileList([]);
        message.info('Image should be less than 20MB');
      }
    
  }

  // Video //
  else if(fileForVideo.length!==0){

    if(parseFloat(fileForVideo[0].size/1024)<=20000){
      
      formDataForVideo.append("file", fileForVideo[0]);
      try {
        
        const res = await joompaApi.post(
          `/uploadUserImage`,
          formDataForVideo,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        socket.emit('send-message-support', {
          attachment: res.data.data.location,
          type:"video",
          profile: "",
          from: "admin",
          userId: userId,
          date: new Date().toISOString(),
        })
        setFileForVideo([]);
      } catch (error) {
       
      }
    }
    else{
      setFileForVideo([]);
      message.info('Image should be less than 20MB');
    }

   
  }

  //document//
  else if(fileForDocument.length!==0){
    if(parseFloat(fileForDocument[0].size/1024)<=20000){
      formDataForDocument.append("file", fileForDocument[0]);
      try {
        
        const res = await joompaApi.post(
          `/uploadUserImage`,
          formDataForDocument,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        socket.emit('send-message-support', {
          attachment: res.data.data.location,
          type:"doc",
          profile: "",
          from: "admin",
          userId: userId,
          date: new Date().toISOString(),
        })
        setFileForDocument([]);
      } catch (error) {
       
      }
    }
    else{
      setFileForDocument([]);
      message.info('Image should be less than 20MB');
    }
    
 
    }
    
   
    setLoadings(false);
    // setAttachment("");
   
  };




  const formData = new FormData();
  const formDataForDocument = new FormData();
  const formDataForVideo = new FormData();


  return (
    <>
      <ChatHeader userProfileData={userProfileData} />
      <div className="ver-line"></div>
      <div className="chat-masg" id="scrollableDiv"  style={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }} >
        <div class="messages-chat"   >


         
          <Chats chatData={chatData} userProfileData={userProfileData} messagesEndRef={messagesEndRef} hasMorePage={hasMorePage} pageNum={pageNum} setPageNum={setPageNum} loading={loading} />         
             
            
      </div>
    
    </div>
    
    <ChatInput setChatData={setChatData} chatData={chatData} setInputValue={setInputValue} inputValue={inputValue} handleSendChat={handleSendChat} attachments={attachments} setAttachment={setAttachment} fileList={fileList} setFileList={setFileList} fileForVideo={fileForVideo} setFileForVideo={setFileForVideo} fileForDocument={fileForDocument} setFileForDocument={setFileForDocument} loadings={loadings}/>
    </>

  );
};

export default ChatRoom;
