import React, { useState } from 'react'
import TableComponent from '../../Components/Common/TableComponent'
import { Spin,Image } from 'antd'
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { colorOfStatus, formatDate } from '../../utils/Constant';
import { subscriptionIcon } from '../../utils/Constant';
import { useLocation } from 'react-router-dom';
import NonPremium from "../../Assets/Images/icons/Vector.svg"
const DashboardDataShowTable = () => {
    const [loading,setLoading]=useState(false)
    const location=useLocation()
    const {data}=location?.state
    const columns = [
        {
            title: "User Name",
            dataIndex: "username",
            // defaultSortOrder: "ascend",
            sorter: (a, b) => a.username < b.username,
            sortDirections: ["ascend","descend"],
            width: "25%",
            // width: "15%",
            className: "col-special",
            render(text, record) {
              return {
                props: {
                  style: { background: "white" },
                },
                children: <div>{text}</div>,
              };
            },
          },
        {
          title: "",
          dataIndex: "photoUrl",
          width: "3%",
          render(text, record) {
            return {
              children: (
                <Image
                  style={{ borderRadius: "50%" }}
                  width={40}
                  height={40}
                  src={text}
                />
                // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
              ),
            };
          },
        },
        
        {
          title: "User Type",
          dataIndex: "isPremium",
          className: "col-normal",
          width: "25%",
          align: "center",
          render(text) {
            return {
              children: text ? (
                <img
                  width="24px"
                  height="19px"
                  src={premiumUsers}
                  alt="user Type"
                />
              ) : (
                <img
                  width="24px"
                  height="19px"
                  src={NonPremium}
                  alt="user Type"
                />
              ),
            };
          },
        },
        {
          title: "Member Since",
          dataIndex: "regDate",
          defaultSortOrder: 'descend',
          width: "25%",
          sorter: (a, b) => new Date(a.regDate) - new Date(b.regDate),
          sortDirections: ["ascend","descend"],
          className: "col-normal",
          render(text) {
            return {
              children: formatDate(text),
            };
          },
        },
        {
          title: "Status",
          dataIndex: "accountStatus",
        //   width: "13%",
          sorter: (a, b) => a.accountStatus < b.accountStatus,
          sortDirections: ["ascend","descend"],
          render(text, record) {
            return {
              children: <div style={colorOfStatus(text)}>{text}</div>,
            };
          },
        //   width: "8%",
          className: "col-normal",
        }
      ];

  return (
    <div className="user-container">
      <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent data={data} columns={columns}/>
      </Spin>
    </div>
  )
}

export default DashboardDataShowTable