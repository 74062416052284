import React, { useState,useEffect } from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import {
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Image, Spin,Popover, message,Popconfirm} from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import CustomPopover from "../../utils/Constant";
import Loading from "../../Components/Common/Loading";
import { colorOfStatus, formatDate } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import TableComponent from "../../Components/Common/TableComponent";
import { Link } from "react-router-dom";
import { TRANSACTIONS } from "../../utils/Urls";
import { headersForProfileDeletion } from "../../utils/Columns";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";

const RequestedProfile = () => {
  const [profileDeletionData,setProfileDeletionData]=useState([])
  const [loading,setLoading]=useState(false)
  const [keypath,setKeypath]=useState('')
    const [deleteLoading,setDeleteLoading]=useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10)
    const [allUsersCount, setAllUsersCount] = useState(0) 
    const [loading1,setLoading1]=useState(false)
    const [forExport, setForExport] = useState([]);//[{}
    const [loading4,setLoading4]=useState(false)
    const [loading3,setLoading3] = useState(false)
    const [filterWithSearch,setFilterWithSearch] = useState([]);
    const [search,setSearch] = useState("");
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let nAllUsersData =forExport
    nAllUsersData.map((item)=>{
      item.requestedOn=formatDate(item.requestedOn);
    })
    setNewAllUsersData(nAllUsersData)
  },[forExport])

  useEffect(() => {
    if (
      localStorage.getItem("search") ||
      localStorage.getItem("filter") ||
      localStorage.getItem("page") || 
      localStorage.getItem("mousePoisition")
    ) {
      localStorage.getItem("search") &&
        setSearch(localStorage.getItem("search"));
      localStorage.getItem("filter") &&
        setFilterWithSearch(localStorage.getItem("filter").split(","));
      localStorage.getItem("filter") &&
        setKeypath(localStorage.getItem("filter").split(",")[0]);
      localStorage.getItem("page") && setPage(localStorage.getItem("page"));
      getDataForLocal(
        localStorage.getItem("filter")
          ? localStorage.getItem("filter").split(",")
          : [],
        localStorage.getItem("search") ? localStorage.getItem("search") : "",
        localStorage.getItem("page") ? localStorage.getItem("page") : 1
      );
      localStorage.removeItem("search");
      localStorage.removeItem("filter");
      localStorage.removeItem("page");

      // setTimeout(() => {
      //   if (localStorage.getItem("mousePoisition")) {
      //     let mouse = localStorage.getItem("mousePoisition");
      //     // setScrollPosition(mouse);
      //     window.document.getElementsByClassName("site-layout")[0].scrollTo(0, 453);

      //   }
      // }, 1000);

      return;
    }
    getData();
  }, []);

  useEffect(()=>{

    if(!keypath&&allUsersCount){
      getData()
    }else if(keypath && allUsersCount){
      handledropdownclick({key:keypath,keyPath:keypath.split("/")})
    }
    
  },[page,count])


  const fetchForExport = async () => {
    try {
      setLoading1(true)
      let res = await joompaApi.get(`/profileDeletion?pageNumber=1&pageSize=${allUsersCount}`);
      let data = res?.data?.data;
      setForExport(data);
      // setProfileDeletionData(data);
      // console.log(mapData)
      setLoading1(false)
    } catch (error) {
      setLoading1(false)
      message.error("Something Went Wrong")
    }
  };

  const getData = async(filter=false,searchs=false) => {
    try {
      setLoading(true)
      let res = await joompaApi.get(`/profileDeletion?pageNumber=${page}&pageSize=${count}&search=${!searchs?search:""}${!filter && filterWithSearch.length>0?`&filter=true&menu=${filterWithSearch[1]?filterWithSearch[1]:""}&submenu=${filterWithSearch[0]?filterWithSearch[0]:""}`:""}`);
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setProfileDeletionData(mapData);
      if (!filter || !searchs) {
        setAllUsersCount(res?.data?.count);
      }
      else if(!searchs && search){
        setAllUsersCount(res?.data?.count);
      }
      else if(!filter && filterWithSearch.length>0){
        setAllUsersCount(res?.data?.count);
      }
      else {
        setAllUsersCount(false);
      }
      // setAllUsersCount(res?.data?.count)
    //   if(allUsersCount!==res?.data?.count){
    //     fetchForExport(res?.data?.count)
    //  }
      // console.log(mapData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error("Something Went Wrong")
    }
  };


  const getDataForLocal = async (filter, search, page) => {
    try {
      setLoading(true)
      let res = await joompaApi.get(`/profileDeletion?pageNumber=${page}&pageSize=${count}&search=${search}${
        filter.length > 0
          ? `&filter=true&menu=${filter[1] ? filter[1] : ""}&submenu=${
              filter[0] ? filter[0] : ""
            }`
          : ""
      }`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setProfileDeletionData(mapData);
      if (page && !search && filter.length == 0) {
        setAllUsersCount(res?.data?.count);
      } else {
        setAllUsersCount(false);
      }
      // setAllUsersCount(res?.data?.count)
    //   if(allUsersCount!==res?.data?.count){
    //     fetchForExport(res?.data?.count)
    //  }
      // console.log(mapData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error("Something Went Wrong")
    }
  };

  
  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
  };

  const [open, setOpen] = useState(true);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [current, setCurrent] = useState("1");
  const onClick = (e) => {
    setCurrent(e.key);
  };
  const handledropdownclick = async({key,keyPath }) => {
    setKeypath(key)
    setFilterWithSearch(keyPath)
    if(keyPath[0] === "All"){
      getData(true)
      setFilterWithSearch([]);
      return
    }
    setLoading3(true)
    // console.log('filterMenu',keyPath[0])
    try {
      
      let res = await joompaApi.get(`/profileDeletion?filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}&search=${search}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setAllUsersCount(false)
      setProfileDeletionData(mapData);
      setLoading3(false)
    } catch (error) {
      setLoading3(false)
      // console.log(error)
    }
  };

  useEffect(()=>{
    if(localStorage.getItem("mousePoisition") && profileDeletionData.length>0){
        setTimeout(()=>{
          const storedPosition = localStorage.getItem("mousePoisition");
      // console.log(storedPosition)
      if (storedPosition !== null) {
          const scrollPosition = (storedPosition);
          const siteLayout = window.document.getElementsByClassName("site-layout")[0];
          if (siteLayout) {
              siteLayout.scrollTo(0, scrollPosition);
              localStorage.removeItem("mousePoisition");
          } else {
              console.error("Element with class 'site-layout' not found.");
          }
      } else {
          console.error("No stored scroll position found.");
      }
        },1000)
    }
  },[ profileDeletionData])



  const handleSearchInput = async(e) => {
    if(e.target.value === "") {
      getData(false,true)
      setSearch("")
      return
    }
    setLoading4(true)
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/profileDeletion?search=${query}&filter=true&menu=${filterWithSearch[1]?filterWithSearch[1]:""}&submenu=${filterWithSearch[0]?filterWithSearch[0]:""}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setProfileDeletionData(mapData);
      setAllUsersCount(false)
      setLoading4(false)
    } catch (error) {
      // console.log(error)
      setLoading4(false)
      message.error("Something Went Wrong")
    }
  };
  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align:"center",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      align: "center",
      width:"12%"
    },
    {
      title: "",
      dataIndex: "userAvatar",
      width:"6%",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.fullName < b.fullName,
      sortDirections: [ "ascend","descend"],
      width: "19%",
      className: "col-special",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
   
    {
      title: "Requested On",
      dataIndex: "requestedOn",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.requestedOn) - new Date(b.requestedOn),
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      width:"14%",
      render(text) {
        return {
          children:formatDate(text),
        };
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      className: "col-normal",
      // width:"10%"
    },
    {
      title: "Status",
      dataIndex: "accountStatus",
      sorter: (a, b) => a.accountStatus < b.accountStatus,
      sortDirections: ["ascend","descend"],
      render(text, record) {
        return {
          children: <div style={colorOfStatus(text)}>{text}</div>,
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "18%",
      className: "col-normal",
      align:"center",
      render: (text, record) => {
        return {
          children:(
        <div>
          {open && <Popover
            placement="bottomRight"
            content={
              <>
                <Link
                  to={TRANSACTIONS}
                  style={{ color: "black" }}
                  state={{userId:record.userId}}
                  onClick={() => {
                    localStorage.setItem("search", search);
                    localStorage.setItem("filter", filterWithSearch);
                    localStorage.setItem("page", page);
                    localStorage.setItem("mousePoisition",window.document.getElementsByClassName("site-layout")[0].scrollTop);// );
                  }}
                >
                  <EyeOutlined /> View Details
                </Link>
                {/* <p>
                  <DeleteOutlined /> Delete
                </p> */}
                <Popconfirm
                      // title="Delete the task"
                      title="Are You Sure To Delete This User?"
                      onConfirm={()=>confirm(record.userId)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      // placement="leftBottom"
                      placement="left"
                    >
                      <button
                        style={{
                          border: "none",
                          marginLeft: "-38px",
                          background: "none",
                          paddingBottom:"10px",
                          cursor:"pointer",
                          color:"red"
                        }}
                      >
                       {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                       <DeleteOutlined style={{marginRight:"5px"}}/>Delete
                      </button>
                    </Popconfirm>
              </>
            }
            trigger="click"
            overlayInnerStyle={{
              borderRadius: "12px",
              textAlign: "center",
            }}
            overlayClassName="popover-menu"
            showArrow={false}
          >
            <Link>{text}</Link>
          </Popover>
        }
        </div>
      ),
        }
      }
    },
  ];

  const confirm = async(uid) => {
    setDeleteLoading(true)
    try {
      const res=await joompaApi.delete(`/userDelete/${uid}`)
      if(res?.data?.status){
        // console.log("User deleted successfully",res)
        message.success(res?.data?.message)
        setDeleteLoading(false)
        setOpen(false)
        getData()
      }
    } catch (error) {
      setDeleteLoading(false)
      message.error("User Delete Failed")
    }

  };
  return (
    <div className="user-container">
    <div className="buttons-container">
    <SearchInput handleSearchInput={handleSearchInput} loading={loading4}/>
        <FilterButton loading={loading3} handledropdownclick={handledropdownclick} keypath={keypath}/>
      {/* {(newAllUsersData.length!==0 || profileDeletionData.length!==0 )&& <ExportButton loading={loading1} data={newAllUsersData} passing={headersForProfileDeletion}/>} */}
      <ExportButtonNew loading={loading1} fetch={fetchForExport} data={newAllUsersData} passing={headersForProfileDeletion}/>
    </div>
    <Spin spinning={loading} tip="Loading..." size="large">
      <TableComponent rowKey="userId" data={profileDeletionData} columns={columns} setPage={setPage} setCount={setCount} count={allUsersCount} page={page}
          pages={true} />
    </Spin>
    {/* {loading?<Loading/>:} */}
    
  </div>
  )
}

export default RequestedProfile;
