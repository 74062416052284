import React, { useState, useEffect } from "react";
import "./usermanagement.css";
import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, InputNumber, Modal, Radio } from "antd";
import { Tabs, Popover, Spin, message, Button } from "antd";
import locationImg from "../../Assets/Images/transactions/location.png";
import age from "../../Assets/Images/transactions/age.png";
import country from "../../Assets/Images/transactions/country.png";
import education from "../../Assets/Images/transactions/education.png";
import height from "../../Assets/Images/transactions/height.png";
import religion from "../../Assets/Images/transactions/religion.png";
import weight from "../../Assets/Images/transactions/weight.png";
import timeline from "../../Assets/Images/transactions/Timeline.png";
import transactionIcon from "../../Assets/Images/transactions/TransactionIcon.png";
import InfoIcon from "../../Assets/Images/transactions/info (1) 1.png";
import conceirgeIcon from "../../Assets/Images/transactions/ConceirgeIcon.png";
import matchIcon from "../../Assets/Images/transactions/matchIcon.png";
import reportsIcon from "../../Assets/Images/transactions/reportsIcon.png";
import GhostIcon from "../../Assets/Images/transactions/ghost-2 1.png";
import {
  HeartFilled,
  FlagFilled,
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import TableComponent from "../../Components/Common/TableComponent";
import TabTimeline from "./TabTimeline";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import CustomButton from "../../Components/Common/CustomButton";
import {
  ADD_SUGGESTION_FORM,
  ADMIN_SUPPORT_CHATS,
  CONCIERGE_REQUEST,
} from "../../utils/Urls";
import getItem from "../../utils/getItem";
import {
  tabFourData,
  tabFourcolumns,
  tabOneData,
  tabOnecolumns,
  tabThreeData,
  tabThreecolumns,
  tabTwoData,
  tabTwocolumns,
} from "./TransactionTableData";
import { useLocation, useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import {
  ageCalculator,
  formatDate,
  getExpireOn,
  getExpiryStatus,
} from "../../utils/Constant";
import CardForLimit from "./CardForLimit";
import FilterButtonWithoutGender from "../../Components/Common/FilterButtonWithoutGender";
import Ban from "../../Assets/Images/adminChat/Vector.png";
import TextArea from "antd/lib/input/TextArea";
import Tick from "../../Assets/Images/transactions/ban.png";
import cross from "../../Assets/Images/transactions/ban2.png";
import moment from "moment";

const transactionTabSubmenuData = [
  getItem("All", "All"),
  getItem("Subscription", "subscription", [
    getItem("All", "All"),
    getItem("Premium", "Premium"),
    getItem("Concierge", "Concierge"),
    getItem("Joompa Coins", "Joompa Coins"),
  ]),
  getItem("Status", "Status", [
    getItem("All", "All"),
    getItem("Active", "Active"),
    getItem("Expired", "Expired"),
  ]),
]; 
const matchesTabSubmenuData = [
  getItem("All", "All"),
  getItem("Profile Type", "Profile Type", [
    getItem("All", "All"),
    getItem("Premium", "Premium"),
    getItem("Concierge", "Concierge"),
  ]),
  getItem("Status", "Status", [
    getItem("All", "All"),
    getItem("Salam Sent", "Salam Sent"),
    getItem("Pending", "Pending"),
  ]),
];
const conciergeTabSubmenuData = [
  getItem("All", "All"),
  getItem("Profile Type", "Profile Type", [
    getItem("All", "All"),
    getItem("Premium", "Premium"),
    getItem("Concierge", "Concierge"),
  ]),
  getItem("Status", "Status", [
    getItem("All", "All"),
    getItem("Match", "Match"),
    getItem("Rejected", "Rejected"),
  ]),
];
const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [userMatchData, setUserMatchData] = useState([]);
  const [userReportData, setUserReportData] = useState([]);
  const [userTimelineData, setUserTimelineData] = useState([]);
  const [userTransactionData, setUserTransactionData] = useState([]);
  const [conerigeData, setConerigeData] = useState([]);
  const [userDeatils, setUserDeatils] = useState({});
  const [tabOption, SetTabOption] = useState("1");
  const [keypath, setKeypath] = useState("");
  const [keypath1, setKeypath1] = useState("");
  const [keypath2, setKeypath2] = useState("");
  const [lastActivityData, setLastActivityData] = useState();
  const [limitData, setLimitData] = useState();
  const [interestArray, setInterestArray] = useState([]);
  // const [userId,setUserId]=useState('')
  const [isModalOpenCoins, setIsModalOpenCoins] = useState(false);
  const [modalFormForCoins] = Form.useForm();
  const [requiredTrue, setRequiredTrue] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [messageToShow, setMessageToShow] = useState({
    image: "",
    message: "",
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendCoinData = async (value) => {
    
    try{
      let data = {
        userId: userDeatils._id,
        givingCoinsByAdmin:value.givingcoinsbyadmin,
      };
      const res = await joompaApi.post("/adminCoinTopupTransactions",data)
      setIsModalOpenCoins(false);
      // console.log(res.data)
      message.success(res.data.message)
      modalFormForCoins.resetFields();
      getLimitData();
      getUserTransactionsData();
      
      // message.success(res.data.message)

    }catch(err){
      message.warn(err.message)
      modalFormForCoins.resetFields();
      setIsModalOpenCoins(false);
      
    }
  };
  const popUpBanModal = async () => {
    try {
      await joompaApi.post("/banUnbanUser", {
        status: "UNBANNED",
        userId: userDeatils._id,
      });
    } catch (err) {
      message.warn(err.response.data.message);
      modalForm.resetFields();
      setSuccessModal(false);
      return;
    }
    getUserDetailsData();
    setSuccessModal(false);
    setSuccessModal(true);
    setMessageToShow({
      image: Tick,
      message: "User " + userDeatils?.fname + " unban successfully!!",
    });
    setTimeout(() => {
      setSuccessModal(false);
    }, [1500]);
  };
  const sendBanData = async (value) => {
    console.log(value);

    try {
      let data = {
        status: "BANNED",
        reason: value.remarks,
        userId: userDeatils._id,
        permanentBanned: !requiredTrue,
      };
      if (requiredTrue) {
        console.log("here");
        data = {
          ...data,
          date: value.ban_till,
        };
      }
      console.log(data);

      await joompaApi.post("/banUnbanUser", data);
    } catch (err) {
      message.warn(err.response.data.message);
      modalForm.resetFields();
      setRequiredTrue(true);
      setIsModalOpen(false);
      return;
    }
    getUserDetailsData();
    let message = requiredTrue ? " Temporary" : " Permanent";
    setSuccessModal(true);
    setTimeout(() => {
      setSuccessModal(false);
    }, [1500]);
    setMessageToShow({
      image: Tick,
      message: userDeatils?.fname + message + " banned from the joompa app!",
    });
    modalForm.resetFields();
    setIsModalOpen(false);
    setRequiredTrue(true);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [modalForm] = Form.useForm();
  // console.log(location);
  // location?.state?.tab && SetTabOption(location?.state?.tab)

  const tab = location.state.tab && location.state.tab;
  const userId =
    location?.state?.userId || JSON.parse(sessionStorage.getItem("userId"));
  // console.log("state record", userId);

  useEffect(() => {
    // SetTabOption(tab)
    // setUserId(location?.state?.userId)
    getUserTransactionsData();
    getUserDetailsData();
    // getData();
  }, []);

  useEffect(() => {
    getUserTransactionsData();
    getUserDetailsData();
  }, [location.state.userId]);

  const onChange = (key) => {
    if (key === "MATCHES") {
      getMatchesData();
    } else if (key === "USERREPORT") {
      getUserReportData();
    } else if (key === "TIMELINE") {
      getUserTimlineData();
    } else if (key === "CONCIERGE") {
      getConcerigeData();
    } else if (key === "LIMITS") {
      getLimitData();
    }
  };

  const getLimitData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/limits-details?_id=${userId}`);
      let data = res?.data;
      data?.limits && setLimitData(data?.limits);
      setLoading(false);
    } catch (error) {}
  };
  const getMatchesData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/userMatchReport?userId=${userId}`);
      let data = res?.data?.data[0];
      data?.matchUsers && setUserMatchData(data.matchUsers);
      setLoading(false);
    } catch (error) {
      // console.log(error.message);
    }
  };
  const getConcerigeData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(
        `/getConcergeMatchForAdmin?userId=${userId}`
      );
      // console.log("Response Coneirge Data", res?.data?.data);
      let data = res?.data?.data;
      setLoading(false);
      const mapData = data?.map((obj) => ({
        ...obj,
        fullName: `${obj.fname} ${obj.lname}`,
      }));
      setConerigeData(mapData);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const getUserReportData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/userReport?userId=${userId}`);
      // console.log("Response User Report", res);
      let data = res?.data?.data[0];
      setLoading(false);
      data?.blockedUserReports && setUserReportData(data.blockedUserReports);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  if (location?.state?.tab && tabOption === "1") {
    SetTabOption("MATCHES");
    getMatchesData();
  }
  if (location?.state?.report && tabOption === "1") {
    console.log("here");
    SetTabOption("USERREPORT");
    getUserReportData();
  }
  if (location?.state?.conceirge && tabOption === "1") {
    SetTabOption("CONCIERGE");
    getConcerigeData();
  }
  const getUserTimlineData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/getUserTimeline/${userId}`);
      let data = res?.data;
      // console.log("user Time Line Data", data,userId);
      setLoading(false);
      setUserTimelineData(data.data.activites);
      setLastActivityData(data.lastLogin);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const getUserTransactionsData = async () => {
    setLoading(true);
    let res = await joompaApi.get(`/transactions?userId=${userId}`);
    let data = res?.data?.data;

    let mapData = data.map((obj) => ({
      ...obj,
      status: getExpiryStatus(obj.expireDate),
    }));
    setLoading(false);
    data && setUserTransactionData(mapData);
  };
  const getUserDetailsData = async () => {
    try {
      setUserLoading(true);
      let res = await joompaApi.get(`/user?userId=${userId}`);
      // console.log("Response user", res?.data?.data);
      let data = res?.data?.data;
      setUserLoading(false);
      data && setUserDeatils(data);
      const interestArray = [
        // ...userDeatils?.interests?.basicQuestion?.answer ??[],
        ...(data?.interests[0]?.sportsAndFitness ?? []),
        ...(data?.interests[0]?.outdoorFun ?? []),
        ...(data?.interests[0]?.hobbies ?? []),
        ...(data?.interests[0]?.artsAndCulture ?? []),
        ...(data?.interests[0]?.technology ?? []),
      ];
      setInterestArray(interestArray);
    } catch (error) {
      setUserLoading(false);
      message.error(error.message);
    }
  };

  const PersonalityInfo = [
    ...(userDeatils?.artistic ?? []),
    ...(userDeatils?.intellectual ?? []),
    ...(userDeatils?.physical ?? []),
    ...(userDeatils?.sociable ?? []),
    ...(userDeatils?.nurturer ?? []),
    ...(userDeatils?.material ?? []),
  ];

  // const interestArray=[
  //   // ...userDeatils?.interests?.basicQuestion?.answer ??[],
  //   ...userDeatils?.interests?.sportsAndFitness ??[],
  //   ...userDeatils?.interests?.outdoorFun ??[],
  //   ...userDeatils?.interests?.hobbies ??[],
  //   ...userDeatils?.interests?.artsAndCulture ??[],
  //   ...userDeatils?.interests?.technology ??[],
  // ]

  const handledropdownclickMatch = async ({ key, keyPath }) => {
    setKeypath(key);
    console.log("filterMenu", keyPath);
    setUserMatchData([]);
    try {
      let res = await joompaApi.get(
        keyPath[0] === "All"
          ? `/userMatchReport?userId=${userId}`
          : `/userMatchReport?userId=${userId}&filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}`
      );
      let data = res?.data?.data[0].matchUsers;
      setUserMatchData(data);
      console.log(data);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleSearchInputMatch = async (e) => {
    try {
      let query = e.target.value;
      let res = await joompaApi.get(
        `/userMatchReport?userId=${userId}&search=${query.trim()}`
      );
      let data = res?.data?.data[0]?.matchUsers;
      console.log(data);
      setUserMatchData(data);
    } catch (error) {
      // console.log(error)
    }
  };
  const handledropdownclickConcerige = async ({ key, keyPath }) => {
    setKeypath1(key);
    // console.log('filterMenu',keyPath[0])
    try {
      let res = await joompaApi.get(
        keyPath[0] === "All"
          ? `/getConcergeMatchForAdmin?userId=${userId}`
          : `/getConcergeMatchForAdmin?userId=${userId}&filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}`
      );
      let data = res?.data?.data;
      const mapData = data?.map((obj) => ({
        ...obj,
        fullName: `${obj.fname} ${obj.lname}`,
      }));
      setConerigeData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleSearchInputConcerige = async (e) => {
    try {
      let query = e.target.value;
      let res = await joompaApi.get(
        `/getConcergeMatchForAdmin?userId=${userId}&search=${query.trim()}`
      );
      let data = res?.data?.data;
      const mapData = data?.map((obj) => ({
        ...obj,
        fullName: `${obj.fname} ${obj.lname}`,
      }));
      setConerigeData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleSearchInputReport = async (e) => {
    try {
      let query = e.target.value;
      let res = await joompaApi.get(
        `/userReport?userId=${userId}&search=${query.trim()}`
      );
      let data = res?.data?.data;
      data = data[0].blockedUserReports;
      data.length !== 0 && setUserReportData(data);
      setUserReportData(data);
    } catch (error) {
      // console.log(error)
    }
  };

  const handledropdownclickTrans = async ({ key, keyPath }) => {
    setKeypath2(key);
    // console.log('filterMenu',keyPath)
    try {
      let res = await joompaApi.get(
        keyPath[0] === "All"
          ? `/transactions?userId=${userId}`
          : `/transactions?userId=${userId}&filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}`
      );
      let data = res?.data?.data[0];
      setUserTransactionData(data);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleSearchInputTrans = async (e) => {
    try {
      let query = e.target.value;
      let res = await joompaApi.get(
        `/transactions?userId=${userId}&search=${query.trim()}`
      );
      let data = res?.data?.data;
      setUserTransactionData(data);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleRevokeAdmin = async () => {
    try {
      const res = await joompaApi.put(`/superAdmin/${userId}`, {
        isSuperAdmin: false,
      });
      if (res?.data?.status === "success") {
        message.success(res?.data?.message);
        getUserDetailsData();
      }
    } catch (error) {
      message.error("Failed To Revoke Super Admin");
    }
  };

  const handleSetSuperAdmin = async () => {
    try {
      const res = await joompaApi.put(`/superAdmin/${userId}`, {
        isSuperAdmin: true,
      });
      if (res?.data?.status === "success") {
        message.success(res?.data?.message);
        getUserDetailsData();
      }
    } catch (error) {
      message.error("Failed To Revoke Super Admin");
    }
  };
  return (
    <>
      <div style={{ textAlign:"right",marginRight:"20px",marginBottom:"20px",marginTop:"-30px" }}>
        <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            textDecoration: "underline",
            color: "#00AB6F",
          }}
        >
          {`Last Active / Sign Up on ${formatDate(
            userDeatils?.lastActivityDate
          )}`}
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <Button
            style={{
              height: "35px",
              marginRight: "5px",
              background:
                userDeatils?.accountStatus !== "BANNED" ? "#F11B4E" : "#F5A504",
              borderRadius: "8px",
              color: "white",
              marginRight: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
            disabled={userDeatils.accountStatus ? false : true}
            icon={userDeatils?.accountStatus !== "BANNED" && <img src={Ban} />}
            onClick={() => {
              if (userDeatils?.accountStatus === "BANNED") {
                setMessageToShow({
                  image: cross,
                  message:
                    "Are you sure you want to unban " +
                    userDeatils?.fname +
                    " from the Joompa app?",
                  showButton: true,
                });
                setSuccessModal(true);
              } else {
                setIsModalOpen(true);
              }
            }}
          >
            {userDeatils?.accountStatus !== "BANNED"
              ? `Ban User`
              : "Unban User"}
          </Button>
        </div>
        <div>
          <Button
            style={{
              height: "35px",
              marginRight: "5px",
              background: "#4DAE47",
              borderRadius: "8px",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => {
              navigate(ADMIN_SUPPORT_CHATS, {
                state: {
                  userId: userDeatils?._id,
                },
              });
            }}
          >
            Support Chat
          </Button>
        </div>
        <div>
          <Button
            style={{
              height: "35px",
              marginRight: "5px",
              background: "#1B75BC",
              borderRadius: "8px",
              color: "white",
              marginRight: "20px",
            }}
            onClick={
              userDeatils?.isSuperAdmin
                ? handleRevokeAdmin
                : handleSetSuperAdmin
            }
          >
            {userDeatils?.isSuperAdmin
              ? "Revoke Super Admin"
              : "Set Super Admin"}
          </Button>
        </div>
      </div>
      <div className="transaction-container">
        <div className="left-profile white-card">
          <Spin spinning={userLoading} tip="Loading..." size="large">
            <div className="profile-img">
              <img
                width="104px"
                // className="ant-image-img"
                height="104px"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                src={userDeatils?.userImg && userDeatils?.userImg[0]?.photoUrl}
                alt="avatar"
              />
              <span
                className="title"
                style={{ textAlign: "center", marginBottom: "8px" }}
              >
                {`${userDeatils?.fname}, ${userDeatils?.age}`}
                {"  "}
                {userDeatils?.isGhostUser && <img src={GhostIcon} />}
              </span>
              <span className="user-info">{`${userDeatils?.occupation}`}</span>
              <span className="user-info">{`${userDeatils?.maritalStatus}`}</span>
            </div>
            <div>
              <span className="profile-heading">{`About ${userDeatils?.fname}`}</span>
              <p className="user-info-text">
                <ul style={{ textAlign: "left", marginLeft: "20px" }}>
                  {userDeatils?.introduction &&
                    userDeatils?.introduction?.map((obj) => (
                      <div>
                        <li style={{ color: "#2775C0", fontSize: "14px" }}>
                          <span style={{ color: "black" }}>
                            {obj?.question}
                          </span>
                        </li>
                        <p style={{ fontSize: "12px" }}>{obj?.answer}</p>
                      </div>
                    ))}
                </ul>
              </p>
            </div>
            <div>
              <span className="profile-heading">{`${userDeatils?.fname}'s basics`}</span>
              <div className="basics">
                <div className="badge">
                  <img src={locationImg} alt="location" />
                  {` ${userDeatils?.country}`}
                </div>
                <div className="badge">
                  <img src={age} alt="location" />
                  {` ${userDeatils?.age}`}
                </div>
                <div className="badge">
                  <img src={weight} alt="location" />{" "}
                  {`${userDeatils?.weight} KG`}
                </div>
                <div className="badge">
                  <img src={height} alt="location" />{" "}
                  {`${userDeatils?.height} cm`}
                </div>
                <div className="badge">
                  <img src={religion} alt="location" />{" "}
                  {`${userDeatils?.religion}`}
                </div>
                <div className="badge">
                  <img src={country} alt="location" />{" "}
                  {`${userDeatils?.ethnicity}`}
                </div>
                <div className="badge">
                  <img src={education} alt="location" />{" "}
                  {`${userDeatils?.education}`}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              {userDeatils?.userImg?.[1] && (
                <img
                  width="104px"
                  // className="ant-image-img"
                  height="104px"
                  style={{ objectFit: "cover" }}
                  src={userDeatils?.userImg[1]?.photoUrl}
                />
              )}
            </div>
            <div>
              <span className="profile-heading">Personality information</span>
              <div style={{ marginTop: "15px" }} className="basics">
                {PersonalityInfo &&
                  PersonalityInfo?.map((obj) => (
                    <span className="badge">{obj}</span>
                  ))}
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              {userDeatils?.userImg?.[2] && (
                <img
                  width="104px"
                  // className="ant-image-img"
                  height="104px"
                  style={{ objectFit: "cover" }}
                  src={userDeatils?.userImg[2]?.photoUrl}
                />
              )}
            </div>
            <div>
              <span className="profile-heading">My Interests</span>
              <div style={{ marginTop: "15px" }} className="basics">
                {interestArray &&
                  interestArray.map((obj) => (
                    <span className="badge">{obj}</span>
                  ))}
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              {userDeatils?.userImg?.[3] && (
                <img
                  width="104px"
                  // className="ant-image-img"
                  height="104px"
                  style={{ objectFit: "cover" }}
                  src={userDeatils?.userImg[3]?.photoUrl}
                />
              )}
            </div>
          </Spin>
        </div>
        <div className="tabs-container">
          <Tabs
            defaultActiveKey={tabOption}
            onChange={onChange}
            items={[
              {
                label: (
                  <span>
                    <img src={transactionIcon} alt="transaction icon" />
                    Transactions
                  </span>
                ),
                key: "1",
                children: (
                  <>
                    <div className="buttons-container">
                      <Input
                        style={{
                          width: "250px",
                          border: "1px solid #E6EBF1",
                          borderRadius: "8px",
                        }}
                        onChange={handleSearchInputTrans}
                        placeholder="Search by subscription type"
                        prefix={<SearchOutlined />}
                      />
                      {/* <Button
                        onClick={() => setIsModalOpenCoins(true)}
                        style={{
                          borderRadius: "10px",
                          background: "#1B75BC",
                          color: "#ffffff",
                        }}
                      >
                        Add Coins
                      </Button> */}
                      {/* <SearchInput handleSearchInput={handleSearchInputTrans}/> */}
                      {/* <FilterButton handledropdownclick={handledropdownclickTrans} keypath={keypath2} width={"150px"}/> */}
                    </div>
                    <Spin spinning={loading} tip="Loading..." size="large">
                      <TableComponent
                        transaction={userTransactionData.length > 0 && true}
                        columns={tabOnecolumns}
                        data={userTransactionData}
                      />
                    </Spin>
                  </>
                ),
              },
              {
                label: (
                  <span>
                    <img src={matchIcon} alt="Matches icon" />
                    Matches
                  </span>
                ),
                key: "MATCHES",
                children: (
                  <>
                    <div className="buttons-container">
                      <SearchInput handleSearchInput={handleSearchInputMatch} />
                      <FilterButtonWithoutGender
                        handledropdownclick={handledropdownclickMatch}
                        keypath={keypath}
                      />
                    </div>
                    <Spin spinning={loading} tip="Loading..." size="large">
                      <TableComponent
                        columns={tabTwocolumns}
                        data={userMatchData}
                      />
                    </Spin>
                  </>
                ),
              },
              {
                label: (
                  <span>
                    <img src={reportsIcon} alt="Matches icon" />
                    Reports
                  </span>
                ),
                key: "USERREPORT",
                children: (
                  <>
                    <div className="buttons-container">
                      <SearchInput
                        handleSearchInput={handleSearchInputReport}
                      />
                    </div>
                    <Spin spinning={loading} tip="Loading..." size="large">
                      <TableComponent
                        columns={tabThreecolumns}
                        data={userReportData}
                      />
                    </Spin>
                  </>
                ),
              },
              {
                label: (
                  <span>
                    <img src={conceirgeIcon} slt="transaction icon" />
                    Concierge
                  </span>
                ),
                key: "CONCIERGE",
                children: (
                  <>
                    <div className="flex-space-between">
                      <div className="buttons-container">
                        <SearchInput
                          handleSearchInput={handleSearchInputConcerige}
                        />
                        {/* <FilterButton items={conciergeTabSubmenuData} handledropdownclick={handledropdownclickConcerige} keypath={keypath1}/> */}
                      </div>
                      <div>
                        <CustomButton
                          title="+ Add Suggestion"
                          href={ADD_SUGGESTION_FORM}
                          data={userDeatils}
                          interest={PersonalityInfo}
                        />
                      </div>
                    </div>
                    <TableComponent
                      columns={tabFourcolumns}
                      data={conerigeData}
                    />
                  </>
                ),
              },
              {
                label: (
                  <span>
                    <img src={timeline} slt="transaction icon" />
                    Timeline
                  </span>
                ),
                key: "TIMELINE",
                children: (
                  <TabTimeline
                    data={userTimelineData}
                    lastActivityData={lastActivityData}
                  />
                ),
              },
              {
                label: (
                  <span>
                    <img src={InfoIcon} slt="transaction icon" />
                    Limits
                  </span>
                ),
                key: "LIMITS",

                children: <CardForLimit data={limitData} setIsModalOpenCoins={setIsModalOpenCoins} />,
              },
            ]}
          />
          <Modal
            centered
            width={700}
            title="Ban User"
            open={isModalOpen}
            footer={false}
            onCancel={handleCancel}
          >
            <Form
              onFinish={sendBanData}
              style={{ width: "80%" }}
              form={modalForm}
              layout="vertical"
            >
              <Form.Item name="ban_value">
                <Radio.Group
                  defaultValue={"true"}
                  onChange={(e) =>
                    setRequiredTrue(e.target.value === "true" ? true : false)
                  }
                >
                  <Radio value="true">
                    <span style={{ fontWeight: "600", fontSize: "14px" }}>
                      Temporary Ban
                    </span>
                  </Radio>
                  <Radio value="false">
                    <span style={{ fontWeight: "600", fontSize: "14px" }}>
                      Permanent Ban
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {requiredTrue && (
                <Form.Item
                  rules={[
                    {
                      required: requiredTrue,
                      message: "Ban Till is required",
                    },
                  ]}
                  style={{ fontWeight: "600", fontSize: "14px" }}
                  name="ban_till"
                  label="Ban Till"
                >
                  <DatePicker
                    disabledDate={(current) => {
                      return current && current < moment().startOf("day");
                    }}
                    stule={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              )}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Remark is required",
                  },
                ]}
                style={{ fontWeight: "600", fontSize: "14px" }}
                name="remarks"
                label="Remarks"
              >
                <TextArea />
              </Form.Item>
              <div style={{ display: "flex", gap: "10px", marginTop: "40px" }}>
                <div>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{ borderRadius: "7px" }}
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Form.Item noStyle>
                    <Button
                      style={{
                        backgroundColor: "#1B75BC",
                        borderRadius: "7px",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
          <Modal
            open={successModal}
            footer={false}
            onCancel={() => setSuccessModal(false)}
            centered
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <div>
                <img src={messageToShow.image} />
              </div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {" "}
                {messageToShow.message}
              </div>
              {messageToShow.showButton && (
                <div style={{ display: "flex", gap: "10px" }}>
                  {" "}
                  <Button
                    onClick={() => setSuccessModal(false)}
                    style={{ borderRadius: "7px" }}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "#1B75BC", borderRadius: "7px" }}
                    type="primary"
                    htmlType="submit"
                    onClick={popUpBanModal}
                  >
                    Unban
                  </Button>
                </div>
              )}
            </div>
          </Modal>
          {isModalOpenCoins && <Modal
            centered
            width={700}
            title="Add Coins"
            open={isModalOpenCoins}
            footer={false}
            onCancel={() => {
              modalFormForCoins.resetFields()
              setIsModalOpenCoins(false)
            }}
          >
            <Form
              onFinish={sendCoinData}
              style={{ width: "100%" }}
              form={modalFormForCoins}
              layout="vertical"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Number is required",
                  },
                 
                ]}
                name="givingcoinsbyadmin"
              >
                <InputNumber
                  onKeyPress={(e) => {
                    const value = e.target.value;

                    if (!/^\d*\.?\d*$/.test(e.key)) {
                      e.preventDefault(); // Prevent non-numeric input
                    }
                  }}
                  min={0}
                  style={{ width: "400px" }}
                  placeholder="No. of Coins"
                />
              </Form.Item>
              <div style={{ display: "flex", gap: "10px", marginTop: "40px" }}>
                <div>
                  <Button
                    onClick={() => {
                      modalFormForCoins.resetFields()
                      setIsModalOpenCoins(false)
                    }}
                    style={{ borderRadius: "7px" }}
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Form.Item noStyle>
                    <Button
                      style={{
                        backgroundColor: "#1B75BC",
                        borderRadius: "7px",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
}
        </div>
      </div>
    </>
  );
};

export default Transactions;

const customValidator = (rule, value, callback) => {
  const number = parseFloat(value);
  if (isNaN(number)) {
    callback("Please enter a valid number");
  } else if (number < 0) {
    callback("Number must be greater than 0");
  } else {
    callback();
  }
};
