import React, { useState,useEffect } from "react";
import { Link, useAsyncError, useLocation, useNavigate } from "react-router-dom";
import Parser from "html-react-parser";
import joompaApi from "../../utils/apis/joompaApi";
import { Popover, Spin,Modal,message } from "antd";
import {UPDATE_TEMPLATE } from "../../utils/Urls";
import FilterButton from "../../Components/Common/FilterButton";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import "./cms.css"

const ViewEmailTemplate = ({ data = "" }) => {
  const [loading,setLoading]=useState(false)
  const [content,setContent]=useState(data)
  const [sendLoading,setSendLoading]=useState(false)
const[editModal,setEditModal]=useState()
const [keypath, setKeypath] = useState("All");
const [target,setTarget]=useState('')
const [targetAudeins,setTargetAudeins]=useState('')
const [emailTemplateName,setEmailTemplateName]=useState('')
  const navigate = useNavigate();
  const location=useLocation()
  const {templateId}=location.state
  useEffect(() => {
    // console.log("tempalteID",templateId)
    getContent()   
  }, []);



  const handleSend = async () => {
    try {
      setSendLoading(true)
      const res = await joompaApi.get(
        `/sendEmailTemplates?emailName=${emailTemplateName}&target=${target}&targetAudience=${targetAudeins}`
      );
      if(res?.status===201){
        setSendLoading(false)
        message.success(res?.data?.message)
        setEditModal(false)
        setKeypath('All');
        setTarget("");
        setTargetAudeins('')
      }
      setSendLoading(false)
    } catch (error) {
      setSendLoading(false)
      message.error("Failed To Send Email Template")
    }
  };


  const getContent = async () => {
    setLoading(true);
    let res = await joompaApi.get(`/viewEmailTemplate/${templateId}`);
    let data = res?.data?.data;
    setEmailTemplateName(data?.name)
    console.log("email template data", data);
    data && setContent(data.content);
    setLoading(false);
  };
  const isConcerge=true
    const isPremium=true
    const submenuData = [
      {
        label: "All",
        key: "All",
      },
      {
        label: "Gender",
        key: "gender",
        children: [
          {
            label: "Male",
            key: "male",
          },
          {
            label: "Female",
            key: "female",
          },
        ],
      },
      {
        label: "Age",
        key: "age",
        children: [
          {
            label: "18-24",
            key: "18-24",
          },
          {
            label: "25-35",
            key: "25-35",
          },
          {
            label: "36-50",
            key: "36-50",
          },
          {
            label: "51-61",
            key: "51-61",
          },
          {
            label: "+62",
            key: "+62",
          },
        ],
      },
      {
        label: "Marital Status",
        key: "maritalStatus",
        children: [
          {
            label: "Not Married",
            key: "NotMarried",
          },
          {
            label: "Separated",
            key: "Separated",
          },
          {
            label: "Widowed",
            key: "Widowed",
          },
          {
            label: "Divorced",
            key: "Divorced",
          },
        ],
      },
      {
        label: "Country",
        key: "country",
        children: [
          {
            label: "Singapore",
            key: "Singapore",
          },
          {
            label: "Malaysia",
            key: "Malaysia",
          },
          {
            label: "Indonesia",
            key: "Indonesia",
          },
          {
            label: "Asian Countries",
            key: "asianCountries",
          },
          {
            label: "European Countries",
            key: "europeanCountries ",
          },
          {
            label: "Oceaania Countries",
            key: "oceaaniaCountries",
          },
          {
            label: "Africa Countries",
            key: "africaCountries",
          },
          {
            label: "North & South America",
            key: "northAndSouthAmerica",
          },
        ],
      },
      {
        label: "Premium",
        key: "isPremium",
        children: [
          {
            label: "True",
            key: isPremium,
          },
        ],
      },
      {
        label: "Concierge",
        key: "isConcerge",
        children: [
          {
            label: "True",
            key: isConcerge,
          },
        ],
      },
    ];



    const handledropdownclick = async ({ key, keyPath }) => {
        setKeypath(key);
        setTarget(keyPath[1]!=="All"?keyPath[1]:null)
        setTargetAudeins(keyPath[0]!=="All"?keyPath[0]:null)
        console.log("target",keyPath[1])
        console.log("targetAudeins",keyPath[0])
      };
const handleSendEmail=()=>{
  setEditModal(true)
}

const functionToCheck = (item)=>{
  switch(item){
    case 'Onboarding':
      return false;
    case 'Delete Request':
      return false;
    case 'Purchase Package':
      return false;
    case 'Delete Confirmation':
      return false;
    case 'Premium Purchase':
      return false;
    case 'Topup Purchase':
      return false;
    case 'Send Invitation':
      return false;
    default:
      return true;
  }
}


const functionToCheckInvitation = (item)=>{
  switch(item){
    case 'Onboarding':
      return false;
    case 'Delete Request':
      return false;
    case 'Purchase Package':
      return false;
    case 'Delete Confirmation':
      return false;
    case 'Premium Purchase':
      return false;
    case 'Topup Purchase':
      return false;
    default:
      return true;
  }
}


  return (
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
      {functionToCheckInvitation(emailTemplateName) &&<button
          className="save-button"
          style={{ background: "#1B75BC",marginRight:"20px"}}
          onClick={handleSendEmail}
        >
          Send Email <SendOutlined style={{marginLeft:"10px"}}/>
        </button>
}
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <Link to={UPDATE_TEMPLATE} style={{color:"white"}} state={{templateId:templateId}}>
        <button
          className="save-button"
          style={{ background: "#1B75BC" ,display:functionToCheck(emailTemplateName)?"":"none"}}
        >
        Edit
        </button>
        </Link>
      </div>
      <div className="white-card">
      <Spin spinning={loading} tip="Loading..." size="large">
        <div style={{ margin: "5% 15% 5% 25%" }}>{Parser(content)}</div>
      </Spin>
      </div>
      <Modal
        wrapClassName="accept-modal"
        centered
        title="Send Email Template"
        visible={editModal}
        footer={null}
        width={800}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setEditModal(false)}
      >
       <div>
            <label className="label-text">Target Audience</label>
            <FilterButton items={submenuData} handledropdownclick={handledropdownclick} keypath={keypath} showImg={false} width={"200px"}/>
          </div>
        <div style={{ marginTop: "5%" }}>
          <button className="back-button" onClick={() => setEditModal(false)}>
            Cancel
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={()=>handleSend()}
            // disabled={sendLoading}
          >
            {sendLoading ? <LoadingOutlined /> : "Send"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewEmailTemplate;
