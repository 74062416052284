import React from 'react'
import Icon from "@ant-design/icons/lib/components/Icon";
const UserCard = ({img,count,text,bgcolor}) => {
    const Styles={
        card:{
            width:"161px",
            height:"181px",
            border:"1px solid #E6EBF1",
            borderRadius:"8px"
        },
        icon:{
             height:"76px",
             width:"76px",
             backgroundColor:bgcolor,
             borderRadius:"50%",
             marginLeft:"25%",
             marginTop:"25px"
        },
        counts:{
            fontFamily:"Roboto",
            fontStyle:"normal",
            fontWeight:900,
            fontSize:"32px",
            lineHeight:"140%",
            textAlign:"center"
        },
        text:{
            fontFamily:"Poppins",
            fontStyle:"normal",
            fontWeight:500,
            fontSize:"16px",
            lineHeight:"140%",
            textAlign:"center",
            color:"#8A8A8A"
        }, 
    }
  return (
    <div style={Styles.card}>
        <div style={Styles.icon}><Icon style={{padding:"26px 21px 26px 21px"}} component={() => <img src={img} alt="Dashboard icon" />} /></div>
        <div style={Styles.counts}>{count}</div>
        <div style={Styles.text}>{text}</div>
    </div>
  )
}

export default UserCard