import React, { useState, useEffect } from "react";
import { PlusOutlined, LoadingOutlined,WarningOutlined } from "@ant-design/icons";
import { Input, message, DatePicker, Upload } from "antd";
import moment from "moment";
import axios from "axios";
import FormData from "form-data";
import joompaApi from "../../utils/apis/joompaApi";
import { useNavigate } from "react-router-dom";

// data.append('file', file, file.name);

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AdminProfieDetails = () => {
  const [values, setValues] = useState({
    profilePhoto: "",
    fullName: "",
    dateOfBirth: "",
    mobileNumber: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState();
  const [errorPhoto,setErrorPhoto]=useState(false)
  const [errorName,setErrorName]=useState(false)
  const [errorDob,setErrorDob]=useState(false)
  const [errorMobile,setErrorMobile]=useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
    let res = await joompaApi.get("/adminProfile");
    let data = res?.data?.data[0];
    setImageUrl(data?.profilePhoto);
    setValues({ ...values, ...data });
    setLoading(false);
    } catch (error) {
      // console.log(error)
    }
  };

  let formData = new FormData();
  const handleImageChange = (info) => {
    const file = info.fileList[0]?.originFileObj;
    if (file) {
      formData.append("file", file);
      setErrorPhoto(false)
      // console.log(formData);
      setFileList(file);
    }
    if (info?.fileList[0]) {
      getBase64(info.fileList[0].originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    name === "fullName" && value != "" && setErrorName(false);
    name === "mobileNumber" && value != "" && setErrorMobile(false);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onDateChange = (date, dateString) => {
    setErrorDob(false)
    setValues({
      ...values,
      dateOfBirth: date,
    });
  };

  const handleSaveInfo = async () => {
    // console.log(values);
    setLoading(true);
    if (!values.mobileNumber || !values.dateOfBirth || !values.fullName) {
      !values.mobileNumber && setErrorMobile(true)
      !values.dateOfBirth && setErrorDob(true)
      !values.fullName && setErrorName(true)
      setLoading(false);
    } else {
      try {
        formData.append("dateOfBirth", values.dateOfBirth);
        formData.append("fullName", values.fullName);
        formData.append("mobileNumber", values.mobileNumber);
        formData.append("file", fileList);
        const res = await joompaApi.put(
          "/admineditprofile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("save admin res", res.data);
        if (res?.data?.status === "success") {
          message.success(res?.data?.message, 5);
          setLoading(false);
          setTimeout(()=>{
            navigate(-1)
          },1000)
        }

        setLoading(false);
      } catch (error) {
        message.error(`Something Went Wrong`, 5);
        setLoading(false);
      }
    }
  };

  const disabledDate = (current)=>{
   return current && current >= moment().startOf("day")
  }
  return (
    <div style={{ padding: "0px 20px" }}>
      <div className="white-card" style={{ padding: "5px 16px 60px 16px" }}>
        <div>
          <label className="label-text">Profile Photo</label>
          <div
            style={{
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
              padding: "10px",
              width: "136px",
              height: "136px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // fileList={fileList}
              // action={avtarUpload}
              beforeUpload={() => false}
              maxCount={1}
              onChange={handleImageChange}
              status={errorPhoto && "error"}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <span
              style={{ marginLeft: "1px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorPhoto && <WarningOutlined />}
            </span>
            {/* <img src={UserAvatar} width="84px" height="84px" /> */}
          </div>
        </div>
        <div style={{ display: "flex", gap: "30%" }}>
          <div>
            <label className="label-text">Full Name</label>
            <Input
              style={{ width: "160%" }}
              className="input-box"
              onChange={handleInputChange}
              value={values.fullName}
              name="fullName"
              status={errorName && "error"}
            />
             <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorName && <WarningOutlined />}
            </span>
          </div>
          <div>
            <label className="label-text">Date of Birth</label>
            <DatePicker
              className="input-box"
              format="DD/MM/YYYY"
              placement="bottomLeft"
              placeholder="DD/MM/YYYY"
              style={{ width: "200%" }}
              onChange={onDateChange}
              disabled={true}
              disabledDate = {disabledDate}
              value={moment(values.dateOfBirth)}
              name="dateOfBirth"
              status={errorDob && "error"}
            />
             <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorDob && <WarningOutlined />}
            </span>
          </div>
        </div>

        <div style={{ display: "flex", gap: "30%" }}>
          <div>
            <label className="label-text">Mobile Number</label>
            <Input
              style={{ width: "160%" }}
              className="input-box"
              onChange={handleInputChange}
              value={values.mobileNumber}
              name="mobileNumber"
              status={errorMobile && "error"}
            />
             <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorMobile && <WarningOutlined />}
            </span>
          </div>

          <div>
            <label className="label-text">Email Address</label>
            <Input
              disabled={true}
              readOnly={true}
              style={{ width: "172%" }}
              className="input-box"
              value={values.email}
              name="email"
            />
          </div>
        </div>
      </div>
      <div className="save-back-button-add-feature">
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSaveInfo}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AdminProfieDetails;
