import React,{useState,useEffect} from "react";
import { Menu, Select,message } from "antd";
import "./dashboard.css";
import BarCharts from "../../Components/Common/BarCharts";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
const data = [
  {
    name: "Jan",
    uv: 4000,
  },
  {
    name: "Feb",
    uv: 3000,
  },
  {
    name: "Mar",
    uv: 2000,
  },
  {
    name: "Apr",
    uv: 2780,
  },
  {
    name: "May",
    uv: 1890,
  },
  {
    name: "June",
    uv: 2390,
  },
  {
    name: "July",
    uv: 3490,
  },
  {
    name: "May",
    uv: 1890,
  },
  {
    name: "June",
    uv: 2390,
  },
  {
    name: "July",
    uv: 3490,
  },
];

const Revenue = ({revenuData,headingData,setFilter,last,loading,handleClick}) => {
  // const [revenuData,setRevenuData]=useState(revenuCardData)
  // const [headingData,setHeadingData]=useState(revenuHeadingData)
  // const [filter,setFilter]=useState('Yearly')

  const handleChange = (value) => {
    setFilter(value)
    // console.log(`selected ${value}`);
  };
  let initialRender=false
useEffect(() => {
  if(initialRender){
    // getRevenuData()
  }
  initialRender=true
}, []);

const lastMonthYear=(last)=>{
  if(last==='Yearly'){
    return "Year"
  }
  else if(last==='Monthly'){
    return "Month"
  }
  else if(last==='Daily'){
    return "Day"
  }
}
  // const getRevenuData = async () => {
  //   try {
  //     let res = await joompaApi.get(`/revenuData?type=${filter}`);
  //     let data = res?.data;
  //     setHeadingData({
  //       percentage:data.total,
  //       totalCountCurrent:data.currentData
  //     })
  //     setRevenuData(data?.data);
  //   } catch (error) {
  //     message.error("Something Went Wrong");
  //   }
  // };

  const revenuePercentage = (item)=>{
    if(item!=="Infinity"){
      return Math.round(item*100)/100
    }
    else{
      return "0"
    }
    
  }
  return (
    <div className="white-card" style={{ height: "560px" }}>
      <div className="middleContainer">
        <div className="card-heading">Revenue</div>
        {/* <div style={Styles.topheading}>Monthly Subscriptions</div> */}
        <div style={{ padding: "24px" }}>
        {loading && <LoadingOutlined style={{marginRight:"10px"}}/>}
          <Select
            defaultValue="Yearly"
            bordered={false}
            style={{
              width: 120,
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            onChange={handleChange}
            options={[
              {
                value: "Daily",
                label: "Daily",
              },
              {
                value: "Monthly",
                label: "Monthly",
              },
              {
                value: "Yearly",
                label: "Yearly",
              },
            ]}
          />
        </div>
      </div>
      <div className="line"></div>
      <div
        style={{
          fontWeight: 800,
          fontSize: "48px",
          fontFamily: "Inter",
          marginLeft: "24px",
          fontStyle: "normal",
          lineHeight: "58px",
          textAlign: "justify",
        }}
      >
        {`S$ ${headingData.percentage && headingData.percentage}`}
      </div>
      <div
        style={{
          fontWeight: 700,
          fontSize: "16px",
          fontFamily: "Poppins",
          marginLeft: "24px",
          fontStyle: "normal",
          lineHeight: "140%",
        }}
      >
        <span style={{ color:headingData.totalCountCurrent!=="Infinity" && headingData.totalCountCurrent>0?"#4DAE47":"#F53434" }}>{revenuePercentage(headingData.totalCountCurrent)} % </span>{`than last ${lastMonthYear(last)}`}
      </div>
      <div style={{ padding: "3rem 0.5rem" }}>
        <BarCharts width={1050} fill={"#4DAE47"} data={revenuData} handleClick={handleClick} dataKeyX="_id" dataKeyY="price" />
      </div>
    </div>
  );
};

export default Revenue;
