import React, { useState, useEffect } from "react";
import { message,Spin } from "antd";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
import { content, formats, modules } from "../../utils/textEditorModules";
const FAQ = () => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    setLoading(true);
    const res = await joompaApi.get("/faq");
    const data = res?.data?.data;
    setValue(data.content);
    setLoading(false);
    // console.log("form data fetch", data);
  };
  const handleSave = async () => {
    setLoading(true);
    if (!value) {
      message.error("Content Required", 5);
    } else {
      try {
        const faqData = {
          title: "FAQ",
          content: value,
        };
        const res = await joompaApi.put("/faq", faqData);
        // console.log(res.data);
        message.success("Content Updated!", 5);
        setLoading(false);
      } catch (error) {
        message.error(`Something Went Wrong`, 5);
        setLoading(false);
        // console.log(error);
      }
    }
  };
  return (
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
      <div className="white-card">
        <Spin spinning={loading} tip="Loading..." size="large">
          <div style={{ marginLeft: "10%" }}>
            <label className="label-text">FAQ</label>
            <ReactQuill
              style={{
                width: "90%",
                borderRadius: "8px",
                paddingBottom: "10%",
              }}
              theme="snow"
              value={value}
              onChange={setValue}
              formats={formats}
              modules={modules}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default FAQ;
