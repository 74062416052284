import React, { useState,useEffect } from "react";
import "./subscriptions.css";
import { Input, Switch, message,Select, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined,WarningOutlined } from "@ant-design/icons";




export const getLimit=(Feature_type,data)=>{
  switch(Feature_type){
    case "CHAT":
      return data?.messages
    case "UNDO PROFILE":
      return data?.revokeProfile
    case "PROFILE SUGGESTIONS":
      return data?.suggestionLimit
    case "CONCIERGE":
      return data?.concierge
    case "AUDIO CALL":
      return data?.audioCallLimit
    case "VIDEO CALL":
      return data?.videoCallLimit
    default:
      return "value"
  }
}
export const getLimitName=(Feature_type)=>{
  switch(Feature_type){
    case "CHAT":
      return "messages"
    case "UNDO PROFILE":
      return "revokeProfile"
    case "PROFILE SUGGESTIONS":
      return "suggestionLimit"
    case "CONCIERGE":
      return "concierge"
    case "AUDIO CALL":
      return "audioCallLimit"
    case "VIDEO CALL":
      return "videoCallLimit"
    default:
      return "value"
  }
}

// const getLimitsName=(Feature_type)=>{
//   switch(Feature_type){
//     case "CHAT":
//       return "Message"
//     case "REVOKE PROFILE":
//       return "Profile"
//     case "PROFILE SUGGESTIONS":
//       return "Suggestion limit"
//     case "CONCIERGE":
//       return "Concierge Matches"
//     case "AUDIO CALL":
//       return "Call limit (in Minutes)"
//     case "VIDEO CALL":
//       return "Call limit (in Minutes)"
//     default:
//       return "Value"
//   }
// }


const ViewPackage = () => {
    const [featureType,setFeatureType]=useState('AUDIO CALL')
    const [expireType,setExpireType]=useState('DAY')
    const [active,setActive]=useState(false)
    const [values, setValues] = useState({});
    // for input according to feature type 
    const [limitInput,SetLimitInput] = useState({});
    const [coinsInput,SetCoinsInput] = useState({});
    // const [descInput,SetDescInput] = useState({});
    const [planTypeInput,SetPlanTypeInput] = useState({})
    const [expiryInput,SetExpiryInput] = useState({});
    const [featuredOptions,setFeatureOptions] = useState([]);
    const [limitName,setLimitName]= useState()
    const [packageCost,setPackageCost] = useState()
    const [exprireType,setExpiryType] = useState([])
    // end
    // const [errorDescription,setErrorDescription]=useState(false)
  const [errorPackageCost,setErrorPackageCost]=useState(false)
  const [errorExpireIn,setErrorExpireIn]=useState(false)
  // const [errorPlanType,setErrorPlanType]=useState(false)
  const [errorLimit,setErrorLimit]=useState(false)
  const [errorCoin , setErrorCoin] = useState(false)
  const [loading,setLoading] = useState(false)
const location=useLocation()
const {packageId,_id,featuredType}=location?.state
useEffect(() => {
    getPackageInfo()
}, []);    
   
useEffect(()=>{
  joompaApi.get("/featured-types").then((res)=>{
    setFeatureOptions(res.data.map((item)=>{
      return{
        ...item,
        label:item.featureType,
        value:item.featureType
      }
    }))
    
  })
  
},[])


useEffect(()=>{
  handleFeatureType(featureType)
},[featuredOptions,featureType])

const getPackageInfo=async()=>{
    try {
        setLoading(true)
        let res=""
        if(featuredType==="TOPUP"){
          res=await joompaApi.get(`/subscriptionsPackages/${packageId}?featuredType=TOPUP`)
          const data=res?.data?.data
          // SetLimitInput(false);
          // SetCoinsInput(true);
          setFeatureType(featuredType)
          // // SetDescInput(false);
          SetPlanTypeInput(false);
          // SetExpiryInput(false);
          setActive(data?.status)
        
          // const limitValue=getLimit(data?.featuredType,data)
          // console.log("response limitValue",limitValue)
          setValues({
            ...values,
            coin:data?.coin,
            packageCost:data?.price,
          
          })
        }
        else if(featuredType==="PREMIUM"){
          res=await joompaApi.get(`/subscriptionsPackages/${packageId}?featuredType=PREMIUM`)
          const data=res?.data?.data
          // SetLimitInput(false);
          // SetCoinsInput(false);
          // // SetDescInput(true);
          SetPlanTypeInput(true);
          // SetExpiryInput(true);
        
          setFeatureType(featuredType)
          setExpireType(data?.expireInType)
          setActive(data?.status)
          // const limitValue=getLimit(data?.featuredType,data)
          // console.log("response limitValue",limitValue)
          setValues({
            ...values,
            // description:data?.details,
            planType:data?.planType,
            packageCost:data?.price,
            expireIn:data?.expireIn,
          })
        }
        else{
          res=await joompaApi.get(`/subscriptionsPackages/${packageId}?planId=${_id}`)
          const data=res?.data?.data
          // SetLimitInput(true)
          // SetCoinsInput(false);
          // // SetDescInput(true);
          SetPlanTypeInput(true);
          SetExpiryInput(true);
      
          // // console.log("response of get package",data)
          setFeatureType(data?.featuredType)
          setExpireType(data?.expireInType)
          setActive(data?.status)
          const limitValue=getLimit(data?.featuredType,data)
          console.log("response limitValue",limitValue)
          setValues({
            ...values,
            // description:data?.description,
            limit:limitValue,
            planType:data?.planType,
            packageCost:data?.packageCost,
            expireIn:data?.expireIn,
          })
        }
    
        // console.log(res?.data?.data)
      
        // console.log("response data of response",data?.getLimitName(data?.featuredType))
        setLoading(false)
    } catch (error) {
        setLoading(false)
        message.error("Failed To Get Package Info")
    }
}
    const navigate = useNavigate();
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      // name==="description" && value!="" && setErrorDescription(false)
      packageCost && name==="packageCost" && value!="" && setErrorPackageCost(false)
      expiryInput && name==="expireIn" && value!="" && setErrorExpireIn(false)
      limitInput && name==="value" && value!="" && setErrorLimit(false)
      coinsInput && name==="coin" && value!="" && setErrorCoin(false)
      setValues({
        ...values,
        [name]: value,
      });
      
    };
    const handleSave = async() => {
      let flag = false;
      if(packageCost && !values.packageCost){
        flag = true;
        setErrorPackageCost(true)
      }
      if(coinsInput && !values.coin){
        flag = true;
        setErrorCoin(true)
      }
      if(limitInput && !values.limit){
        flag = true;
        setErrorLimit(true)
      }
      if(expiryInput && !values.expireIn){
        flag = true;
        setErrorExpireIn(true)
      }
      if(flag){
        return;
      }

      if(featureType==="PREMIUM"){
       
          try {
            // const key=getLimitName(featureType)
            // const limit={
            //   [key]:parseInt(values.limit)
            // }
            setLoading(true);
            const obj={
            
                  planType:values.planType,
                  price:parseFloat(values.packageCost).toFixed(2),
                  expireIn:parseInt(values.expireIn),
                  expireInType:expireType,             
              status:active,
            }
            // const obj2={
            //   featuredType:featureType,
            //   type: active?"ACTIVE":"INACTIVE"
            // }
            let res = await joompaApi.put(`/subscriptionsPackages/${packageId}?featuredType=PREMIUM`,obj);
        console.log("put package response",res)
        if(res?.data?.status==="success"){
          message.success(res?.data?.message, 5);
          setFeatureType('')
          setExpireType('')
          setActive(false)
          setValues({
            // description: "",
            packageCost: "",
            expireIn: "",
            planType: "",
            limit: '',
          })
          navigate(-1)
          setLoading(false)
        }
        //  let res2 = await joompaApi.put(`/activeOrInactive/${packageId}`,obj2); 
        // console.log(mapData);
        // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
        setLoading(false);
         
          } catch (error) {
            setLoading(false);
            message.error("Something Went Wrong", 5);
          }
          // console.log(values);

      }
      else if(featureType==="TOPUP"){
     
          try {
            // const key=getLimitName(featureType)
            // const limit={
            //   [key]:parseInt(values.limit)
            // }
            setLoading(true);
            const obj={
              
              
                
                  coin: parseInt(values.coin),
                  
                  price:parseFloat(values.packageCost).toFixed(2),
                  
               
              status:active,
            }
            const obj2={
              featuredType:featureType,
              type: active?"ACTIVE":"INACTIVE"
            }
            let res = await joompaApi.put(`/subscriptionsPackages/${packageId}?featuredType=TOPUP`,obj);
        console.log("put package response",res)
        if(res?.data?.status==="success"){
          message.success(res?.data?.message, 5);
          setFeatureType('')
          setExpireType('')
          setActive(false)
          setValues({
            // description: "",
            packageCost: "",
            expireIn: "",
            planType: "",
            limit: '',
          })
          navigate(-1)
          setLoading(false)
        }
         let res2 = await joompaApi.put(`/activeOrInactive/${packageId}`,obj2); 
        // console.log(mapData);
        // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
        setLoading(false);
         
          } catch (error) {
            setLoading(false);
            message.error("Something Went Wrong", 5);
          }
          // console.log(values);

      }

      else{
    
        try {
          const key=getLimitName(featureType)
          const limit={
            [key]:parseInt(values.limit)
          }
          setLoading(true);
          let planName="";
          if(featureType==="VIDEO CALL"){
            planName=limit.videoCallLimit+" MINUTES OF "+featureType
          }
          else if(featureType==="AUDIO CALL"){
            planName=limit.audioCallLimit+" MINUTES OF "+featureType
          }
          const obj={
            featuredType:featureType,
            plans:[
              {
                planName:planName===""?values.planName:planName,
                ...limit,
                planType:values.planType,
                packageCost:parseInt(values.packageCost),
                expireIn:parseInt(values.expireIn),
                expireInType:expireType
              }
            ],
            status:active,
          }

          const obj2={
            featuredType:featureType,
            type: active?"ACTIVE":"INACTIVE"
          }
          let res2;
          let res = await joompaApi.put(`/subscriptionsPackages/${packageId}?planId=${_id}`,obj);
      console.log("put package response",res)
      if(res?.data?.status==="success"){
        message.success(res?.data?.message, 5);
        setFeatureType('')
        setExpireType('')
        setActive(false)
        setValues({
          // description: "",
          packageCost: "",
          expireIn: "",
          planType: "",
          limit: '',
        })
        navigate(-1)
        setLoading(false)
      }
      
      res2 = await joompaApi.put(`/activeOrInactive/${packageId}?planId=${_id}`,obj2); 
    
      // console.log(mapData);
      // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
      setLoading(false);
       
        } catch (error) {
          setLoading(false);
          message.error("Something Went Wrong", 5);
        }

    }
    };
    
    const handleFeatureType = (value) => {
      console.log(value)
        featuredOptions.forEach((item)=>{
          if(item.value===value){
            SetLimitInput(item.limit)
            if(item.limit){
              setLimitName(item.limit_name)
            }
            SetCoinsInput(item.sgd_purchases)
            setPackageCost(item.coin_purchases)
            SetExpiryInput(item.timing_expire)
          }
        })
        if(value==="PREMIUM"){

          setExpireType("DAY")
          setExpiryType([
            {
              label: "DAY",
              value: "DAY",
            },
            {
              label: "WEEK",
              value: "WEEK",
            },
            {
              label: "MONTH",
              value: "MONTH",
            },
            {
              label: "YEAR",
              value: "YEAR",
            },
          ])
        }
        else{
          setExpireType("DAY")
          setExpiryType([
            {
              label: "MIN",
              value: "MINUTE",
            },
            {
              label: "HOUR",
              value: "HOUR",
            },
            {
              label: "DAY",
              value: "DAY",
            },
            {
              label: "WEEK",
              value: "WEEK",
            },
            {
              label: "MONTH",
              value: "MONTH",
            },
            {
              label: "YEAR",
              value: "YEAR",
            },
          ])
        }
      
      // if(value==="TOPUP"){
      //   SetLimitInput(false);
      //   SetCoinsInput(true);
      //   // SetDescInput(false);
      //   SetPlanTypeInput(false);
      //   SetExpiryInput(false);
       
      // }
      // else if(value==="PREMIUM"){
      //   SetCoinsInput(false);
      //   // SetDescInput(true);
      //   SetPlanTypeInput(true);
      //   SetExpiryInput(true);
       
      // }
      // else{
      //   SetLimitInput(true)
      //   SetCoinsInput(false);
      //   // SetDescInput(true);
      //   SetPlanTypeInput(true);
      //   SetExpiryInput(true);
       
      // }
      // setFeatureType(value);
    };
    const handleSwitch = (value) => {
      setActive(value);
    };
    const handleExpireType = (value) => {
      setExpireType(value);
    };
    return (
      <div style={{ padding: "0px 20px 0px 20px" }}>
        <Spin spinning={loading} size="large">
        <div className="form-container white-card">
          <div>
            <label className="label-text">Feature Type</label>
            <Select
              value={featureType}
              bordered={false}
              disabled={true}
              style={{
                width: 200,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              onChange={handleFeatureType}
              options={
                featuredOptions
                // [
                // {
                //   label: "AUDIO CALL",
                //   value: "AUDIO CALL",
                // },
                // {
                //   label: "VIDEO CALL",
                //   value: "VIDEO CALL",
                // },
                // {
                //   label: "CONCIERGE",
                //   value: "CONCIERGE",
                // },
                // {
                //   label: "PREMIUM",
                //   value: "PREMIUM",
                // },
                // {
                //   label: "CHAT",
                //   value: "CHAT",
                // },
                // {
                //   label: "REFER",
                //   value: "REFER",
                // },
                // {
                //   label: "TOPUP",
                //   value: "TOPUP",
                // },
                // {
                //   label: "REVOKE PROFILE",
                //   value: "REVOKE PROFILE",
                // },
                // {
                //   label: "PROFILE SUGGESTIONS",
                //   value: "PROFILE SUGGESTIONS",
                // },
                // // {
                // //   label: "INCOGNITO",
                // //   value: "INCOGNITO",
                // // },
                // {
                //   label: "PASSPORT",
                //   value: "PASSPORT",
                // },
              // ]
              }/>
          </div>
         {/* {descInput &&  <div>
            <label className="label-text">Description</label>
            <Input
              name="description"
              value={values.description}
              onChange={handleInputChange}
              placeholder="Description"
              style={{ width: "55%" }}
              className="input-box"
              status={errorDescription && "error"}
            />
             <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorDescription && <WarningOutlined />}</span>
          </div>
          } */}
          {coinsInput===true&&
          <div>
            <label className="label-text">Coin</label>
            <Input
              type="number"
              name="coin"
              min={0}
              value={values.coin}
              onChange={handleInputChange}
              placeholder="Coin"
              style={{ width: "55%" }}
              className="input-box"
              status={errorCoin && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorLimit && <WarningOutlined />}</span>
          </div>
          }
          {limitInput===true&&
          <div>
            <label className="label-text">{limitName}</label>
            <Input
              min={0}
              type="number"
              name="limit"
              value={values.limit}
              onChange={handleInputChange}
              placeholder="Limit"
              style={{ width: "55%" }}
              className="input-box"
              status={errorLimit && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorLimit && <WarningOutlined />}</span>
          </div>
          }
          {planTypeInput &&
          <div>
            <label className="label-text">Plan Type</label>
            <Input
              name="planType"
              value={values.planType}
              onChange={handleInputChange}
              placeholder="Eg - POPULAR , RECOMMENDED"
              style={{ width: "55%" }}
              className="input-box"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
              }}
              onKeyPress={(e) => {
                // const charCode = e.which || e.keyCode;
                  if(e.target.value.length>=14){
                    message.destroy()
                    message.warn("Plan Type should not be more than 14 characters")
                    e.preventDefault()
                  }
                  const charCode = e.which || e.keyCode;
  
                  if ((charCode === 45) || // ASCII code for minus sign
                    (charCode < 48 || charCode > 57) && charCode !== 8 && (charCode < 37 || charCode > 40)) {
                    
                  }
                  else{
                    e.preventDefault();
                  }
              }}
              // status={errorPlanType && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}></span>
          </div>
          }
          {packageCost && <div>
            <label className="label-text">Package Cost</label>
            <Input
            type="number"
            min={0}
              name="packageCost"
              value={values.packageCost}
              onChange={handleInputChange}
              placeholder="Package Cost"
              style={{ width: "55%" }}
              className="input-box"
              status={errorPackageCost && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorPackageCost && <WarningOutlined />}</span>
          </div>
}
          {expiryInput && 
          <div>
            
            <label className="label-text">Expiry In</label>
            <Input
            min={0}
              type="number"
              name="expireIn"
              value={values.expireIn}
              onChange={handleInputChange}
              placeholder="Expiry In"
              style={{ width: "55%" }}
              className="input-box"
              status={errorExpireIn && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorExpireIn && <WarningOutlined />}</span>
           <span style={{fontWeight:900,marginLeft:"7%",marginRight:"15px"}}>Expiry Type </span>
            <Select
            placement="topLeft"
              value={expireType}
              bordered={false}
              style={{
                width: 120,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              onChange={handleExpireType}
              options={exprireType}/>
            
          </div>
          }
          <div>
            <label className="label-text">Status</label>
            {/* <span> Inactive   </span> */}
            <Switch checked={active} onChange={handleSwitch}/>
            {/* <span> Active   </span> */}
          </div>
        </div>
        </Spin>
        <div className="save-back-button-add-feature">
          <button className="back-button" onClick={() => navigate(-1)}>
            Back
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={handleSave}
          >
            {loading ?<LoadingOutlined/>:"Save"}
          </button>
        </div>
      </div>
    );
}

export default ViewPackage