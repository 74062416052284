import React,{useState,useEffect} from 'react'
import WavingHand from "../../Assets/Images/icons/wavingHand.png"
import Icon from "@ant-design/icons/lib/components/Icon";
import joompaApi from '../../utils/apis/joompaApi';

const Styles={
    banner:{
        width:"100%",
        height:"116px",
        left:0,
        top:"50px",
        backgroundColor:"#E5E9FC",
        padding:"22px"
    },
    heading:{
      fontStyle: "normal",
      fontWeight:700,
      fontSize:"30px",
      lineHeight:"40px",
      letterSpacing:"-0.25px",
    },
    subheading:{  
      fontStyle: "normal",
      fontWeight:400,
      fontSize:"16px",
      lineHeight:"24px",
      letterSpacing:"-0.25px",
      color:"rgba(39, 44, 53, 0.8)"
    }
}

const Greetings = () => {// pass the props as user name with key is 'name'
const [adminName,setAdminName]=useState("")
  let myDate = new Date();
    let hours= myDate.getHours();
    let greet;
    useEffect(() => {
      getData();
    }, []);
  
    const getData = async () => {
      let res = await joompaApi.get("/adminProfile");
      let data = res?.data?.data[0]
      setAdminName(data?.fullName)
    };
      
    if (hours < 12)
        greet =  "Morning";
    else if (hours >= 12 && hours <= 17)
        greet = "Afternoon";
    else if (hours >= 17 && hours <= 24)
        greet = "Evening";

    return (
    <div style={Styles.banner}>
        <div style={Styles.heading}>Good {greet} {adminName},{<Icon component={() => <img src={WavingHand} width="35px" height="35px" alt="wavinghand icon" style={{marginTop:"-15px"}}/>}/>}</div> 
        <div style={Styles.subheading}>Here is what's happening at Joompa today</div>
    </div>
  )
}

export default Greetings