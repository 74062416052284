import { message } from 'antd';
import React, { useState } from 'react'
import { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"
import { getUserData } from '../../redux/action/action';
import { useReducer } from 'react';
import { useMemo } from 'react';

const ProtectedRoute = ({children}) => {
    let location = useLocation();
    const userData = useSelector(state=>state.userData);


    if(!userData.userData) {
        message.warn("You cannot access this page")
        return <Navigate to="/dashboard" state={{ from: location}} replace />
    }
 return children

};

export default ProtectedRoute;

