import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { message,Spin } from "antd";
import "react-quill/dist/quill.snow.css";
import { content, formats, modules } from "../../utils/textEditorModules";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
const AboutUs = () => {
  const [value, setValue] = useState();
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();

  useEffect(()=>{
    getContent()
  },[])


  const getContent= async()=>{
    try {
      setLoading(true)
    const res = await joompaApi.get("/aboutUs");
    const data = res?.data?.data;
    setValue(data.content)
    setLoading(false)
    } catch (error) {
      // console.log(error.message)
    }
    // console.log("form data fetch",data)
  }
  const handleSave = async() => {
    setLoading(true)
    if (
      !value
    ) {
      message.error("Content Required", 5);
    } 
    else {
      try {
        const aboutUsData={
          title:"About Us",
          content:value
        }
        const res = await joompaApi.put('/aboutUs',aboutUsData);
        // console.log(res.data)
        message.success("Content Updated!", 5);
        setLoading(false)
      } catch (error) {
        message.error(`Something Went Wrong`, 5);
        setLoading(false)
        // console.log(error);
      }
    }
  };
  return (
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
        >
           {loading ? <LoadingOutlined/>:"Save"}
        </button>
      </div>
      <div className="white-card">
      <Spin spinning={loading} tip="Loading..." size="large">
      <div style={{ marginLeft: "10%" }}>
          <label className="label-text">About Us</label>
          <ReactQuill
            style={{ width: "90%", borderRadius: "8px", paddingBottom: "10%" }}
            theme="snow"
            value={value}
            onChange={setValue}
            modules={modules}
          />
        </div>
      </Spin>
        
      </div>
    </div>
  );
};

export default AboutUs;
