import React, { useState, useEffect } from "react";
import CustomButton from "../../Components/Common/CustomButton";
import { Popover, Spin, message, Modal } from "antd";
import {
  MoreOutlined,
  EyeOutlined,
  DeleteOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TableComponent from "../../Components/Common/TableComponent";
import { ADD_NEW_TEMPLATE, VIEW_EMAIL_TEMPLATE } from "../../utils/Urls";
import { Link, useAsyncError, useLocation } from "react-router-dom";
import { formatDate } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import FilterButton from "../../Components/Common/FilterButton";

const EmailTemplates = () => {
  const [emailTemplateData, setEmailTemplateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [EmailTemplateId, setEmailTemplateId] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [keypath, setKeypath] = useState("All");
  const [target, setTarget] = useState("");
  const [targetAudeins, setTargetAudeins] = useState("");
  const [emailTemplateName, setEmailTemplateName] = useState("");
  const location = useLocation();
  useEffect(() => {
    getEmailTemplateData();
  }, []);

  const getEmailTemplateData = async () => {
    setLoading(true);
    let res = await joompaApi.get(`/emailTemplates`);
    let data = res?.data?.data;
    // console.log("email template data", data);
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // let convertedData;
    let url = window.location.href;
    if(url.includes("dhf-reddot.netlify.app/"))
    if(data){
      mapData = mapData.filter((item)=>functionToCheck(item.name))
    }

    data && setEmailTemplateData(mapData);
    setLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      let res = await joompaApi.delete(`/emailTemplates/${id}`);
      // console.log("delete Response",res);
      if (res?.status == 201) {
        message.success(res?.data?.message);
        // getScheduledData();
        getEmailTemplateData();
      }
    } catch (error) {
      message.error("Failed to Delete");
    }
  };

  const handleSendEmail = (emailId, tempalteName) => {
    setEmailTemplateId(emailId);
    setEmailTemplateName(tempalteName);
    setEditModal(true);
  };

  const handleSend = async () => {
    try {
      setSendLoading(true)
      const res = await joompaApi.get(
        `/sendEmailTemplates?emailName=${emailTemplateName}&target=${target}&targetAudience=${targetAudeins}`
      );
      if(res?.status===201){
        setSendLoading(false)
        message.success(res?.data?.message)
        setEditModal(false)
        setKeypath('All');
        setTarget("");
        setTargetAudeins('')
      }
      setSendLoading(false)
    } catch (error) {
      setSendLoading(false)
      message.error("Failed To Send Email Template")
    }
  };

  const isConcerge = true;
  const isPremium = true;
  const submenuData = [
    {
      label: "All",
      key: "All",
    },
    {
      label: "Gender",
      key: "gender",
      children: [
        {
          label: "Male",
          key: "male",
        },
        {
          label: "Female",
          key: "female",
        },
      ],
    },
    {
      label: "Age",
      key: "age",
      children: [
        {
          label: "18-24",
          key: "18-24",
        },
        {
          label: "25-35",
          key: "25-35",
        },
        {
          label: "36-50",
          key: "36-50",
        },
        {
          label: "51-61",
          key: "51-61",
        },
        {
          label: "+62",
          key: "+62",
        },
      ],
    },
    {
      label: "Marital Status",
      key: "maritalStatus",
      children: [
        {
          label: "Never Married",
          key: "Never Married",
        },
        {
          label: "Separated",
          key: "Separated",
        },
        {
          label: "Widowed",
          key: "Widowed",
        },
        {
          label: "Divorced",
          key: "Divorced",
        },
      ],
    },
    {
      label: "Country",
      key: "country",
      children: [
        {
          label: "Singapore",
          key: "Singapore",
        },
        {
          label: "Malaysia",
          key: "Malaysia",
        },
        {
          label: "Indonesia",
          key: "Indonesia",
        },
        {
          label: "Asian Countries",
          key: "asianCountries",
        },
        {
          label: "European Countries",
          key: "europeanCountries ",
        },
        {
          label: "Oceaania Countries",
          key: "oceaaniaCountries",
        },
        {
          label: "Africa Countries",
          key: "africaCountries",
        },
        {
          label: "North & South America",
          key: "northAndSouthAmerica",
        },
      ],
    },
    {
      label: "Premium",
      key: "isPremium",
      children: [
        {
          label: "True",
          key: isPremium,
        },
      ],
    },
    {
      label: "Concierge",
      key: "isConcerge",
      children: [
        {
          label: "True",
          key: isConcerge,
        },
      ],
    },
  ];
  const handledropdownclick = async ({ key, keyPath }) => {
    setKeypath(key);
    setTarget(keyPath[1] !== "All" ? keyPath[1] : null);
    setTargetAudeins(keyPath[0] !== "All" ? keyPath[0] : null);
    console.log("target", keyPath[1]);
    console.log("targetAudeins", keyPath[0]);
  };

  
  const functionToCheck = (item)=>{
    switch(item){
      case 'Onboarding':
        return false;
      case 'Delete Request':
        return false;
      case 'Purchase Package':
        return false;
      case 'Delete Confirmation':
        return false;
      case 'Premium Purchase':
        return false;
      case 'Topup Purchase':
        return false;
      case 'Send Invitation':
        return false;
      default:
        return true;
    }
  }
   const functionToCheckSend = (item)=>{
    switch(item){
      case 'Onboarding':
        return false;
      case 'Delete Request':
        return false;
      case 'Purchase Package':
        return false;
      case 'Delete Confirmation':
        return false;
      case 'Premium Purchase':
        return false;
      case 'Topup Purchase':
        return false;
      default:
        return true;
    }
  }

  const columns = [
    {
      title: "Template Name",
      dataIndex: "name",
      className: "col-normal",
      render(text){
        return{
          children:<div style={{display:"flex",gap:"10px",alignItems:"center"}}><div>{text}</div> {!functionToCheck(text) && <div style={{backgroundColor:"#1B75BC",padding:"0.1px 8px",borderRadius:"8px",fontSize:"11px",color:"white"}}>Fixed</div>}</div>
        }
      }
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text, true),
        };
      },
    },
    {
      title: "Last Update",
      dataIndex: "updatedAt",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.updatedAt - b.updatedAt,
      sortDirections: ["ascend", "descend"],
      width: "25%",
      className: "col-normal",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{formatDate(text, true)}</div>,
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              <Popover
                placement="bottomRight"
                content={
                  <>
                    <Link
                      to={VIEW_EMAIL_TEMPLATE}
                      style={{ color: "black" }}
                      state={{ templateId: record._id }}
                    >
                      <EyeOutlined /> View Details
                    </Link>
                   {functionToCheckSend(record.name) && <p style={{marginBottom:"0px !important"}}>
                      <button
                        style={{
                          marginLeft: "3px",
                          padding: "0px",
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          color: "rgb(27, 117, 188)",
                        }}
                        onClick={() => handleSendEmail(record._id, record.name)}
                      >
                        <SendOutlined style={{ color: "rgb(27, 117, 188)" }} /> Send
                      </button>
                    </p>
                      }
                    {/* <p style={{ marginTop: "0px",marginBottom:"0px !important"}}> */}
                     {functionToCheck(record.name)&& <div style={{ marginTop: "-15px"}}>
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDelete(record._id)}
                      >
                        <DeleteOutlined style={{ color: "red" }} /> Delete
                      </button>
                      </div>
                }
                    {/* </p> */}
                  </>
                }
                trigger="click"
                overlayInnerStyle={{
                  borderRadius: "12px",
                  textAlign: "center",
                }}
                overlayClassName="popover-menu"
                showArrow={false}
              >
                <Link>{text}</Link>
              </Popover>
            </div>
          ),
        };
      },
    },
  ];
  return (
    <div>
      <div style={{ float: "right" }}>
        <CustomButton title="Add New Template" href={ADD_NEW_TEMPLATE} />
      </div>
      <br />
      <br />
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent rowKey="_id" data={emailTemplateData} columns={columns} number={10} />
      </Spin>
      <Modal
        wrapClassName="accept-modal"
        centered
        title="Send Email Template"
        visible={editModal}
        footer={null}
        width={800}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setEditModal(false)}
      >
        <div>
          <label className="label-text">Target Audience</label>
          <FilterButton
            items={submenuData}
            handledropdownclick={handledropdownclick}
            keypath={keypath}
            showImg={false}
            width={"200px"}
          />
        </div>
        <div style={{ marginTop: "5%" }}>
          <button className="back-button" onClick={() => setEditModal(false)}>
            Cancel
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={() => handleSend()}
            // disabled={sendLoading}
          >
            {sendLoading ? <LoadingOutlined /> : "Send"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmailTemplates;
