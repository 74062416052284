export const headersForConcierge =[
    {label:"Phone Number", key:"phoneNumber"},
    {label:"First Name", key:"fname"},
    {label:"Last Name", key:"lname"},
    {label:"email", key:"email"},
    {label:"valid Upto",key:"concergeExpire"},
    {label:"Premium",key:"isPremium"}

]

export const headersForAllUser=[
    {label:"Phone Number", key:"phoneNumber"},
    {label:"Name", key:"fullName"},
    {label:"User Type",key:"isPremium"},
    {label:"last Activity Date",key:"lastActivityDate"},
    {label:"Member Since",key:"regDate"},
    {label:"account Status",key:"accountStatus"},
    {label:"Match Count",key:"matchCount"},
    {label:"Super Admin",key:"isSuperAdmin"},
    {label:"Total Spent",key:"totalSpent"},

]

export const headersForVerification=[
    {label:"Phone Number", key:"phoneNumber"},
    {label:"Name", key:"fullName"},
    // {label:"last Activity Date",key:"lastActivityDate"},
    {label:"Register Date",key:"regDate"},
    {label:"account Status",key:"accountStatus"},
    // {label:"Match Count",key:"matchCount"},
    // {label:"Super Admin",key:"isSuperAdmin"},
    // {label:"Total Spent",key:"totalSpent"},
    // {label:"Premium",key:"isPremium"}
    {label:"Verification Request",key:"verificationRequest"},
    {label:"verificationRequestDone",key:"verificationRequestDate"}
]

export const headerForPackage=[
    {label:"Plan Name", key:"planName"},
    {label:"Messages", key:"messages"},
    {label:"Revoke Profile",key:"revokeProfile"},
    {label:"Suggestion Limit",key:"suggestionLimit"},
    {label:"planType",key:"planType"},
    {label:"Package Cost",key:"packageCost"},
    {label:"Expire In",key:"expireIn"},
    {label:"Expire in type",key:"expireInType"},
    {label:"Status",key:"status"},
    {label:"Audio Call Limit",key:"audioCallLimit"},
    {label:"Video Call Limit",key:"videoCallLimit"},
    {label:"Feature Type",key:"featuredType"},
    {label:"Last Updated",key:"updatedAt"},
    {label:"Package Cost",key:"packageCost"}
]

export const headerForUserWallet =[
    {label:"fullName",key:"fullName"},
    {label:"Account Status",key:"accountStatus"},
    {label:"Verification Request",key:"verificationRequest"},
    {label:"Premium",key:"isPremium"},
    {label:"Phone Number",key:"phoneNumber"},
    {label:"Wallet Balance",key:"walletBalance"},
]

export const headerForPremiumSignupUser = [
    {label:"Name",key:"userName"},
    {label:"Premium",key:"premium"},
    {label:"Purchased On",key:"purchaseDate"},
    {label:"Valid Till",key:"validateUpto"},
    {label:"Phone Number",key:"phoneNumber"}
]

export const headersForFinanceReport=[
    {label:"Phone Number",key:"phoneNumber"},
    {label:"Name",key:"userName"},
    {label:"Payment Amount Of Coins",key:"paymentAmountOfCoins"},
    {label:"Total Payment",key:"totalPayment"},
    {label:"Coins Recived",key:"coinsRecived"},
    {label:"Payment Date",key:"paymentDate"}
]

export const headersForInactive=[
    {label:"Phone",key:"phoneNumber"},
    {label:"Name",key:"fullName"},
    {label:"User Type",key:"isPremium"},
    {label:"Active Since",key:"inactiveSince"},
    {label:"Inactive days",key:"inactiveDays"},
    {label:"Total Matches",key:"totalMatches"},
    {label:"Total Spent",key:"totalSpent"},
    {label:"Account Status",key:"accountStatus"}
]

export const headersForPremiumUsers=[
    {label:"Name",key:"fullName"},
    {label:"Premium",key:"premium"},
    {label:"Phone Number",key:"phoneNumber"},
    {label:"Amount spent",key:"amountSpent"},
    {label:"Purchase Date",key:"purchaseDate"},
    {label:"Expire Date",key:"expireDate"},
    {label:"Account Status",key:"accountStatus"}
]

export const headersForProfileDeletion=[
    {label:"Name",key:"fullName"},
    {label:"Phone Number",key:"phoneNumber"},
    {label:"Amount spent",key:"amountSpent"},
    {label:"Requested On",key:"requestedOn"},
    {label:"Reason",key:"reason"},
    {label:"Account Status",key:"accountStatus"}
]


export const headersForVerificationRequest=[
    {label:"Name",key:"fullName"},
    {label:"Phone Number",key:"phoneNumber"},
    {label:"Register Date",key:"regDate"},
    {label:"Account Status",key:"accountStatus"},
    {label:"Verification Request",key:"verificationRequest"},
    {label:"Verification Request Date",key:"verificationRequestDate"},
]

export const headersForEvents=[
    {label:"Event Name",key:"eventName"},
    {label:"eventCaption",key:"eventCaption"},
    {label:"Start Time",key:"startTime"},
    {label:"End Time",key:"endTime"},
    {label:"Event Date",key:"eventDate"},
    {label:"Building Name",key:"buildingName"},  
    {label:"Address",key:"address"},  
    {label:"About Event",key:"aboutEvent"},  
    {label:"Status",key:"eventStatus"},  
    {label:"Status Changed Date",key:"eventStatusChangeDate"},  
    {label:"Created on",key:"createdAt"},  
    {label:"Last Updated",key:"updatedAt"},  

]

export const headersForReport=[
    { label: "User",key:"UserName"},
    { label: "Reported By",key:"blockerReporterUserName"},
    { label: "Reported On", key:"date",},
    {label: "Reason",key: "reasion",},
    ];

export const headersForBanUser = [
    {label:"User" , key:"username"},
    {label:"Phone Number", key:"phoneNumber"},
    {label:"Banned Till",key:"bannedTill"},
    {label:"Remarks",key:"bannedRemark"}
]