import React, { useState, useEffect } from "react";
import { message, Alert, Spin,Modal } from "antd";
import sunrise from "../../Assets/Images/chatgame/sunrise.png";
import sunset from "../../Assets/Images/chatgame/sunset.png";
import "./cms.css";
import ChatGameCard from "./ChatGameCard";
import joompaApi from "../../utils/apis/joompaApi";
const UserChatGame = () => {
  const [loading, setLoading] = useState(false);
  const [chatGameData, setChatGameData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [openModal,setOpenModal]=useState(false)
  const handleClose = () => {
    setVisible(false);
  };
  const handleSave = () => {
    setVisible(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
   try {
    setLoading(true);
    let res = await joompaApi.get("/changeGameQuestionAnswers");
    let data = res?.data?.data;
    // console.log("whole data", data);
    setChatGameData(data);
    setLoading(false);
   } catch (error) {
    setLoading(false);
    message.error("Something Went Wrong")
   }
  };

  return (
    <>
      {visible && (
        <Alert
          message="Chat game question saved !"
          type="success"
          closable
          afterClose={handleClose}
          banner={true}
          style={{ backgroundColor: "#00ab6f" }}
        />
      )}
      <div style={{ padding: "0px 20px" }}>
        <div style={{ position: "absolute", top: "75px", right: "26px" }}>
          <button
            className="save-button"
            onClick={()=>setOpenModal(true)}
            style={{ background: "#1B75BC" }}
          >
            + Add New
          </button>
        </div>

        <Spin spinning={loading} tip="Loading..." size="large">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {chatGameData && chatGameData.map((obj,index) => (
              <ChatGameCard
              count={index+1}
              key={obj._id}
              fetchAgainData={getData}
              questionData={obj}
              id={obj._id} 
                />
            ))}
          </div>
        </Spin>
        {openModal &&   <Modal
        wrapClassName="accept-modal"
        centered
        title="Add New Chat Game Question"
        visible={openModal}
        footer={null}
        width={800}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setOpenModal(false)}
      >
        <div>
            <ChatGameCard isNew={true} setOpenModal={setOpenModal} fetchAgainData={getData}/>
        </div>
      </Modal>
      }
      </div>
    </>
  );
};

export default UserChatGame;
