import React, { useState,useEffect } from "react";
import { Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "../../utils/textEditorModules";
import ViewEmailTemplate from "./ViewEmailTemplate";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
import './cms.css'
const EditEmailTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [templateName, setTemplateName] = useState();
    const [templateSubject, setTemplateSubject] = useState();
    const [templateConetent, setTemplateConetent] = useState();
    const [errorName,setErrorName]=useState(false)
  const [errorSubject,setErrorSubject]=useState(false)
    const navigate = useNavigate();
    const location=useLocation()
    const {templateId}=location.state

    useEffect(() => {
        // console.log("tempalteID",templateId)
        getContent()   
      }, []);


      const getContent = async () => {
        try {
          setLoading(true);
          let res = await joompaApi.get(`/viewEmailTemplate/${templateId}`);
          let data = res?.data?.data;
          // console.log("email template data", data);
         setTemplateConetent(data.content);
          setTemplateName(data.name);
        setTemplateSubject(data.subject);
          setLoading(false);
        } catch (error) {
          message.error("something went wrong")
        }
      };
    const handleSave = async() => {
      if(!templateConetent || !templateName || !templateSubject ){
        return message.error("All Feild Are Required")
      }
      else{
        try {
          setLoading(true);
          const templateData={name:templateName,content:templateConetent,subject:templateSubject}
      // setEmailForm({...emailForm,content:value})
          const res=await joompaApi.put(`/emailTemplates/${templateId}`,templateData)
          // console.log(res?.data)
      message.success(res?.data?.message, 5);
      navigate("/cms/emailtemplates");
      setLoading(false)
        } catch (error) {
          setLoading(false)
          message.error(error.message)
        }
      }
      // setPreviewShown(true);
  
      // console.log(value);
    };
  
  
//   const handleInputChange=(e)=>{
//     const { name, value } = e.target;
//       // console.log(name,value)
//       setEmailForm({
//         ...emailForm,
//         [name]: value,
//       });
//   }

const functionToCheck = (item)=>{
  switch(item){
    case 'Onboarding':
      return false;
    case 'Delete Request':
      return false;
    case 'Purchase Package':
      return false;
    case 'Delete Confirmation':
      return false;
    case 'Premium Purchase':
      return false;
    case 'Topup Purchase':
      return false;
    case 'Send Invitation':
      return false;
    default:
      return true;
  }
}
    return (
      <>
      <div style={{ padding: "2px 20px 0px 20px" }}>
        <div style={{ position: "absolute", top: "75px", right: "26px" }}>
          <button className="back-button" onClick={() => navigate(-1)}>
            Back
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ?<LoadingOutlined/>:"Save"}
          </button>
        </div>
        <div className="white-card">
          <div style={{ marginLeft: "10%" }}>
            <div>
              <label className="label-text">Template Name</label>
              <Input
                placeholder="Template Name"
                style={{ width: "65%" }}
                className="input-box"
                name="name"
                disabled={!functionToCheck(templateName)?true:false}
                onChange={(e)=>setTemplateName(e.target.value)}
                value={templateName}
              />
            </div>
            <div>
              <label className="label-text">Subject</label>
              <Input
                placeholder="Subject"
                style={{ width: "65%" }}
                className="input-box"
                name="subject"
                value={templateSubject}
                onChange={(e)=>setTemplateSubject(e.target.value)}
              />
            </div>
            <div>
              <label className="label-text">Text</label>
              {/* <Input  value={templateConetent}
                onChange={(e)=>setTemplateConetent(e.target.value)}/> */}
              <ReactQuill
                style={{
                  width: "85%",
                  borderRadius: "8px",
                  paddingBottom: "10%",
                }}
                theme="snow"
                value={templateConetent}
                onChange={setTemplateConetent}
                formats={formats}
                modules={modules}
              />
            </div>
          </div>
        </div>
      </div>
      </>
      
    );
  };
export default EditEmailTemplate