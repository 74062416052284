import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/Common/CustomButton";
import { Popover, Switch, Spin, message } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import TableComponent from "../../Components/Common/TableComponent";
import { ADD_NEW_NOTIFICATION, EDIT_NOTIFICATION } from "../../utils/Urls";
import {
  formatDate,
  statusActiveStyle,
  statusRejectedStyle,
} from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import { Link } from "react-router-dom";

const ScheduledNotification = () => {
  const [scheduledData, setScheduledData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    getScheduledData();
  }, []);

  const handleSwitch = async (id, status,endDate) => {
    if(new Date(endDate)<new Date()){
      message.error("This notification has completed its cycle please change start and end date to activate this notification.");
      return;
    }
    setSwitchLoading(true);
    const upd_obj = scheduledData.map(obj => {
      if (obj._id == id) {
       obj.status=!status;
      }
      return obj;
     })
    setScheduledData(upd_obj)
    // status = !status;
    // console.log("handleSwitch", id, status);
    try {
      status = !status;
      let res = await joompaApi.put(`/adminCustomPromotions/cron/${id}`, {
        isOn: status ? 1 : 0,
      });
      if (res?.data?.status == "success") {
        message.success("Notification Update Successfully");
        setSwitchLoading(false);
        getScheduledData();
      }
    } catch (error) {
      const upd_obj = scheduledData.map(obj => {
        if (obj._id == id) {
         obj.status=!status;
        }
        return obj;
       })
      setScheduledData(upd_obj)
      setSwitchLoading(false);
      message.error("Failed to On Notification");
    }
  };

  const handleDelete = async (id) => {
    try {
      let res = await joompaApi.delete(`/adminCustomPromotions/${id}`);
      // console.log("delete Response", res);
      if (res?.data?.status == "success") {
        message.success(res?.data?.message);
        setOpen(false)
        getScheduledData();
      }
    } catch (error) {
      message.error("Failed to Delete");
    }
  };
  const columns = [
    {
      title: "Notification Title",
      dataIndex: "title",
      className: "col-normal",
      sorter: (a, b) => a.title < b.title,
      sortDirections: ["ascend","descend"],
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text, true),
        };
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      sortDirections: ["ascend","descend"],
      width: "25%",
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text, true),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status < b.status,
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      width: "10%",
      render(text, record) {
        return {
          children: (
            <div
              style={text === true ? statusActiveStyle : statusRejectedStyle}
            >
              {text ? "Active" : "Inactive"}
            </div>
          ),
        };
      },
    },
    {
      title: "Active/Inactive",
      dataIndex: "activeInactive",
      className: "col-normal",
      align: "center",
      render: (text, record) => (
        <>
          <Switch
            key={record._id}
            disabled={switchLoading}
            checked={record.status}
            onChange={() => handleSwitch(record._id, record.status,record.endDate)}
          />
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              <Popover
                placement="bottomRight"
                content={
                  <>
                    <Link
                      to={EDIT_NOTIFICATION}
                      style={{ color: "black" }}
                      state={{ id: record._id }}
                    >
                      <EditOutlined /> Edit
                    </Link>
                    <p>
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDelete(record._id)}
                      >
                        <DeleteOutlined style={{ color: "red" }} /> Delete
                      </button>
                    </p>
                  </>
                }
                trigger="click"
                overlayInnerStyle={{
                  borderRadius: "12px",
                  textAlign: "center",
                }}
                overlayClassName="popover-menu"
                showArrow={false}
              >
                <Link>{text}</Link>
              </Popover>
            </div>
          ),
        };
      },
    },
  ];

  const getScheduledData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/adminCustomPromotions`);
      let data = res?.data?.data;
      // console.log("email template data", data);
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setScheduledData(mapData);
      setOpen(true)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something Went Wrong");
    }
  };
  return (
    <div>
      <div style={{ float: "right" }}>
        <CustomButton
          title="Add New Notifications"
          href={ADD_NEW_NOTIFICATION}
        />
      </div>
      <br />
      <br />
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent rowKey="_id" data={scheduledData} columns={columns} />
      </Spin>
    </div>
  );
};

export default ScheduledNotification;
