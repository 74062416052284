import { ADD_SUGGESTION_FORM, TRANSACTIONS } from "./Urls";
import { Popover } from "antd";
import dollar from "../Assets/Images/tableIcons/dollar.png";
import like from "../Assets/Images/tableIcons/like.svg"
import incognito from "../Assets/Images/tableIcons/incognito 2.svg"
import premium from "../Assets/Images/tableIcons/premium.png";
import right from "../Assets/Images/tableIcons/right.png";
import chat from "../Assets/Images/tableIcons/chat.png";
import video from "../Assets/Images/tableIcons/video.png";
import Reward from "../Assets/Images/tableIcons/Coin topup.svg"
import love from "../Assets/Images/tableIcons/love.png";
import revoke from "../Assets/Images/tableIcons/revoke.png";
import passport from "../Assets/Images/tableIcons/passport.png";
import phone from "../Assets/Images/tableIcons/phone.png";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ColumnGroup from "antd/lib/table/ColumnGroup";
export const PopoverContent = (
  <>
    <Link to={TRANSACTIONS} style={{ color: "black" }}>
      <EyeOutlined /> View Details
    </Link>
    <p>
      <DeleteOutlined /> Delete
    </p>
  </>
);

const CustomPopover = ({
  text,
  hrefLink = TRANSACTIONS,
  formhrefLink = ADD_SUGGESTION_FORM,
  userId,
}) => {
  return (
    <Popover
      placement="bottomRight"
      content={
        <>
          <Link
            to={hrefLink}
            style={{ color: "black" }}
            state={{ formLink: formhrefLink, userId: userId }}
          >
            <EyeOutlined /> View Details
          </Link>
          {/* <p>
            <DeleteOutlined /> Delete
          </p> */}
        </>
      }
      trigger="click"
      overlayInnerStyle={{
        borderRadius: "12px",
        textAlign: "center",
      }}
      overlayClassName="popover-menu"
      showArrow={false}
    >
      <a>{text}</a>
    </Popover>
  );
};

export const statusActiveStyle = {
  background: "#EDFFF9",
  borderRadius: "6px",
  color: "#00AB6F",
  padding: "2px",
  width: "max-content",
  // fontWeight: 600
};
export const statusRejectedStyle = {
  background: "#FFE7E7",
  borderRadius: "6px",
  color: "#FF3B3B",
  padding: "2px",
  width: "max-content",
  // fontWeight: 600
};
export const statusPendingStyle = {
  background: "#FFF6EA",
  borderRadius: "6px",
  color: "#FF8F00",
  padding: "2px",
  width: "max-content",
  // fontWeight: 600
};

export const colorOfStatus = (status) => {
  switch (status) {
    case "ACTIVE":
      return statusActiveStyle;
      break;
    case "INCOGNITO":
      return statusPendingStyle;
      break;
    case "PENDING":
      return statusPendingStyle;
      break;
    case "REJECT":
      return statusRejectedStyle;
      break;
    case "REJECTED":
      return statusRejectedStyle;
      break;
    case "ACCEPT":
      return statusActiveStyle;
      break;
    case "APPROVED":
      return statusActiveStyle;
      break;
    case "EXPIRED":
        return statusRejectedStyle;
        break;
    case "INACTIVE":
        return statusRejectedStyle;
        break;
    case "SUCCESS":
      return statusActiveStyle;
      break;
    case "CREATED":
        return statusPendingStyle;
        break;
    case "FAILED":
        return statusRejectedStyle;
        break;
    default:
      return statusPendingStyle;
  }
};

export const formatDate = (date, showTime = false) => {
  if (date == null || undefined) {
    return "--";
  }
  let d = new Date(date);
  if (showTime) {
    const newDate =
      d.toLocaleDateString([], { dateStyle: "medium" }) +
      " " +
      d.toLocaleTimeString([], { timeStyle: "short" });
    return newDate;
  }
  return d.toLocaleDateString([], { dateStyle: "medium" });
};

export const subscriptionIcon = (text) => {
  switch (text) {
    case "TOPUP":
      return dollar;
    case "PREMIUM":
      return premium;
    case "AUDIO CALL":
      return phone
    case "VIDEO CALL":
      return video
    case "CONCIERGE":
      return love
    case "CHAT":
      return chat
    case "REFER":
      return right
    case "PROFILE SUGGESTIONS":
      return love
    case "REVOKE PROFILE":
      return revoke
    case "PASSPORT":
      return passport
    case "INCOGNITO":
      return incognito
    case "VIEW LIKES":
      return like
    case "REWARD":
      return Reward
  }
};

export const getExpireOn = (date, expireInType, expireIn) => {
  switch (expireInType) {
    case "DAY":
      return new Date(date + 3600 * 1000 * 24 * expireIn);
    case "WEEK":
      return new Date(date + 3600 * 1000 * 24 * 7 * expireIn);
    case "MONTH":
      return new Date(date + 3600 * 1000 * 24 * 7 * 30*expireIn);
    case "MINUTE":
      return new Date(date + 60000* expireIn);
    case "SECOND":
      return new Date(date + 1000 * expireIn);
    case "HOUR":
      return new Date(date + 3600 * 1000 * expireIn);
    case "YEAR":
      return new Date(date + 3600 * 1000 * 24 * 30 * 12 * expireIn);
    case null:
      return null;
    // case "MONTH":
  }
};



export const getExpiryStatus=(date)=>{
  // console.log("expiry Date",date)

  if(date==null){
    return "ACTIVE"
  }
  let myDate=new Date(date)
  let expiryDate=myDate.getTime()
let today=Date.now()
// console.log("Expiry Date",expiryDate)
// console.log("Today",today)
 if(expiryDate>=today){
  return "ACTIVE"
 }
 else{
  return "EXPIRED"
 } 
}


export const ageCalculator = (dob) => {
  var birthDate=dob;    	 
 // get difference from current date;
 var difference=Date.now() - birthDate.getTime(); 	 	 
 var  ageDate = new Date(difference); 
 var calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);
  
  return (calculatedAge);       
}


export const getTransactionStatus=(text,type)=>{
    console.log(text);
    if(type==="CREDIT" ){
    return <span style={{color:"#009648"}}>+{text}</span> 
    }
    else if(text===0){
      return <span style={{color:"#009648"}}>{text}</span> 
    }
    else{
     return  <span style={{color:"#F53434"}}>-{text}</span>
    }
  
}
export default CustomPopover;
