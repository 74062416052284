import { io } from "socket.io-client"; 
const URL = "https://api.letsjoompa.com/support"

export const socket = io(URL, {
    transports: ['websocket', 'polling'],
  });

// export const supportSocket = socket.of('/support');
  
// export const socket = io(URL, {
//     autoConnect: false
//   });

// socket.connect() 
// const io = new Server({
//     cors: {
//       origin: "http://localhost:3000"
//     }
//   });
//   join-room
//   io.listen(4000)





// const supportSocket = io.of('/support')
// supportSocket.on('connection',(socket) => {
//   console.log(`Connected to Support Socket - ${socket.id}`);
//   socket.on("support-join-room", async(data) => {
//     console.log("data",data)
//     socket.join(data.userId);
//     console.log(socket.rooms.has(data.userId))
//     const checkData = await supportChat.findOne({
//       userId: data.userId
//     })
//     // .populate("userId", "fname lname")
//     const getAdmin = await adminSchema.findOne()
//     const Name = await userSchema.findOne({
//       _id: data.userId
//     })
//     if (checkData == null) {
//       const saveUser = new supportChat({
//         userId: data.userId,
//         adminId: getAdmin._id,
//         status: "STARTED",
//         chat:[]
//       })
//       saveUser.save()
//       const obj = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${Name.fname} ${Name.lname} have some query.`
//       }
//       const allData = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${Name.fname} ${Name.lname} have some query.`,
//         adminId: getAdmin._id.toString(),
//         userId: data.userId
//       }
//       await notificationForAdmin("firebase token",obj,JSON.stringify(allData))
//     }
//     socket.emit("support-join-room", {
//       msg: "Join Sucessfully"
//     })
//   })
//   socket.on("support-leave-room", (data) => {
//     socket.leave(data.room_id);
//     socket.emit("support-leave-room", {
//       msg: "leave Successfully"
//     })
//   })
//   socket.on("send-message-support",async (data) => {
//     console.log(data)
//     const Chats = await supportChat.findOne({
//       userId: data.userId
//     }).populate("userId", 'fname lname')
//     console.log("Chats",Chats)
//     const Name = Chats.userId
//     const adminId = await adminSchema.findOne({})
//     if (data.from == "admin") {
//       const chatObj = {
//         message: data.message,
//         profile: data.profile,
//         date: new Date(),
//         to: "user",
//         from: "admin",
//         user_seen: false
//       }
//       await supportChat.updateOne({
//         userId: data.userId
//       },{
//         $push:{
//           chat:chatObj
//         }
//       })
//       socket.emit("send-message-support",{
//         data: chatObj
//       })
//       socket.to(data.userId).emit("send-message-support",{
//         data: chatObj
//       })
//       await pushNotification("messages", Chats.userId, {
//         ...chatObj,
//         messageData: chatObj,
//       });
//     } else if(data.from == "user") {
//       console.log(data.from)
//         if (Chats.chat.length === 0) {
//           var chat0 = {
//             message: "Hello, How can I help you, let's know the issue that your facing?",
//             to: 'user',
//             profile: "",
//             date: new Date(),
//             from: 'admin',
//             user_seen: false
//           }
//           const add = await supportChat.updateOne({
//             userId: data.userId
//           },{
//             $push: {
//               chat: chat0
//             }
//           })
//           // console.log(add)
//           //send notification to admin
//           const obj = {
//             title: `Support Query`,
//             body: `${chat0.message}`
//           }
//           const allData = {
//             title: `${Name.fname} ${Name.lname}`,
//             body: `${Name.fname} ${Name.lname} have some query.`,
//             adminId: adminId._id.toString(),
//             userId: data.userId
//           }
//           await notificationForAdmin("firebase token", Chats.adminId, JSON.stringify(allData));
//         }
//         const chatObj = {
//           message: data.message,
//           profile: data.profile,
//           date: new Date(),
//           to: "admin",
//           from: "user",
//           user_seen: false
//         }
//         await supportChat.updateOne({
//           userId: data.userId
//         },{
//           $push:{
//             chat:chatObj
//           }
//         })
//         socket.emit("send-message-support",{
//           data: chatObj
//         })
//         socket.to(data.userId).emit("send-message-support",{
//           data: chatObj
//         })
//         const obj = {
//           title: `${Name.fname} ${Name.lname}`,
//           body: `${data.message}`
//         }
//         const allData = {
//           title: `${Name.fname} ${Name.lname}`,
//           body: `${data.message} have some query.`,
//           adminId: adminId._id.toString(),
//           userId: data.userId
//         }
//         //send notification to admin
//         await notificationForAdmin("firebase token", obj, JSON.stringify(allData));
//     }
//   })
//   socket.on("get-all-messages" ,async(data)  => {
//     // console.log(data)
//     const userId = data.userId
//     const findingChatObj = await supportChat.findOne({
//       userId: userId
//     })
//     // console.log(findingChatObj)
//     if (findingChatObj === null) {
//       if (type == "user") {
//         var chat0 = {
//           message: "Hello, How can I help you, let's know the issue that your facing?",
//           to: 'user',
//           profile: "",
//           date: new Date(),
//           from: 'admin',
//           user_seen: true
//         }
//         // console.log(chat0)
//         socket.to(userId).emit("get-all-messages",{
//           data: [chat0],
//           userId: userId
//         })
//         socket.emit("get-all-messages",{
//           data: [chat0],
//           userId: userId
//         })
//       } else {
//         socket.emit("get-all-messages",{
//           data: findingChatObj.chat,
//           userId: userId
//         })
//         socket.to(userId).emit("get-all-messages",{
//           data: findingChatObj.chat,
//           userId: userId
//         })
//       }
//     } else {
//       socket.to(userId).emit("get-all-messages",{
//         data: findingChatObj,
//         userId: userId
//       })
//       socket.emit("get-all-messages",{
//         data: findingChatObj,
//         userId: userId
//       })
//     }
//   })
//   socket.on("end-chat",async (data) => {
//     console.log(data)
//     const ChatData = await supportChat.findOne({
//       userId: data.userId
//     }).populate("adminId").populate("userId", "fname lname")
//     console.log(ChatData)
//     const Name = ChatData.userId
//     const photo = ChatData.adminId
//     if(data.from == "admin") {
//       const chatObj = {
//         message: "This chat is ended by support team",
//         profile: photo.profilePhoto,
//         date: new Date(),
//         to: "user",
//         from: "admin",
//         user_seen: false
//       }
//       const updatingStatus = await supportChat.updateOne({
//         _id: ChatData._id
//       },{
//         $set: {
//           status: "ENDED"
//         },
//         $push: {
//           chat: chatObj
//         }
//       })
//       socket.emit("end-chat",{
//         data: chatObj
//       })
//       socket.to(data.userId).emit("end-chat",{
//         data: chatObj
//       })
//       await pushNotification("messages", ChatData.userId, {
//         ...chatObj,
//         messageData: chatObj,
//       });
//     } else if (data.from == "user") {
//       const chatObj = {
//         message: "This chat is ended by you",
//         profile: data.profile,
//         date: new Date(),
//         to: "user",
//         from: "admin",
//         admin_seen: false
//       }
//       const updatingStatus = await supportChat.updateOne({
//         _id: ChatData._id
//       },{
//         $set: {
//           status: "ENDED"
//         },
//         $push: {
//           chat: chatObj
//         }
//       })
//       socket.emit("end-chat",{
//         data: chatObj
//       })
//       socket.to(data.userId).emit("end-chat",{
//         data: chatObj
//       })
//       const obj = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${chatObj.message}`
//       }
//       const allData = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${chatObj.message}`,
//         adminId: ChatData.adminId.toString(),
//         userId: data.userId
//       }
//       //send notification to admin
//       await notificationForAdmin("firebase token", obj, JSON.stringify(allData));
//     }
//   })
//   socket.on("start-chat",async(data) => {
//     console.log(data)
//     const ChatData = await supportChat.findOne({
//       userId: data.userId
//     }).populate("adminId").populate("userId", "fname lname")
//     const Name = ChatData.userId
//     const photo = ChatData.adminId
//     if(data.from == "admin") {
//       const chatObj = {
//         message: "Chat started by support team",
//         profile: photo.profilePhoto,
//         date: new Date(),
//         to: "user",
//         from: "admin",
//         user_seen: false
//       }
//       const updatingStatus = await supportChat.updateOne({
//         _id: ChatData._id
//       },{
//         $set: {
//           status: "STARTED"
//         },
//         $push: {
//           chat: chatObj
//         }
//       })
//       socket.emit("start-chat",{
//         data: chatObj
//       })
//       socket.to(data.userId).emit("start-chat",{
//         data: chatObj
//       })
//       await pushNotification("messages", ChatData.userId, {
//         ...chatObj,
//         messageData: chatObj,
//       });
//     } else if (data.from == "user") {
//       const chatObj = {
//         message: "Chat started by you",
//         profile: data.profile,
//         date: new Date(),
//         to: "user",
//         from: "admin",
//         admin_seen: false
//       }
//       const updatingStatus = await supportChat.updateOne({
//         _id: ChatData._id
//       },{
//         $set: {
//           status: "STARTED"
//         },
//         $push: {
//           chat: chatObj
//         }
//       })
//       socket.emit("start-chat",{
//         data: chatObj
//       })
//       socket.to(data.userId).emit("start-chat",{
//         data: chatObj
//       })
//       const obj = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${chatObj.message}`
//       }
//       const allData = {
//         title: `${Name.fname} ${Name.lname}`,
//         body: `${chatObj.message}`,
//         adminId: ChatData.adminId.toString(),
//         userId: data.userId
//       }
//       //send notification to admin
//       await notificationForAdmin("firebase token", obj, JSON.stringify(allData));
//     }
//   })
//   socket.on('disconnect', () => {
//     console.log('user disconnected');
//   });
// })