import React,{useEffect, useState} from "react";
import { LoadingOutlined} from '@ant-design/icons';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./login.css";
import { Input,Checkbox,message, Button} from "antd";
import { MailOutlined,LockOutlined} from "@ant-design/icons";
import loginImage from "../../Assets/Images/loginImage.png";
import loginIcon from "../../Assets/Images/loginIcon.png";
import { Link, useNavigate } from "react-router-dom";
import {app} from "../../utils/firbase-config"
import joompaApi from "../../utils/apis/joompaApi"
// import { requestPermission } from "../../utils/firbase-config";
import {getMessaging , getToken ,onMessage} from "firebase/messaging"



const Login = ({setLogin}) => {
  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()
  const [values, setValues] = useState({adminId:"",adminPassword:""});
  const handleInputChange=(e)=>{
    const {name,value}=e.target
    // console.log(name,value)
    setValues({
      ...values,
      [name]: value,
    });
  }

const [remember,setRemember] = useState(false)
  const onChange = (e) => {
    setRemember(e.target.checked)
    // console.log(`checked = ${e.target.checked}`);
  };
  
  let currentTok;
const requestPermission=  ()=>{
    console.log("Requesting permission.....");
    Notification.requestPermission().then((permission)=>{
      if(permission==="granted"){
        console.log("Notificaion")
        const messaging = getMessaging(app);
        getToken(messaging,{vapidKey:"BKwOmadGXAPiwEDMUEa0wBh-IH1hnutVUiGzSboJJmdmXUyYANlYDNls8uGAlPgonwWXRe0ll-T29hcnJ0pTN1A"}).then((currentToken)=>{
    if(currentToken){
      console.log("firebase",currentToken)
        currentTok=currentToken;
    }
    else{
      console.log("cant get")
    }
  })
      }
      else{
        console.log("Do not have permission")
      }
    })
  }
  useEffect(()=>{
    requestPermission();
  },[])
const genrateTokenFromBackend= async(uid)=>{
  const res=await joompaApi.post('/adminsignin',JSON.stringify({
    uid:uid,
    fcmToken:currentTok
  }))
  // const res=await joompaApi.post('/adminsignin',uid)
 return res?.data?.data?.token
// if(res.data.token!=undefined ||res.data.token!=null){
//  return res.data.token}
}
 



  
const handleSignIn=async()=>{
  setLoading(true)
const auth = getAuth(app);
try {
 const userCredential= await signInWithEmailAndPassword(auth, values.adminId, values.adminPassword);
// console.log("after login data",userCredential)
 const user = userCredential.user;
//  console.log(`user UID:-${user.uid}`)
 const authToken=await genrateTokenFromBackend(user.uid)
 if(authToken){
  remember && localStorage.setItem('Auth_Token', authToken)
  setLogin(sessionStorage.setItem('Auth_Token', authToken))
  navigate("/dashboard")
  message.success('Login Sucess');
  window.location.reload(true);
 }
 else{
  message.error('User not found or marked as Inactive !');
 }
} catch (error) {
  const errorCode = error.code;
  const errorMessage = error.message;
  console.log(errorMessage)
  if (errorCode === 'auth/wrong-password') {
    message.error("Invalid Email or Password")
  }
}
setLoading(false);
}



  return (
    <div className="login-container">
      <div className="left-login">
        <img src={loginImage} alt="engge-image"/>
      </div>
      <div className="right-login">
        <div className="login-box">
          <div style={{textAlign: "center"}}>
          <div className="login-icon">
              <img src={loginIcon} alt="icon" 
        //       style={{
        //   width:"90%"
        // }}
        />
          </div>
          <div className="sign-in-head">
              SIGN IN
          </div>
          </div>
          <div>
            <label className="label-text">Username</label>
            <Input
              value={values.adminId}
              name="adminId"
              prefix={<MailOutlined />} 
              placeholder="Enter Email"
              style={{ width: "95%" }}
              className="input-box"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="label-text">Password</label>
            <Input.Password
              value={values.adminPassword}
              name="adminPassword"
              prefix={<LockOutlined />}
              placeholder="Enter Password"
              style={{ width: "95%" }}
              className="input-box"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"95%"}}> 
          <Checkbox style={{marginTop:"10px"}} onChange={onChange} value={remember}>Remember Me</Checkbox>
          <Link to="/forgot-password" style={{border:"none",padding:"0px",marginTop:"10px"}}>Forgot Password</Link>
          </div>
          </div>
          <button className="signin-button" onClick={handleSignIn} disabled={loading}>{loading?<LoadingOutlined style={{fontSize:"20px"}} />:"SIGN IN"}</button>
        </div>
        
      </div>
    </div>
  );
};

export default Login;
