import React,{useState,useEffect} from 'react'
import "./notification.css";
import { LogoutOutlined, EditOutlined } from "@ant-design/icons";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png"
import { Link, Navigate } from 'react-router-dom';
import { ADMIN_PROFILE_DETAILS } from '../../utils/Urls';
import { useNavigate } from "react-router-dom";
import joompaApi from '../../utils/apis/joompaApi';
const ProfileCard = ({setPopoverVisible}) => {
  const [adminInfo,setAdminInfo]=useState({})
  const navigate=useNavigate()
  const handleSignout=()=>{
    sessionStorage.removeItem("Auth_Token")
    localStorage.removeItem('Auth_Token')
    setPopoverVisible(false);
    navigate("/")
    window.location.reload(true);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await joompaApi.get("/adminProfile");
    let data = res?.data?.data[0]
    setAdminInfo(data)
  };
    return (
        <div className="profile-card">
          <div className="profile-menu-list">
            <div style={{ display: "flex" }}>
              <img src={adminInfo?.profilePhoto} width="40px" height="40px" style={{borderRadius:"50%",objectFit:"cover"}}/>
              <div style={{ marginLeft: "8px" }}>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "17px"
                  }}
                >
                  {adminInfo?.fullName}
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px"
                  }}
                >
                  System Admin
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "15px"
              }}
            >
              <Link to={ADMIN_PROFILE_DETAILS} onClick={()=>  setPopoverVisible(false)} style={{color: "black"}}>
                <EditOutlined /> <span style={{marginLeft:"8px"}}>Edit profile</span>
              </Link>
              <button onClick={handleSignout} style={{border:"none",backgroundColor:"white",cursor:"pointer"}}>
               <span style={{marginLeft:"-25px",marginRight:"10px"}}><LogoutOutlined /></span><span>Sign Out</span>
              </button>
            </div>
          </div>
        </div>
      );
}

export default ProfileCard