import React, { useState, useEffect } from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { MoreOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover, Image, Spin } from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import NonPremium from "../../Assets/Images/icons/Vector.svg"
import {
  SUBSCRIPTION_ADD_SUGGESTION_FORM,
  SUBSCRIPTION_USERDETAILS,
  TRANSACTIONS,
} from "../../utils/Urls";
import CustomPopover, { formatDate, colorOfStatus } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import Loading from "../../Components/Common/Loading";
import { headerForPremiumSignupUser } from "../../utils/Columns";

const content = (
  <div className="popover-menu">
    <a href={TRANSACTIONS}>
      <EyeOutlined /> View Details
    </a>
    <p>
      <DeleteOutlined /> Delete
    </p>
  </div>
);

const columns = [
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    width: "15%",
  },
  {
    title: "",
    dataIndex: "userAvatar",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "User Name",
    dataIndex: "userName",
    // defaultSortOrder: "ascend",
    sorter: (a, b) => a.userName < b.userName,
    sortDirections: ["ascend","descend"],
    width: "15%",
    className: "col-special",
    render(text, record) {
      return {
        props: {
          style: { background: "white" },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "User Type",
    dataIndex: "premium",
    className: "col-normal",
    width: "15%",
    align: "center",
    render(text) {
      return {
        children:
          text ? (
            <img
              width="24px"
              height="19px"
              src={premiumUsers}
              alt="user Type"
            />
          ) : (
            <img
              width="24px"
              height="19px"
              src={NonPremium}
              alt="user Type"
            />
          ),
      };
    },
  },
  {
    title: "Purchased On",
    dataIndex: "purchaseDate",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.signUpDate) - new Date(b.signUpDate),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    width: "25%",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Valid Till",
    dataIndex: "validateUpto",
    sorter: (a, b) => new Date(a.validateUpto) - new Date(b.validateUpto),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    width: "20%",
    render(text) {
      return <>{text===null ? '' : formatDate(text) }</>
      // console.log(text);
      // return {
      //   children: text!==null?formatDate(text):"-"
      // };
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "12%",
    align: "center",
    className: "col-normal",
    render: (text,record) => (
      <>
        <CustomPopover
          text={text}
          hrefLink={SUBSCRIPTION_USERDETAILS}
          formhrefLink={SUBSCRIPTION_ADD_SUGGESTION_FORM}
          userId={record.userId}
        />
        {/* <Popover placement="bottomRight" content={content} trigger="click">
          <a>{text}</a>
        </Popover> */}
      </>
    ),
  },
];

const PremimumSignup = () => {
  const [loading, setLoading] = useState(false);
  const [premuimSignupsData, setPremuimSignupsData] = useState([]);
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let data = premuimSignupsData.map((item) => {
      // Create a new object with the same properties as the original item
      let newItem = {
        ...item,
        validateUpto: formatDate(item.validateUpto),
        signUpDate: formatDate(item.signUpDate),
        purchaseDate: formatDate(item.purchaseDate),
        premium: item.premium ? "Yes" : "No",
      };
      return newItem;
    });
    
    // Now, set the newAllUsersData state with the modified array
    setNewAllUsersData(data);
  },[premuimSignupsData])


  
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let res = await joompaApi.get("/subscriptionsPremiumSignups");
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      ...obj.coinTransactions,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // console.log(mapData);
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setLoading(false);
    setPremuimSignupsData(mapData);
  };

  const handleSearchInput = async(e) => {
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/subscriptionsPremiumSignups?search=${query.trim()}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        ...obj.coinTransactions,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setPremuimSignupsData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };
  return (
    <div>
      <div className="buttons-container">
      <SearchInput handleSearchInput={handleSearchInput}/>
        {premuimSignupsData.length!==0 && <ExportButton data={newAllUsersData} passing={headerForPremiumSignupUser} />}
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent rowKey="userId" data={premuimSignupsData} columns={columns} />
      </Spin>
    </div>
  );
};

export default PremimumSignup;
