// import joompaApi from "../../utils/apis/joompaApi";
// import { MoreOutlined} from "@ant-design/icons";
import { ALL_USER_DATA_FETCH, ALL_USER_DATA_FETCH_SUCESS } from "../action/action.types";
import { initalState } from "./state";


const userManagementReducer = async(state=initalState.allUser, {type,payload}) => {
    switch (type) {
      case ALL_USER_DATA_FETCH:
        return {...state,state:payload}
      case ALL_USER_DATA_FETCH_SUCESS:
        return {...state,state:payload}
      default:
        return state
    }
  }
  
  export default userManagementReducer
