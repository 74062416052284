import React, { useEffect, useRef, useState } from "react";
import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { message, Input, Upload, Spin } from "antd";
import "./cms.css";
import axios from "axios";
import joompaApi from "../../utils/apis/joompaApi";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ChatGameCard = ({
  questionData,
  isNew = false,
  setOpenModal,
  fetchAgainData,
  count,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [optionOneId, setOptionOneId] = useState("");
  const [optionTwoId, setOptionTwoId] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [values, setValues] = useState({
    question: questionData?.question,
    option1: questionData?.options[0]?.answer,
    option2: questionData?.options[1]?.answer,
  });
  const [fileList1, setFileList1] = useState(questionData?.options[0]?.icon);
  const [fileList2, setFileList2] = useState(questionData?.options[1]?.icon);
  const [imageUrl1, setImageUrl1] = useState(questionData?.options[0]?.icon);
  const [imageUrl2, setImageUrl2] = useState(questionData?.options[1]?.icon);
  const [uploadImage, setUploadImage] = useState({
    firstImageUrl: questionData?.options[0]?.icon,
    secondImageUrl: questionData?.options[1]?.icon,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const uploadImageApi = async (file) => {
    setUploadLoading(true);
    const firstImageFormData = new FormData();
    firstImageFormData.append("file", file);
    try {
      const res = await joompaApi.post(
        `/uploadUserImage`,
        firstImageFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("fileUploadResponse", res);
      // console.log("ImageURL", res?.data?.data?.location);
      if (res.status === 200) {
        setUploadLoading(false);
        message.success(res?.data?.message);
        return res?.data?.data?.location;
        // fetchAgainData();
      }
    } catch (error) {
      setUploadLoading(false);
      message.error("Failed to Upload Image");
    }
  };
  const handleChangeFirstImg = async (info) => {
    // console.log("insideFirstImage");
    const file = info?.fileList[0]?.originFileObj;

    if (file) {
      const imageUrl = await uploadImageApi(file);
      setUploadImage({ ...uploadImage, firstImageUrl: imageUrl });
      // setImageUrl1(imageUrl)
      // console.log(formData);
      setFileList1(file);
    }
    if (info?.fileList[0]) {
      getBase64(info.fileList[0].originFileObj, (url) => {
        // setLoading(false);
        // setFileList1(file);
        setImageUrl1(url);
      });
    }
  };
  const handleChangeSecondImg = async (info) => {
    const file = info.fileList[0]?.originFileObj;
    // console.log("handleImageChage2", file);
    if (file) {
      const imageUrl = await uploadImageApi(file);
      setUploadImage({ ...uploadImage, secondImageUrl: imageUrl });
      setFileList2(file);
    }
    if (info?.fileList[0]) {
      getBase64(info.fileList[0].originFileObj, (url) => {
        setImageUrl2(url);
      });
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      const res = await joompaApi.delete(
        `/changeGameQuestionAnswers/${questionData?._id}`
      );
      // console.log("delete res", res);
      if (res.status === 201) {
        setDeleteLoading(false);
        message.success(res?.data?.message);
        fetchAgainData();
      }
    } catch (error) {
      setDeleteLoading(false);
      message.error("Failed To Delete Question");
    }
  };

  const handleSave = async () => {
    // console.log("question Data", questionData);
    setOptionOneId(questionData?.options?.[0]?._id);
    setOptionTwoId(questionData?.options?.[1]?._id);
    // console.log("question id and options", optionOneId, optionTwoId);
    // console.log("ICONS", fileList1, fileList2);
    if (
      !values?.question ||
      !values?.option1 ||
      !values?.option2 ||
      !fileList1 ||
      !fileList2
    ) {
      return message.error("Empty Input/Image Not Allowed");
    }

    if (isNew) {
      try {
        setLoading(true);
        const res = await joompaApi.post(`/changeGameQuestionAnswers`, {
          question: values.question,
          options: [
            {
              icon: uploadImage?.firstImageUrl,
              answer: values?.option1,
            },
            {
              icon: uploadImage?.secondImageUrl,
              answer: values?.option2,
            },
          ],
        });
        if (res.status === 201) {
          setLoading(false);
          message.success(res?.data?.message);
          setOpenModal(false);
          fetchAgainData();
          setFileList1("");
          setFileList2("");
          setValues({});
          setImageUrl1("");
          setImageUrl2("");
        }
        // setLoading(false)
      } catch (error) {
        setLoading(false);
        message.error("Failed To Add New Question");
      }
    } else {
      try {
        setLoading(true);
        const res = await joompaApi.put(
          `/changeGameQuestionAnswers/${questionData._id}`,
          {
            question: values.question,
            options: [
              {
                icon: uploadImage?.firstImageUrl,
                answer: values?.option1,
                _id: questionData?.options?.[0]?._id,
              },
              {
                icon: uploadImage?.secondImageUrl,
                answer: values?.option2,
                _id: questionData?.options?.[1]?._id,
              },
            ],
          }
        );
        // console.log("put response", res);
        if (res.status === 201) {
          setLoading(false);
          message.success(res?.data?.message);
          fetchAgainData();
        }
      } catch (error) {
        setLoading(false);
        message.error("Failed To Update Question");
      }
    }
  };

  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      {/* <PlusOutlined /> */}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div className="white-card chat-game-card">
      <div
        //  style={{ position: "absolute", top: "75px", right: "26px" }}
        style={{ float: "right" }}
      >
        {!isNew && (
          <button
            className="save-button"
            style={{ background: "#2b3044", color: "white" }}
            onClick={handleDelete}
            disabled={loading}
          >
            {deleteLoading ? (
              <LoadingOutlined />
            ) : (
              <>
                <DeleteOutlined style={{marginRight:"5px"}}/>
                Delete
              </>
            )}
          </button>
        )}
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
      {!isNew && (
        <Input
          style={{ width: "50px" }}
          readOnly
          className="chat-game-input"
          value={count}
        />
      )}
      <Input
        name="question"
        onChange={handleInputChange}
        placeholder="Type Here"
        className="chat-game-input"
        value={values?.question}
      />
      <div className="chat-game-img-container" >
        <div className="dashed-border-img" style={{width:"220px",textAlign:"center"}}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            // beforeUpload={beforeUpload}
            onChange={handleChangeFirstImg}
            beforeUpload={() => false}
            maxCount={1}
            accept="image/*"
          >
            {/* {questionData.imageUrl2 ? ( */}
            {imageUrl1 ? (
              <img
                src={imageUrl1}
                // src={questionData.imageUrl1}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div className="dashed-border-img" style={{width:"220px",textAlign:"center"}}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            // beforeUpload={beforeUpload}
            onChange={handleChangeSecondImg}
            beforeUpload={() => false}
            maxCount={1}
            accept="image/*"
          >
            {/* {questionData.imageUrl2 ? ( */}
            {imageUrl2 ? (
              <img
                src={imageUrl2}
                // src={questionData.imageUrl2}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </div>
      <div className="chat-game-img-container">
        <div>
          <Input
            name="option1"
            onChange={handleInputChange}
            placeholder="Type Here"
            className="chat-game-option-input"
            value={values?.option1}
            style={{width:"220px"}}
          />
        </div>
        <div>
          <Input
            name="option2"
            onChange={handleInputChange}
            placeholder="Type Here"
            className="chat-game-option-input"
            value={values?.option2}
            style={{width:"220px"}}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatGameCard;
