import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
// import { requestForToken } from './firebase';
import { onMessageListener } from './utils/firbase-config';
import { useNavigate } from 'react-router-dom';
const Notification = ({onHandleClick}) => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () =>  toast(<ToastDisplay/>);
  
  

  function ToastDisplay() {
    return (
      <div  onClick={()=>onHandleClick(notification)}>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])


  onMessageListener()
    .then((payload) => {
        console.log(
            "[firebase-messaging-sw.js] Received background message ",
            payload
          );
          const item = JSON.parse(payload.data.data)
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body,userId:item.userId});     
    })
    .catch((err) => console.log('failed: ', err));

  return (
     <Toaster 
     toastOptions={{
      duration: 8000,
    }}
     position="bottom-right"/>
  )
}

export default Notification
