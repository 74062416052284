import React, { useEffect, useState } from "react";
import "./subscriptions.css";
import { Input, Switch, message,Select } from "antd";
import { useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined,WarningOutlined } from "@ant-design/icons";

const initialValues = {
  featuretype: "",
  packageCost: "",
  expiryindays: "",
  expiryindaysUnlimted: false,
  credits: "",
  creditsUnlimited: false,
};

// const getLimitName=(Feature_type)=>{
//   switch(Feature_type){
//     case "CHAT":
//       return "Message"
//     case "REVOKE PROFILE":
//       return "Profile"
//     case "PROFILE SUGGESTIONS":
//       return "Suggestion limit"
//     case "CONCIERGE":
//       return "Concierge Matches"
//     case "AUDIO CALL":
//       return "Call limit (in Minutes)"
//     case "VIDEO CALL":
//       return "Call limit (in Minutes)"
//     default:
//       return "Value"
//   }
// }


const AddNewFeature = () => {
  const [featureType,setFeatureType]=useState('AUDIO CALL')
  const [expireType,setExpireType]=useState('DAY')
  const [active,setActive]=useState(false)
  const [values, setValues] = useState({});
  // const [errorDescription,setErrorDescription]=useState(false)
  const [errorPackageCost,setErrorPackageCost]=useState(false)
  const [errorExpireIn,setErrorExpireIn]=useState(false)
  const [errorPlanType,setErrorPlanType]=useState(false)
  const [errorvalue,setErrorvalue]=useState(false)
  const [loading,setLoading] = useState(false)
  const [errorCoin,setErrorCoin] = useState(false)
  const [featuredOptions,setFeatureOptions] = useState([]);


   // for input according to feature type 
   const [limitName,setLimitName]= useState()
   const [limitInput,SetLimitInput] = useState({});
   const [coinsInput,SetCoinsInput] = useState(false);
   const [descInput,SetDescInput] = useState({});
   const [planTypeInput,SetPlanTypeInput] = useState({})
   const [expiryInput,SetExpiryInput] = useState({});
   const [PackageCost,setPackageCost]=useState();
   const [exprireType,setExpiryType] = useState([])
   // end


  useEffect(()=>{
    joompaApi.get("/featured-types").then((res)=>{
      setFeatureOptions(res.data.map((item)=>{
        return{
          ...item,
          label:item.featureType,
          value:item.featureType
        }
      }))
      
    })
    
  },[])

  useEffect(()=>{
    handleFeatureType(featureType || "AUDIO CALL")
  },[featuredOptions])
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    PackageCost && name==="packageCost" && value!="" && setErrorPackageCost(false)
    expiryInput && name==="expireIn" && value!="" && setErrorExpireIn(false)
    limitInput && name==="value" && value!="" && setErrorvalue(false)
    coinsInput && name==="coin" && value!="" && setErrorCoin(false)
    setValues({
      ...values,
      [name]: value,
    });
    
  };
  const handleSave = async() => {
    let flag = false;
    if(PackageCost && !values.packageCost){
      flag = true;
      setErrorPackageCost(true)
    }
    if(coinsInput && !values.coin){
      flag = true;
      setErrorCoin(true)
    }
    if(limitInput && !values.value){
      flag = true;
      setErrorvalue(true)
    }
    if(expiryInput && !values.expireIn){
      flag = true;
      setErrorExpireIn(true)
    }

    if(flag){
      return;
    }
   if(featureType==="TOPUP"){

      try {
        setLoading(true);
        const obj={
         
          price:parseFloat(values.packageCost).toFixed(2),
          coin:parseInt(values.coin),
          status:active
        }
    let res = await joompaApi.post("/postCoinTopup",obj);
    // console.log("post response",res)
    if(res?.status===201){
      message.success(res?.data?.message, 5);
      setFeatureType('')
      setExpireType('')
      setActive(false)
      setValues({
        // description: "",
        packageCost: "",
        expireIn: "",
        planType: "",
        coin:"",
        value: '',
      })
      navigate(-1)
      setLoading(false)
    }
    // console.log(mapData);
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setLoading(false);
     
      } catch (error) {
        setLoading(false);
        message.error("Something Went Wrong", 5);
      }
      // console.log(values);
  
   }
   else if(featureType==="PREMIUM"){

      try {
        setLoading(true);
        const obj={
          planName:featureType,
          // details: values.description,
          planType:values.planType,
          expireIn:parseInt(values.expireIn),
          price:parseFloat(values.packageCost).toFixed(2),
          expireInType:expireType,
          status:active
        }
    let res = await joompaApi.post("/postPremium",obj);
    // console.log("post response",res)
    if(res?.status===201){
      message.success(res?.data?.message, 5);
      setFeatureType('')
      setExpireType('')
      setActive(false)
      setValues({
        // description: "",
        packageCost: "",
        expireIn: "",
        planType: "",
        value: '',
      })
      navigate(-1)
      setLoading(false)
    }
    // console.log(mapData);
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setLoading(false);
     
      } catch (error) {
        setLoading(false);
        message.error("Something Went Wrong", 5);
      }
      // console.log(values);

   }
   else{

      try {
        setLoading(true);
        const obj={
          ...values,
          expireIn:parseInt(values.expireIn),
          packageCost:parseInt(values.packageCost),
          featuredType:featureType,
          expireInType:expireType,
          status:active
        }
    let res = await joompaApi.post("/createSubscription",obj);
    // console.log("post response",res)
    if(res?.status===200){
      message.success(res?.data?.message, 5);
      setFeatureType('')
      setExpireType('')
      setActive(false)
      setValues({
        // description: "",
        packageCost: "",
        expireIn: "",
        planType: "",
        value: '',
      })
      navigate(-1)
      setLoading(false)
    }
    // console.log(mapData);
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setLoading(false);
     
      } catch (error) {
        setLoading(false);
        message.error("Something Went Wrong", 5);
      }
  }
  };
  
  const handleFeatureType = (value) => {
    setFeatureType(value);
    if(value==="TOPUP"){
      SetPlanTypeInput(false);
    }
    else{
      SetPlanTypeInput(true);
    }
    featuredOptions.forEach((item)=>{
      if(item.value===value){
        SetLimitInput(item.limit)
        if(item.limit){
          setLimitName(item.limit_name)
        }
        SetCoinsInput(item.sgd_purchases)
        setPackageCost(item.coin_purchases)
        SetExpiryInput(item.timing_expire)
      }
    })
    if(value==="PREMIUM"){
      setExpireType("DAY")
    setExpiryType([
      {
        label: "DAY",
        value: "DAY",
      },
      {
        label: "WEEK",
        value: "WEEK",
      },
      {
        label: "MONTH",
        value: "MONTH",
      },
      {
        label: "YEAR",
        value: "YEAR",
      },
    ])
  }
  else{
    setExpireType("DAY")
    setExpiryType([
      {
        label: "MIN",
        value: "MINUTE",
      },
      {
        label: "HOUR",
        value: "HOUR",
      },
      {
        label: "DAY",
        value: "DAY",
      },
      {
        label: "WEEK",
        value: "WEEK",
      },
      {
        label: "MONTH",
        value: "MONTH",
      },
      {
        label: "YEAR",
        value: "YEAR",
      },
    ])
  }

    // if(value==="PREMIUM"){
    //   SetLimitInput(false);
    //   SetCoinsInput(false);
    //   // SetDescInput(true);
    //   SetPlanTypeInput(true);
    //   SetExpiryInput(true);
    //  }
    //  else if(value==="TOPUP"){
    //   SetLimitInput(false);
    //           SetCoinsInput(true);
    //           // SetDescInput(false);
    //           SetPlanTypeInput(false);
    //           SetExpiryInput(false);
    //  }
    //  else{
    //   SetLimitInput(true);
    //   SetCoinsInput(false);
    //   // SetDescInput(true);
    //   SetPlanTypeInput(true);
    //   SetExpiryInput(true);
    //  }
  };
  const handleSwitch = (value) => {
    setActive(value);
  };
  const handleExpireType = (value) => {
    setExpireType(value);
  };
  return (
    <div style={{ padding: "0px 20px 0px 20px" }}>
      <div className="form-container white-card">
        <div>
          <label className="label-text">Feature Type</label>
          <Select
            defaultValue={{value:"AUDIO CALL",label:"AUDIO CALL"}}
            bordered={false}
            style={{
              width: 200,
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            onChange={handleFeatureType}
            options={featuredOptions
            //   [
            //   {
            //     label: "AUDIO CALL",
            //     value: "AUDIO CALL",
            //   },
            //   {
            //     label: "VIDEO CALL",
            //     value: "VIDEO CALL",
            //   },
            //   {
            //     label: "CONCIERGE",
            //     value: "CONCIERGE",
            //   },
            //   {
            //     label: "PREMIUM",
            //     value: "PREMIUM",
            //   },
            //   {
            //     label: "CHAT",
            //     value: "CHAT",
            //   },
            //   {
            //     label: "REFER",
            //     value: "REFER",
            //   },
            //   {
            //     label: "TOPUP",
            //     value: "TOPUP",
            //   },
            //   {
            //     label: "REVOKE PROFILE",
            //     value: "REVOKE PROFILE",
            //   },
            //   {
            //     label: "PROFILE SUGGESTIONS",
            //     value: "PROFILE SUGGESTIONS",
            //   },
            //   // {
            //   //   label: "INCOGNITO",
            //   //   value: "INCOGNITO",
            //   // },
            //   {
            //     label: "PASSPORT",
            //     value: "PASSPORT",
            //   },
            // ]
            }/>
        </div>
        {/* {descInput &&
        <div>
          <label className="label-text">Description</label>
          <Input
            name="description"
            value={values.description}
            onChange={handleInputChange}
            placeholder="Description"
            style={{ width: "55%" }}
            className="input-box"
            status={errorDescription && "error"}
          />
           <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorDescription && <WarningOutlined />}</span>
        </div>
        } */}
        {limitInput && 
            <div>
            <label className="label-text">{limitName}</label>
            <Input
              type="number"
              name="value"
              value={values.value}
              min={0}
              onChange={handleInputChange}
              placeholder={limitName}
              style={{ width: "55%" }}
              className="input-box"
              status={errorvalue && "error"}
            />
            <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorvalue && <WarningOutlined />}</span>
          </div>
 
        }
       {planTypeInput && 
        <div>
          <label className="label-text">Plan Type</label>
          <Input
            name="planType"
            value={values.planType}
            onChange={handleInputChange}
            placeholder="Eg - POPULAR , RECOMMENDED"
            style={{ width: "55%" }}
            className="input-box"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
            }}
            onKeyPress={(e) => {
              // const charCode = e.which || e.keyCode;
                if(e.target.value.length>=14){
                  message.destroy()
                  message.warn("Plan Type should not be more than 14 characters")
                  e.preventDefault()
                }
            }}
            // status={errorPlanType && "error"}
          />
          <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}></span>
          {/* <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorPlanType && <WarningOutlined />}</span> */}
        </div>
      }
    {coinsInput && 
      <div>
          <label className="label-text">Coin</label>
          <Input
          type="number"
            name="coin"
            value={values.coin}
            min={0}
            onChange={handleInputChange}
            placeholder="Coin"
            style={{ width: "55%" }}
            className="input-box"
            status={errorCoin && "error"}
          />
          <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorCoin && <WarningOutlined />}</span>
        </div>
      }
    {PackageCost && 
        <div>
          <label className="label-text">Package Cost</label>
          <Input
          type="number"
            name="packageCost"
            value={values.packageCost}
            onChange={handleInputChange}
            placeholder="Package Cost"
            min={0}
            style={{ width: "55%" }}
            className="input-box"
            status={errorPackageCost && "error"}
          />
          <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorPackageCost && <WarningOutlined />}</span>
        </div>
  }
        {expiryInput && 
        <div>
          <label className="label-text">Expiry In</label>
          <Input
            type="number"
            name="expireIn"
            value={values.expireIn}
            onChange={handleInputChange}
            min={0}
            placeholder="Expiry In"
            style={{ width: "55%" }}
            className="input-box"
            status={errorExpireIn && "error"}
          />
          <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorExpireIn && <WarningOutlined />}</span>
         <span style={{fontWeight:900,marginLeft:"7%",marginRight:"15px"}}>Expiry Type </span>
          <Select
            // defaultValue="DAY"
            value={expireType}
            bordered={false}
            style={{
              width: 120,
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            placement="topLeft"
            onChange={handleExpireType}
            options={exprireType}/>
        </div>
      }
        <div>
          <label className="label-text">Status</label>
          {/* <span> Inactive   </span> */}
          <Switch checked={active} onChange={handleSwitch}/>
          {/* <span> Active   </span> */}
        </div>
      </div>
      <div className="save-back-button-add-feature">
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
        >
          {loading ?<LoadingOutlined/>:"Save"}
        </button>
      </div>
    </div>
  );
};

export default AddNewFeature;
