import React, { useState, useEffect } from "react";
import UserCardOfStackedBar from "./UserCardOfStackedBar";
import Greetings from "./Greetings";
import MonthlySubscriptions from "./MonthlySubscriptions";
import SubscriptionsByCategory from "./SubscriptionsByCategory";
import TopPremiumUsers from "./TopPremiumUsers";
import TopProfiles from "./TopProfiles";
import totalUsers from "../../Assets/Images/dashboard/totalUsers.png";
import femaleUsers from "../../Assets/Images/dashboard/femaleUsers.png";
import maleUsers from "../../Assets/Images/dashboard/maleUsers.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import UserStatistics from "./UserStatistics";
import CountrySignups from "./CountrySignups";
import Revenue from "./Revenue";
import "./dashboard.css";
import axios from "axios";
import joompaApi from "../../utils/apis/joompaApi";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_USER, DASHBOARD_DATA,DASHBOARD_DATA_REVENUE,DASHBOARD_DATA_NEW_USER, DASHBOARD_COUNTRY_SIGNUP,DASHBOARD_DATA_RELIGION } from "../../utils/Urls";
import { LoadingOutlined } from "@ant-design/icons";

const currentYear = new Date().getFullYear();

const Dashboard = () => {
  const [loadingForSubscription ,setLoadingForSubscriptions] = useState(false)
  const [loadingForSubscription2 ,setLoadingForSubscriptions2] = useState(false)
  const [loadingForRevenue,setLoadingForRevenue] = useState(false);
  const [LoadingForStackedBar1,setLoadingForStackedBar1] = useState(false);
  const [LoadingForStackedBar2,setLoadingForStackedBar2] = useState(false);
  const [LoadingForStackedBar3,setLoadingForStackedBar3] = useState(false);
  const [LoadingForStackedBar4,setLoadingForStackedBar4] = useState(false);
  const [ageGroupData, setAgeGroupData] = useState([]);
  const [relationshipStatusData, setRelationshipData] = useState([]);
  const [religionData, setReligionData] = useState([]);
  const [premuimSubscription, setPremuimSubscription] = useState([]);
  const [userStatisticsData, setUserStatisticsData] = useState([]);
  const [topPremiumUsers, setTopPremiumUsers] = useState([]);
  const [topProfiles, setTopProfiles] = useState([]);
  const [revenuData, setRevenuData] = useState([]);
  const [countrySignupsData, setCountrySignupsData] = useState([]);

  const [revenuHeadingData, setRevenuHeadingData] = useState({
    percentage: 0,
    totalCountCurrent: 0,
  });
  const [newUserSigupData, setNewUserSignupData] = useState([]);
  const [subscriptionsByCategoryData, setSubscriptionsByCategoryData] =
    useState([]);
  const [premiumFilter, setPremiumFilter] = useState("Yearly");
  const [userSignupFilter, setuserSignupFilter] = useState("Yearly");
  const [revenuFilter, setRevenuFilter] = useState("Yearly");
  const [ageGroupYear, setAgeGroupYear] = useState(currentYear);
  const [relationshipYear, setRelationshipYear] = useState(currentYear);
  const [religionUserYear, setReligionUserYear] = useState(currentYear);
  const [countryYear, setCountryYear] = useState(currentYear);
  const [newUserPrecentage, setNewUserPrecentage] = useState({
    percentage: 0,
    totalCountCurrent: 0,
  });
  const [premiumHeadingData, setPremiumHeadingData] = useState({
    percentage: 0,
    totalCountCurrent: 0,
  });
  const [initialRender,setInitialRender]=useState(true)
  const navigate=useNavigate()
  // let initialRender = true;
  useEffect(() => {
    if (initialRender) {
      getAllDashboardData();
      setInitialRender(false)
      // initialRender = false;
    } else {
      getAgeGroupData();
      // getrelationshipStatusData();
      // getReligionData();
      // getPremiumSubscriptionData();
      // getNewUserSignupData()
      // getRevenuData()
      // getCountrySignupsData()
    }
  }, [
    // premiumFilter,
    ageGroupYear,
    // relationshipYear,
    // religionUserYear,
    // userSignupFilter,
    // revenuFilter,
    // countryYear,
  ]);

  useEffect(() => {
      getPremiumSubscriptionData();
  }, [
    premiumFilter
  ]);

  useEffect(() => {
    getrelationshipStatusData();
}, [
  relationshipYear,
]);

useEffect(() => {

    getReligionData();
}, [

  religionUserYear,
]);

useEffect(() => {
    getNewUserSignupData()
    // getRevenuData()
    // getCountrySignupsData()
}, [
  userSignupFilter,
  // revenuFilter,
  // countryYear,
]);


useEffect(() => {
  getRevenuData()
}, [
revenuFilter,
]);

useEffect(() => {
  getCountrySignupsData()
}, [

countryYear,
]);




  const getAgeGroupData = async () => {
    setLoadingForStackedBar1(true)
    let res = await joompaApi.get(`/ageGroupUsers?year=${ageGroupYear}`);
    let data = res?.data?.data;
    setAgeGroupData(data);
    // console.log("ageGroupUersData",data)
    setLoadingForStackedBar1(false)
  };
  const getrelationshipStatusData = async () => {
    setLoadingForStackedBar2(true)
    let res = await joompaApi.get(
      `/relationshipStatus?year=${relationshipYear}`
    );
    let data = res?.data?.data;
    setRelationshipData(data);
    setLoadingForStackedBar2(false)
    // console.log("setRelationshipData",data)
  };

  const getReligionData = async () => {
    setLoadingForStackedBar3(true)
    let res = await joompaApi.get(`/religionOfUsers?year=${religionUserYear}`);
    let data = res?.data?.data;
    setReligionData(data);
    // console.log("setReligionData",data)
    setLoadingForStackedBar3(false)
  };

  const getAllDashboardData = async () => {
    try {
      let res = await joompaApi.get(`/getAllDashboardData?type=Yearly`);
      // console.log("dashbaordall data", res?.data);
      const userStaticsdata = [
        {
          img: totalUsers,
          count: res?.data?.userStatistics?.totalUsersCount,
          text: "Total Users",
          bgcolor: "#EEF8FF",
          filter:false
        },
        {
          img: premiumUsers,
          count: res?.data?.userStatistics?.premiumUsersCount,
          text: "Premium Users",
          bgcolor: "#E9FDE8",
          filter:false
        },
        {
          img: maleUsers,
          count: res?.data?.userStatistics?.maleUsersCount,
          text: "Male Users",
          bgcolor: "#FFEEED",
          menu:"gender",
          submenu:"male",
          filter:true,
        },
        {
          img: femaleUsers,
          count: res?.data?.userStatistics?.femaleUserCount,
          text: "Female Users",
          bgcolor: "#FEEFD0",
          menu:"gender",
          submenu:"female",
          filter:true
        },
      ];
      setUserStatisticsData(userStaticsdata);
      setAgeGroupData(res?.data?.ageGroupUsers);
      setSubscriptionsByCategoryData(res?.data?.subscriptionByCategory);
      setRelationshipData(res?.data?.relationshipStatus);
      setPremiumHeadingData({
        percentage: res?.data?.subscriptionData?.currentData==="Infinity"?"0":res?.data?.subscriptionData?.currentData,
        totalCountCurrent: res?.data?.subscriptionData?.total,
      });
      if (res?.data?.subscriptionData?.data?.length !== 0) {
        const premuimData = res?.data?.subscriptionData?.data?.map((obj) => ({
          period: obj?._id,
          count: obj?.count,
        }));
        setPremuimSubscription(premuimData);
      } else {
        setPremuimSubscription([]);
      }
      setNewUserPrecentage({
        percentage: res?.data?.newUserSignups?.percentage,
        totalCountCurrent: res?.data?.newUserSignups?.totalCountCurrent,
      });
      // console.log("New user sign up data",newUserPrecentage)
      if (res?.data?.newUserSignups?.data?.length !== 0) {
        const newUserData = res?.data?.newUserSignups?.data?.map((el) => {
          return {
            period: el._id.period,
            count: el.count,
          };
        });
        setNewUserSignupData(newUserData);
      } else {
        setNewUserSignupData([]);
      }
      // setPremuimSubscription(premuimData)
      setReligionData(res?.data?.religionOfUsers);
      const mapData = res?.data?.topPremiumUsers?.map((obj) => ({
        ...obj,
        name: `${obj.fname} ${obj.lname}`,
      }));
      setTopPremiumUsers(mapData);
      setTopProfiles(res?.data?.topProfiles);
      setRevenuData(res?.data?.revenuData?.data);
      setRevenuHeadingData({
        percentage: res?.data?.revenuData?.total,
        totalCountCurrent: res?.data?.revenuData?.currentData,
      });
      setCountrySignupsData(res?.data?.countrySignups);
      // setPremiumHeadingData({
      //   percentage:res?.data.previosData,
      //   totalCountCurrent:res?.data?.currentData
      // })
      // console.log("susbrption in dashbaord state",subscriptionsByCategoryData)
      // console.log("susbrption in dashbaord",res?.data?.subscriptionByCategory)
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };

  const getPremiumSubscriptionData = async () => {
    try {
      setLoadingForSubscriptions(true)
      let res = await joompaApi.get(`/subscriptionData?type=${premiumFilter}`);
      // console.log("premiuim response",res?.data);
      setPremiumHeadingData({
        percentage: res?.data?.currentData==="Infinity"?"0":res?.data?.currentData,
        totalCountCurrent: res?.data?.total,
      });
      let data = res?.data?.data;
      if (data.length !== 0) {
        const mapData = data.map((el) => {
          return {
            period: el._id,
            count: el.count,
          };
        });
        setPremuimSubscription(mapData);
      } else {
        setPremuimSubscription([]);
      }
      setLoadingForSubscriptions(false)
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };
  const getNewUserSignupData = async () => {
    try {
      setLoadingForSubscriptions2(true)
      let res = await joompaApi.get(`/newUserSignups?type=${userSignupFilter}`);
      let data = res?.data;
      setNewUserPrecentage({
        percentage: data.percentage,
        totalCountCurrent: data.totalCountCurrent,
      });
      // console.log("New user sign up data",newUserPrecentage)
      if (data?.data.length !== 0) {
        const mapData = data?.data?.map((el) => {
          return {
            period: el._id.period,
            count: el.count,
          };
        });
        setNewUserSignupData(mapData);
      } else {
        setNewUserSignupData([]);
      }
      setLoadingForSubscriptions2(false)
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };

  const getRevenuData = async () => {
    try {
      setLoadingForRevenue(true);
      let res = await joompaApi.get(`/revenuData?type=${revenuFilter}`);
      let data = res?.data;
      setRevenuHeadingData({
        percentage: data.total,
        totalCountCurrent: data.currentData,
      });
      const revenue = data?.data?.map((el) => {
        return{
          ...el,
          price: Number(el.price.toFixed(2))
        }
      })
      setRevenuData(revenue);
      setLoadingForRevenue(false);
    } catch (error) {
      message.error("Something Went Wrong");
    }
  };
  const getCountrySignupsData = async () => {
    try {
      // setLoading(true)
      setLoadingForStackedBar4(true)
      let res = await joompaApi.get(`/countrySignups?year=${countryYear}`);
      let data = res?.data?.data;
      setCountrySignupsData(data);
      // setLoading(false)
      setLoadingForStackedBar4(false)
    } catch (error) {
      // setLoading(false)
      message.error(error);
    }
  };


const handleClickOfBarAgeGroup=(entry,index)=>{
  const {payload}=entry
  const obj={filter:true,menu:"ageGroup",submenu:payload?.ageGroup}
 navigate(ALL_USER,{
  state: {
    ...obj
  }
}) 
  // console.log("Stacked bar Clicked paload",payload)
}

const handleClickOfBarRealtionShip=(entry,index)=>{
  const {payload}=entry
  const obj={filter:true,menu:"maritalStatus",submenu:payload?._id}
 navigate(ALL_USER,{
  state: {
    ...obj
  }
}) 
  console.log("Stacked bar Clicked paload",payload)
}

const handleClickOfReligionOfUser = async(entry,index)=>{
  const {payload}=entry
  try {
    const res=await joompaApi.get(`/religionOfUsersRedirect?year=${religionUserYear}&religion=${payload?._id}`)
    console.log("bar click res",res)
    if(res?.status===201){
      console.log(res.data)
      const data= res?.data?.data;
      console.log(data);
      navigate(DASHBOARD_DATA_RELIGION,{
        state: {
         data:data
        }
      }) 
    }
  } catch (error) {
    console.log("Can't Fetch Data",error)
  }
}

const handleClickOfCountrySignups =async(entry,index)=>{
  console.log(entry)
  const {payload}=entry
  try {
    const res=await joompaApi.get(`countrySignupsRedirect?year=${countryYear}&country=${payload?._id}`)
    console.log("bar click res",res)
    if(res?.status===201){
      console.log(res.data)
      const data= res?.data?.data;
      console.log(data);
      navigate(DASHBOARD_COUNTRY_SIGNUP,{
        state: {
         data:data
        }
      }) 
    }
  } catch (error) {
    console.log("Can't Fetch Data",error)
  }
}

const handleClickORevenue=async(entry,index)=>{
  console.log("bar click",entry)
  const {payload}=entry
  try {
    const res=await joompaApi.get(`/revenuDataRedirect?type=${revenuFilter}&date=${payload?._id}`)
    console.log("bar click res",res)
    if(res?.status===200){
      const data = res.data.data
      console.log(data);
      navigate(DASHBOARD_DATA_REVENUE,{
        state: {
         data:data
        }
      }) 
    }
  } catch (error) {
    console.log("Can't Fetch Data",error)
  }
  const obj={menu:userSignupFilter,submenu:payload?.period}
  console.log("Stacked bar Clicked paload",payload)
}


const handleClickOfPremiumSubscriptions=async(entry,index)=>{
  // console.log("bar click",entry)
  console.log("in premium")
  const {payload}=entry
  try {
    const res=await joompaApi.get(`/premiumDataRedirect?type=${premiumFilter}&date=${payload?.period}`)
    // console.log("bar click res",res.status)
    if(res?.status===200){
      console.log(res.data?.data)
      const data = res.data?.data
      navigate(DASHBOARD_DATA,{
        state: {
         data:data
        }
      }) 
    }
  } catch (error) {
    console.log("Can't Fetch Data",error)
  }
  const obj={menu:userSignupFilter,submenu:payload?.period}
  console.log("Stacked bar Clicked paload",payload)
}


const handleClickOfBarNewUserSignups=async(entry,index)=>{
  // console.log("bar click",entry)
  const {payload}=entry
  try {
    const res=await joompaApi.get(`/newUserSignupsRedirect?type=${userSignupFilter}&date=${payload?.period}`)
    console.log("bar click res",res)
    if(res?.data?.status==='success'){
      const data= res?.data?.data
      navigate(DASHBOARD_DATA_NEW_USER,{
        state: {
         data:data
        }
      }) 
    }
  } catch (error) {
    console.log("Can't Fetch Data",error)
  }
  const obj={menu:userSignupFilter,submenu:payload?.period}
  console.log("Stacked bar Clicked paload",payload)
}
  return (
    <>
      <Greetings />

      <div className="container">
        <div className="item1">
          <MonthlySubscriptions
            data={premuimSubscription}
            dataKeyX="period"
            dataKeyY="count"
            setFilter={setPremiumFilter}
            last={premiumFilter}
            headingData={premiumHeadingData}
            loading={loadingForSubscription}
            handleClick={handleClickOfPremiumSubscriptions}
          />
          
        </div>
        <div className="item2">
          <UserStatistics data={userStatisticsData} />
        </div>
        <div className="item3">
          <Revenue
           loading={loadingForRevenue}
            revenuData={revenuData}
            headingData={revenuHeadingData}
            setFilter={setRevenuFilter}
            last={revenuFilter}
            handleClick={handleClickORevenue}
          />
        </div>
        <div className="item4">
          <SubscriptionsByCategory data={subscriptionsByCategoryData} />
        </div>
        <div className="item5">
          <MonthlySubscriptions
            title="New User Sign Up"
            data={newUserSigupData}
            loading={loadingForSubscription2}
            dataKeyX="period"
            dataKeyY="count"
            setFilter={setuserSignupFilter}
            last={userSignupFilter}
            headingData={newUserPrecentage}
            handleClick={handleClickOfBarNewUserSignups}
          />
        </div>
        <div className="item6">
          <TopPremiumUsers data={topPremiumUsers} />
        </div>
        <div className="item7">
          <TopProfiles data={topProfiles} />
        </div>
      </div>
      <div className="bottomContainer">
        <UserCardOfStackedBar
          loading={LoadingForStackedBar1}
          title={"Age Group Users"}
          bottomTitle={"Age Group"}
          data={ageGroupData}
          dataKey1={"male"}
          dataKey2={"female"}
          dataKeyX={"ageGroup"}
          setYearState={setAgeGroupYear}
          handleClick={handleClickOfBarAgeGroup}
        />
        <UserCardOfStackedBar
        loading={LoadingForStackedBar2}
          title={"Relationship Status"}
          bottomTitle={"Relationship Status"}
          data={relationshipStatusData}
          dataKey1={"male"}
          dataKey2={"female"}
          dataKeyX={"_id"}
          setYearState={setRelationshipYear}
          handleClick={handleClickOfBarRealtionShip}
        />
        <UserCardOfStackedBar
        loading={LoadingForStackedBar3}
          title={"Religion Of Users"}
          bottomTitle={"Religion"}
          data={religionData}
          dataKey1={"male"}
          dataKey2={"female"}
          dataKeyX={"_id"}
          setYearState={setReligionUserYear}
          handleClick={handleClickOfReligionOfUser}
        />
        <CountrySignups
        loading={LoadingForStackedBar4}
          countrySignupsData={countrySignupsData}
          setYearState={setCountryYear}
          handleClick={handleClickOfCountrySignups}
        />
      </div>
    </>
  );
};

export default Dashboard;
