import React, { useEffect, useState } from 'react'
import SearchInput from '../../Components/Common/SearchInput';
import { Image, Spin, message } from 'antd';
import TableComponent from '../../Components/Common/TableComponent';
import joompaApi from '../../utils/apis/joompaApi';
import { formatDate } from '../../utils/Constant';
import ExportButton from '../../Components/Common/ExportButton';
import { Link } from 'react-router-dom';
import { TRANSACTIONS } from '../../utils/Urls';
import { headersForReport } from '../../utils/Columns';
import ReadMore from "../../Components/Common/ReadMore";
import Message from "../../Assets/Images/messagetext.png";

function Report() {
    const [userReportData, setUserReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nAllUsers, setNAllUsers] = useState([])
    const [visible,setVisibile] = useState(false);
    const [readMore,setReadMore] =useState({});

    useEffect(()=>{
      let data = userReportData.map((item)=>{
        return{
          ...item,
          date: formatDate(item.date),
        }
      })
      setNAllUsers(data)
    },[userReportData])

    const handleSearchInputReport = async(e) => {
        try {
          let query=e.target.value
          let res = await joompaApi.get(`/getAllUsersReport?&search=${query.trim()}`)
          let data = res?.data?.data;
          if(res.status===204){
            setUserReportData([])
          }
          else{
          data && setUserReportData(data)
          }
        } catch (error) {
          // console.log(error)
        }
      };

      const getUserReportData = async () => {
        try {
          setLoading(true);
          let res = await joompaApi.get(`/getAllUsersReport`);
          // console.log("Response User Report", res);
          let data = res?.data?.data;
          data && setUserReportData(data)
        } catch (error) {
          setLoading(false);
          console.log(error)
          message.error(error.message)
        }
        setLoading(false);
      };

   const tabThreecolumns = [
    {
      title: "",
      dataIndex: "userImage",
      className: "col-normal",
      width:"5%",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User",
      width:"15%",
      // dataIndex: "UserName",
      render(text){
        return {
          children: (
            <Link  to={TRANSACTIONS} style={{  }} state={{ userId: text.userId }}>{text.UserName}</Link>
          ),

        }
      }
    },
        {
          title: "",
          dataIndex: "blockerReporterImage",
          width:"5%",
          className: "col-normal",
          render(text, record) {
            return {
              children: (
                <Image
                  style={{ borderRadius: "50%" }}
                  width={40}
                  height={40}
                  src={text}
                />
                // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
              ),
            };
          },
        },
        {
          title: "Reported By",
          width:"15%",
          // dataIndex: "blockerReporterUserName",
          render(text){
            return {
              children: (
                <Link  to={TRANSACTIONS} style={{  }} state={{ userId: text.blockerReporterUserId}}>{text.blockerReporterUserName}</Link>
              ),
    
            }
          }
        },
        {
          title: "Reported On",
          dataIndex: "date",
          defaultSortOrder: 'descend',
          sorter: (a, b) => new Date(a.date) - new Date(b.date),
          sortDirections: ["ascend","descend"],
          width:"15%",
          align:"center",
          render(text) {
            return {
              children: formatDate(text),
            };
          },
        },
        {
          title: "Reason",
          dataIndex: "reasion",
          align:"center",
          width:"20%",
          render(text) {
            return {
              children: <div style={{cursor:"pointer"}} onClick={()=>{setVisibile(true)
                    setReadMore(text)
              }}><img src={Message}/></div>
            };
          },
        },
      ];
    
      
      useEffect(()=>{
        getUserReportData()
      },[])
  return (
        <>
          <div className="buttons-container">
            <SearchInput handleSearchInput={handleSearchInputReport}/>
            {nAllUsers.length!==0 && <ExportButton data={nAllUsers} passing={headersForReport} />}
          </div>
          <Spin spinning={loading} tip="Loading..." size="large">
            <TableComponent
              columns={tabThreecolumns}
              data={userReportData}
            />
          </Spin>
          <ReadMore visible={visible} setVisibile={setVisibile} readMore={readMore} remarks="Reason"/>
        </>
  )
}

export default Report;