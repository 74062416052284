import "antd/dist/antd.min.css";
import "./index.css";
import Notification from "./Notification";
import "./App.css";
import Navbar from "./Components/Layout/Navbar";
import PageFooter from "./Components/Layout/PageFooter";
import Sidebar from "./Components/Sidebar/Sidebar";
import Router from "./utils/Routes";
import { Layout, message } from "antd";
import { useLocation } from "react-router-dom";
import React, { useState,useEffect } from "react";
import { socket } from "./Pages/AdminSupportChats/socket";
import Login from "./Pages/Login";
import CustomBreadcrumb from "./Components/Common/CustomBreadcrumb";
import { EVENT_DETAILS, TRANSACTIONS, VERIFICATION_REQUEST , ADMIN_SUPPORT_CHATS, REQUESTED_PROFILES} from "./utils/Urls";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import joompaApi from "./utils/apis/joompaApi";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import LoginRoute from "./utils/Routes/LoginRoute";
const { Content, Sider } = Layout;

// const AUTH_TOKEN=sessionStorage.getItem("Auth_Token")
let authToken =localStorage.getItem('Auth_Token') ||sessionStorage.getItem('Auth_Token')
axios.defaults.headers.common['token'] = authToken;
function App() {
  const [login, setLogin] = useState(authToken || "");
  const navigate = useNavigate();
  const location = useLocation();
  const onHandleClick=(item)=>{
    // console.log(type);
    // navigate(ALL_USER,{
    //   state: {
    //     ...obj
    //   }
    // console.log(" i am on click")
    // console.log("hey",item)
    // console.log("type",item.type);
    switch (item.title){
      case "Support Chat":
        // notification.destroy();
        // return   navigate(ADMIN_SUPPORT_CHATS)
        console.log(item.userId)
        return   navigate(ADMIN_SUPPORT_CHATS,{
            state: {
              userId:item.userId,
            }})
        
      case "end_chat":
        // notification.destroy();
        return   navigate(ADMIN_SUPPORT_CHATS,{
            state: {
              userId:item.userId,
            }})
     
        case "support_chat":
          // notification.destroy();
          return   navigate(ADMIN_SUPPORT_CHATS,{
              state: {
                userId:item.userId,
              }})
          
        case "verification_request":
          // notification.destroy();
          return   navigate(VERIFICATION_REQUEST)
        
        case "delete_request":
          // notification.destroy();
          return   navigate(REQUESTED_PROFILES)
        case "event_create":
          // notification.destroy();
          return   navigate(EVENT_DETAILS,{
            state: {
              eventId:item.eventId,
            }})
        case "report":
             return navigate(TRANSACTIONS,{
              state:{
                userId:item.userNavId,
              }
             });

    }
  }

  useEffect(()=>{
    socket.on();
    socket.on("connect", (data) => {
    
    }) 
    socket.emit("support-leave-room",{room_id: localStorage.getItem("roomId"),type:"admin"})
  },[location])
  if (!login) return (
    <>  
    <LoginRoute setLogin={setLogin} />
    </>
  );
  else
    return (
      <div style={{height:"100vh",overflowY:"hidden"}}>
      <Notification onHandleClick={onHandleClick}/>
        <Navbar  />
        <Layout style={{height:"100%",}}>
          <Sider
            width="245px"
            // width="240px"
            style={{
              // minHeight: "70vh",
              
              height:"100%",
              // 
            }}
          
          >
            <Sidebar/>
          </Sider>
          <Layout
            className="site-layout"
            style={{
              width: "100%",
              height:"90%",
              overflowY:"scroll",
            }}
          >
            <Content style={{ width: "100%" }}>
              <div
                style={{
                  margin: "15px 0 0 15px",
                }}
              >
                <CustomBreadcrumb />
              </div>
              <div
                className="site-layout-background"
                style={{
                  marginTop: "19.5px",
                  width: "100%",
                }}
              >
                <Router/>
                
              </div>
              <PageFooter/>
            </Content>
  
        
          </Layout>
        </Layout>
      </div>
    );
}

export default App;
