import React, { useState, useEffect } from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { ADMIN_SUPPORT_CHATS, TRANSACTIONS } from "../../utils/Urls";
import {
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  MessageOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Table,
  Image,
  Popover,
  Spin,
  message,
  Popconfirm,
  Button,
  Modal,
  Form,
  Radio,
  DatePicker,
} from "antd";
import "./usermanagement.css";
import SearchInput from "../../Components/Common/SearchInput";
import Tick from "../../Assets/Images/transactions/ban.png";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import cross from "../../Assets/Images/transactions/ban2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colorOfStatus, formatDate } from "../../utils/Constant";
import TableComponent from "../../Components/Common/TableComponent";
import joompaApi from "../../utils/apis/joompaApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUserData } from "../../redux/action/userManagementActions";
import { getAllUserData } from "../../redux/action/action";
import { headersForAllUser } from "../../utils/Columns";
import NonPremium from "../../Assets/Images/icons/Vector.svg";
import moment from "moment-timezone";
import TextArea from "antd/lib/input/TextArea";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";
const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const AllUser = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [requiredTrue, setRequiredTrue] = useState(true);
  const [allUsersData, setAllUsersData] = useState([]);
  const [open, setOpen] = useState(true);
  const [keypath, setKeypath] = useState("");
  const [search, setSearch] = useState("");
  const location = useLocation();

  const dispatch = useDispatch();
  const { allUser } = useSelector((state) => state.userManagement);
  const { filter, menu, submenu } = location?.state ?? {
    filter: false,
    menu: "All",
    submenu: "All",
  };
  const [newAllUsersData, setNewAllUsersData] = useState([]);
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [userDeatils, setUserDeatils] = useState({});
  const [allUsersCount, setAllUsersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [forExport, setForExport] = useState([]); //[{}
  const [loading3, setLoading3] = useState(false); //[{}
  const [loading4, setLoading4] = useState(false);
  const [filterWithSearch, setFilterWithSearch] = useState([]);
  const [messageToShow, setMessageToShow] = useState({
    image: "",
    message: "",
  });

  const [modalForm] = Form.useForm();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position =
      window.document.getElementsByClassName("site-layout")[0].scrollTop;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.document
      .getElementsByClassName("site-layout")[0]
      .addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.document
        .getElementsByClassName("site-layout")[0]
        .removeEventListener("scroll", handleScroll);
    };
  }, []);
  const sendBanData = async (value) => {
    console.log(value);

    try {
      let data = {
        status: "BANNED",
        reason: value.remarks,
        userId: userDeatils.userId,
        permanentBanned: !requiredTrue,
      };
      if (requiredTrue) {
        console.log("here");
        data = {
          ...data,
          date: value.ban_till,
        };
      }
      console.log(data);

      await joompaApi.post("/banUnbanUser", data);
    } catch (err) {
      message.warn(err.response.data.message);
      modalForm.resetFields();
      setRequiredTrue(true);
      setIsModalOpen(false);
      return;
    }
    getData();
    let message = requiredTrue ? " Temporary" : " Permanent";
    setSuccessModal(true);
    setTimeout(() => {
      setSuccessModal(false);
    }, [1500]);
    setMessageToShow({
      image: Tick,
      message: userDeatils?.fullName + message + " banned from the joompa app!",
    });
    modalForm.resetFields();
    setIsModalOpen(false);
    setRequiredTrue(true);
  };

  const popUpBanModal = async () => {
    try {
      await joompaApi.post("/banUnbanUser", {
        status: "UNBANNED",
        userId: userDeatils.userId,
      });
    } catch (err) {
      message.warn(err.response.data.message);
      modalForm.resetFields();
      setSuccessModal(false);
      return;
    }
    // getUserDetailsData()
    getData();
    setSuccessModal(false);
    setSuccessModal(true);
    setMessageToShow({
      image: Tick,
      message: "User " + userDeatils?.fullName + " unban successfully!!",
    });
    setTimeout(() => {
      setSuccessModal(false);
    }, [1500]);
  };

  useEffect(() => {
    if (!keypath && allUsersCount) {
      getData();
    } else if (keypath && allUsersCount) {
      handledropdownclick({ key: keypath, keyPath: keypath.split("/") });
    }
  }, [page, count]);

  // setAllUsersData(allUserData)
  const hide = () => {
    setOpen(false);
  };
  console.log("stackedbar", filter, menu, submenu);
  const handleOpenChange = () => {
    setOpen(!open);
  };

  useEffect(()=>{
    if(localStorage.getItem("mousePoisition") && allUsersData.length>0){
        setTimeout(()=>{
          const storedPosition = localStorage.getItem("mousePoisition");
      // console.log(storedPosition)
      if (storedPosition !== null) {
          const scrollPosition = (storedPosition);
          const siteLayout = window.document.getElementsByClassName("site-layout")[0];
          if (siteLayout) {
              siteLayout.scrollTo(0, scrollPosition);
              localStorage.removeItem("mousePoisition");
          } else {
              console.error("Element with class 'site-layout' not found.");
          }
      } else {
          console.error("No stored scroll position found.");
      }
        },1000)
    }
  },[allUsersData])

  useEffect(() => {
    if (
      localStorage.getItem("search") ||
      localStorage.getItem("filter") ||
      localStorage.getItem("page") || localStorage.getItem("mousePoisition")
    ) {
      localStorage.getItem("search") &&
        setSearch(localStorage.getItem("search"));
      localStorage.getItem("filter") &&
        setFilterWithSearch(localStorage.getItem("filter").split(","));
      localStorage.getItem("filter") &&
        setKeypath(localStorage.getItem("filter").split(",")[0]);
      localStorage.getItem("page") && setPage(localStorage.getItem("page"));
      getDataForLocal(
        localStorage.getItem("filter")
          ? localStorage.getItem("filter").split(",")
          : [],
        localStorage.getItem("search") ? localStorage.getItem("search") : "",
        localStorage.getItem("page") ? localStorage.getItem("page") : 1
      );
      localStorage.removeItem("search");
      localStorage.removeItem("filter");
      localStorage.removeItem("page");



      return;
    }
    filter && setKeypath(submenu);
    filter ? applyfilteFromDashbaord() : getData();
    // getData();
  }, []);

  const applyfilteFromDashbaord = async () => {
    try {
      let res = await joompaApi.get(
        `/alluser?filter=true&menu=${menu}&submenu=${submenu}`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setAllUsersData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };

  useEffect(() => {
    let nAllUsersData = forExport.map((item) => {
      return {
        ...item,
        lastActivityDate: formatDate(item.lastActivityDate),
        regDate: formatDate(item.regDate),
        isPremium: item.isPremium ? "Premium" : "Non-Premium",
        isSuperAdmin: item.isSuperAdmin ? "YES" : "NO",
      };
    });

    setNewAllUsersData(nAllUsersData);
  }, [forExport]);

  const fetchForExport = async () => {
    try {
      setLoading1(true);
      let res = await joompaApi.get(
        `/alluser?pageNumber=1&pageSize=${allUsersCount}`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
      }));
      setForExport(mapData);
      setLoading1(false);
    } catch {
      setLoading1(false);
      message.error("Something Went Wrong");
    }
  };

  const getData = async (filter = false, searchs = false) => {
    try {
      setLoading(true);
      let res = await joompaApi.get(
        `/alluser?pageNumber=${page}&pageSize=${count}&search=${
          !searchs ? search : ""
        }${
          !filter && filterWithSearch.length > 0
            ? `&filter=true&menu=${
                filterWithSearch[1] ? filterWithSearch[1] : ""
              }&submenu=${filterWithSearch[0] ? filterWithSearch[0] : ""}`
            : ""
        }`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setAllUsersData(mapData);

      if (!filter || !searchs) {
        setAllUsersCount(res?.data?.count);
      }
      else if(!searchs && search){
        setAllUsersCount(res?.data?.count);
      }
      else if(!filter && filterWithSearch.length>0){
        setAllUsersCount(res?.data?.count);
      }
      else {
        setAllUsersCount(false);
      }
      // if(allUsersCount!==res?.data?.count){
      //    fetchForExport(res?.data?.count)
      // }
      dispatch(fetchAllUserData(mapData));
      setOpen(true);
      setLoading(false);
    } catch {
      setLoading(false);
      message.error("Something Went Wrong");
    }
  };

  const getDataForLocal = async (filter, search, page) => {
    try {
      setLoading(true);
      let res = await joompaApi.get(
        `/alluser?pageNumber=${page}&pageSize=${count}&search=${search}${
          filter.length > 0
            ? `&filter=true&menu=${filter[1] ? filter[1] : ""}&submenu=${
                filter[0] ? filter[0] : ""
              }`
            : ""
        }`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setAllUsersData(mapData);

      if (page && !search && filter.length == 0) {
        setAllUsersCount(res?.data?.count);
      } else {
        setAllUsersCount(false);
      }
      // if(allUsersCount!==res?.data?.count){
      //    fetchForExport(res?.data?.count)
      // }

      dispatch(fetchAllUserData(mapData));
      setOpen(true);
      setLoading(false);
    } catch {
      setLoading(false);
      message.error("Something Went Wrong");
    }
  };

  const handledropdownclick = async ({ key, keyPath }) => {
    setKeypath(key);
    setFilterWithSearch(keyPath);
    if (keyPath[0] === "All" && search === "") {
      getData(false, false);
      setFilterWithSearch([]);
      return;
    }
    // console.log('filterMenu',keyPath)
    if (keyPath[0] === "All") {
      getData(true);
      setFilterWithSearch([]);
      return;
    }
    setLoading3(true);
    try {
      let res = await joompaApi.get(
        `/alluser?filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}&pageNumber=${page}&pageSize=${count}&search=${search}`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setAllUsersData(mapData);
      setAllUsersCount(false);
      setLoading3(false);
    } catch (error) {
      setLoading3(false);
      message.error("Something Went Wrong");
      // console.log(error)
    }
  };

  const handleSearchInput = async (e) => {
    console.log(e.target.value)
    if (e.target.value === "" && filterWithSearch.length === 0) {
      getData(false,true);
      setSearch("");
      // setFilterWithSearch([]);
      return;
    }
    if (e.target.value === "") {
      getData(false, true);
      setSearch("");
      return;
    }
    setLoading4(true);
    try {
      let query = e.target.value;
      setSearch(query.trim());
      let res = await joompaApi.get(
        `/alluser?search=${query.trim()}&filter=true&menu=${
          filterWithSearch[1] ? filterWithSearch[1] : ""
        }&submenu=${filterWithSearch[0] ? filterWithSearch[0] : ""}`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setAllUsersData(mapData);
      setAllUsersCount(false);
      setLoading4(false);
    } catch (error) {
      setLoading4(false);
      console.log(error);
    }
  };
  const confirm = async (uid) => {
    setDeleteLoading(true);
    try {
      const res = await joompaApi.delete(`/userDelete/${uid}`);
      if (res?.data?.status) {
        // console.log("User deleted successfully",res)
        message.success(res?.data?.message);
        setDeleteLoading(false);
        setOpen(false);
        getData();
      }
    } catch (error) {
      setDeleteLoading(false);
      message.error("User Delete Failed");
    }
  };

  const onBan = (e) => {
    console.log(e);
  };

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "center",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "",
      dataIndex: "userAvatar",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.fullName < b.fullName,
      sortDirections: ["ascend", "descend"],
      width: "12%",
      className: "col-special",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "User Type",
      dataIndex: "isPremium",
      className: "col-normal",
      align: "center",
      render(text) {
        return {
          children: text ? (
            <img
              width="24px"
              height="19px"
              src={premiumUsers}
              alt="user Type"
            />
          ) : (
            <img width="24px" height="19px" src={NonPremium} alt="user Type" />
          ),
        };
      },
    },
    {
      title: "Member Since",
      dataIndex: "regDate",
      defaultSortOrder: "descend",
      align: "center",
      width: "11%",
      sorter: (a, b) => new Date(a.regDate) - new Date(b.regDate),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Last Activity Date",
      dataIndex: "lastActivityDate",
      width: "15%",
      align: "center",
      sorter: (a, b) =>
        new Date(a.lastActivityDate) - new Date(b.lastActivityDate),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Total Matches",
      dataIndex: "matchCount",
      sorter: (a, b) => a.matchCount - b.matchCount,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "Total Spent",
      dataIndex: "totalSpent",
      sorter: (a, b) => a.totalSpent - b.totalSpent,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "Super Admin",
      dataIndex: "isSuperAdmin",
      sorter: (a, b) => a.isSuperAdmin < b.isSuperAdmin,
      sortDirections: ["ascend", "descend"],
      render(text, record) {
        return {
          children: <div>{text ? "YES" : "NO"}</div>,
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Status",
      dataIndex: "accountStatus",
      sorter: (a, b) => a.accountStatus < b.accountStatus,
      sortDirections: ["ascend", "descend"],
      render(text, record) {
        return {
          children: <div style={colorOfStatus(text)}>{text}</div>,
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              {open && (
                <Popover
                  placement="bottomRight"
                  content={
                    <>
                      <Link
                        to={TRANSACTIONS}
                        style={{ color: "black" }}
                        state={{ userId: record.userId }}
                        onClick={() => {
                          localStorage.setItem("search", search);
                          localStorage.setItem("filter", filterWithSearch);
                          localStorage.setItem("page", page);
                          localStorage.setItem("mousePoisition",window.document.getElementsByClassName("site-layout")[0].scrollTop);
                        }}
                      >
                        <EyeOutlined /> View Details
                      </Link>
                      <Link
                        style={{ color: "black" }}
                        to={{
                          pathname: ADMIN_SUPPORT_CHATS,
                          state: {
                            userId: record.userId,
                          },
                        }}
                      >
                        <MessageOutlined /> Admin Support Chat
                      </Link>

                      <button
                        style={{
                          border: "none",
                          textAlign: "left",
                          background: "none",
                          cursor: "pointer",
                          // color: "red",
                        }}
                        onClick={() => {
                          setUserDeatils(record);
                          if (record?.accountStatus === "BANNED") {
                            setMessageToShow({
                              image: cross,
                              message:
                                "Are you sure you want to unban " +
                                record?.fullName +
                                " from the Joompa app?",
                              showButton: true,
                            });
                            setSuccessModal(true);
                          } else {
                            setIsModalOpen(true);
                          }
                        }}
                      >
                        {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                        <StopOutlined style={{ marginRight: "5px" }} />
                        {record?.accountStatus !== "BANNED"
                          ? "Ban User"
                          : "Unban User"}
                      </button>
                      <Popconfirm
                        // title="Delete the task"
                        title="Are You Sure To Delete This User?"
                        onConfirm={() => confirm(record.userId)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        // placement="leftBottom"
                        placement="left"
                      >
                        <button
                          style={{
                            border: "none",
                            textAlign: "left",
                            background: "none",
                            paddingBottom: "10px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                          <DeleteOutlined style={{ marginRight: "5px" }} />
                          Delete
                        </button>
                      </Popconfirm>
                    </>
                  }
                  trigger="click"
                  overlayInnerStyle={{
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                  overlayClassName="popover-menu"
                  showArrow={false}
                >
                  <Link>{text}</Link>
                </Popover>
              )}
            </div>
          ),
        };
      },
    },
  ];

  return (
    <div className="user-container">
      <div className="buttons-container">
        <SearchInput loading={loading4} handleSearchInput={handleSearchInput} />
        <FilterButton
          handledropdownclick={handledropdownclick}
          keypath={keypath}
          loading={loading3}
        />
        {/* {(allUsersData.length!==0 || newAllUsersData.length !== 0) && ( */}
        <ExportButtonNew
          fetch={fetchForExport}
          loading={loading1}
          data={newAllUsersData}
          passing={headersForAllUser}
        />
        {/* )} */}
      </div>
      <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent
          rowKey="userId"
          data={allUsersData}
          columns={columns}
          setPage={setPage}
          setCount={setCount}
          count={allUsersCount}
          page={page}
          pages={true}
        />
      </Spin>
      <Modal
        centered
        width={700}
        title="Ban User"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          onFinish={sendBanData}
          style={{ width: "80%" }}
          form={modalForm}
          layout="vertical"
        >
          <Form.Item name="ban_value">
            <Radio.Group
              defaultValue={"true"}
              onChange={(e) =>
                setRequiredTrue(e.target.value === "true" ? true : false)
              }
            >
              <Radio value="true">
                <span style={{ fontWeight: "600", fontSize: "14px" }}>
                  Temporary Ban
                </span>
              </Radio>
              <Radio value="false">
                <span style={{ fontWeight: "600", fontSize: "14px" }}>
                  Permanent Ban
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>
          {requiredTrue && (
            <Form.Item
              rules={[
                {
                  required: requiredTrue,
                  message: "Ban Till is required",
                },
              ]}
              style={{ fontWeight: "600", fontSize: "14px" }}
              name="ban_till"
              label="Ban Till"
            >
              <DatePicker
                disabledDate={(current) => {
                  return current && current < moment().startOf("day");
                }}
                stule={{ width: "100%" }}
                format="DD-MM-YYYY"
              />
            </Form.Item>
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Remark is required",
              },
            ]}
            style={{ fontWeight: "600", fontSize: "14px" }}
            name="remarks"
            label="Remarks"
          >
            <TextArea />
          </Form.Item>
          <div style={{ display: "flex", gap: "10px", marginTop: "40px" }}>
            <div>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{ borderRadius: "7px" }}
                type="button"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Form.Item noStyle>
                <Button
                  style={{
                    backgroundColor: "#1B75BC",
                    borderRadius: "7px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        open={successModal}
        footer={false}
        onCancel={() => setSuccessModal(false)}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <div>
            <img src={messageToShow.image} />
          </div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {" "}
            {messageToShow.message}
          </div>
          {messageToShow.showButton && (
            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              <Button
                onClick={() => setSuccessModal(false)}
                style={{ borderRadius: "7px" }}
                type="button"
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#1B75BC", borderRadius: "7px" }}
                type="primary"
                htmlType="submit"
                onClick={popUpBanModal}
              >
                Unban
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AllUser;
