import React, { useEffect } from 'react'
import {Button,message,Dropdown } from "antd";
import filterIcon from "../../Assets/Images/filterIcon.png"
import getItem from '../../utils/getItem';

  const submenuData = [
    {
      label: 'All',
      key: 'All',
    },
    {
      label: 'Gender',
      key: 'gender',
      children:[
        {
          label: 'Male',
          key: 'male',
        },
        {
          label: 'Female',
          key: 'female',
        }
      ]
    },
    {
      label: 'Age',
      key: 'ageGroup',
      children:[
        {
          label: '18-24',
          key: '18-24',
        },
        {
          label: '25-35',
          key: '25-35',
        },
        {
          label: '36-50',
          key: '36-50',
        },
        {
          label: '51-61',
          key: '51-61',
        },
        {
          label: '+62',
          key: '+62',
        },
      ]
    },
    {
      label: 'Marital Status',
      key: 'maritalStatus',
      children:[
        {
          label: 'Not Married',
          key: 'Never Married',
        },
        {
          label: 'Separated',
          key: 'Separated',
        },
        {
          label: 'Widowed',
          key: 'Widowed',
        },
        {
          label: 'Divorced',
          key: 'Divorced',
        }
      ]
    },
    // getItem("All", "1"),
    // getItem("User Type", "sub1", [
    //   getItem("All", "2"),
    //   getItem("Premium", "3"),
    //   getItem("Concierge", "4"),
    // ]),
    // getItem("Status", "sub2", [
    //   getItem("All", "5"),
    //   getItem("Active", "6"),
    //   getItem("Suspended", "7"),
    // ]),
  ];

  const handleDropdown=({key,keyPath})=>{
    // console.log('filterMenu',key)
    // console.log(keyPath[keyPath.length-1],keyPath[0])
    alert(keyPath[keyPath.length-1],keyPath[0])
  }

const FilterButton = ({items=submenuData,handledropdownclick=handleDropdown,keypath,showImg=true,width="200px",loading=false}) => {
  return (
    <Dropdown
    menu={{
      items,
      onClick:handledropdownclick,
      selectable: true,
      defaultValue:'All',
      defaultSelectedKeys:"All"
    }}
    style={{width:"200px"}}
    trigger={['click']}
  >
     <a onClick={(e) => e.preventDefault()}>
     <Button style={{
      border:"1px solid #E6EBF1",
      borderRadius:"8px",
      width:width
    }}
     loading={loading}>
         {showImg && <img src={filterIcon} className="filter-icon"/>}
          <span style={{marginLeft:"14px"}}>{keypath===""? "Filters":keypath}</span>
        </Button>
      </a>
  </Dropdown>
  )
}

export default FilterButton