// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getMessaging , getToken ,onMessage} from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5JIIMR_CWVYS4oujD4MPKWhvAWyjLAj4",
  authDomain: "joompa-firebas.firebaseapp.com",
  projectId: "joompa-firebas",
  storageBucket: "joompa-firebas.appspot.com",
  messagingSenderId: "473827098828",
  appId: "1:473827098828:web:c9e6add7d5eb4a0fb5c269",
  measurementId: "G-B38YSKFG69"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


 export const requestPermission=  ()=>{
  console.log("Requesting permission.....");
  Notification.requestPermission().then((permission)=>{
    if(permission==="granted"){
      console.log("Notificaion")
      const messaging = getMessaging(app);
      getToken(messaging,{vapidKey:"BKwOmadGXAPiwEDMUEa0wBh-IH1hnutVUiGzSboJJmdmXUyYANlYDNls8uGAlPgonwWXRe0ll-T29hcnJ0pTN1A"}).then((currentToken)=>{
  if(currentToken){
   
  }
  else{
    console.log("cant get")
  }
})
    }
    else{
      console.log("Do not have permission")
    }
  })
}

export const messaging = getMessaging(app);

// const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

// requestPermission();