import React,{useState,useEffect} from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { MoreOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover,Image, message,Spin } from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import { SUBSCRIPTION_ADD_SUGGESTION_FORM, SUBSCRIPTION_USERDETAILS, TRANSACTIONS } from "../../utils/Urls";
import getItem from "../../utils/getItem";
import CustomPopover, { formatDate } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import { headersForConcierge } from "../../utils/Columns";
import NonPremium from "../../Assets/Images/icons/Vector.svg"


const submenuData = [
  getItem("All", "1"),
  getItem("User Type", "sub1", [
    getItem("All", "2"),
    getItem("Premium", "3"),
    getItem("Concierge", "4"),
  ]),
];

const content = (
  <div className="popover-menu">
    <a href={TRANSACTIONS}>
      <EyeOutlined /> View Details
    </a>
    <p>
      <DeleteOutlined /> Delete
    </p>
  </div>
);



const columns = [
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    width: "15%",
  },
  {
    title: "",
    dataIndex: "photoUrl",
    width:"2%",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "User Name",
    dataIndex: "username",
    // defaultSortOrder: "ascend",
    sorter: (a, b) => a.username - b.username,
    sortDirections: [ "ascend","descend"],
    width: "20%",
    className: "col-special",
    render(text, record) {
      return {
        props: {
          style: { background: "white" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: "User Type",
    dataIndex: "isPremium",
    className: "col-normal",
    width: "20%",
    align: "center",
    render(text) {
      return {
        children: text ? (
          <img
            width="24px"
            height="19px"
            src={premiumUsers}
            alt="user Type"
          />
        ) : (
          <img
            width="24px"
            height="19px"
            src={NonPremium}
            alt="user Type"
          />
        ),
      };
    },
  },
  {
    title: "Requested On",
    dataIndex: "requestDate",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.verificationRequestDate) - new Date(b.verificationRequestDate),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    width: "15%",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Valid Upto",
    dataIndex: "concergeExpire",
    className: "col-normal",
    width: "15%",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "12%",
    align:"center",
    className: "col-normal",
    render: (text,record) => (
      <>
      <CustomPopover text={text} hrefLink={SUBSCRIPTION_USERDETAILS} formhrefLink={SUBSCRIPTION_ADD_SUGGESTION_FORM} userId={record._id}/>
        {/* <Popover placement="bottomRight" content={content} trigger="click">
          <a>{text}</a>
        </Popover> */}
      </>
    ),
  },
];

const ConciergeRequest = () => {
  const [requestData,setRequestData]=useState([])
  const [loading, setLoading] = useState(false);
  const [keypath,setKeypath]=useState('')
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let nAllUsersData = requestData
    nAllUsersData.map((item)=>{
      item.concergeExpire=formatDate(item.concergeExpire);
    })
    setNewAllUsersData(nAllUsersData)
  },[requestData])

  
  useEffect(() => {
    getconeigeRequest()
  }, []);

const getconeigeRequest=async()=>{
  try {
    setLoading(true);
    let res = await joompaApi.get("/getAllconciergeRequest");
    let data = res?.data?.data;
    // console.log("coneirge request data",data)
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // console.log(mapData);
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setRequestData(mapData);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    message.error("Something Went Wrong")
  }
}
const handledropdownclick = async({key,keyPath }) => {
  setKeypath(key)
  // console.log('filterMenu',keyPath[0])
  try {
    
    let res = await joompaApi.get(keyPath[0]==="All"?`/getAllconciergeRequest`:`/getAllconciergeRequest?filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}`)
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }))
    setRequestData(mapData);
  } catch (error) {
    // console.log(error)
  }
};


const handleSearchInput = async(e) => {
  try {
    let query=e.target.value
    let res = await joompaApi.get(`/getAllconciergeRequest?search=${query.trim()}`)
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }))
    setRequestData(mapData);
  } catch (error) {
    // console.log(error)
  }
};
  return (
    <div>
      <div className="buttons-container">
      <SearchInput handleSearchInput={handleSearchInput} />
        <FilterButton handledropdownclick={handledropdownclick} keypath={keypath}  width={"150px"}/>
        <ExportButton data={newAllUsersData} passing={headersForConcierge}/>
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
      <TableComponent data={requestData} columns={columns} />
      </Spin>
    </div>
  );
};
export default ConciergeRequest;
