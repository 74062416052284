import Vector from "../../Assets/Images/Vector.png";
// import Vector from "../../Assets/Images/loginIcon.png";
import { Button, Layout, Badge, Input, notification, Popover, message,Card,Image } from "antd";
import { SearchOutlined, BellOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import Initials from "../../Assets/Images/Initials.png";
import NotificationCard from "../Common/NotificationCard";
import ProfileCard from "../Common/ProfileCard";
import joompaApi from "../../utils/apis/joompaApi";
import { Link, useNavigate } from "react-router-dom";
import { EVENT_DETAILS, TRANSACTIONS, VERIFICATION_REQUEST } from "../../utils/Urls";
import { ADMIN_SUPPORT_CHATS,REQUESTED_PROFILES } from "../../utils/Urls";
const { Header } = Layout;

const Styles = {
  icon: {
    background: "black",
    color: "white",
  },
  NavbarIcons: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "flex-end",
  },
};

const notificationRedirection = {
  "support_request":"/adminsupportchats",
  "end_chat":"/adminsupportchats",
  "support_chat": "/adminsupportchats",
  "verification_request":"/verification",
  "delete_request" : "/verification",
  "event":  "/event",
  "report":"/report",
}


const Navbar = () => {
  const [showDot, setShowDot] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [showCard,setShowCard]=useState(false)
  const [result,setResult]=useState([])
  const [searchLoading,setSearchLoading]=useState(false)
  // const [searchInput,setSearchInput]=useState('')
  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const onHandleClick=(item)=>{
    // console.log(type);
    // navigate(ALL_USER,{
    //   state: {
    //     ...obj
    //   }

    switch (item.type){
      case "support_request":
        notification.destroy();
        return   navigate(ADMIN_SUPPORT_CHATS,{
            state: {
              userId:item.userId,
            }})
        
      case "end_chat":
        notification.destroy();
        return   navigate(ADMIN_SUPPORT_CHATS,{
            state: {
              userId:item.userId,
            }})
     
        case "support_chat":
          notification.destroy();
          return   navigate(ADMIN_SUPPORT_CHATS,{
              state: {
                userId:item.userId,
              }})
          
        case "verification_request":
          notification.destroy();
          return   navigate(VERIFICATION_REQUEST)
        
        case "delete_request":
          notification.destroy();
          return   navigate(REQUESTED_PROFILES)
        case "event_create":
          notification.destroy();
          return   navigate(EVENT_DETAILS,{
            state: {
              eventId:item.eventId,
            }})
        case "report":
            notification.destroy();
           return navigate(TRANSACTIONS,{
            state:{
              userId:item.userNavId,
              report:true,
            }
           });

    }
  }
  // useEffect(()=>{
  //   setTimeout(() => {
  //     searchFunction()
  //   }, 4000)
  //   return () => clearTimeout()
  // },[searchInput])

let timeOutId
const hanndleChange=(e)=>{
  clearTimeout(timeOutId)
  timeOutId=setTimeout(()=>{
    const {value,name}=e.target
    // console.log(name,value)
    searchFunction(value)
  },1000)
}

const [popoverVisible, setPopoverVisible] = useState(false);

const handleVisibleChange = (visible) => {
  setPopoverVisible(visible);
};

const searchFunction=async(searchInput)=>{
  try {
    setSearchLoading(true)
    setShowCard(true)
    let res=await joompaApi.get(`/searchUserbyAdmin?search=${searchInput}`)
    // console.log(res?.data?.data)
    setResult(res?.data?.data)
    setSearchLoading(false)
  } catch (error) {
    setSearchLoading(false)
    message.error("Something Went Wrong")
  }
}
  
  const handleSearchShow = () => {
    setShowSearch((prev) => !prev);
  };

  /* This for outside click function */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSearch(false);
          setShowCard(false)
          
        
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const wrappRef = useRef(null);
  useOutsideAlerters(wrappRef);
  
  function useOutsideAlerters(ref) {
    console.log("in");
    useEffect(() => {
      function handleClickOutsides(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          notification.destroy();
        
        }
      }
      document.addEventListener("mousedown", handleClickOutsides);
      return () => {
        document.removeEventListener("mousedown", handleClickOutsides);
      };
    }, [ref]);
  }

  /* This end outside click function */

  const fetchData = async () => {
    try {
       // setLoading(true)
    let res = await joompaApi.get(`/getAllNotificationForAdmin?page=0&limit=5`);
    let data = res?.data.unreadCount;
    // setShowDot(true)
    // data.docs.length > 0 && setShowDot(true);
      setShowDot(data)
    } catch (error) {
      // console.log(error)
    }
  };
  const handleNotification = () => {
    // setIsOpenNotification(!isOpenNotification)
    notification.destroy();
    notification.open({
      description: <NotificationCard setShowDot={setShowDot} wrappRef={wrappRef} onHandleClick={onHandleClick}/>,
      onClick: () => {
        // console.log('Notification Clicked!');
      },
      duration: 0,
      style: { padding: "15px", margin: "10px" },
    });
  };
  return (
    <Header
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Link to="/">
        <img src={Vector} alt="logo" 
        style={{
          // filter:"invert(99%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(110%) contrast(101%)",
          height:"42px",
          width:"95%",
          // color:"white"
        }}
         />
         </Link>
      </div>
      <div style={Styles.NavbarIcons} ref={wrapperRef}>
        {showSearch && (
          <Input
            placeholder="Search..."
            prefix={searchLoading ? <LoadingOutlined/>:<SearchOutlined />}
            onChange={hanndleChange}
            style={{
              width: "300px",
              color: "#8A8A8A",
              // backgroundColor:"black",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              border: "1px solid #AAAAAA",
              borderRadius: "8px",
              // transition: "width 2s",
              // transitionTimingFunction:"ease-in"
            }}
          />
        )}
        {
          showCard &&   <Card
          style={{
            width: "20%",
            border:"1px solid #E6EBF1",
            borderRadius: "8px",
            position:"absolute",
            top:"9%",
            right:"15%",
            zIndex:100,
            maxHeight:"50%",
            overflowY:"scroll"
          }}
        >
        {
        result.length==0 ? "No Result Found" : result?.map((obj)=>{
           return  <div style={{ display: "flex",marginBottom:"15px",alignItems:"flex-start"}}>
           <img src={obj?.userAvatar} width="40px" height="40px" style={{borderRadius:"50%",objectFit:"cover"}}/>
           <div style={{ marginLeft: "8px" }}>
             <div
               style={{
                 fontWeight: "700",
                 fontSize: "14px",
                 lineHeight: "17px"
               }}
             >
                <Link
                  to={TRANSACTIONS}
                  style={{ color: "black" }}
                  state={{userId:obj?.userId}}
                >
                <p>{`${obj?.fullName}`}</p>
                </Link>
             </div>
             <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                    marginTop:"-10px"
                  }}
                >
                  {obj.phoneNumber}
                </div>
           </div>
         </div>
          })
        }
        </Card>
        }
        <Button
          shape="circle"
          style={Styles.icon}
          icon={<SearchOutlined />}
          onClick={handleSearchShow}
        />

        <Badge count={showDot}  >
          <Button
            shape="circle"
            style={Styles.icon}
            icon={<BellOutlined />}
            onClick={handleNotification}
          />
        </Badge>
        <Popover
          placement="bottomRight"
          content={<ProfileCard setPopoverVisible={setPopoverVisible} />}
          trigger="click"
          showArrow={false}
          visible={popoverVisible}
        onVisibleChange={handleVisibleChange}
        >
          <Button shape="circle" style={Styles.icon} icon={<UserOutlined />} />
        </Popover>
      </div>
    </Header>
  );
};

export default Navbar;
