import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const StackedBarChart = ({
  data,
  dataKey1,
  dataKey2,
  dataKeyX,
  handleClick,
}) => {
  return (
    <ResponsiveContainer width="97%" height={350}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid stroke="#E6EAF0" vertical={true} />
        <XAxis dataKey={dataKeyX} />
        <YAxis />
        <Tooltip cursor={{ fill: "transparent" }} />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="center"
          wrapperStyle={{
            marginTop: "-15px",
            paddingLeft: "5px",
            textTransform: "capitalize",
          }}
        />
        {/* <Legend /> */}

        <Bar
          dataKey={dataKey1}
          stackId="a"
          fill="#4DAE47"
          barSize={25}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />

        <Bar
          dataKey={dataKey2}
          stackId="a"
          fill="#1B75BC"
          barSize={25}
          onClick={handleClick}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
