import React from "react";
import { Menu, Select, message } from "antd";
import "./dashboard.css";
import { LoadingOutlined } from "@ant-design/icons";
import BarCharts from "../../Components/Common/BarCharts";
const MonthlySubscriptions = ({ title = "Premium Subscriptions",data,dataKeyX,dataKeyY,setFilter,last,headingData,handleClick,loading}) => {
  const handleSelect = (value) => {
    setFilter(value);
  };

const lastMonthYear=(last)=>{
  if(last==='Yearly'){
    return "Year"
  }
  else if(last==='Monthly'){
    return "Month"
  }
  else if(last==='Daily'){
    return "Day"
  }
}
  return (
    <div className="white-card" style={{ height: "560px" }}>
      <div className="middleContainer">
        <div className="card-heading">{title}</div>
        {/* <div style={Styles.topheading}>Monthly Subscriptions</div> */}
        
        <div style={{ padding: "24px" }}>
        {loading && <LoadingOutlined style={{marginRight:"10px"}}/>}
          <Select
            defaultValue="Yearly"
            bordered={false}
            style={{
              width: 120,
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            onChange={handleSelect}
            options={[
              {
                value: "Daily",
                label: "Daily",
              },
              {
                value: "Monthly",
                label: "Monthly",
              },
              {
                value: "Yearly",
                label: "Yearly",
              },
            ]}
          />
        </div>
      </div>
      <div className="line"></div>
      <div
        style={{
          fontWeight: 800,
          fontSize: "48px",
          fontFamily: "Inter",
          marginLeft: "24px",
          fontStyle: "normal",
          lineHeight: "58px",
          textAlign: "justify",
        }}
      >
        {headingData.totalCountCurrent}
      </div>
      <div
        style={{
          fontWeight: 700,
          fontSize: "16px",
          fontFamily: "Poppins",
          marginLeft: "24px",
          fontStyle: "normal",
          lineHeight: "140%",
        }}
      >
        <span style={{ color:headingData.percentage>0?"#4DAE47":"#F53434" }}>{Math.round(headingData.percentage*100)/100} % </span>{`than last ${lastMonthYear(last)}`}
      </div>
      <div style={{ padding: "2rem 0.5rem" }}>
        <BarCharts data={data} dataKeyX={dataKeyX} dataKeyY={dataKeyY} handleClick={handleClick}/>
      </div>
    </div>
  );
};

export default MonthlySubscriptions;
