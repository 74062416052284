import React, { useState, useEffect } from "react";
import { Popover, Image, Spin,message,Popconfirm } from "antd";
import CustomButton from "../../Components/Common/CustomButton";
import ExportButton from "../../Components/Common/ExportButton";
import SearchInput from "../../Components/Common/SearchInput";
import { MoreOutlined, EditOutlined,DeleteOutlined } from "@ant-design/icons";
import TableComponent from "../../Components/Common/TableComponent";
import { ADD_NEW_FEATURE, EDIT_PACKAGE } from "../../utils/Urls";
import {
  subscriptionIcon,
  colorOfStatus,
} from "../../utils/Constant";
import { Link } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { formatDate } from "../../utils/Constant";
import { headerForPackage } from "../../utils/Columns";
const FeatureCost = () => {
  const [packageData, setPackageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open,setOpen]=useState(true)
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let nAllUsersData = packageData;
    nAllUsersData.map((item)=>{
      item.updatedAt=formatDate(item.updatedAt);
    })
    setNewAllUsersData(nAllUsersData)
  },[packageData])
  
  useEffect(() => {
    getPackageData();
  }, []);


  const confirm = async(planId,id,featuredType) => {
    if(featuredType==="TOPUP"){
      try {
        const res=await joompaApi.delete(`/subscriptionsPackages/${planId}?featuredType=TOPUP`)
        console.log("delete response",res)
        if(res?.data?.status==="success"){
          // console.log("User deleted successfully",res)
          message.success(res?.data?.message)
          // setDeleteLoading(false)
          setOpen(false)
          getPackageData()
        }
      } catch (error) {
        // setDeleteLoading(false)
        message.error("Package Delete Failed")
      }
    }
    else if(featuredType==="PREMIUM"){
      try {
        const res=await joompaApi.delete(`/subscriptionsPackages/${planId}?featuredType=PREMIUM`)
        console.log("delete response",res)
        if(res?.data?.status==="success"){
          // console.log("User deleted successfully",res)
          message.success(res?.data?.message)
          // setDeleteLoading(false)
          setOpen(false)
          getPackageData()
        }
      } catch (error) {
        // setDeleteLoading(false)
        message.error("Package Delete Failed")
      }
    }
    else{
      try {
        const res=await joompaApi.delete(`/subscriptionsPackages/${planId}?planId=${id}`)
        console.log("delete response",res)
        if(res?.data?.status==="success"){
          // console.log("User deleted successfully",res)
          message.success(res?.data?.message)
          // setDeleteLoading(false)
          setOpen(false)
          getPackageData()
        }
      } catch (error) {
        // setDeleteLoading(false)
        message.error("Package Delete Failed")
      }
  
    }
    
  };




  const columns = [
    {
      title: "Package Name",
      // dataIndex: "planId",
      width:"20%",
      align: "center",
      dataIndex: "planName",
      sorter: (a, b) => a.planName < b.planName,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "Feature Type",
      dataIndex: "featuredType",
      align: "center",
      sorter: (a, b) => a.featuredType < b.featuredType,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: (
            <img src={subscriptionIcon(text)} alt="user Type" />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "Package Cost",
      dataIndex: "packageCost",
      align: "center",
      sorter: (a, b) => a.packageCost - b.packageCost,
      sortDirections: ["ascend", "descend"],
      render(text,record) {
        return {
          children: (
            <span>{record.featuredType==="PREMIUM"||record.featuredType==="TOPUP"?`${text} SGD`:`${text} COIN`}</span>
            )
        };
      },
    },
    {
      title: "Expiry",
      dataIndex: "expireIn",
      align: "center",
      render: (text, record) => (
        <>
          {record.expireIn} {record.expireInType}
        </>
      ),
    },
    {
      title: "Last Update On",
      dataIndex: "updatedAt",
      defaultSortOrder: 'descend',
      align: "center",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status < b.status,
      sortDirections: ["ascend", "descend"],
      render(text) {
        return {
          children: (
            <div style={colorOfStatus(text ? "ACTIVE" : "INACTIVE")}>
              {text ? "ACTIVE" : "INACTIVE"}
            </div>
          ),
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => (
        <>
          <div>
            {
             open &&  <Popover
              placement="bottomRight"
              content={
                <>
                <Link
                  to={EDIT_PACKAGE}
                  style={{ color: "black" }}
                  state={{ packageId: record.planId,_id:record?._id,featuredType:record?.featuredType}}
                >
                  <EditOutlined /> Edit
                </Link>
                <Popconfirm
                      // title="Delete the task"
                      title="Are You Sure To Delete This Package?"
                      onConfirm={()=>confirm(record?.planId,record?._id,record?.featuredType)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      // placement="leftBottom"
                      placement="left"
                    >
                      <button
                        style={{
                          border: "none",
                          // marginLeft: "-38px",
                          background: "none",
                          paddingBottom:"10px",
                          cursor:"pointer",
                          color:"red"
                        }}
                      >
                       {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                       <DeleteOutlined style={{marginRight:"5px"}}/>Delete
                      </button>
                    </Popconfirm>
                </>
              }
              trigger="click"
              overlayInnerStyle={{
                borderRadius: "12px",
                textAlign: "center",
              }}
              overlayClassName="popover-menu"
              showArrow={false}
            >
              <Link>{text}</Link>
            </Popover>
            }
          </div>
        </>
      ),
    },
  ];
  const getPackageData = async () => {
    setLoading(true);
    let res = await joompaApi.get(`/subscriptionsPackages`);
    console.log("package data",res?.data?.data)
    let data = res?.data?.data;
    let alData=[...data,...res?.data?.premiumData]
    let mapData = alData.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    data && setPackageData(mapData);
    setOpen(true)
    setLoading(false);
  };


  const handleSearchInput = async(e) => {
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/searchSubscription?search=${query.trim()}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setPackageData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };

  return (
    <div>
      <div className="flex-space-between">
        <div className="buttons-container">
          <SearchInput handleSearchInput={handleSearchInput} />
          {packageData.length!==0 && <ExportButton data={newAllUsersData} passing={headerForPackage}/>}
        </div>
        <div>
          <CustomButton title="+ Add New" href={ADD_NEW_FEATURE} />
        </div>
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent rowKey="_id" data={packageData} columns={columns} />
      </Spin>
    </div>
  );
};

export default FeatureCost;
