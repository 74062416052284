import React,{useEffect,useState} from 'react'
import {Table,Select,Image, message} from "antd"
import { DownOutlined } from '@ant-design/icons';
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png"
import { Menu, Dropdown, Button } from "antd";
import joompaApi from '../../utils/apis/joompaApi';
import { Link } from 'react-router-dom';
import { TRANSACTIONS } from '../../utils/Urls';


const TopProfiles = ({data}) => {
  // console.log("Top profile data from top",data)
      let columns = [
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          render: (text, record) => (
            <span>
              <Image
                style={{ borderRadius: "50%",objectFit:"cover" }}
                width={40}
                height={40}
                src={record.photoUrl}
              />{" "}
              <Link to={TRANSACTIONS} state={{ userId: record.userId, tab:"MATCHES" }}>
              <span style={{ fontWeight: 600, color: "#1B75BC", fontSize: "14px" }}>
                {record.fullName}
              </span>
              </Link>
            </span>
          ),
          width:"50%"
        },
        {
          title: "Matches",
          dataIndex: "totalMatched",
          key: "totalMatched",
          width:"42%"
        },
        {
            title: "Likes",
            dataIndex: "totalLikes",
            key: "totalLikes",
            
          },
    
      ]
  


  return (
    <div className='white-card' style={{height:"437px"}}>
      <div className='middleContainer'>
        <div className='card-heading'>Top Profiles</div>
        {/* <div style={Styles.topheading}>Monthly Subscriptions</div> */}
        {/* <div style={{ padding: "24px" }}>
        <Select
            defaultValue="yearly"
            bordered={false}
            style={{
              width: 120,
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            onChange={handleChange}
            options={[
              {
                value: "daily",
                label: "Daily",
              },
              {
                value: "weekly",
                label: "Weekly",
              },
              {
                value: "monthly",
                label: "Monthly",
              },
              {
                value: "yearly",
                label: "Yearly",
              },
            ]}
          />
        </div> */}
      </div>
      <div className="line"></div>
      <div
        style={{
          width:"92%",
          marginLeft:"2.5rem",
          padding: "1rem",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "21px",
          textAlign:"center",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
        }}
      >
        <div>USER</div>
        <div>MATCHES</div>
        <div>LIKES</div>
      </div>
      <div className="line"></div>
      <Table
        dataSource={data}
        // dataSource={topProfiles}
        columns={columns}
        showHeader={false}
        pagination={false}
      />
    </div>
  )
}

export default TopProfiles