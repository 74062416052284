import React from "react";
import "./usermanagement.css";
import Icon from "@ant-design/icons/lib/components/Icon";
import block from "../../Assets/Images/transactions/block.png";
import event from "../../Assets/Images/transactions/event.png";
import filter from "../../Assets/Images/transactions/filter.png";
import like from "../../Assets/Images/transactions/like.png";
import report from "../../Assets/Images/transactions/report.png";
import updateProfile from "../../Assets/Images/transactions/updateProfile.png";
import chat from "../../Assets/Images/transactions/chat.png";
import { Timeline } from "antd";
import { formatDate } from "../../utils/Constant";



const data=[
  { 
    key:1,
    icon:like,
    time:"Today 4:00 pm",
    title:"Liked a picture on Sara M profile",
  }
  ,{
    
      key:2,
      icon:updateProfile,
      time:"Today 2:00 pm",
      title:"Updated profile with new picture",
    
  }
  ,{
    key:3,
    icon:like,
    time:"Yesterday, 10:00 am",
    title:"New interest for user added",
}
,{  
  key:4,
  icon:like,
  time:"Yesterday, 1:00 pm",
  title:"Sent an salam to Sara M",

}
,{   
  key:5,
  icon:like,
  time:"Yesterday, 2:00 pm",
  title:"Match with sara m",
}
,{
  key:6,
  icon:report,
  time:"Yesterday, 2:00 pm",
  title:"reported sara m for hate speech",

}
,{
    
  key:7,
  icon:block,
  time:"Yesterday, 2:00 pm",
  title:"John blocked sara m for hate speech.",
}
,{
  key:8,
  icon:like,
  time:"5th Sept 2022, 2:00 pm",
  title:"updated compatibility test details.",

}
,{
  key:9,
  icon:filter,
  time:"5th Sept 2022, 2:00 pm",
  title:"applied new filters for profile suggestions.",
}
,{
  key:10,
  icon:event,
  time:"5th Sept 2022, 2:00 pm",
  title:"created an event name Meet & Greet",
}
,{
  key:11,
  icon:event,
  time:"5th Sept 2022, 2:00 pm",
  title:"shown interest in event name Music Night",
},
,{
  key:12,
  icon:like,
  time:"5th Sept 2022, 2:00 pm",
  title:" drop an comment on event name Music Night",
}
]

const TabTimeline = ({data,lastActivityData}) => {
  return (
    <div className="timeline-container white-card">
      {lastActivityData && <div className="last-login">
        <p className="text-style-ip-location text-green">
          Last active on {formatDate(lastActivityData.lastActivityDate)}
        </p>
        <p className="text-style-ip-location">{lastActivityData.latitude}</p>
        <p className="text-style-ip-location">{lastActivityData.country}</p>
      </div>
}
      <div className="timeline">
        <Timeline>
          {
           data && data.map((item)=>
            <Timeline.Item key={item._id}
            color="#155B9F"
            dot={
              item.icon && <Icon
                component={() => (
                  <img className="icon-img" src={item.icon} alt="heart icon" width="20px" height="20px" style={{borderRadius:"50%"}}/>
                )}
              />
            }
          >
          <div className="timelinw-text-group">
            <p className="timeline-time">{formatDate(item.time,true)}</p>
            <p className="timeline-title">{item.title}</p>
            </div>
            
          </Timeline.Item>
            )
          }
         
          
        </Timeline>
      </div>
    </div>
  );
};

export default TabTimeline;
