import React,{useState,useEffect} from "react";
import { MoreOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {Spin,Image, message } from "antd"; 
import SearchInput from "../../Components/Common/SearchInput"; 
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import { SUBSCRIPTION_ADD_SUGGESTION_FORM, SUBSCRIPTION_USERDETAILS, TRANSACTIONS } from "../../utils/Urls";
import CustomPopover from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import {formatDate } from "../../utils/Constant";
import { headersForFinanceReport } from "../../utils/Columns";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";
import FilterButton from "../../Components/Common/FilterButton";



const columns = [
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend","descend"],
    className: "col-normal",
  },
  {
    title: "",
    dataIndex: "userAvatar",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "User Name",
    dataIndex: "userName",
    // defaultSortOrder: "ascend",
    sorter: (a, b) => a.userName < b.userName,
    sortDirections: ["ascend","descend"],
    width: "20%",
    className: "col-special",
    render(text, record) {
      return {
        props: {
          style: { background: "white" }
        },
        children: <div>{text}</div>
      };
    }
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.paymentDate) - new Date(b.paymentDate),
      sortDirections: ["ascend","descend"],
    className: "col-normal",
    render(text) {
      return {
        children: <div>{formatDate(text)}</div>,
      };
    },
  },
  {
    title: "Payment Amount",
    dataIndex: "paymentAmountOfCoins",
    sorter: (a, b) => a.paymentAmountOfCoins - b.paymentAmountOfCoins,
      sortDirections: ["ascend","descend"],
    className: "col-normal",
    render: (text) => <div>{text.toFixed(2)}</div>,
  },
  {
    title: "Total Amount Spent",
    dataIndex: "totalPayment",
    sorter: (a, b) => a.totalPayment - b.totalPayment,
      sortDirections: ["ascend","descend"],
    className: "col-normal",
    render: (text) => <div>{text.toFixed(2)}</div>,
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "8%",
    className: "col-normal",
    render: (text,record) => (
      <>
      <CustomPopover text={text} hrefLink={SUBSCRIPTION_USERDETAILS} formhrefLink={SUBSCRIPTION_ADD_SUGGESTION_FORM} userId={record.userId}/>
        {/* <Popover placement="bottomRight" content={content} trigger="click">
          <a>{text}</a>
        </Popover> */}
      </>
    ),
  },
];

const submenuData = [
  {
    label: 'All',
    key: 'ALL',
  },
  {
    label: 'Success',
    key: 'SUCCESS',
  },
  {
    label: 'Pending',
    key: 'PENDING',
  },
  {
    label: 'Failed',
    key: 'FAILED',
  },

];

const FinanceReport = () => {
  const [loading, setLoading] = useState(false);
  const [financeReportData, setFinanceReportData] = useState([]);
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(10)
  const [allUsersCount, setAllUsersCount] = useState(0) 
  const [loading1,setLoading1] = useState(false)
  const [filter,setFilter]=useState(true)
  const [forExport,setForExport]=useState([])
  const [loading4,setLoading4]=useState(false)
  const [loading3,setLoading3]=useState(false)
  const [status,setStatus]=useState("")

  useEffect(()=>{
    let nAllUsersData = forExport
    nAllUsersData.map((item)=>{
      item.paymentDate=formatDate(item.paymentDate);
    })
    setNewAllUsersData(nAllUsersData)
  },[forExport])
  
  useEffect(()=>{
    if(!status){
      getData()
    }
      
  },[page,count])
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(status==="PENDING" || status==="FAILED" || status==="SUCCESS"){

      getData();
    }
    else{
      setFilter(true)
      getData();
    }
  }, [status]);


  const handleDropdown=({key})=>{
    setStatus(key)
    // console.log("handleDorpdonw",key)
  }

  const handleSearchInput = async(e) => {
    if(e.target.value === "") {
      getData()
      return
    }
    setLoading4(true)
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/subscriptionsFinanceReport?search=${query.trim()}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setFinanceReportData(mapData);
      setAllUsersCount(false)
      setLoading4(false)
    } catch (error) {
      setLoading4(false)
      // console.log(error)
    }
  };


  const getDataForExport = async()=>{
    setLoading1(true)
    try {
      let res = await joompaApi.get(`/subscriptionsFinanceReport?pageNumber=1&pageSize=${allUsersCount}`)
      let data = res?.data?.data;
      setLoading1(false)
      setForExport(data)
    } catch (error) {
      setLoading1(false)
      message.error("Something went wrong")
      // console.log(error)
    }
  }

  const filteredData = async()=>{
    setLoading3(true)
    let res = await joompaApi.get(`/subscriptionsFinanceReport?status=${status==="ALL"?"":status}&pageNumber=${1}&pageSize=${allUsersCount}`);
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // let pendingData=mapData.filter((obj)=>obj.verificationRequest!=="null")
    setFinanceReportData(mapData);
    setFilter(false)
    setLoading3(false)
    // console.log(mapData)
    // setLoading(false)
  }

  const getData = async () => {
    if(status==="SUCCESS" || status==="PENDING" || status==="FAILED" ){
      setFilter(false)
      filteredData()
      return;
    }
    setLoading(true);
    let res = await joompaApi.get(`/subscriptionsFinanceReport?pageNumber=${page}&pageSize=${count}`);
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setFinanceReportData(mapData);
    // if(res?.data?.count!==allUsersCount){
    //   getDataForExport(res?.data?.count)
    // }
    setAllUsersCount(res?.data?.count)
    setLoading(false);
  };
  return (
    <div>
    <div className="buttons-container">
    <SearchInput loading={loading4} handleSearchInput={handleSearchInput} />
    <FilterButton loading={loading3} items={submenuData} handledropdownclick={handleDropdown} keypath={status}/>
    <ExportButtonNew loading={loading1} data={newAllUsersData} fetch={getDataForExport} passing={headersForFinanceReport} />
      {/* {(newAllUsersData.length!==0 || financeReportData.length!==0) && <ExportButton loading={loading1} data={newAllUsersData} passing={headersForFinanceReport} />} */}
    </div>
    <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent rowKey="userId" data={financeReportData} columns={columns}setPage={setPage} setCount={setCount} count={filter?allUsersCount:filter} />
    </Spin>
  </div>
  )
}

export default FinanceReport