import React, { useState, useEffect } from "react";
import { Switch, Table, Image } from "antd";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import joompaApi from "../../utils/apis/joompaApi";
import { Link } from "react-router-dom";
import { TRANSACTIONS } from '../../utils/Urls';
const TopPremiumUsers = ({data}) => {
  // const [topPremuiumUser, setTopPremuiumUser] = useState(data);
  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   // setLoading(true);
  //   let res = await joompaApi.get("/topPremiumUser");
  //   let data = res?.data?.data;
  //   let mapData = data.map((obj) => ({
  //     ...obj,
  //     name: `${obj.fname} ${obj.lname}`,
  //   }));
  //   // console.log("pendig data", mapData);
  //   setTopPremuiumUser(mapData);
  //   // setLoading(false);
  // };
  console.log(data);
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div style={{display:"flex",gap:"10px"}}>
          <div>
          <Image
            style={{ borderRadius: "50%",objectFit:"cover" }}
            width={40}
            height={40}
            src={record.photoUrl}
          />{" "}
          </div>
         
          <Link to={TRANSACTIONS} state={{ userId: record._id }}>
              <span style={{ fontWeight: 600, color: "#1B75BC", fontSize: "14px" }}>
                {record.name}
              </span>
              </Link>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total_cost",
      key: "amount",
      render: (text, record)=>{
        const number = text; // Replace with your number

    const formattedNumber = formatNumberWithTwoDecimalPlaces(number);
        return(
          <span>
            {formattedNumber}
          </span>
        )
      }
    },
  ];

  function formatNumberWithTwoDecimalPlaces(number) {
    return Number.isFinite(number) ? number.toFixed(2).replace(/\.?0*$/, '') : number;
  }

  return (
    <div className="white-card" style={{ height: "565px",overflowY:"scroll" }}>
      <div className="card-heading">Top Premium Users</div>
      <div className="line"></div>
      <div
        style={{
          height: "5%",
          padding: "1.5rem",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "21px",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>USER</div>
        <div>AMOUNT SPENT</div>
      </div>
      <Table
        dataSource={data}
        // dataSource={topPremuiumUser}
        columns={columns}
        showHeader={false}
        pagination={false}
      />
    </div>
  );
};

export default TopPremiumUsers;
