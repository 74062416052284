import { MoreOutlined } from "@ant-design/icons";
import joompaApi from "../../utils/apis/joompaApi";
import * as types from "./action.types.js";
import { message } from "antd";

export const getAllUserData = () => async (dispatch) => {
  dispatch({ type: types.ALL_USER_DATA_FETCH });
  try {
    let res = await joompaApi.get("/alluser");
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    console.log("action data", mapData);
    console.log("action data below", data);
    dispatch({ type: types.ALL_USER_DATA_FETCH_SUCESS, payload: mapData });
  } catch {
    // setLoading(false);
    message.error("Something Went Wrong");
  }
};

export const getUserData = async() => {
  try {
    let res = await joompaApi.get("/adminProfile");
    let data = res?.data?.data[0];
    return ({ type: types.USER_DATA, payload: data.isSuperAdmin });
  } catch (error) {
    return ({ type: types.USER_DATA, payload: false });
  }
};
