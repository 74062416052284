import React, { useState } from "react";
import { Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "../../utils/textEditorModules";
import ViewEmailTemplate from "./ViewEmailTemplate";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined,WarningOutlined } from "@ant-design/icons";
const AddNewTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [emailForm,setEmailForm]=useState({})
  const [errorName,setErrorName]=useState(false)
  const [errorSubject,setErrorSubject]=useState(false)
  const [isPreviewShown, setPreviewShown] = useState(false);
  const navigate = useNavigate();
  const location=useLocation()
  const {templateId}=location.state
  const handleSave = async() => {
    if(!emailForm.name || !emailForm.subject){
      !emailForm.name && setErrorName(true)
      !emailForm.subject && setErrorSubject(true)
      // return message.error("All Field Are Required")
    }
    else{
      try {
        setLoading(true);
        const templateData={...emailForm,content:value}
    // setEmailForm({...emailForm,content:value})
        const res=await joompaApi.post('/emailTemplates',templateData)
        // console.log(res?.data)
    message.success(res?.data?.message, 5);
    setLoading(false)
        navigate("/cms/emailtemplates")
    setEmailForm({})
      } catch (error) {
        setLoading(false)
        message.error(error.message)
      }
    }
    // setPreviewShown(true);

    // console.log(value);
  };


const handleInputChange=(e)=>{
  const { name, value } = e.target;
  name==="name" && value!="" && setErrorName(false)
  name==="subject" && value!="" && setErrorSubject(false)
    // console.log(name,value)
    setEmailForm({
      ...emailForm,
      [name]: value,
    });
}
  return (
    <>
    {isPreviewShown ? <ViewEmailTemplate data={value}/>:
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ?<LoadingOutlined/>:"Save"}
        </button>
      </div>
      <div className="white-card">
        <div style={{ marginLeft: "10%" }}>
          <div>
            <label className="label-text">Template Name</label>
            <Input
              placeholder="Template Name"
              style={{ width: "65%" }}
              className="input-box"
              name="name"
              onChange={handleInputChange}
              value={emailForm.name}
              status={errorName && "error"}
            />
           <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorName && <WarningOutlined />}</span>

          </div>
          <div>
            <label className="label-text">Subject</label>
            <Input
              placeholder="Subject"
              style={{ width: "65%" }}
              className="input-box"
              name="subject"
              value={emailForm.subject}
              onChange={handleInputChange}
              status={errorSubject && "error"}
            />
           <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{errorSubject && <WarningOutlined />}</span>

          </div>
          <div>
            <label className="label-text">Text</label>
            <ReactQuill
              style={{
                width: "85%",
                borderRadius: "8px",
                paddingBottom: "10%",
              }}
              theme="snow"
              value={value}
              onChange={setValue}
              formats={formats}
              modules={modules}
            />
          </div>
        </div>
      </div>
    </div>
}
    </>
    
  );
};

export default AddNewTemplate;
