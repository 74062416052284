import React, { useEffect, useState } from "react";
import { ExportOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { CSVLink } from "react-csv";
const ExportButton = ({ data, passing,loading}) => {
  const [SrNoData, setSrNoData] = useState([]);
  const [column, setColumns] = useState([]);

  useEffect(() => {
    if (SrNoData.length === 0 && data.length !== 0) {
      setSrNoData(data.map((item, index) => ({ ...item, SrNo: index + 1 })));
    }
   
  }, [data]);


  useEffect(()=>{
    if (column.length === 0) {
      passing.unshift({ label: "Sr. No", key: "SrNo" });
      setColumns(passing);
    }
  },[passing])

  return (
    <Button
    loading={loading}
      style={{
        border: "1px solid #E6EBF1",
        borderRadius: "8px",
        display:"flex"
      }}
    >
      {data &&
      data.length !== 0 &&
      SrNoData.length !== 0 &&
      column.length !== 0 ? (
        <CSVLink
          data={SrNoData}
          headers={column}
          filename={"Joompa-admin-portal.csv"}
        >
          <span style={{ transform: "rotate(-90deg)", marginRight: "5px" }}>
            <ExportOutlined />
          </span>
          Export
        </CSVLink>
      ) : (
        <div> Export</div>
      )}
    </Button>
  );
};

export default ExportButton;
