import React, { useState } from "react";
import { Spin, Tag, message } from "antd";
import "./cms.css";
import { Button, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import joompaApi from "../../utils/apis/joompaApi";

const PersonalityCard = ({ title, id, data,arrayName }) => {
  const [loading, setLoading] = useState(false);
  const [taglist, setTaglist] = useState(data);
  const [tagInput, setTagInput] = useState("");
  // console.log("taglist", data);
  const handleClose =async(value) => {
    // console.log("close tag value:-",value)
    try {
      setLoading(true)
      const res=await joompaApi.put("/profileInfos",{title:value.title,_id:id,key:arrayName,status:false})
      const data=res?.data
      const newTags = taglist.filter((tag) => tag !== value);
      setTaglist(newTags);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
  };
  const handleTagAdd = async() => {
    if (!tagInput) {
      message.warning("please enter a tag");
      return ;
    } 
    try {
      setLoading(true)
      const res=await joompaApi.put("/profileInfos",{title:tagInput,_id:id,key:arrayName,status:true})
      const data=res?.data
      // console.log("response data",data)
      setTaglist([
        ...taglist,
        {
          title: tagInput,
          status: true,
          _id: Math.random(),
        },
      ]);
      setLoading(false)
      setTagInput("");
      // console.log(`tag ${taglist}`); // status not send when put
    } catch (error) {
      setLoading(false)
      message.error(error.message);
    }
      
    
  };
  return (
    <div className="white-card">
      <div className="personality-card">
        <div className="personality-card-title">{title}</div>
        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
          <Input
            placeholder="Type Here"
            style={{
              width: "340px",
              border: "1px solid #E6EBF1",
              borderRadius: "8px",
            }}
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
          />
          <Button className="add-button" onClick={handleTagAdd}>
            {loading ? <LoadingOutlined /> : "Add"}
          </Button>
        </div>
        <hr />
        <div className="tag-list">
          {taglist &&
            taglist.map((obj) => (
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(obj);
                }}
                className="tag"
                key={obj._id}
              >
                {obj.title}
              </Tag>
            ))}
            {loading && <Spin/>}
        </div>
      </div>
    </div>
  );
};

export default PersonalityCard;
