import React, { useState, useEffect } from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import {
  SearchOutlined,
  MoreOutlined,
  ExportOutlined,
  AlignCenterOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Table, Input, Popover, Button, Image, Spin, message, Popconfirm } from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
// import ExportButton from "../../Components/Common/ExportButton";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";
import CustomPopover, { PopoverContent } from "../../utils/Constant";
import TableComponent from "../../Components/Common/TableComponent";
import Loading from "../../Components/Common/Loading";
import { colorOfStatus, formatDate } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import { TRANSACTIONS } from "../../utils/Urls";
import { Link } from 'react-router-dom';
import { headersForPremiumUsers } from "../../utils/Columns";
import NonPremium from "../../Assets/Images/icons/Vector.svg"
const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const PremiumUsers = () => {
  const [open, setOpen] = useState(true);
  const [premiumUsersData, setPremiumUsersData] = useState([])
  const [loading, setLoading] = useState(false)
  const [keypath, setKeypath] = useState('')
  const [newAllUsersData, setNewAllUsersData] = useState([]);
  const [allUsersCount, setAllUsersCount] = useState(0)
  const [loading1, setLoading1] = useState(false)
  const [filterWithSearch, setFilterWithSearch] = useState([]);
  const [forExport, setForExport] = useState([]);//[{}
  const [loading4, setLoading4] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(10)
  const [search, setSearch] = useState("");
  useEffect(() => {
    let nAllUsersData = forExport
    nAllUsersData.map((item) => {
      item.purchaseDate = formatDate(item.purchaseDate);
      item.expireDate = formatDate(item.expireDate);
    })
    setNewAllUsersData(nAllUsersData)
  }, [forExport])


  useEffect(() => {

    if (!keypath && allUsersCount) {
      getData(true, false)
      setFilterWithSearch([])
    } else if (keypath && allUsersCount) {
      handledropdownclick({ key: keypath, keyPath: keypath.split("/") })
    }

  }, [page, count])

  const fetchForExport = async () => {
    try {
      setLoading1(true)
      let res = await joompaApi.get(`/premiumusers?pageNumber=1&pageSize=${allUsersCount}`);
      let data = res?.data?.data;
      setForExport(data);
      // console.log(mapData)
      setLoading1(false)
    } catch (error) {
      setLoading1(false)
      message.error("Something Went Wrong")
    }
  };

  useEffect(()=>{
    if(localStorage.getItem("mousePoisition")&& premiumUsersData.length>0){
        setTimeout(()=>{
          const storedPosition = localStorage.getItem("mousePoisition");
      // console.log(storedPosition)
      if (storedPosition !== null) {
          const scrollPosition = (storedPosition);
          const siteLayout = window.document.getElementsByClassName("site-layout")[0];
          if (siteLayout) {
              siteLayout.scrollTo(0, scrollPosition);
              localStorage.removeItem("mousePoisition");
          } else {
              console.error("Element with class 'site-layout' not found.");
          }
      } else {
          console.error("No stored scroll position found.");
      }
        },1000)
    }
  },[premiumUsersData])


  useEffect(() => {
    if (
      localStorage.getItem("search") ||
      localStorage.getItem("filter") ||
      localStorage.getItem("page") || localStorage.getItem("mousePoisition")
    ) {
      localStorage.getItem("search") &&
        setSearch(localStorage.getItem("search"));
      localStorage.getItem("filter") &&
        setFilterWithSearch(localStorage.getItem("filter").split(","));
      localStorage.getItem("filter") &&
        setKeypath(localStorage.getItem("filter").split(",")[0]);
      localStorage.getItem("page") && setPage(localStorage.getItem("page"));
      getDataForLocal(
        localStorage.getItem("filter")
          ? localStorage.getItem("filter").split(",")
          : [],
        localStorage.getItem("search") ? localStorage.getItem("search") : "",
        localStorage.getItem("page") ? localStorage.getItem("page") : 1
      );
      localStorage.removeItem("search");
      localStorage.removeItem("filter");
      localStorage.removeItem("page");
      return;
    }

    getData();
  }, []);

  const confirm = async (uid) => {
    try {
      const res = await joompaApi.delete(`/userDelete/${uid}`)
      if (res?.data?.status) {
        // console.log("User deleted successfully",res)
        message.success(res?.data?.message)
        setOpen(false)
        getData()
      }
    } catch (error) {
      message.error("User Delete Failed")
    }

  };

  const getData = async (filter = false, searchs = false) => {
    try {
      setLoading(true)
      let res = await joompaApi.get(`/premiumusers?${!filter && filterWithSearch.length > 0 ? `filter=true&menu=${filterWithSearch[1] ? filterWithSearch[1] : ""}&submenu=${filterWithSearch[0] ? filterWithSearch[0] : ""}` : ""}&search=${!searchs ? search : ""}`);
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setPremiumUsersData(mapData);
      setLoading(false)
      if (!filter && !searchs) {
        setAllUsersCount(res?.data?.count)
      } else {
        setAllUsersCount(false)
      }
      //   if(allUsersCount!==res?.data?.count){
      //     fetchForExport(res?.data?.count)
      //  }
    } catch (error) {
      setLoading(false)
      message.error("Something went wrong")
    }
  };


  const getDataForLocal = async (filter, search, page) => {
    setPage(page)
    try {
      setLoading(true)
      let res = await joompaApi.get(`/premiumusers?${filter.length > 0
        ? `&filter=true&menu=${filter[1] ? filter[1] : ""}&submenu=${
            filter[0] ? filter[0] : ""
          }`
        : ""}&search=${search}`);
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setPremiumUsersData(mapData);
      setLoading(false)
      if (page && !search && filter.length == 0) {
        setAllUsersCount(res?.data?.count);
      } else {
        setAllUsersCount(false);
      }
      //   if(allUsersCount!==res?.data?.count){
      //     fetchForExport(res?.data?.count)
      //  }
    } catch (error) {
      setLoading(false)
      message.error("Something went wrong")
    }
  };


  const handledropdownclick = async ({ key, keyPath }) => {
    setKeypath(key)

    if (keyPath[0] === "All") {
      getData(true, false)
      setFilterWithSearch([])
      return
    }
    setFilterWithSearch(keyPath)
    setLoading3(true)
    // console.log('filterMenu',keyPath[0])
    try {

      let res = await joompaApi.get(keyPath[0] === "All" ? `/premiumusers` : `/premiumusers?filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}&search=${search}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setPremiumUsersData(mapData);
      setAllUsersCount(false)
      setLoading3(false)
    } catch (error) {
      message.error("Something went wrong")
      setLoading3(false)
      // console.log(error)
    }
  };


  const handleSearchInput = async (e) => {
    setSearch(e.target.value)
    if (e.target.value === "") {
      getData(false, true)
      setSearch("")
      return
    }
    setLoading4(true)
    try {
      let query = e.target.value
      let res = await joompaApi.get(`/premiumusers?search=${query}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setPremiumUsersData(mapData);
      setAllUsersCount(false)
      setLoading4(false)
    } catch (error) {
      setLoading4(false)
      message.error("Something went wrong")
      // console.log(error)
    }
  };


  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [current, setCurrent] = useState("1");
  const onClick = (e) => {
    setCurrent(e.key);
  };

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "center",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "",
      dataIndex: "userAvatar",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.fullName < b.fullName,
      sortDirections: ["ascend", "descend"],
      width: "121px",
      className: "col-special",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "User Type",
      dataIndex: "isPremium",
      className: "col-normal",
      align: "center",
      render(text) {
        return {
          children: text ? <img width="24px" height="19px" src={premiumUsers} alt="user Type" /> : <img width="24px" height="19px" src={NonPremium} alt="user Type" />
        };
      },
    },
    {
      title: "Purchase Date",
      dataIndex: "purchaseDate",
      sorter: (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: text ? formatDate(text) : "-",
        };
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expireDate",
      sorter: (a, b) => new Date(a.expireDate) - new Date(b.expireDate),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: text ? formatDate(text) : "-",
        };
      },
    },
    {
      title: "Amount Spent",
      dataIndex: "amountSpent",
      sorter: (a, b) => a.amountSpent - b.amountSpent,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
    },
    {
      title: "Status",
      dataIndex: "accountStatus",
      sorter: (a, b) => a.accountStatus < b.accountStatus,
      sortDirections: ["ascend", "descend"],
      render(text, record) {
        return {
          children: <div style={colorOfStatus(text)}>{text}</div>,
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              {open && <Popover
                placement="bottomRight"
                content={
                  <>
                    <Link
                      to={TRANSACTIONS}
                      style={{ color: "black" }}
                      state={{ userId: record.userId }}
                      onClick={()=>{
                        localStorage.setItem("search", search);
                        localStorage.setItem("filter", filterWithSearch);
                        localStorage.setItem("page", page);
                        localStorage.setItem("mousePoisition",window.document.getElementsByClassName("site-layout")[0].scrollTop);
                      }}
                    >
                      <EyeOutlined /> View Details
                    </Link>
                    {/* <p>
                  <DeleteOutlined /> Delete
                </p> */}
                    <Popconfirm
                      // title="Delete the task"
                      title="Are You Sure To Delete This User?"
                      onConfirm={() => confirm(record.userId)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      // placement="leftBottom"
                      placement="left"
                    >
                      <button
                        style={{
                          border: "none",
                          marginLeft: "-38px",
                          background: "none",
                          paddingBottom: "10px",
                          cursor: "pointer",
                          color: "red"
                        }}
                      >
                        {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                        <DeleteOutlined style={{ marginRight: "5px" }} />Delete
                      </button>
                    </Popconfirm>
                  </>
                }
                trigger="click"
                overlayInnerStyle={{
                  borderRadius: "12px",
                  textAlign: "center",
                }}
                overlayClassName="popover-menu"
                showArrow={false}
              >
                <Link>{text}</Link>
              </Popover>
              }
            </div>
          ),
        }
      }
    },
  ];

  const content = (
    <div className="popover-menu">
      <p>
        <EyeOutlined /> View Details
      </p>
      <p>
        <DeleteOutlined /> Delete
      </p>
    </div>
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows);
    },
  };
  return (
    <div className="user-container">
      <div className="buttons-container">
        <SearchInput handleSearchInput={handleSearchInput} loading={loading4} />
        <FilterButton loading={loading3} handledropdownclick={handledropdownclick} keypath={keypath} />
        {/* {newAllUsersData.length!==0 || premiumUsersData.length!==0 && */}
        <ExportButtonNew fetch={fetchForExport} loading={loading1} data={newAllUsersData} passing={headersForPremiumUsers} />
        {/* } */}
      </div>
      <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent rowKey="userId" data={premiumUsersData} columns={columns} setPage={setPage} setCount={setCount} count={allUsersCount} page={page}
          type="premium" />
      </Spin>


      {/* {loading?<Loading/>:<TableComponent data={premiumUsersData} columns={columns} />} */}


    </div>
  )
}

export default PremiumUsers