import React from 'react'
import {Spin } from 'antd';
const Loading = () => {
  return (
    <div style={{
      position:"absolute",
      top:"30%",
      right:"40%",
    }}>
      <Spin size="large" tip="Loading..."/>
    </div>
  )
}

export default Loading