import React, { useState, useEffect } from "react";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { MoreOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Popover, Image, Spin, message } from "antd";
import dollar from "../../Assets/Images/tableIcons/dollar.png";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import NonPremium from "../../Assets/Images/icons/Vector.svg"
 import {
  SUBSCRIPTION_ADD_SUGGESTION_FORM,
  SUBSCRIPTION_USERDETAILS,
  TRANSACTIONS,
} from "../../utils/Urls";
import CustomPopover, { formatDate, colorOfStatus } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import { headerForUserWallet } from "../../utils/Columns";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";




const columns = [
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    sortDirections: ["ascend","descend"],
    className: "col-normal",
  },
  {
    title: "",
    dataIndex: "userAvatar",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "User Name",
    dataIndex: "fullName",
    // defaultSortOrder: "ascend",
    sorter: (a, b) => a.fullName < b.fullName,
    sortDirections: ["ascend","descend"],
    width: "12%",
    className: "col-special",
    render(text, record) {
      return {
        props: {
          style: { background: "white" },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "User Type",
    dataIndex: "isPremium",
    className: "col-normal",
    align: "center",
    render(text) {
      return {
        children: text ? (
          <img width="24px" height="19px" src={premiumUsers} alt="user Type" />
        ) : (
          <img width="24px" height="19px" src={NonPremium} alt="user Type" />
        ),
      };
    },
  },
  {
    title: "wallet Balance",
    dataIndex: "walletBalance",
    sorter: (a, b) => a.walletBalance - b.walletBalance,
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    align:"center"
  },
  {
    title: "Last Purchase",
    dataIndex: "lastTransactionDate",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.lastTransactionDate) - new Date(b.lastTransactionDate),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Total Spending",
    dataIndex: "totalSpendPrice",
    sorter: (a, b) => a.totalSpendPrice - b.totalSpendPrice,
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    align:"center"
  },
  {
    title: "Status",
    dataIndex: "accountStatus",
    sorter: (a, b) => a.accountStatus < b.accountStatus,
    sortDirections: ["ascend","descend"],
    render(text, record) {
      return {
        children: <div style={colorOfStatus(text)}>{text}</div>,
      };
    },
    width: "8%",
    className: "col-normal",
  },
  {
    title: "Action",
    dataIndex: "action",
    width: "8%",
    className: "col-normal",
    render: (text,record) => (
      <>
        <CustomPopover
          text={text}
          hrefLink={SUBSCRIPTION_USERDETAILS}
          formhrefLink={SUBSCRIPTION_ADD_SUGGESTION_FORM}
          userId={record.userId}
        />
        {/* <Popover placement="bottomRight" content={content} trigger="click">
          <a>{text}</a>
        </Popover> */}
      </>
    ),
  },
];

const UserWallet = () => {
  const [loading, setLoading] = useState(false);
  const [userWalletsData, setUserWalletsData] = useState([]);
  const [keypath,setKeypath]=useState('')
  const [allUsersCount, setAllUsersCount] = useState(0) 
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(10)
  const [loading1, setLoading1] = useState(false)
  const [forExport, setForExport] = useState([])
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)
  useEffect(() => {
    getData();
  }, []);

  const exportData = async()=>{
    setLoading1(true)
    try {
      let res = await joompaApi.get(`/subscriptions?pageNumber=1&pageSize=${allUsersCount}`)
      let data = res?.data?.data;
      setLoading1(false)
      setForExport(()=>{
       return data.map((obj) =>{
          return{
            ...obj,
            isPremium:obj.isPremium?"Yes":"No",
          }
        })
      })
    } catch (error) {
      setLoading1(false)
      message.error("Something went wrong")
      // console.log(error)
    }
  }

  const getData = async () => {
    setLoading(true);
    let res = await joompaApi.get(`/subscriptions?pageNumber=${page}&pageSize=${count}`);
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      ...obj.coinTransactions,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // console.log(mapData);
    // if(res?.data?.count!==allUsersCount){
    //   exportData(res?.data?.count)
    // }
    // let pendingData = mapData.filter((user)=>user.verificationRequest==="PENDING")
    setLoading(false);
    setUserWalletsData(mapData);
    setAllUsersCount(res?.data?.count)
  };


  useEffect(()=>{

    if(!keypath && allUsersCount){
      getData()
    }else if(keypath && allUsersCount){
      handledropdownclick({key:keypath,keyPath:keypath.split("/")})
    }
    
  },[page,count])

  const handledropdownclick = async({key,keyPath }) => {
    setKeypath(key)
    // console.log('filterMenu',keyPath[0])
    setLoading3(true)
    try {
      
      let res = await joompaApi.get(`/subscriptions?filter=true&menu=${keyPath[1]}&submenu=${keyPath[0]}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        ...obj.coinTransactions,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setUserWalletsData(mapData);
      setAllUsersCount(false)
      setLoading3(false)
    } catch (error) {
      setLoading3(false)
      // console.log(error)
    }
  };


  const handleSearchInput = async(e) => {
    if(e.target.value === "") {
      getData()
      return
    }
    setLoading4(true)
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/subscriptions?search=${query}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        ...obj.coinTransactions,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }));
      setUserWalletsData(mapData);
      setAllUsersCount(false)
      setLoading4(false)
    } catch (error) {
      setLoading4(false)
      // console.log(error)
    }
  };
  return (
    <div>
      <div className="buttons-container">
      <SearchInput loading={loading4} handleSearchInput={handleSearchInput} />
        <FilterButton loading={loading3} handledropdownclick={handledropdownclick} keypath={keypath}  width={"150px"}/>
        {/* {(userWalletsData.length!==0 || forExport.length!==0 )&& <ExportButton loading={loading1} data={forExport} passing={headerForUserWallet}/>} */}
        <ExportButtonNew loading={loading1} data={forExport} passing={headerForUserWallet} fetch={exportData} />
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent data={userWalletsData} columns={columns}setPage={setPage} setCount={setCount} count={allUsersCount} />
      </Spin>
    </div>
  );
};

export default UserWallet;
