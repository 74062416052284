const ADMIN_PROFILE_DETAILS="/profiledetails"
const DASHBOARD = "/dashboard";
/*User management */
const ALL_USER = "/usermanagement/allusers";
const DASHBOARD_DATA = "/usermanagement/dashboardData/PremiumSubscriptions";
const DASHBOARD_DATA_REVENUE = "/usermanagement/dashboardData/Revenue";
const DASHBOARD_DATA_NEW_USER = "/usermanagement/dashboardData/NewUser"
const DASHBOARD_DATA_RELIGION = "/usermanagement/dashboardData/Religion"
const  DASHBOARD_COUNTRY_SIGNUP = "/usermanagement/dashboardData/UserSignUp"
const PREMIUM_USERS = "/usermanagement/premiumusers";
const INACTIVE_USERS="/usermanagement/inactiveusers"
const PENDING_PROFILES = "/usermanagement/verificationRequest";
const REQUESTED_PROFILES = "/usermanagement/profiledeletion";
const TRANSACTIONS="/usermanagement/userdetails";
const ADD_SUGGESTION_FORM=`${TRANSACTIONS}/addsuggestion`;
const BAN_USERS = "/usermanagement/banUsers";
/*Staff management */
const ADMIN_MANAGEMENT = "/staffmanagement/admin";
const VIEW_ADMIN = "/staffmanagement/admin/add";
const EDIT_ADMIN = "/staffmanagement/admin/edit";
/*Subsription */
const SUBSCRIPTION_USERDETAILS="/subsciption/userdetails"
const SUBSCRIPTION_ADD_SUGGESTION_FORM="/subsciption/userdetails/addsuggestion"
const CONCIERGE_REQUEST = "/subsciption/conciergerequest";
const FEATURE_COST = "/subsciption/packages";
const ADD_NEW_FEATURE="/subsciption/packages/addnewpackage";
const EDIT_PACKAGE="/subsciption/packages/editpackage";
const PREMIMUM_SIGNUP = "/subsciption/premimumsignups";
const USER_WALLET = "/subsciption/userwallets";
const FINANCE_REPORT="/subsciption/financereport";
/*Verfication request */
const VERIFICATION_REQUEST = "/pendingVerification";
/*admin support */
const ADMIN_SUPPORT_CHATS = "/adminsupportchats";
/*CMS */
const ABOUT_US = "/cms/aboutus";
const CONTACT_US = "/cms/contactus";
const EMAIL_TEMPLATE = "/cms/emailtemplates";
const ADD_NEW_TEMPLATE="/cms/emailtemplates/addnewtemplate";
const UPDATE_TEMPLATE="/cms/emailtemplates/updateEmailTemplate";
const PERSONALITY_INFORMATION="/cms/personalityinformation";
const USER_CHAT_GAME="/cms/chatgame";
const VIEW_EMAIL_TEMPLATE = "/cms/viewemailtemplate";
const PRIVACY_POLICY = "/cms/privacypolicy";
const FAQ = "/cms/faq";
const SCHEDULED_NOTIFICATION = "/cms/schedulednotifications";
const ADD_NEW_NOTIFICATION="/cms/schedulednotifications/addnewnotification";
const EDIT_NOTIFICATION="/cms/schedulednotifications/editNotification";
const TERM_AND_CONDITIONS = "/cms/termsandconditions";
const ABOUT_ME_QUESTIONS = "/cms/aboutMeQuestions";
const FEATURES_FREE_QUOTA = "/cms/featuresFreeQuota";
const ALL_USER_REPORTS = "/userReports";

/*Events */
const EVENTS = "/events";
const EVENT_DETAILS='/events/eventDetails'

export {
  DASHBOARD_DATA,
  DASHBOARD_DATA_REVENUE,
  ADMIN_MANAGEMENT,
  DASHBOARD_DATA_NEW_USER,
  DASHBOARD_DATA_RELIGION,
  DASHBOARD_COUNTRY_SIGNUP,
  EDIT_PACKAGE,
  FEATURES_FREE_QUOTA,
  ABOUT_ME_QUESTIONS,
  EVENTS,
  UPDATE_TEMPLATE,
  EVENT_DETAILS,
  SUBSCRIPTION_USERDETAILS,
  EDIT_NOTIFICATION,
  SUBSCRIPTION_ADD_SUGGESTION_FORM,
  VIEW_ADMIN,
  EDIT_ADMIN,
  ADMIN_PROFILE_DETAILS,
  PERSONALITY_INFORMATION,
  USER_CHAT_GAME,
  ALL_USER,
  DASHBOARD,
  PREMIUM_USERS,
  INACTIVE_USERS,
  PENDING_PROFILES,
  REQUESTED_PROFILES,
  TRANSACTIONS,
  ADD_SUGGESTION_FORM,
  CONCIERGE_REQUEST,
  FEATURE_COST,
  ADD_NEW_FEATURE,
  PREMIMUM_SIGNUP,
  USER_WALLET,
  FINANCE_REPORT,
  VERIFICATION_REQUEST,
  ADMIN_SUPPORT_CHATS,
  ABOUT_US,
  CONTACT_US,
  EMAIL_TEMPLATE,
  ADD_NEW_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
  PRIVACY_POLICY,
  FAQ,
  SCHEDULED_NOTIFICATION,
  ADD_NEW_NOTIFICATION,
  TERM_AND_CONDITIONS,
  ALL_USER_REPORTS,
  BAN_USERS
};



