import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import dashboardIcon from "../../Assets/Images/icons/dashboardBlueIcon.png";
import events from "../../Assets/Images/icons/events.png";
import userIcon from "../../Assets/Images/User.png";
import verifiedIcon from "../../Assets/Images/VerifiedIcon.png";
import cmsIcon from "../../Assets/Images/CMS.png";
import verificationIcon from "../../Assets/Images/icons/verificationRequest.png";
import adminSupporIcon from "../../Assets/Images/icons/adminSupportChat.png";
import user from "../../Assets/Images/icons/flag.png"
import staff from "../../Assets/Images/icons/Group.png"
const breadIcon = [
  { path: "dashboard", icon: dashboardIcon },
  { path: "usermanagement", icon: userIcon },
  { path: "subsciption", icon: verifiedIcon },
  { path: "cms", icon: cmsIcon },
  { path: "verificationrequest", icon: verificationIcon },
  { path: "adminsupportchats", icon: adminSupporIcon },
  { path: "events", icon: events },
  { path: "userReports", icon: user },
  { path: "staffmanagement", icon: staff},
];

const UrlsBreadCrumbs = [
  { path: "dashboard", title: "Dashboard" },
  { path: "staffmanagement", title: "Staff Management" },
  { path: "dashboardData", title: "User Details" },
  { path: "PremiumSubscriptions", title: "Premium Subscription" },
  { path: "Revenue", title: "Revenue" },
  { path: "NewUser" , title :"New User"},
  { path: "profiledetails", title: "Profile Details" },
  { path: "Religion", title: "Religion" },
  { path: "admin", title: "All Admins" },
  { path: "UserSignUp" , title: "Country SignUp"},
  { path: "allusers", title: "All Users" },
  { path: "usermanagement", title: "User Management", disable: true },
  { path: "premiumusers", title: "Premium Users" },
  { path: "inactiveusers", title: "Inactive Users" },
  { path: "pendingverifications", title: "Pending Verifications" },
  { path: "profiledeletion", title: "Profile Deletion" },
  { path: "userdetails", title: "User Details" },
  { path: "addsuggestion", title: "Add Suggestion" },
  { path: "subsciption", title: "Subscription" },
  { path: "conciergerequest", title: "Concierge Request" },
  { path: "packages", title: "Packages" },
  { path: "addnewpackage", title: "Add New Package" },
  { path: "editpackage", title: "Edit Package" },
  { path: "add", title: "Add" },
  { path :"edit", title: "Edit"},
  { path: "premimumsignups", title: "Premium Signups" },
  { path: "userwallets", title: "User Wallets" },
  { path: "financereport", title: "Finance Report" },
  { path: "verificationrequest", title: "Verification Request" },
  { path: "adminsupportchats", title: "Admin Support Chats" },
  { path: "cms", title: "CMS" },
  { path: "contactus", title: "Contact Us" },
  { path: "aboutus", title: "About Us" },
  { path: "emailtemplates", title: "Email Templates" },
  { path: "privacypolicy", title: "Privacy Policy" },
  { path: "faq", title: "FAQ"},
  { path: "schedulednotifications", title: "Scheduled Notifications" },
  { path: "addnewnotification", title: "Add New Notification" },
  { path: "termsandconditions", title: "Terms & Conditions" },
  { path: "addnewtemplate", title: "Add New Template" },
  { path: "personalityinformation", title: "Personality Information" },
  { path: "chatgame", title: "Chat Game" },
  { path: "viewemailtemplate", title: "View Email Template" },
  { path: "events", title: "Events"},
  { path: "eventDetails", title: "Event Details"},
  { path: "editNotification", title: "Edit Notification"},
  { path: "updateEmailTemplate", title: "Update Email Template"},
  { path: "aboutMeQuestions", title: "About Me Questions"},
  { path: "featuresFreeQuota", title: "Features Free Quota"},
  { path: "userReports", title: "User Report" },
  { path: "banUsers", title: "Ban Users" }
];
const CustomBreadcrumb = () => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split("/").filter((item) => item);
    return (
      <div>
        <Breadcrumb>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            const isFirst = index === 0;
            return isLast || isFirst ? (
              <Breadcrumb.Item
                style={{
                  color: isLast ? "#1B75BC" : "#72849A",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {breadIcon.map(
                  ({ path, icon }) =>
                    path === name && (
                      <img
                        style={{ marginRight: "5px", marginTop: "-4px" }}
                        src={icon}
                      />
                    )
                )}
                {UrlsBreadCrumbs.map(
                  ({ path, title }) => path === name && title
                )}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                style={{
                  color: "#72849A", //white
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                <Link to={`${routeTo}`}>
                  {breadIcon.map(
                    ({ path, icon }) =>
                      path === name && (
                        <img
                          style={{ marginRight: "5px", marginTop: "-4px" }}
                          src={icon}
                        />
                      )
                  )}
                  {UrlsBreadCrumbs.map(
                    ({ path, title }) => path === name && title
                  )}
                </Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    );
  };
  return <>{breadCrumbView()}</>;
};

export default CustomBreadcrumb;
