import React,{useState} from 'react'
import "./adminchat.css";
import { MoreOutlined } from "@ant-design/icons";
import userAvatar from "../../Assets/Images/userImage.png";
import { Popover, Modal,Image } from "antd";
import accept from "../../Assets/Images/accept.png";
import reject from "../../Assets/Images/reject.png";
import TextArea from "antd/lib/input/TextArea";

const ChatHeader = ({userProfileData}) => {
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const handleCloseChat = () => {
    setRejectModal(false);
    setAcceptModal(true);
  };
    // const content = (
    //     <div className="popover-menu">
    //       <p>
    //         <button
    //           style={{
    //             border: "none",
    //             backgroundColor: "white",
    //             cursor: "pointer",
    //           }}
    //           onClick={() => setRejectModal(true)}
    //         >
    //           Close Chat
    //         </button>
    //       </p>
    //     </div>
    //   );
  return (
    <div className="user-chat-header">
            <div className="chat-profile">
              <div style={{ display: "flex" }}>
                <Image src={userProfileData?.profilePhoto} width="48px" height="45px" style={{ borderRadius: "50%" }}/>
                <div className="chat-user-data">
                  <span className="chat-user-name">{userProfileData?.userName}</span>
                  <span className="chat-user-email">{userProfileData?.email}</span>
                </div>
              </div>
              <div>
                {/* <Popover placement="leftTop" content={content} trigger="click" showArrow={false}>
                  <a>
                    <MoreOutlined style={{fontSize:"20px",fontStyle:"bold"}} />
                  </a>
                </Popover> */}
              </div>
            </div>
            <Modal
        wrapClassName="accept-modal"
        centered
        visible={acceptModal}
        footer={null}
        onCancel={() => setAcceptModal(false)}
      >
        <div className="accept-modal">
          <img src={accept} alt="accept-icon" />
          <p className="modal-masg">Chat Closed Successfully!</p>
          {/* <p className='modal-user-info'></p> */}
        </div>
      </Modal>
      <Modal
        wrapClassName="accept-modal"
        centered
        visible={rejectModal}
        footer={null}
        onCancel={() => setRejectModal(false)}
      >
        <div className="accept-modal">
          <img src={reject} alt="accept-icon" />
          <p className="modal-masg">Please enter remark before closing chat.</p>
          <TextArea rows={3} placeholder="Type Here..." />
          <div className="modal-buttons">
            <button className="save-button" onClick={handleCloseChat}>
              Close
            </button>
            <button
              className="back-button"
              onClick={() => setRejectModal(false)}
            >
              No Cancel
            </button>
          </div>
        </div>
      </Modal>
          </div>
  )
}

export default ChatHeader