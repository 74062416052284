import dollar from "../../Assets/Images/tableIcons/dollar.png"
import premium from "../../Assets/Images/tableIcons/premium.png"
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { Tooltip } from "antd";
import {
    HeartFilled,
    FlagFilled,
    MoreOutlined,
    DeleteOutlined,
    EyeOutlined,
  } from "@ant-design/icons";
  import { Image } from "antd";
  import { colorOfStatus, formatDate,getTransactionStatus,subscriptionIcon } from "../../utils/Constant";
import { statusActiveStyle, statusRejectedStyle } from "../../utils/Constant";
import NonPremium from "../../Assets/Images/icons/Vector.svg"
export const tabOnecolumns = [
  {
    title: "Transaction Id",
    dataIndex: "_id",
    // width:"15%",
    className: "col-special",
  },
  {
    title: "Subscription",
    dataIndex: "featuredType",
    // width:"2%",
    align:"center",
    // subscriptionIcon
    render(text, record) {
      return {
        children: (
          <Tooltip placement="bottom" title={text==="REVOKE PROFILE"?"UNDO PROFILE":text} >
          <img
          width="24px"
          height="19px"
            // style={{ borderRadius: "50%" }}
           
            src={subscriptionIcon(text)}
          />
          </Tooltip>
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "Amount",
    dataIndex: "price",
    render(text,record) {
      return {
        children: getTransactionStatus(record.price==0?record.coins:text,record.type),
      };
    },
  },
  {
    title: "Subscribed On",
    dataIndex: "date",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    sortDirections: ["ascend","descend"],
    width:'30%',
    className: "col-normal",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
    // align: "center",
  },
  {
    title: "Expired On",
    width:'30%',
    dataIndex: "expireDate",
    sorter: (a, b) => new Date(a.expireDate) - new Date(b.expireDate),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Status",
    dataIndex: "paymentStatus",
    // width: "8%",
    className: "col-normal",
    render(text, record) {
      if(record.featuredType==="REWARD"){
        text = "SUCCESS"
      }
      return {
        children: <div style={colorOfStatus(text)}>{text==="CREATED"?"PENDING":text}</div>,
      };
    },
}
];

export const tabTwocolumns = [
  {
    title: "",
    dataIndex: "userAvatar",
    className: "col-normal",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "Matched Profile",
    dataIndex: "matchedProfileName",
  },
  {
    title: "Profile Type",
    dataIndex: "profileType",
    render(text) {
      return {
        children: text=="PREMIUM" ? (
          <img
            width="24px"
            height="19px"
            src={premiumUsers}
            alt="user Type"
          />
        ) : (
          <img
            width="24px"
            height="19px"
            src={NonPremium}
            alt="user Type"
          />
        ),
      };
    },
  },
  {
    title: "Date of Match",
    dataIndex: "dateOfMatch",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.dateOfMatch) - new Date(b.dateOfMatch),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    // align: "center",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    // width: "8%",
    className: "col-normal",
    render(text) {
      return {
        children: <div style={colorOfStatus(text)}>{text}</div>,
      };
    },
}
];


export const tabThreecolumns = [
  {
    title: "",
    dataIndex: "userAvatar",
    className: "col-normal",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "Reported By",
    dataIndex: "reportedBy",
  },
  {
    title: "Reported On",
    dataIndex: "reportedOn",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.reportedOn) - new Date(b.reportedOn),
    sortDirections: ["ascend","descend"],
    align:"center",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
  },
  {
    title: "Reason",
    dataIndex: "reason",
  },

];
export const tabFourData = [];
for (let i = 0; i < 31; i++) {
    tabFourData.push({
      key: i,
      avatar: <img src={UserAvatar} alt="user avatar" />,
      suggestedprofile: `Sara M`,
      profiletype: <img src={premium} alt="user avatar" />,
      dateofsuggestion: `${i + 1}/05/2022`,
      status: "Match",
      action: <MoreOutlined style={{fontSize:"20px",fontStyle:"bold"}}/>,
  });
}
export const tabFourcolumns = [
   {
    title: "",
    dataIndex: "photoUrl",
    className: "col-normal",
    render(text, record) {
      return {
        children: (
          <Image
            style={{ borderRadius: "50%" }}
            width={40}
            height={40}
            src={text}
          />
          // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
        ),
      };
    },
  },
  {
    title: "Suggested Profile",
    dataIndex: "fullName",
  },
  {
    title: "Profile Type",
    dataIndex: "profiletype",
    render(text) {
      return {
        children: text=="PREMIUM" ? (
          <img
            width="24px"
            height="19px"
            src={premiumUsers}
            alt="user Type"
          />
        ) : (
          <img
            width="24px"
            height="19px"
            src={premiumUsers}
            alt="user Type"
          />
        ),
      };
    },
  },
  {
    title: "Date of Suggestion",
    dataIndex: "statusChangeDate",
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.statusChangeDate) - new Date(b.statusChangeDate),
    sortDirections: ["ascend","descend"],
    className: "col-normal",
    render(text) {
      return {
        children: formatDate(text),
      };
    },
    // align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    // width: "8%",
    className: "col-normal",
    render(text) {
      return {
        children: <div style={colorOfStatus(text)}>{text}</div>,
      };
    },
},
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   // width: "8%",
  //   className: "col-normal",
  //   align:"center"
  //   // render: (text) => (
  //   //   <>
  //   //     <Popover
  //   //       placement="bottomRight"
  //   //       content={
  //   //         <div className="popover-menu">
  //   //           <p>
  //   //             <EyeOutlined /> View Details
  //   //           </p>
  //   //           <p>
  //   //             <DeleteOutlined /> Delete
  //   //           </p>
  //   //         </div>
  //   //       }
  //   //       trigger="click"
  //   //     >
  //   //       <a>{text}</a>
  //   //     </Popover>
  //   //   </>
  //   // ),
  // },
];