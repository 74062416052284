import React from 'react'
import accept from "../../Assets/Images/accept.png";
import {Modal } from "antd";
const AcceptModal = ({setAcceptModal,acceptModal,userID="1345",fullName="xyz"}) => {
  return (
    <Modal
    wrapClassName="accept-modal"
    centered
    visible={acceptModal}
    footer={null}
    // cancelButtonProps={{ style: { display: 'none' } }}
    // okButtonProps={{ style: { display: 'none' } }}
    onCancel={() => setAcceptModal(false)}
  >
    <div className="accept-modal">
      <img src={accept} alt="accept-icon" />
      <p className="modal-masg">User Account verified successfully!</p>
      <p className="modal-user-info">{`User Id ${userID}, ${fullName} is verified successfully.`}</p>
    </div>
  </Modal>
  )
}

export default AcceptModal