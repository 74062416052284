import React, { useState, useEffect } from "react";
import UserAvatar from "../../Assets/Images/dashboard/UserAvatar.png";
import { MoreOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { TRANSACTIONS } from "../../utils/Urls";
import {
  Table,
  Input,
  Popover,
  Button,
  Image,
  Spin,
  Popconfirm,
  message,
} from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import CustomPopover from "../../utils/Constant";
import TableComponent from "../../Components/Common/TableComponent";
import Loading from "../../Components/Common/Loading";
import { colorOfStatus, formatDate } from "../../utils/Constant";
import joompaApi from "../../utils/apis/joompaApi";
import { headersForVerification } from "../../utils/Columns";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";
const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const submenuData = [
  {
    label: "All",
    key: "ALL",
  },
  {
    label: "Accept",
    key: "ACCEPT",
  },
  {
    label: "Reject",
    key: "REJECT",
  },
  {
    label: "Pending",
    key: "PENDING",
  },
];
const PendingProfiles = () => {
  const [pendingVerificationData, setPendingVerificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [newAllUsersData, setNewAllUsersData] = useState([]);
  const [allUsersCount, setAllUsersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [loading1, setLoading1] = useState(false);
  const [forExport, setForExport] = useState([]); //[{}
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [filterWithSearch, setFilterWithSearch] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    let nAllUsersData = forExport;
    nAllUsersData.map((item) => {
      item.regDate = formatDate(item.regDate);
      item.verificationRequestDate = formatDate(item.verificationRequestDate);
    });
    setNewAllUsersData(nAllUsersData);
  }, [forExport]);

  useEffect(() => {
    if (status === "ACCEPT" || status === "REJECT" || status === "PENDING") {
      getData();
    } else {
      getData(true, false);
    }
  }, [status]);

  const handleDropdown = ({ key }) => {
    setStatus(key);
    // console.log("handleDorpdonw",key)
  };
  const filteredData = async () => {
    setLoading3(true);
    let res = await joompaApi.get(
      `/verificationRequest?verificationRequest=${
        status === "ALL" ? "" : status
      }&search=${search}`
    );
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // let pendingData=mapData.filter((obj)=>obj.verificationRequest!=="null")
    setPendingVerificationData(mapData);
    setAllUsersCount(false);
    setLoading3(false);
    // console.log(mapData)
    // setLoading(false)
  };

  const fetchForExport = async () => {
    try {
      setLoading1(true);
      let res = await joompaApi.get(
        `/verificationRequest?pageNumber=1&pageSize=${allUsersCount}`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
      }));
      setForExport(mapData);
      setLoading1(false);
    } catch {
      setLoading1(false);
      message.error("Something Went Wrong");
    }
  };

  const getData = async (filter = false, searchs = false) => {
    if (
      !searchs &&
      !filter &&
      (status === "ACCEPT" || status === "REJECT" || status === "PENDING")
    ) {
      filteredData();
      return;
    }
    setLoading(true);
    let res = await joompaApi.get(
      `/verificationRequest?pageNumber=${page}&pageSize=${count}&search=${
        searchs ? "" : search
      }&verificationRequest=${filter ? "" : status}`
    );
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // let pendingData=mapData.filter((obj)=>obj.verificationRequest!=="null")
    setPendingVerificationData(mapData);
    //   if(allUsersCount!==res?.data?.count){
    //     fetchForExport(res?.data?.count)
    //  }
    if (!filter || !searchs) {
      setAllUsersCount(res?.data?.count);
    }
    else if(!searchs && search){
      setAllUsersCount(res?.data?.count);
    }
    else if(!filter && filterWithSearch.length>0){
      setAllUsersCount(res?.data?.count);
    }
    else {
      setAllUsersCount(false);
    }
    // setAllUsersCount(res?.data?.count)
    // console.log(mapData)
    setLoading(false);
  };
  const [open, setOpen] = useState(true);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [current, setCurrent] = useState("1");
  const onClick = (e) => {
    setCurrent(e.key);
  };

  const confirm = async (uid) => {
    try {
      const res = await joompaApi.delete(`/userDelete/${uid}`);
      if (res?.data?.status) {
        // console.log("User deleted successfully",res)
        message.success(res?.data?.message);
        setOpen(false);
        getData();
      }
    } catch (error) {
      message.error("User Delete Failed");
    }
  };

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: "15%",
      align: "center",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      // align: "center",
    },
    {
      title: "",
      dataIndex: "userAvatar",
      width: "15%",
      align: "center",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.fullName < b.fullName,
      sortDirections: ["ascend", "descend"],
      width: "15%",
      className: "col-special",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Uploaded Selfie",
      dataIndex: "verifyPhotoUrl",
      className: "col-normal",
      width: "15%",
      align: "center",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "Requested On",
      dataIndex: "verificationRequestDate",
      defaultSortOrder: "descend",
      width: "15%",
      sorter: (a, b) =>
        new Date(a.verificationRequestDate) -
        new Date(b.verificationRequestDate),
      sortDirections: ["ascend", "descend"],
      className: "col-normal",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "verificationRequest",
      width: "15%",
      sorter: (a, b) => a.verificationRequest < b.verificationRequest,
      sortDirections: ["ascend", "descend"],
      render(text, record) {
        return {
          children: <div style={colorOfStatus(text)}>{text}</div>,
        };
      },
      width: "8%",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "8%",
      className: "col-normal",
      render: (text, record) => {
        return {
          children: (
            <div>
              {open && (
                <Popover
                  placement="bottomRight"
                  content={
                    <>
                      <Link
                        to={TRANSACTIONS}
                        style={{ color: "black" }}
                        state={{ userId: record.userId }}
                        onClick={() => {
                          localStorage.setItem("search", search);
                          localStorage.setItem("filter", status);
                          localStorage.setItem("page", page);
                          localStorage.setItem("mousePoisition",window.document.getElementsByClassName("site-layout")[0].scrollTop);
                        }}
                      >
                        <EyeOutlined /> View Details
                      </Link>
                      {/* <p>
                  <DeleteOutlined /> Delete
                </p> */}
                      <Popconfirm
                        // title="Delete the task"
                        title="Are You Sure To Delete This User?"
                        onConfirm={() => confirm(record.userId)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        // placement="leftBottom"
                        placement="left"
                      >
                        <button
                          style={{
                            border: "none",
                            marginLeft: "-38px",
                            background: "none",
                            paddingBottom: "10px",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                          <DeleteOutlined style={{ marginRight: "5px" }} />
                          Delete
                        </button>
                      </Popconfirm>
                    </>
                  }
                  trigger="click"
                  overlayInnerStyle={{
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                  overlayClassName="popover-menu"
                  showArrow={false}
                >
                  <Link>{text}</Link>
                </Popover>
              )}
            </div>
          ),
        };
      },
    },
  ];

  const getDataForLocal = async (filter, search, page) => {
    setLoading(true);
    let res = await joompaApi.get(
      `/verificationRequest?pageNumber=${page}&pageSize=${count}&search=${search}&verificationRequest=${filter}`
    );
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    
    setPendingVerificationData(mapData);
    setAllUsersCount(res?.data?.count);
    setLoading(false);
  
  }


  useEffect(() => {
    if(localStorage.getItem("mousePoisition") && pendingVerificationData.length!==0){
      setTimeout(()=>{
        const storedPosition = localStorage.getItem("mousePoisition");
    // console.log(storedPosition)
    if (storedPosition !== null) {
        const scrollPosition = (storedPosition);
        const siteLayout = window.document.getElementsByClassName("site-layout")[0];
        if (siteLayout) {
            siteLayout.scrollTo(0, scrollPosition);
            localStorage.removeItem("mousePoisition");
        } else {
            console.error("Element with class 'site-layout' not found.");
        }
    } else {
        console.error("No stored scroll position found.");
    }
      },1000)
  }
  },[
    pendingVerificationData
  ])

  useEffect(() => {
    if (
      localStorage.getItem("search") ||
      localStorage.getItem("filter") ||
      localStorage.getItem("page") || 
      localStorage.getItem("mousePoisition")
    ) {
      localStorage.getItem("search") &&
        setSearch(localStorage.getItem("search"));
      localStorage.getItem("filter") &&
        setFilterWithSearch(localStorage.getItem("filter").split(","));
      localStorage.getItem("filter") &&
        setStatus(localStorage.getItem("filter"));
      localStorage.getItem("page") && setPage(localStorage.getItem("page"));
      getDataForLocal(
        localStorage.getItem("filter") ? localStorage.getItem("filter") : "ALL",
        localStorage.getItem("search") ? localStorage.getItem("search") : "",
        localStorage.getItem("page") ? localStorage.getItem("page") : 1
      );
      localStorage.removeItem("search");
      localStorage.removeItem("filter");
      localStorage.removeItem("page");

      // setTimeout(() => {
      //   if (localStorage.getItem("mousePoisition")) {
      //     let mouse = localStorage.getItem("mousePoisition");
      //     // setScrollPosition(mouse);
      //     window.document.getElementsByClassName("site-layout")[0].scrollTo(0, 453);

      //   }
      // }, 1000);

      return;
    }
    getData();
  }, []);

  const handleSearchInput = async (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      getData(false, true);
      return;
    }
    setLoading4(true);
    try {
      let query = e.target.value;
      let res = await joompaApi.get(
        `/verificationRequest?search=${query}&verificationRequest=${
          status === "ALL" ? "" : status
        }`
      );
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: (
          <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />
        ),
      }));
      setPendingVerificationData(mapData);
      setAllUsersCount(false);
      setLoading4(false);
    } catch (error) {
      setLoading4(false);
      // console.log(error)
    }
  };

  useEffect(() => {
    if (allUsersCount) {
      getData();
    }
  }, [page, count]);

  return (
    <div className="user-container">
      <div className="buttons-container">
        <SearchInput loading={loading4} handleSearchInput={handleSearchInput} />
        <FilterButton
          loading={loading3}
          items={submenuData}
          handledropdownclick={handleDropdown}
          keypath={status}
        />
        {/* {pendingVerificationData.length!==0 && <ExportButton loading={loading1} data={newAllUsersData} passing={headersForVerification}/>} */}
        <ExportButtonNew
          loading={loading1}
          fetch={fetchForExport}
          data={newAllUsersData}
          passing={headersForVerification}
        />
      </div>
      <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent
          rowKey="userId"
          data={pendingVerificationData}
          columns={columns}
          setPage={setPage}
          setCount={setCount}
          count={allUsersCount}
          page={page}
          pages={true}
        />
      </Spin>
    </div>
  );
};

export default PendingProfiles;
