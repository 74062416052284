import React,{useEffect,useState} from 'react'
import {
  MoreOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Popover, Spin,Popconfirm,message } from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import FilterButton from "../../Components/Common/FilterButton";
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import { Link } from 'react-router-dom';
import  { colorOfStatus, formatDate } from "../../utils/Constant";
// import AcceptModal from "./AcceptModal";
import { EVENT_DETAILS } from '../../utils/Urls';
import joompaApi from '../../utils/apis/joompaApi';
import { headersForEvents } from '../../utils/Columns';


const Events = () => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let nAllUsersData = eventsData
    nAllUsersData.map((item)=>{
      item.startTime=formatDate(item.startTime,true);
      item.endTime=formatDate(item.endTime,true);
      item.eventDate = formatDate(item.eventDate);
      item.eventStatusChangeDate = formatDate(item.eventStatusChangeDate);
      item.createdAt = formatDate(item.createdAt);
      item.updatedAt = formatDate(item.updatedAt);
    })
    setNewAllUsersData(nAllUsersData)
  },[eventsData])

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
      try {
        setLoading(true);
    let res = await joompaApi.get("/getEventByfilter");
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // console.log("pendig data", mapData);
    setEventsData(mapData);
    setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong")
      }
  };
  const confirm = async(uid) => {
    try {
      const res=await joompaApi.delete(`/deleteEvent?type=ADMIN&eventId=${uid}`)
      // console.log("delete Response event",res)
      if(res?.status===201){
        message.success(res?.data?.message)
        getData()
      }
    } catch (error) {
      message.error("Event Delete Failed")
    }

  };
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id", 
    // },
      {
        title: "Event Name",
        dataIndex: "eventName",
        sorter: (a, b) => a.eventName < b.eventName,
        sortDirections: ["ascend","descend"],
  
      },
      {
        title: "Event Location",
        dataIndex: "address",
        width:"30%"
      },
      {
        title: "Date & Time",
        dataIndex: "eventDate",
        sorter: (a, b) => new Date(a.eventDate) - new Date(b.eventDate),
        sortDirections: ["ascend","descend"],
        width:"15%",
        render:(text,record)=>(
          <span>
            {
              `${formatDate(text)} ${new Date(record?.startTime).toLocaleTimeString([], { timeStyle: "short" })} To ${new Date(record?.endTime).toLocaleTimeString([], { timeStyle: "short" })}`
            }
          </span>
        )
         
        
      },
      {
        title: "Created By",
        dataIndex: "username",
      },
      {
        title: "Created On",
        dataIndex: "eventCereatedOn",
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.eventCereatedOn) - new Date(b.eventCereatedOn),
        sortDirections: ["ascend","descend"],
        width:"10%",
        render:(text,record)=>(
          <span>
            {
              `${formatDate(text)}`
            }
          </span>
        )
      },
      {
        title: "Status",
        dataIndex: "eventStatus",
        sorter: (a, b) => a.eventStatus < b.eventStatus,
        sortDirections: ["ascend","descend"],
        render(text, record) {
          return {
            children: <div style={colorOfStatus(text)}>{text}</div>,
          };
        },
        width: "5%",
        className: "col-normal",
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        className: "col-normal",
        render: (text, record) => {
          return {
            children: (
              <div>
                <Popover
                  placement="bottomRight"
                  content={
                    <>
                      <Link
                        to={EVENT_DETAILS}
                        style={{ color: "black" }}
                        state={{ eventId: record._id }}
                      >
                        <EyeOutlined /> View Details
                      </Link>
                      <Popconfirm
                        // title="Delete the task"
                        title="Are You Sure To Delete This User?"
                        onConfirm={()=>confirm(record._id)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        // placement="leftBottom"
                        placement="left"
                      >
                        <button
                          style={{
                            border: "none",
                            marginLeft: "-38px",
                            background: "none",
                            paddingBottom:"10px",
                            cursor:"pointer",
                            color:"red"
                          }}
                        >
                         {/* {deleteLoading ? <LoadingOutlined/>:<><DeleteOutlined />Delete</> } */}
                         <DeleteOutlined style={{marginRight:"5px"}}/>Delete
                        </button>
                      </Popconfirm>
                    </>
                  }
                  trigger="click"
                  overlayInnerStyle={{
                    borderRadius: "12px",
                    textAlign: "center",
                  }}
                  overlayClassName="popover-menu"
                  showArrow={false}
                >
                  <Link>{text}</Link>
                </Popover>
              </div>
            ),
          };
        }
      },
    ];

    const handleSearchInput = async(e) => {
      try {
        let query=e.target.value
        let res = await joompaApi.get(`/getEventByfilter?search=${query.trim()}`)
        let data = res?.data?.data;
        let mapData = data.map((obj) => ({
          ...obj,
          action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
        }))
        setEventsData(mapData);
      } catch (error) {
        // console.log(error)
      }
    };
  return (
    <div>
    <div className="buttons-container">
    <SearchInput handleSearchInput={handleSearchInput} />
      {/* <FilterButton items={submenuData} /> */}
      {eventsData.length!==0 && <ExportButton data={newAllUsersData} passing={headersForEvents} />}
    </div>
    <Spin spinning={loading} tip="Loading..." size="large">
      <TableComponent data={eventsData} columns={columns} />
    </Spin>
    </div>
  )
}

export default Events