import React, { useEffect, useState } from "react";
import SearchInput from "../../Components/Common/SearchInput";
import { Image, Spin, message } from "antd";
import TableComponent from "../../Components/Common/TableComponent";
import joompaApi from "../../utils/apis/joompaApi";
import { formatDate } from "../../utils/Constant";
import ExportButton from "../../Components/Common/ExportButton";
import { Link } from "react-router-dom";
import { TRANSACTIONS } from "../../utils/Urls";
import { headersForBanUser, headersForReport } from "../../utils/Columns";
import ReadMore from "../../Components/Common/ReadMore";
import Message from "../../Assets/Images/messagetext.png";
import ExportButtonNew from "../../Components/Common/ExportButtonNew";

function BanUser() {
  const [userReportData, setUserReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nAllUsers, setNAllUsers] = useState([]);
  const [visible,setVisibile] = useState(false);
  const [readMore,setReadMore] =useState({});
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(10)
  const [allUsersCount, setAllUsersCount] = useState(0) 
  const [loading1, setLoading1] = useState(false);
  const [forExport, setForExport] = useState([]);
  const [loading4, setLoading4] = useState(false);

  useEffect(() => {
    let data = forExport.map((item) => {
      return {
        ...item,
        bannedTill:item.bannedTill==="PERMANENT"?item.bannedTill:formatDate(item.bannedTill),
      };
    });
    setNAllUsers(data);
  }, [forExport]);

  const handleSearchInputReport = async (e) => {
    if(e.target.value === "") {
      getUserReportData()
      return
    }
    setLoading4(true)
    try {
      let query = e.target.value;
      let res = await joompaApi.get(`/banUserList?search=${query.trim()}`);
      let data = res?.data?.filteredData;
      setAllUsersCount(false)
      setUserReportData(data);
      setLoading4(false)
    } catch (error) {
      setLoading4(false)
      // console.log(error)
    }
  };

  useEffect(()=>{
    if(allUsersCount){
      getUserReportData()
    }
      
  },[page,count])

  const getAllExportData = async()=>{
    try {
      setLoading1(true);
      let res = await joompaApi.get(`/banUserList?pageNumber=1&pageSize=${allUsersCount}`);
      // console.log("Response User Report", res);
      let data = res?.data?.filteredData;
      setForExport(data);
    } catch (error) {
      setLoading1(false);
      console.log(error);
      message.error(error.message);
    }
    setLoading1(false);
  }

  const getUserReportData = async () => {
    try {
      setLoading(true);
      let res = await joompaApi.get(`/banUserList?pageNumber=${page}&pageSize=${count}`);
      // console.log("Response User Report", res);
      let data = res?.data?.filteredData;
      setUserReportData(data);
      // if(res?.data?.count!==allUsersCount){
      //   getAllExportData(res?.data?.count)
      // }
      setAllUsersCount(res?.data?.count)
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(error.message);
    }
    setLoading(false);
  };

  const tabThreecolumns = [
    {
      title: "",
      dataIndex: "photoUrl",
      className: "col-normal",
      width:"5%",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User",
      width:"15%",
      // dataIndex: "UserName",
      render(text) {
        return {
          children: (
            <Link
              to={TRANSACTIONS}
              style={{  }}
              state={{ userId: text._id }}
            >
              {text.username}
            </Link>
          ),
        };
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width:"15%",
    },
    {
      title: "BANNED TILL",
      dataIndex: "bannedTill",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.bannedTill) - new Date(b.bannedTill),
      sortDirections: ["ascend","descend"],
      width:"15%",
      align: "center",
      render(text) {
        return {
          children: <div>{text==="PERMANENT"?text:formatDate(text)}</div>
        };
      },
    },
    {
      title: "Remarks",
      dataIndex: "bannedRemark",
      width:"20%",
      align:"center",
      render(text) {
        return {
          children: <div style={{cursor:"pointer"}} onClick={()=>{setVisibile(true)
                setReadMore(text)
          }}><img src={Message}/></div>
        };
      },
    },
  ];

  useEffect(() => {
    getUserReportData();
  }, []);
  
  return (
    <>
      <div className="buttons-container">
        <SearchInput loading={loading4} handleSearchInput={handleSearchInputReport} />
        {/* {(nAllUsers.length!==0 || userReportData.length!==0) && <ExportButton loading={loading1} data={nAllUsers} passing={headersForBanUser} />} */}
        <ExportButtonNew loading={loading1} data={nAllUsers} passing={headersForBanUser} fetch={getAllExportData}/>
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent columns={tabThreecolumns} data={userReportData} setPage={setPage} setCount={setCount} count={allUsersCount}/>
      </Spin>
      <ReadMore visible={visible} setVisibile={setVisibile} readMore={readMore} remarks="Remarks"/>
    </>
  );
}

export default BanUser;
