import React, { useEffect, useState } from "react";
import { Input, message,Spin } from "antd";
import { useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "../../Components/Common/Loading";
const ContactUs = () => {
  const [formValue, setFormValue] = useState({ writeUs: "", callUs: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getContactUsData();
  }, []);

  const getContactUsData = async () => {
    try {
      setLoading(true);
      const res = await joompaApi.get("/contactSupport");
      const data = res?.data?.data;
      // console.log("form data fetch",data)
      setFormValue({
        ...formValue,
        ...data,
      });
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }

    // console.log(formValue)
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    // console.log(name,value)
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleSaveContact = async () => {
    setLoading(true);
    if (!formValue.writeUs || !formValue.callUs) {
      message.error("All Field Required", 5);
    } else {
      try {
        const formData = {
          writeUs: formValue.writeUs,
          callUs: formValue.callUs,
        };
        const res = await joompaApi.put("/contactSupport", formData);
        // console.log(res.data);
        message.success("Contact details updated successfully", 5);
        setLoading(false);
      } catch (error) {
        message.error(`Something Went Wrong`, 5);
        setLoading(false);
        // console.log(error);
      }
    }
  };
  return (
    <div style={{ padding: "0px 20px" }}>
      <div className="form-container white-card">
      <Spin spinning={loading} tip="Loading..." size="large">
      <div style={{ marginLeft: "30%" }}>
            <div>
              <label className="label-text">Email Address</label>
              <Input
                onChange={handleInputValue}
                value={formValue.writeUs}
                placeholder="Email Address"
                style={{ width: "55%" }}
                className="input-box"
                name="writeUs"
              />
            </div>

            <div>
              <label className="label-text">Office Mobile Number</label>
              <Input
                onChange={handleInputValue}
                value={formValue.callUs}
                placeholder="Office Mobile Number"
                style={{ width: "55%" }}
                className="input-box"
                name="callUs"
              />
            </div>
          </div>
      </Spin>
      </div>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          onClick={handleSaveContact}
          style={{ background: "#1B75BC" }}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default ContactUs;
