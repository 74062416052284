import React,{useState,useRef} from "react";
import "./adminchat.css";
import userAvatar from "../../Assets/Images/userImage.png";
import Chatlist from "./Chatlist";
import ChatHeader from "./ChatHeader";
import ChatRoom from "./ChatRoom";
import ChatInput from "./ChatInput";
import { useLocation } from "react-router-dom";

const intialData=[
  {
    id: Math.random(),
    senderId: "admin",
    message: 'Hi, i am fine',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: true,
  },
  {
    id: Math.random(),
    avatar:userAvatar,
    senderId: "user",
    message: 'Hello, how are you?',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: false,
  },
  {
    id: Math.random(),
    senderId: "admin",
    message: 'Hi, i am fine',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: true,
  },
  {
    id: Math.random(),
    avatar:userAvatar,
    senderId: "user",
    message: 'Hello, how are you?',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: false,
  },
  {
    id: Math.random(),
    senderId: "admin",
    message: 'Hi, i am fine',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: true,
  },
  {
    id: Math.random(),
    avatar:userAvatar,
    senderId: "user",
    message: "Loreum ipsum is simply dummy text of the printing and typesetting industry.",
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: false,
  },
  {
    id: Math.random(),
    senderId: "admin",
    message: 'Hi, i am fine',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: true,
  },
  {
    id: Math.random(),
    avatar:userAvatar,
    senderId: "user",
    message: 'Hello, how are you?',
    timestamp: new Date().toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
    read: false,
  },
]
const AdminSupportChats = () => {
  const [chatData,setChatData]=useState([])
  const [showChat,setShowChat]=useState(false)
  const [listenerData,setListenerData] = useState([]);
  const [pageNum,setPageNum] = useState(0);
  const [hasMorePage,setHasMorePage] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [fileForVideo,setFileForVideo] = useState([]);
  const [fileForDocument,setFileForDocument] = useState([]);
  const [loading,setLoading]=useState(false);
  const location=useLocation()
  const {userId}=location.state || ""
  const [userProfileData,setUserProfileData]=useState({
    userName:'',
    profilePhoto:'',
    userId:'',
    email:''
  })
  const handleClickOfUserChat=(userId,userName,profilePhoto,email)=>{
    setUserProfileData({
      userName,
      profilePhoto,
      userId,
      email
    })
    setShowChat(true)
    if(userId!==userProfileData.userId){
      setChatData([]);
      setPageNum(0);  
      if(!hasMorePage){
        setHasMorePage(true);
      }
    }
    
    
   
      messagesEndRef.current?.scrollIntoView({ })
    
    
  }

  const messagesEndRef = useRef(null)
  return (
    <div style={{ padding: "0px 20px 0px 20px" }}>
      <div className="chat-container white-card">
          <Chatlist handleClick={handleClickOfUserChat} setUserProfileData={setUserProfileData} listenerData={listenerData} setListenerData={setListenerData} setFileList={setFileList} setFileForDocument={setFileForDocument} setFileForVideo={setFileForVideo}/>
        <div className="ver-line"></div>
        <div className={`chat-room ${userId && "chat-room-w100"}`}>
          {/* <ChatHeader/> */}
          
       {showChat &&  <ChatRoom chatData={chatData} setChatData={setChatData} userProfileData={userProfileData} messagesEndRef={messagesEndRef} setListenerData={setListenerData} pageNum={pageNum} setPageNum={setPageNum} hasMorePage={hasMorePage} setHasMorePage={setHasMorePage} fileList={fileList} setFileList={setFileList} fileForDocument={fileForDocument} setFileForDocument={setFileForDocument} fileForVideo={fileForVideo} setFileForVideo={setFileForVideo} loadings={loading} setLoadings={setLoading}/>}
        <div>
          {/* <ChatInput setChatData={setChatData} chatData={chatData}/> */}
        </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSupportChats;
