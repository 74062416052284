import { legacy_createStore,combineReducers, applyMiddleware } from "redux";
import userManagementReducer from "./reducers/userManagementReducer";
import thunk from "redux-thunk";
import userDataReducer from "./reducers/userDataReducer";

const rootReducer=combineReducers({
    userManagement:userManagementReducer,
    userData:userDataReducer

})

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;


export const store=legacy_createStore(rootReducer,applyMiddleware(thunk))