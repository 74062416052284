import React, { useState, useEffect } from "react";
import {
  MoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Popover, Modal, Image, message, Spin } from "antd";
import SearchInput from "../../Components/Common/SearchInput";
import ExportButton from "../../Components/Common/ExportButton";
import TableComponent from "../../Components/Common/TableComponent";
import reject from "../../Assets/Images/reject.png";
import TextArea from "antd/lib/input/TextArea";
import getItem from "../../utils/getItem";
import { colorOfStatus, formatDate } from "../../utils/Constant";
import AcceptModal from "./AcceptModal";
import joompaApi from "../../utils/apis/joompaApi";
import { headersForVerificationRequest } from "../../utils/Columns";

const submenuData = [
  getItem("All", "1"),
  getItem("Status", "sub1", [
    getItem("All", "2"),
    getItem("Verified", "3"),
    getItem("Rejected", "4"),
    getItem("Pending", "5"),
  ]),
];

const PopOverComponent = ({acceptLoading,verificationRequestAccept,verificationRequestReject,text,record})=>{
  const [open, setOpen] = useState(false);

  const handleForPopOver = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
return(
        <Popover
        open={open}
        onOpenChange={handleOpenChange}
            placement="bottomRight"
            content={
              <div className="popover-menu">
                <p>
                  <CheckCircleOutlined />{" "}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                    disabled={acceptLoading}
                    onClick={() => {
                      // setAcceptModal(true)
                      verificationRequestAccept(record);
                    }}
                  >
                    {acceptLoading ?<LoadingOutlined/>:"Accept"}
                  </button>
                </p>
                <p>
                  <CloseCircleOutlined />{" "}
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                    onClick={() =>{

                    verificationRequestReject(record);
                    handleForPopOver();
                    
                    }}
                  
                  >
                    Reject
                  </button>
                </p>
              </div>
            }
            trigger="click"
            showArrow={false}
          >
            <a>{text}</a>
          </Popover>
)
}
const VerificationRequest = () => {
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [verficationData, setVerificationData] = useState([]);
  const [remark, setRemark] = useState("");
  const [uid, setUid] = useState("");
  const [acceptUserData, setAcceptUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [rejectLoading,setRejectLoading]=useState(false)
  const [acceptLoading,setAcceptLoading]=useState(false)
  const [newAllUsersData,setNewAllUsersData] = useState([]);
  useEffect(()=>{
    let nAllUsersData = verficationData
    nAllUsersData.map((item)=>{
      item.regDate=formatDate(item.regDate);
      item.verificationRequestDate=formatDate(item.verificationRequestDate);
    })
    setNewAllUsersData(nAllUsersData)
  },[verficationData])

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
    let res = await joompaApi.get("/verificationRequest?verificationRequest=PENDING");
    let data = res?.data?.data;
    let mapData = data.map((obj) => ({
      ...obj,
      action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
    }));
    // console.log("pendig data", mapData);
    // setVerificationData(mapData);
    setVerificationData(mapData);
    setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something Went Wrong")
    }
  };


  const handleSearchInput = async(e) => {
    try {
      let query=e.target.value
      let res = await joompaApi.get(`/verificationRequest?verificationRequest=PENDING&search=${query}`)
      let data = res?.data?.data;
      let mapData = data.map((obj) => ({
        ...obj,
        action: <MoreOutlined style={{ fontSize: "20px", fontStyle: "bold" }} />,
      }))
      setVerificationData(mapData);
    } catch (error) {
      // console.log(error)
    }
  };
  const verificationRequestAccept = async (record) => {
    const uid = record.userId;
    try {
      setAcceptLoading(true)
      let res = await joompaApi.post(
        "/verificationRequestAccept",
        { uid },
      );
      if(res?.data?.status==='success'){
        setAcceptLoading(false)
        setAcceptModal(true);
        setAcceptUserData({
          userID: record.userId,
          fullName: record.fullName,
        });
        getData();
      }
      else{
        setAcceptLoading(false)
        message.error("Can't Aprrove User, Server Not Respond")
      }
      // console.log(record.userId, record.fullName);
      // acceptModel(setAcceptModal,acceptModal,record.userId,record.fullName)
    } catch (error) {
      setAcceptLoading(false)
      message.error(error);
    }
  };

  const verificationRequestReject = (record) => {
    setRejectModal(true);
    setUid(record.userId);
  };

  const handleReject = async () => {
    
    if (!remark) {
    message.destroy()
      return message.error("remark required");
    }
    try {
      setRejectLoading(true)
      let res = await joompaApi.post(
        "/verificationRequestReject",
        { uid, rejectRemark: remark }
      );

      // console.log("RejectRsponse",res);
      // let data = res?.data?.data.acknowledged;
      setRejectLoading(false)
      setRejectModal(false);
      // setAcceptModal(data)
      // acceptModel(setAcceptModal,acceptModal,record.userId,record.fullName)
      getData();
    } catch (error) {
      setRejectLoading(false)
      message.error(error);
    }
    // console.log(remark);
  };

  // const content = (
  //   <div className="popover-menu">
  //     <p>
  //       <CheckCircleOutlined />{" "}
  //       <button
  //         style={{
  //           border: "none",
  //           backgroundColor: "white",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => {
  //           setAcceptModal(true);
  //           verificationRequestAccept(record);
  //         }}
  //       >
  //         Accept
  //       </button>
  //     </p>
  //     <p>
  //       <CloseCircleOutlined />{" "}
  //       <button
  //         style={{
  //           border: "none",
  //           backgroundColor: "white",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => setRejectModal(true)}
  //       >
  //         Reject
  //       </button>
  //     </p>
  //   </div>
  // );

  const columns = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align:"center",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      width: "164px",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "",
      dataIndex: "userAvatar",
      width: "67px",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
            // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
          ),
        };
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.fullName < b.fullName,
      sortDirections: ["ascend","descend"],
      width: "121px",
      className: "col-special",
      render(text, record) {
        return {
          props: {
            style: { background: "white" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Uploaded Selfie",
      dataIndex: "verifyPhotoUrl",
      className: "col-normal",
      align: "center",
      width: "164px",
      render(text, record) {
        return {
          children: (
            <Image
              style={{ borderRadius: "50%" }}
              width={40}
              height={40}
              src={text}
            />
          ),
        };
      },
    },
    {
      title: "Requested On",
      dataIndex: "verificationRequestDate",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.verificationRequestDate) - new Date(b.verificationRequestDate),
      sortDirections: ["ascend","descend"],
      className: "col-normal",
      width: "141px",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "verificationRequest",
      render(text, record) {
        return {
          children: <div style={colorOfStatus(text)}>{text}</div>,
        };
      },
      width: "138px",
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "200px",
      align: "center",
      className: "col-normal",
      render: (text, record) => (
        <>
          {/* <CustomPopover text={text}/> */}
          <PopOverComponent acceptLoading={acceptLoading} verificationRequestAccept={verificationRequestAccept} verificationRequestReject={verificationRequestReject}text={text} record={record}/>
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="buttons-container">
      <SearchInput handleSearchInput={handleSearchInput} />
        {verficationData.length!==0 && <ExportButton data={newAllUsersData} passing={headersForVerificationRequest} />}
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
        <TableComponent data={verficationData} columns={columns} />
      </Spin>

      <AcceptModal
        setAcceptModal={setAcceptModal}
        acceptModal={acceptModal}
        userID={acceptUserData.userID}
        fullName={acceptUserData.fullName}
      />
      <Modal
        wrapClassName="accept-modal"
        centered
        visible={rejectModal}
        footer={null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setRejectModal(false)}
      >
        <div className="accept-modal">
          <img src={reject} alt="accept-icon" />
          <p className="modal-masg">
            Please enter remark before rejecting verification
          </p>
          <TextArea
            rows={3}
            placeholder="Type Here..."
            onChange={(e) => setRemark(e.target.value)}
          />
          <div className="modal-buttons">
            <button className="save-button" onClick={handleReject}>
                  {rejectLoading?<LoadingOutlined/>:"Yes Reject"} 
            </button>
            <button
              className="back-button"
              onClick={() => setRejectModal(false)}
            >
              No Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VerificationRequest;
