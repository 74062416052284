import React from 'react'
import { Routes,Route, useLocation, Navigate } from 'react-router-dom'
import Login from '../../Pages/Login/index'
import Forgot from '../../Pages/Login/Forgot'

function LoginRoute({setLogin}) {
  return (
    <>
    <Routes>
   
    <Route path='/' element={<Login setLogin={setLogin} />}/> 
    <Route path='/forgot-password' element={<Forgot/>}/>
    <Route path="*" element={<Navigate to="/" />} />
    </Routes>
        </>
  )
}

export default LoginRoute