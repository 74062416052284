import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/Constant";
import TableComponent from "../../Components/Common/TableComponent";
import { Link } from "react-router-dom";
import { Button, Popover, Spin, Modal, Input, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import joompaApi from "../../utils/apis/joompaApi";

const AboutMeQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoding, setdeleteLoding] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [isNew,SetIsNew]=useState(false)
  const [aboutMeQuestionsData, setAboutMeQuestionsData] = useState([]);


  useEffect(() => {
   getData();
  }, []);

  const getData = async () => {
    let count=1
    try {
    setLoading(true);
    let res = await joompaApi.get("/aboutMeAdmin");
    let data = res?.data?.data;
    const mapData=data?.map((obj)=>({
        ...obj,
        srNo:count++
    }))
    setAboutMeQuestionsData(mapData);
    setLoading(false);
    } 
    catch (error) {
        message.error("Something Went Wrong")
    }
  };



  const handleEdit=(record)=>{
    setQuestion(record?.question)
    setQuestionId(record?._id)
    setEditModal(true)
  }

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      className: "col-normal",
      width: "5%",
    },
    {
      title: "Question",
      dataIndex: "question",
      //   align:"center",
      width: "30%",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sortDirections: ["ascend","descend"],
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render(text, record) {
        return {
          children: (
          !text ? "Admin":text
          ),
        };
      },
      className: "col-normal",
      align: "center",
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      className: "col-normal",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      sortDirections: ["ascend","descend"],
      width: "10%",
      render(text) {
        return {
          children: formatDate(text),
        };
      },
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      align:"center",
      render(text, record) {
        return {
          children: (
          !text ? "Admin":text
          ),
        };
      },
      className: "col-normal",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      align: "center",
      className: "col-normal",
      render(text, record) {
        return {
          children: (
            <div style={{ display: "flex", gap: "10%" }}>
              <Button
                style={{ border: "none" }}
                onClick={()=>handleEdit(record)}
              >
                <EditOutlined />
              </Button>
              <Button style={{ border: "none" }} onClick={()=>handleDelete(record?._id)}>
                <DeleteOutlined style={{ color: "red" }} />
                {deleteLoding && <LoadingOutlined/>}
              </Button>
            </div>
          ),
        };
      },
    },
  ];

const handleAddNew=()=>{
    setEditModal(true)
    SetIsNew(true)
}


  const handleSave = async() => {
    if(question===""){
        return message.error("Please Enter Question")
    }
    else{
        if(isNew){
            try {
                setEditLoading(true)
                const res=await joompaApi.post(`/aboutMe`,{
                    question
                })
               if(res.status===201){
                setEditLoading(false)
                message.success(res?.data?.message)
                setEditModal(false)
                SetIsNew(false)
                setQuestion('')
                getData()
               }
            } catch (error) {
                setEditLoading(false)
                SetIsNew(false)
                message.error("Failed To Add Question")
            } 
        }
       else{
        try {
            setEditLoading(true)
            const res=await joompaApi.put(`/aboutMe?qId=${questionId}`,{
                question
            })
           if(res.status===201){
            setEditLoading(false)
            message.success(res?.data?.message)
            setEditModal(false)
            setQuestion('')
            getData()
           }
        } catch (error) {
            setEditLoading(false)
            message.error("Failed To Add Question")
        }
       }
    }
  };
  const handleDelete = async(Qid) => {
        try {
            setdeleteLoding(true)
            const res=await joompaApi.delete(`/aboutMe?qId=${Qid}`)
           if(res.status===201){
            setdeleteLoding(false)
            message.success(res?.data?.message)
            getData()
           }
        } catch (error) {
            setdeleteLoding(false)
            message.error("Failed To Add Question")
        }
  };
  return (
    <div style={{ padding: "0px 16px 0px 16px" }} >
      <div style={{ position: "absolute", top: "75px", right: "35px" }}>
        <button className="save-button" style={{ background: "#1B75BC" }} onClick={handleAddNew}>
          + Add New
        </button>
      </div>
      <div>
        <Spin spinning={loading} size="large" tip="Loading...">
          <TableComponent rowKey="_id" data={aboutMeQuestionsData} columns={columns} />
        </Spin>
      </div>
      <Modal
        wrapClassName="accept-modal"
        centered
        title="Add/Edit About Me Question"
        visible={editModal}
        footer={null}
        width={800}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setEditModal(false)}
      >
        <div>
          <label className="label-text">Question</label>
          <Input
            className="input-box"
            name="question"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
            style={{ width: "70%" }}
            placeholder="Add Question"
          />
        </div>
        <div style={{ marginTop: "5%" }}>
          <button className="back-button" onClick={() => setEditModal(false)}>
            Cancel
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={handleSave}
            // disabled={editLoading}
          >
            {editLoading ? <LoadingOutlined /> : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AboutMeQuestions;
