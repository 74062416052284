import React, { useState } from "react";
import UserCard from "./UserCard";
import { Link } from "react-router-dom";
import { ALL_USER, PREMIUM_USERS } from "../../utils/Urls";
const Styles = {
  userCard: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "1.5rem",
    padding: "1rem",
  },
};
const UserStatistics = ({ data }) => {
  // const [userStatData,setUserStatcData]=useState(data)

  // console.log("ageGroupUersData",data)

  return (
    <div className="white-card" style={{ height: "560px" }}>
      <div className="card-heading">Total Active User Statistics</div>
      <div className="line"></div>
      <div style={Styles.userCard}>
        {data.map((el, i) => (
          <Link
            style={{ color: "black" }}
            to={el.text === "Premium Users" ?PREMIUM_USERS:ALL_USER}
            state={{filter:el.filter,menu:el.menu,submenu:el.submenu}}
          >
            <UserCard
              key={i}
              img={el.img}
              text={el.text}
              count={el.count}
              bgcolor={el.bgcolor}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default UserStatistics;
