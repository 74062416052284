import { USER_DATA } from "../action/action.types"
import { initalState } from "./state"



const userDataReducer = (state=initalState.userData, {type,payload}) => {
    switch (type) {
      case USER_DATA:
        return {...state,userData:payload}
      default:
        return state
    }
  }
  
  export default userDataReducer