import React from "react";
import "./usermanagement.css";
import AudioCall from "../../Assets/Images/transactions/audio call.png";
import profileCount from "../../Assets/Images/transactions/profile count.png";
import videoCall from "../../Assets/Images/transactions/videocalls.png";
import conceirge from "../../Assets/Images/transactions/concierge.png";
import revoke from "../../Assets/Images/transactions/revoke rejected profile.png";
import message from "../../Assets/Images/transactions/message.png";
import Premium from "../../Assets/Images/transactions/Premium.png";
import Passport from "../../Assets/Images/transactions/passport 1.png";
import Coin from "../../Assets/Images/transactions/joompa coin.svg";

import { Button, Divider } from "antd";

function CardForLimit({ data,setIsModalOpenCoins }) {

  const callLimitAdd = (free, purchase) => {
    var add = 0;
    if (free > 0) {
      add += free;
    }
    if (purchase > 0) {
      add += purchase;
    }
    // add = 100
    if (add > 59) {
      // add = add/60;
      return `${parseInt(add / 60)} Mins ${add % 60} Sec`;
    } else {
      return `${add} Sec`;
    }
  };
  return (
    <>
     <div style={{textAlign:"right",marginBottom:"10px"}} className="mb-3">
          <Button
            onClick={() => setIsModalOpenCoins(true)}
            style={{
              borderRadius: "10px",
              background: "#1B75BC",
              color: "#ffffff",
            }}
          >
            Add Coins
          </Button>
        </div>
      <div class="CardLimit">
        <div class="CardLimitBox">
          <img src={AudioCall} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard"> Audio Calls</div>
            <Divider type="vertical" />
            {/* <div class="textSmallCard">{data&&data.premium.purchase===false?`${data&&parseInt(data.audioLimit.free+data.audioLimit.purchase)} Mins` :"Unlimited"} </div> */}
            <div class="textSmallCard">
              {data && data.premium.purchase === false
                ? `${callLimitAdd(
                    data.audioLimit.free,
                    data.audioLimit.purchase
                  )}`
                : "Unlimited"}{" "}
            </div>
          </div>
        </div>
        <div class="CardLimitBox">
          <img src={profileCount} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Profile Suggestions</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.premium.purchase === false
                ? `${
                    data &&
                    parseInt(
                      data.suggestionLimit.free + data.suggestionLimit.purchase
                    )
                  } Profiles`
                : "Unlimited"}
            </div>
          </div>
        </div>
        <div class="CardLimitBox">
          <img src={videoCall} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Video Calls</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.premium.purchase === false
                ? `${callLimitAdd(
                    data.videoLimit.free,
                    data.videoLimit.purchase
                  )}`
                : "Unlimited"}
            </div>
          </div>
        </div>
      </div>
      <div class="CardLimit">
        <div class="CardLimitBox">
          <img src={conceirge} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Concierge</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data &&
                parseInt(
                  data.conciergeLimit.free + data.conciergeLimit.purchase
                )}{" "}
              Profiles
            </div>
          </div>
        </div>
        <div class="CardLimitBox">
          <img src={revoke} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard"> Revoke Profile</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.premium.purchase === false
                ? parseInt(data.revokeLimit.free + data.revokeLimit.purchase) +
                  " Profiles"
                : "Unlimited"}{" "}
            </div>
          </div>
        </div>
        <div class="CardLimitBox">
          <img src={message} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Message</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {" "}
              {data && data.premium.purchase === false
                ? `${
                    data &&
                    parseInt(
                      data.dailyMsgLimit.free + data.dailyMsgLimit.purchase
                    )
                  }`
                : "Unlimited"}
            </div>
          </div>
        </div>
      </div>
      <div class="CardLimit">
        <div class="CardLimitBox">
          <img src={Premium} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Premium</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.premium.purchase === true
                ? "Has Premium"
                : "No Premium"}
            </div>
          </div>
        </div>

        <div class="CardLimitBox">
          <img src={Passport} style={{ height: "33px" }} />
          <div class="flex">
            <div class="textCard">Passport</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.passport.purchase === true
                ? "Has Passport"
                : "No Passport"}
            </div>
          </div>
        </div>
        <div class="CardLimitBox" >
          <img src={Coin} style={{ width: "40px", height: "40px" }} />
          <div class="flex">
            <div class="textCard">Available Coins</div>
            <Divider type="vertical" />
            <div class="textSmallCard">
              {data && data.availableCoins.coins+" Coins"}
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
}

export default CardForLimit;
