import React,{useState,useEffect} from "react";
import { Progress,Switch, message } from 'antd';
import joompaApi from "../../utils/apis/joompaApi";
const SubscriptionsByCategory = ({data}) => {
const [switchStatus,setSwitchStatus]=useState(false)
 


const handleSwitch=(value)=>{
  setSwitchStatus(value)
} 
  return (
    <div style={{height:"437px",overflowY:"scroll"}} className='white-card'> 
      {/* do height max-content */}
       <div style={{display:'flex',justifyContent:"space-between"}} className="card-heading">
      <div>Subscriptions By Category</div>
      <div>
        $ <Switch onChange={handleSwitch}/> #
      </div>
      </div>
      {/* <div className="card-heading">Subscriptions By Category</div> */}
      <div className="line"></div>
      <div style={{padding:"1rem"}}>
        {data && data?.map((el)=>{return <div key={el._id} style={{padding:"1rem"}}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{fontWeight:700,fontSize:"14px",}}>{el._id}</div>
                <div style={{fontWeight:500,fontSize:"14px",}}>{switchStatus ? `Coins ${el.coins}`:`SGD ${el.price.toFixed(2)}`}</div>
                {/* <div style={{fontWeight:500,fontSize:"13px",}}>{switchStatus ? el.coins==0 ? el.price:el.coins:el.price==0?el.coins:el.price}</div> */}
            </div>
            <Progress percent={el.count} showInfo={false} strokeColor="#31BA96"/>
        </div>})}
      </div>
    </div>
  );
};

export default SubscriptionsByCategory;
