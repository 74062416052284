import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";

const defaultData = [
  {
    name: "Jan",
    uv: 4000,
  },
  {
    name: "Feb",
    uv: 3000,
  },
  {
    name: "Mar",
    uv: 2000,
    
  },
  {
    name: "Apr",
    uv: 2780,
    
  },
  {
    name: "May",
    uv: 1890,
    
  },
  {
    name: "June",
    uv: 2390,
    
  },
  {
    name: "July",
    uv: 3490,
   
  }

];
const BarCharts = ({data=defaultData,width=662,height=304,fill="#1B75BC",dataKeyX="name",dataKeyY="uv",handleClick}) => {
  return (
    <ResponsiveContainer width="97%" height={height}>
    <BarChart data={data}>
      <CartesianGrid stroke="#E6EAF0" />
    <XAxis dataKey={dataKeyX} stroke="#000000"/>
    <YAxis />
    <Tooltip cursor={{fill: 'transparent'}}/>
    {/* <CartesianGrid stroke="#ccc" strokeDasharray="1" /> */}
    <Bar dataKey={dataKeyY} fill={fill} barSize={15} onClick={handleClick}/>
  </BarChart>
  </ResponsiveContainer>
  )
}

export default BarCharts