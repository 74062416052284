import React,{useState,useEffect} from 'react'
import { Spin,Button,Table, Switch,Modal,Input, message } from 'antd'
import { formatDate } from '../../utils/Constant'
import { EditOutlined,LoadingOutlined } from '@ant-design/icons'
import chat from "../../Assets/Images/tableIcons/chat.png"
import love from "../../Assets/Images/tableIcons/love.png"
import phone from "../../Assets/Images/tableIcons/phone.png"
import video from "../../Assets/Images/tableIcons/video.png"
import revoke from "../../Assets/Images/tableIcons/revoke.png"
import referIcon from "../../Assets/Images/tableIcons/refer.png"
import passport from "../../Assets/Images/tableIcons/passport.png"
import joompaApi from '../../utils/apis/joompaApi'
const FeaturesFreeQuota = () => {
    const [loading,setLoading]=useState(false)
    const [editModal,setEditModal]=useState(false)
    const [featureName,setFeatureName]=useState('')
    const [freeQuota,setFreeQuota]=useState('')
    const [editLoading,setEditLoading]=useState(false)
    const [featuresList,setFeaturesList]=useState([])
    const [id,setId]=useState('')


useEffect(()=>{
    fetchDataOFDailyQuota()
},[])


const fetchDataOFDailyQuota=async()=>{
    try {
        setLoading(true);
        let res = await joompaApi.get("/dailyQuota");
        let data = res?.data?.data?.[0];
        const messages=data?.messages
        const crossCountry=data?.passport
        const revokeProfile=data?.revokeProfile
        const suggestionLimit=data?.suggestionLimit
        const updatedAt=data?.updatedAt
        const audioCall=data?.audioCall
        const videoCall=data?.videoCall
        const refer = data?.refer
        setId(data?._id)
        setFeaturesList([{
            srNo:1,
            icon:chat,
            featureName:"Messages",
            freeQuota:messages,
            updatedOn:data.messagesTime,
            // action:""
        },
        {
            srNo:2,
            icon:video,
            featureName:"Video Calls",
            freeQuota:videoCall,
            updatedOn:data.videoCallTime,
            action:""
        },
        {
            srNo:3,
            icon:phone,
            featureName:"Audio Calls",
            freeQuota:audioCall,
            updatedOn:data.audioCallTime,
            action:""
        },
        {
            srNo:4,
            icon:revoke,
            featureName:"Undo Rejected Profile",
            freeQuota:revokeProfile,
            updatedOn:data.revokeProfileTime,
            action:""
        },
        {
            srNo:5,
            icon:love,
            featureName:"Profile Suggestion",
            freeQuota:suggestionLimit,
            updatedOn:data.suggestionLimitTime,
            action:""
        },
        {
            srNo:6,
            icon:passport,
            featureName:"Cross Country Filters",
            freeQuota:crossCountry,
            updatedOn:data.passportTime,
            action:""
        },
      {
          srNo:7,
          icon:referIcon,
          featureName:"Refer",
          freeQuota:refer,
          updatedOn:data.referTime,
          action:""
      }])
        setLoading(false);
        } 
        catch (error) {
            message.error("Something Went Wrong")
        }
}

const handleEdit=(record)=>{
    setFeatureName(record?.featureName)
    if(record?.featureName==="Audio Calls"|| record?.featureName==="Video Calls"){
      setFreeQuota(parseInt(record?.freeQuota)/60)
    }
    else{
      setFreeQuota(record?.freeQuota)
    }
    
    setEditModal(true)
}
    const columns = [
        {
          title: "Sr No",
          dataIndex: "srNo",
          className: "col-normal",
          width: "5%",
          align:"center"
        },
        {
          title: "Features",
          dataIndex: "featureName",
          //   align:"center",
          width: "15%",
          render: (text, record) => (
            <><img src={record.icon} width="24px" height="24px" style={{objectFit:"contain",marginRight:"10px"}}/>{record.featureName}</>
          ),
        },
        {
          title: "Free Quota (Daily)",
          // dataIndex: "freeQuota",
          width:"15%",
          align:"center",
          render(text) {
            return {
              children: (
                typeof(text.freeQuota)=="boolean"?<Switch checked={text.freeQuota}/>:text.featureName==="Audio Calls"?SecondsToHoursAndMinutes(text.freeQuota): text.featureName==="Video Calls"?SecondsToHoursAndMinutes(text.freeQuota):text.featureName==="Refer"?parseInt(text.freeQuota)+" Coins":text.freeQuota
              ),
            };
          },
        },
        {
          title: "Updated On",
          dataIndex: "updatedOn",
          className: "col-normal",
          width: "15%",
          render(text) {
            return {
              children: formatDate(text),
            };
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          width: "15%",
          align: "center",
          className: "col-normal",
          render(text, record) {
            return {
              children: (
                <div>
                  <Button
                    style={{ border: "none" }}
                    onClick={() => handleEdit(record)}
                  >
                    <EditOutlined />
                  </Button>
                </div>
              ),
            };
          },
        },
      ];



const handlePutMethod=async(obj)=>{
    try {
        setEditLoading(true)
        const res= await joompaApi.put(`/dailyQuota?id=${id}`,obj)
        if(res?.status===201){
            message.success(res?.data?.message)
            fetchDataOFDailyQuota()
            setEditModal(false)
        }
        setEditLoading(false)
    } catch (error) {
        setEditLoading(false)
        message.error("Something Went Wrong")
    }
}


const handleSave=async(featureName)=>{
    if(featureName==="Messages"){
        let obj={messages:freeQuota}
        handlePutMethod(obj)
    } else if(featureName==="Undo Rejected Profile"){
        let obj={revokeProfile:freeQuota}
        handlePutMethod(obj)
    } else if(featureName==="Profile Suggestion"){
        let obj={suggestionLimit:freeQuota}
        handlePutMethod(obj)
    } else if(featureName==="Cross Country Filters"){
        let obj={passport:freeQuota}
        handlePutMethod(obj)
    }else if(featureName==="Video Calls"){
        let obj={videoCall:parseInt(freeQuota)*60}
        handlePutMethod(obj)
    }else if(featureName==="Audio Calls"){
        let obj={audioCall:parseInt(freeQuota)*60}
        handlePutMethod(obj)
    }
    else if(featureName==="Refer"){
      let obj={refer:parseInt(freeQuota)}
      handlePutMethod(obj)
  }

}

const labelFeature = (name)=>{
  if(name==="Video Calls"||name==="Audio Calls"){
    return "Free Quota (Daily in mins)"
  }
  else{
    return "Free Quota (Daily)"
  }
}

const SecondsToHoursAndMinutes = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  var time = "";
  if(hours>0){
    time=hours+" Hour"
  }
  if(minutes>0){
    time=time+minutes +" min"
  }
  return time
};
  return (
    <div style={{ padding: "0px 16px 0px 16px" }} >
      <div>
        <Spin spinning={loading} size="large" tip="Loading...">
        <Table
        size="small"
        columns={columns}
        dataSource={featuresList}
        pagination={false}
      />
        </Spin>
      </div>
      <Modal
        wrapClassName="accept-modal"
        centered
        title="Edit Feature Quota"
        visible={editModal}
        footer={null}
        width={800}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setEditModal(false)}
      >
        <div>
          <label className="label-text">Feature</label>
          <Input
            className="input-box"
            readOnly={true}
            name="feature"
            // onChange={(e) => setFreeQuota(e.target.value)}
            value={featureName}
            disabled={true}
            style={{ width: "70%" }}
            placeholder="Add Question"
          />
        </div>
        {featureName==="Cross country filters"?<div>
          <label className="label-text">Feature ON/OFF</label>
        <Switch checked={freeQuota} onChange={(value)=>setFreeQuota(value)}/>
        </div> :<div>
          <label className="label-text">{labelFeature(featureName)}</label>
          <Input
            className="input-box"
            type='number'
            name="freeQuota"
            onChange={(e) => setFreeQuota(e.target.value)}
            value={freeQuota}
            style={{ width: "70%" }}
            placeholder="Add Question"
          />
        </div>}
        <div style={{ marginTop: "5%" }}>
          <button className="back-button" onClick={() => setEditModal(false)}>
            Cancel
          </button>
          <button
            className="save-button"
            style={{ background: "#1B75BC" }}
            onClick={()=>handleSave(featureName)}
            // disabled={editLoading}
          >
            {editLoading ? <LoadingOutlined /> : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default FeaturesFreeQuota