import React, { useEffect, useState } from 'react'
import { Input, message, DatePicker, Upload, Switch, Radio, Modal, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import moment from "moment";
import { useLocation, useNavigate } from 'react-router-dom';
import joompaApi from '../../utils/apis/joompaApi';

function ViewAdmin() {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [fileList, setFileList] = useState();
    const [errorPhoto,setErrorPhoto]=useState(false)
    const [errorName,setErrorName]=useState(false)
    const [errorDob,setErrorDob]=useState(false)
    const [errorMobile,setErrorMobile]=useState(false)
    const [errorEmail,setErrorEmail]=useState(false)
    const [password,setPassword]=useState(false)
    const [confirmPassword,setConfirmPassword] = useState(false);
    const [ResetModal,setResetModal] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const {id,uid}=location.state;
    const [values, setValues] = useState({
        profilePhoto: "",
        fullName: "",
        dateOfBirth: "",
        mobileNumber: "",
        email: "",
        password:"",
        confirmPassword:"",
        status:true,
        admin:false,
      });

      const handleResetPassword = async()=>{
        if(!values.resetPassword){
          message.warn("Please enter new password")
          return;
        }
        if(!values.resetConfirmPassword){
          message.warn("Please enter new confirm password")
          return;
        }
        if(values.resetPassword && values.resetPassword.length < 8){
          message.warn("Password length should be more than 8 characters")
          return;
        }
        if(values.resetPassword !== values.resetConfirmPassword){
          message.warn("Password and Confirm Password not matched")
          return;
        }

        if(id){
          const data = {
              password:values.resetPassword
          }
          try{
              const res = await joompaApi.put("/editAdmin/"+uid,data)
              console.log(res)
              if(res?.status ==200){
                message.success("Password Updated")
            }
          }
          catch(err){
            message.warn(err.response.data.error)
         
          }
          setResetModal(false)
      }
      }
      
      const getData = async()=>{
        await joompaApi.get(`/get-admin-by-id?id=${id}`).then((res)=>{
            setValues({
                fullName:res?.data?.data?.fullName,
                dateOfBirth:res?.data?.data?.dateOfBirth,
                mobileNumber:res?.data?.data?.mobileNumber,
                email:res?.data?.data?.email,
                status:res?.data?.data?.status,
                admin:res?.data?.data?.isSuperAdmin,
                profilePhoto:res?.data?.data?.profilePhoto
            })
            setImageUrl(res?.data?.data?.profilePhoto)
        }).catch((err)=>{
            console.log(err)
        })
        
      }
      useEffect(()=>{
        console.log(id)
        if(id){
            getData();
        }

      },[])

      const handleSaveInfo = async () => {
        // if(!fileList && !imageUrl){
        //   setErrorPhoto(true)
        //   message.warn("Please Uplaod Image")
        //   return;
        // }
        if(!values.fullName){
          setErrorName(true)
          return;
        }
        // if(!values.dateOfBirth){
        //   setErrorDob(true)
        //   return;
        // }
        // if(!values.mobileNumber){
        //   setErrorMobile(true)
        //   return;
        // }
        if(!values.email){
          setErrorEmail(true)
          return;
        }
        if(!values.password && !id){
          setPassword(true)
          return;
        }
        if(!values.confirmPassword  &&!id){
          setConfirmPassword(true)

        }
        if(values.password && values.password != values.confirmPassword){
          message.error("Password and Confirm Password not matched")
          
          return;

        }
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(values.email && !regex.test(values.email)){
            message.error("Please enter valid email")
            return;
        }
        if(values.password && values.password.length < 6){
          message.warn("Password length should be more than 6 characters")
          return;
        }

        setLoading(true)
        const image = await uploadImageApi(fileList);
        if(id){
            const data = {
                fullName:values.fullName,
                dateOfBirth:values.dateOfBirth,
                mobileNumber:values.mobileNumber,
                email:values.email,
                status:values.status,
                isSuperAdmin:values.admin,
                profilePhoto:image
            }
            try{
                const res = await joompaApi.put("/editAdmin/"+uid,data)
                console.log(res)
                if(res?.status ==200){
                  message.success(res?.data?.message)
                  navigate(-1)
              }
            }
            catch(err){
              message.error(err.response.data.error) 
              console.log(err.response.data.error)
            }
        }
        else{
        const data = {
            fullName:values.fullName,
            dateOfBirth:values.dateOfBirth,
            mobileNumber:values.mobileNumber,
            email:values.email,
            password:values.password,
            status:values.status,
            isSuperAdmin:values.admin,
            profilePhoto:image
        }


        try{
            const res = await joompaApi.post("/createAdmin",data)
            console.log(res)
            if(res?.status ==200){
                message.success(res?.data?.message)
                navigate(-1)
            }
        }
        catch(err){
            console.log(err)
        }
    }
        setLoading(false)
    }

    const uploadImageApi = async (file) => {
        const firstImageFormData = new FormData();
        firstImageFormData.append("file", file);
        try {
          const res = await joompaApi.post(
            `/uploadUserImage`,
            firstImageFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // console.log("fileUploadResponse", res);
          // console.log("ImageURL", res?.data?.data?.location);
          if (res.status === 200) {

            return res?.data?.data?.location;
            // fetchAgainData();
          }
        } catch (error) {

          message.error("Failed to Upload Image");
        }
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        name === "fullName" && value != "" && setErrorName(false);
        name === "mobileNumber" && value != "" && setErrorMobile(false);
        name === "dateOfBirth" && value != "" && setErrorDob(false);
        name === "email" && value != "" && setErrorEmail(false);
        name === "password" && value != "" && setPassword(false);
        name === "confirmPassword" && value != "" && setConfirmPassword(false);
        setValues({
          ...values,
          [name]: value,
        });
      };
    
      const onDateChange = (date, dateString) => {
        setErrorDob(false)
        setValues({
          ...values,
          dateOfBirth: date,
        });
      };
    

    const uploadButton = (
        <div>
          {loading && fileList ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      );


      let formData = new FormData();
      const handleImageChange = (info) => {
        const file = info.fileList[0]?.originFileObj;
        if (file) {
          formData.append("file", file);
          setErrorPhoto(false)
          // console.log(formData);
          setFileList(file);
        }
        if (info?.fileList[0]) {
          getBase64(info.fileList[0].originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };

      const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
      };
  return (
    <>
    <div className="white-card" style={{ padding: "5px 16px 60px 16px" ,margin:"10px 10px"}}>
    <div>
      <label className="label-text">Profile Photo</label>
      <div
        style={{
          border: "1px solid #E6EBF1",
          borderRadius: "8px",
          padding: "10px",
          width: "136px",
          height: "136px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          // fileList={fileList}
          // action={avtarUpload}
          beforeUpload={() => false}
          maxCount={1}
          onChange={handleImageChange}
          status={errorPhoto && "error"}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        {/* <span
          style={{ marginLeft: "1px", color: "#ff9900", fontSize: "20px" }}
        >
          {errorPhoto && <WarningOutlined />}
        </span> */}
        {/* <img src={UserAvatar} width="84px" height="84px" /> */}
      </div>
    </div>
    <div style={{ display: "flex", gap: "30%" }}>
          <div>
            <label className="label-text">Full Name</label>
            <Input
              style={{ width: "160%" }}
              className="input-box"
              onChange={handleInputChange}
              onKeyPress={(e)=>{
                const regex = /^[^0-9]*$/;
                if(!regex.test(e.key)){
                  e.preventDefault();
                }
              }}
              value={values.fullName}
              name="fullName"
              status={errorName && "error"}
            />
             <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorName && <WarningOutlined />}
            </span>
          </div>
          <div>
            <label className="label-text">Date of Birth</label>
            <DatePicker
              className="input-box"
              format="DD/MM/YYYY"
              placement="bottomLeft"
              placeholder="DD/MM/YYYY"
              style={{ width: "200%" }}
              onKeyDown={(e) => {
                const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
                if(!regex.test(e.key)){
                  e.preventDefault();
                }
              }
              }
              onChange={onDateChange}
              disabledDate = {(current)=>{
                return current && current > moment().startOf('day');
              }}
              value={values.dateOfBirth && moment(values.dateOfBirth)}
              name="dateOfBirth"
              status={errorDob && "error"}
            />
             <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorDob && <WarningOutlined />}
            </span>
          </div>
        </div>
    <div style={{ display: "flex", gap: "30%" }}>
      <div>
        <label className="label-text">Mobile Number</label>
        <Input
          style={{ width: "160%" }}
          className="input-box"
          onChange={handleInputChange}
          value={values.mobileNumber}
          onKeyPress={(e)=>{
            if(isNaN(e.key)){
              e.preventDefault();
            }
          }}

          name="mobileNumber"
          status={errorMobile && "error"}
        />
         <span
          style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
        >
          {errorMobile && <WarningOutlined />}
        </span>
      </div>

      <div>
        <label className="label-text">Email Address</label>
        <Input
          style={{ width: "165%" }}
          className="input-box"
          value={values.email}
          autoComplete='nope'
          onChange={handleInputChange}
          name="email"
          status={errorEmail && "error"}
        />
         <span
          style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
        >
          {errorEmail && <WarningOutlined />}
        </span>
      </div>
    </div>
    {!id &&
    <div style={{ display: "flex", gap: "28.3%" }}>
      <div>
        <label className="label-text">Password</label>
        <Input.Password
          style={{ width: "148%" }}
          className="input-box"
          autoComplete='nope'
          type="password"
          onChange={handleInputChange}
          value={values.password}
          name="password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          status={password && "error"}
        />
         <span
          style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
        >
          {password && <WarningOutlined />}
        </span>
      </div>

      <div>
        <label className="label-text">Confirm Password</label>
        <Input.Password
          style={{ width: "150%" }}
          className="input-box"
          type="password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          value={values.confirmPassword}
          onChange={handleInputChange}
          name="confirmPassword"
          status={confirmPassword && "error"}
        />
          <span
          style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
        >
          {confirmPassword && <WarningOutlined />}
        </span>
      </div>
    </div>
}
    <div style={{ display: "flex", }}>
      <div style={{width:"50%"}}>
        <label className="label-text">Status</label>
       <span style={{fontSize:"13px",marginRight:"10px"}}>Active</span> 
       <Switch  name="status"
          // value={values.status}
          // defaultChecked
          checked={values.status}
          onChange={(checked) => {
            setValues({
              ...values,
              status: checked,
            });
          }}
       />

      </div>

      <div style={{width:"45%"}}>
        <label className="label-text">Access for Staff Management</label>
        <Switch
        name="admin"
        checked={values.admin}
        onChange={(checked) => {
            setValues({
              ...values,
              admin: checked,
            });
          }}/>
        {/* <Radio.Group defaultValue={"Admin"} onChange={handleInputChange} value={values.admin} name="admin">
            <Radio value="Admin">Admin</Radio>
            <Radio value="SuperAdmin">Superadmin</Radio>
        </Radio.Group> */}
      </div>
    </div>
  </div>
  <div style={{display:"flex",justifyContent:"space-between",marginLeft:"10px",marginRight:"10px",marginBottom:"30px",marginTop:"10px"}}>
    <div>
    {id && <button className="back-button" onClick={() => setResetModal(true)}>
          Reset Password
        </button>}
        </div>
        <div>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSaveInfo}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
        </div>
      </div>
      <Modal footer={false} open={ResetModal} title="Reset Password" onCancel={()=>setResetModal(false)}>
      <div >
      <div>
        <label className="label-text" style={{marginTop:"-5px"}}>New Password</label>
        <Input.Password
          // style={{ width: "160%" }}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          className="input-box"
          type="password"
          onChange={handleInputChange}
          value={values.resetPassword}
          name="resetPassword"
        
        />
         
      </div>

      <div>
        <label className="label-text">Confirm New Password</label>
        <Input.Password
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          // style={{ width: "172%" }}
          className="input-box"
          type="password"
          value={values.resetConfirmPassword}
          onChange={handleInputChange}
          name="resetConfirmPassword"
         
        />
          
      </div>
    </div>
    <Button style={{marginTop:"20px"}}type="primary" onClick={handleResetPassword}>Save</Button>
      </Modal>

  </>
  )
}

export default ViewAdmin