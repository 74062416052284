import React,{useEffect, useState} from "react";
import { LoadingOutlined} from '@ant-design/icons';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import "./login.css";
import { Input,Checkbox,message} from "antd";
import { MailOutlined,LockOutlined} from "@ant-design/icons";
import loginImage from "../../Assets/Images/loginImage.png";
import loginIcon from "../../Assets/Images/loginIcon.png";
import { Link, useNavigate } from "react-router-dom";
import {app} from "../../utils/firbase-config"
import joompaApi from "../../utils/apis/joompaApi"
// import { requestPermission } from "../../utils/firbase-config";
import {getMessaging , getToken ,onMessage} from "firebase/messaging"



const Login = ({setLogin}) => {
  const [loading,setLoading]=useState(false)
  const navigate=useNavigate()
  const [values, setValues] = useState({adminId:""});
  const [emailMessage, setEmailMessage] = useState(false)
  const handleInputChange=(e)=>{
    const {name,value}=e.target
    // console.log(name,value)
    setValues({
      ...values,
      [name]: value,
    });
  }

  
const handleSignIn=async()=>{
    if(values.adminId){

   
  setLoading(true)
const auth = getAuth(app);
try {
    console.log(values.adminId)
   const user =  await sendPasswordResetEmail(auth,values.adminId);
    console.log("Password reset email sent")
    console.log(user)

    setEmailMessage(true)
 
} catch (err) {
  console.error(err);
  alert(err.message);
}
setLoading(false);
    }
    else{
        message.warn("Please Enter Email")
    }
}



  return (
    <div className="login-container">
      <div className="left-login">
        <img src={loginImage} alt="engge-image"/>
      </div>
      
      <div className="right-login">

        {emailMessage?

      (<div className="login-box">
      <h3>The Email has been sent Check your Inbox!</h3>
     <div style={{textAlign: "center"}}> <Link to="/"> Login</Link></div>
      </div>):(
        <div className="login-box">
       
          <div style={{textAlign: "center"}}>
            
          <div className="login-icon">
              <img src={loginIcon} alt="icon" 
        //       style={{
        //   width:"90%"
        // }}
        />
          </div>
          <div className="sign-in-head">
              FORGOT PASSWORD
          </div>
          </div>
          <div>
            <label className="label-text">Username</label>
            <Input
              value={values.adminId}
              name="adminId"
              prefix={<MailOutlined />} 
              placeholder="Enter Email"
              style={{ width: "95%" }}
              className="input-box"
              onChange={handleInputChange}
            />
          </div>
          
        
          <button className="signin-button" onClick={handleSignIn} disabled={loading}>{loading?<LoadingOutlined style={{fontSize:"20px"}} />:"FORGOT PASSWORD"}</button>
        </div>
      )}
        
      </div>
    </div>
  );
};

export default Login;
