import { Menu } from "antd";
import React from "react";
import Icon from "@ant-design/icons/lib/components/Icon";
import VerfiedIcon from "../../Assets/Images/VerifiedIcon.png";
import Dashboard from "../../Assets/Images/Dashboard.png";
import CMS from "../../Assets/Images/CMS.png";
import SupportAdmin from "../../Assets/Images/SupportAdmin.png";
import User from "../../Assets/Images/User.png";
import VerificationRequest from "../../Assets/Images/VerificationRequest.png";
import userReport from "../../Assets/Images/icons/flag_black_24dp 1.png"
import staff from "../../Assets/Images/icons/staff.svg"

import {
  ABOUT_ME_QUESTIONS,
  ABOUT_US,
  ADMIN_SUPPORT_CHATS,
  ALL_USER,
  CONCIERGE_REQUEST,
  CONTACT_US,
  DASHBOARD,
  EMAIL_TEMPLATE,
  EVENTS,
  FEATURES_FREE_QUOTA,
  FEATURE_COST,
  FINANCE_REPORT,
  INACTIVE_USERS,
  PENDING_PROFILES,
  PERSONALITY_INFORMATION,
  PREMIMUM_SIGNUP,
  PREMIUM_USERS,
  PRIVACY_POLICY,
  FAQ,
  REQUESTED_PROFILES,
  SCHEDULED_NOTIFICATION,
  TERM_AND_CONDITIONS,
  USER_CHAT_GAME,
  USER_WALLET,
  VERIFICATION_REQUEST,
  ALL_USER_REPORTS,
  BAN_USERS,
  ADMIN_MANAGEMENT,
} from "../../utils/Urls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}


const Sidebar = () => {
  const navigate = useNavigate();
  const handleClickOnMenu = ({ key }) => {
    return navigate(key);
  };
  const userData = useSelector(state=>state.userData);

  const items = [
    getItem(
      "Dashboard",
      DASHBOARD,
      <Icon component={() => <img src={Dashboard} alt="Dashboard icon" />} />
    ),
    getItem(
      "User Management",
      "sub1",
      <Icon component={() => <img src={User} alt="user icon" />} />,
      [
        getItem("All Users", ALL_USER),
        getItem("Premium Users", PREMIUM_USERS),
        getItem("Verification Request", PENDING_PROFILES),
        getItem("Profile Deletion", REQUESTED_PROFILES),
        getItem("Inactive Users", INACTIVE_USERS),
        getItem("Banned Users",BAN_USERS)
      ]
    ),
   userData.userData ? getItem(
      "Staff Management",
      "sub32",
      <Icon
        component={() => <img src={staff} alt="staffManagement" />}
      />,
      [
        getItem("All Admins", ADMIN_MANAGEMENT),
      ]
    ):"",
    getItem(
      "Subscriptions",
      "sub2",
      <Icon
        component={() => <img src={VerfiedIcon} alt="subscripation icon" />}
      />,
      [
        getItem("Packages", FEATURE_COST),
        getItem("User's Wallets", USER_WALLET),
        getItem("Concierge request", CONCIERGE_REQUEST),
        getItem("Premium Signups", PREMIMUM_SIGNUP),
        getItem("Finance Report", FINANCE_REPORT),
      ]
    ),
    getItem(
      "Pending Verification",
      VERIFICATION_REQUEST,
      <Icon
        component={() => (
          <img src={VerificationRequest} alt="verification icon" />
        )}
      />
    ),
    getItem(
      "Admin Support Chats",
      ADMIN_SUPPORT_CHATS,
      <Icon
        component={() => <img src={SupportAdmin} alt="Admin support icon" />}
      />
    ),
    // getItem(
    //   "Notifications",
    //   "14",
    //   <Icon
    //     component={() => <img src={notificationBell} alt="notification icon" />}
    //   />
    // ),
    getItem(
      "CMS",
      "sub3",
      <Icon component={() => <img src={CMS} alt="CMS icon" />} />,
      [
        getItem("Email Templates", EMAIL_TEMPLATE),
        getItem("Scheduled Notifications", SCHEDULED_NOTIFICATION),
        getItem("About Us", ABOUT_US),
        getItem("Terms & Conditions", TERM_AND_CONDITIONS),
        getItem("Privacy Policy", PRIVACY_POLICY),
        getItem("FAQ", FAQ),
        getItem("Contact Us", CONTACT_US),
        getItem("Personality Information", PERSONALITY_INFORMATION),
        getItem("User Chat Game", USER_CHAT_GAME),
        getItem("About Me Questions",ABOUT_ME_QUESTIONS),
        getItem("Features Free Quota",FEATURES_FREE_QUOTA),
      ]
    ),
    getItem(
      "Events",
      EVENTS,
      <Icon component={() => <img src={Dashboard} alt="Dashboard icon" />} />
    ),
    getItem(
      "User Report",
      ALL_USER_REPORTS,
      <Icon component={() => <img src={userReport} alt="All user report" />} />
    ),
  ];
  return (
    <Menu 
      onClick={handleClickOnMenu}
      theme="dark"
      mode="inline"
      items={items}
      style={{ fontSize: "0.9rem", fontWeight: 700, lineHeight: "21px",height:"100%",overflowY:"auto", }}
    />
  );
};

export default Sidebar;
