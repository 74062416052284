import React, { useState, useEffect } from "react";
import { DatePicker, Spin, message } from "antd";
import BarCharts from "../../Components/Common/BarCharts";
import axios from "axios";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";

const currentYear = new Date().getFullYear();
const CountrySignups = ({ countrySignupsData, setYearState,loading,handleClick }) => {
  // const [countrySignupsData,setCountrySignupsData]=useState(countrySignupsData)
  // const [loading,setLoading]=useState(false)
  // const [year,setYear]=useState(currentYear)
  // useEffect(() => {
  //   getCountrySignupsData()
  //  }, [year]);

  const onChange = (date, dateString) => {
    setYearState(dateString);
  };

  // const getCountrySignupsData=async()=>{
  //   try {
  //     setLoading(true)
  //   let res=await joompaApi.get(`/countrySignups?year=${year}`)
  //   let data=res?.data?.data
  //   setCountrySignupsData(data)
  //   setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //     message.error(error)
  //   }
  // }
  return (
    <div className="white-card">
      <div className="middleContainer">
        <div className="card-heading">Country Signups</div>
        <div style={{ padding: "24px" }}>
        {loading && <LoadingOutlined style={{marginRight:"10px"}}/>}
          <DatePicker
            style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
            picker="year"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="line"></div>
      <div style={{ padding: "5% 3%" }}>
        {/* <Spin spinning={loading} size="large" tip="Loading..."> */}
        <BarCharts
          data={countrySignupsData}
          height={300}
          dataKeyX={"_id"}
          dataKeyY={"count"}
          handleClick={handleClick}
        />

        {/* </Spin> */}
      </div>
      <div
        style={{
          fontWeight: 600,
          fontSize: "14px",
          textAlign: "center",
          marginTop: "-25px",
          color: "#1B75BC",
        }}
      >
        Country
      </div>
    </div>
  );
};

export default CountrySignups;
