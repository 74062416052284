import React, { useState, useEffect } from "react";
import "./cms.css";
import PersonalityCard from "./PersonalityCard";
import joompaApi from "../../utils/apis/joompaApi";
import Loading from "../../Components/Common/Loading";

const PersonalityInformation = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPersonalityInfoData();
  }, []);

  const getPersonalityInfoData = async () => {
    setLoading(true);
    const res = await joompaApi.get("/profileInfos");
    // console.log("personality info data fetch",res.data)
    const data = res?.data?.data[0];
    // console.log("personal info",data)
    setPersonalInfo(data);
    setLoading(false);
    // console.log("personality info state",personalInfo)
    // console.log("personality info _id",personalInfo._id)
    // console.log("personality info physical",personalInfo.physical)
    // setFormValue({
    //   ...formValue,
    //   ...data,
    // });
  };
  return (
    <div style={{ padding: "0px 20px" }}>
      {/* <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="save-button" style={{ background: "#1B75BC" }}>
          Save
        </button>
      </div> */}
      {loading ? (
        <Loading />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {/* <PersonalityCard title="Physical Personality" /> */}
          <PersonalityCard
            title="Physical Personality"
            id={personalInfo._id}
            data={personalInfo.physical}
            arrayName='physical'
          />
          <PersonalityCard
            title="Intellectual Personality"
            id={personalInfo._id}
            data={personalInfo.intellectual}
            arrayName='intellectual'
          />
          <PersonalityCard
            title="Sociable Personality"
            id={personalInfo._id}
            data={personalInfo.sociable}
            arrayName='sociable'
          />
          <PersonalityCard
            title="Artistic Personality"
            id={personalInfo._id}
            data={personalInfo.artistic}
            arrayName='artistic'
          />
          <PersonalityCard
            title="Nurturer / Duty Bound Personality"
            id={personalInfo._id}
            data={personalInfo.nurturerOrDutyBound}
            arrayName='nurturerOrDutyBound'
          />
          <PersonalityCard
            title="Material Personality"
            id={personalInfo._id}
            data={personalInfo.material}
            arrayName='material'
          />
        </div>
      )}
    </div>
  );
};

export default PersonalityInformation;
