import React, { useState } from 'react'
import TableComponent from '../../Components/Common/TableComponent'
import { Spin,Image } from 'antd'
import premiumUsers from "../../Assets/Images/dashboard/premiumUsers.png";
import { colorOfStatus, formatDate } from '../../utils/Constant';
import { subscriptionIcon } from '../../utils/Constant';
import { useLocation } from 'react-router-dom';
import NonPremium from "../../Assets/Images/icons/Vector.svg"
const DashboardDataAllUser = () => {
    const [loading,setLoading]=useState(false)
    const location=useLocation()
    const {data}=location?.state
    const columns = [
        {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            sorter: (a, b) => a.phoneNumber - b.phoneNumber,
            sortDirections: ["ascend","descend"],
            className: "col-normal",
          },
        
        {
          title: "",
          dataIndex: "photoUrl",
          align: "center",
          width: "10%",
          render(text, record) {
            return {
              children: (
                <Image
                  style={{ borderRadius: "50%" }}
                  width={40}
                  height={40}
                  src={text}
                />
                // <img src={text} width="40px" height="40px" style={{borderRadius:"50%"}}/>
              ),
            };
          },
        },
        {
            title: "User Name",
            dataIndex: "username",
            // defaultSortOrder: "ascend",
            sorter: (a, b) => a.username < b.username,
            sortDirections: ["ascend","descend"],
            width: "30%",
            // width: "15%",
            className: "col-special",
            render(text, record) {
              return {
                props: {
                  style: { background: "white" },
                },
                children: <div>{text}</div>,
              };
            },
          },
        {
          title: "User Type",
          dataIndex: "isPremium",
          className: "col-normal",
          width: "20%",
          align: "center",
          render(text) {
            return {
              children: text ? (
                <img
                  width="24px"
                  height="19px"
                  src={premiumUsers}
                  alt="user Type"
                />
              ) : (
                <img
                  width="24px"
                  height="19px"
                  src={NonPremium}
                  alt="user Type"
                />
              ),
            };
          },
        },
        {
          title: "Religion",
          dataIndex: "religion",
         width:"25%",
          className: "col-normal",
          render(text) {
            return {
              children: text,
            };
          },
        },
        // {
        //   title: "Status",
        //   dataIndex: "accountStatus",
        // //   width: "13%",
        //   sorter: (a, b) => a.accountStatus < b.accountStatus,
        //   sortDirections: ["ascend","descend"],
        //   render(text, record) {
        //     return {
        //       children: <div style={colorOfStatus(text)}>{text}</div>,
        //     };
        //   },
        // //   width: "8%",
        //   className: "col-normal",
        // }
      ];

  return (
    <div className="user-container">
      <Spin spinning={loading} size="large" tip="Loading...">
        <TableComponent data={data} columns={columns}/>
      </Spin>
    </div>
  )
}

export default DashboardDataAllUser