import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  DatePicker,
  Switch,
  message,
  TimePicker,
  Dropdown,
  Button,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import FilterButton from "../../Components/Common/FilterButton";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
const { RangePicker } = DatePicker;
const AddNewNotification = () => {
  const [select, setSelect] = useState("offer");
  const [featureType,setFeatureType] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [time, setTime] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  // const [body,setBody]=useState('')
  // const [title,setTitle]=useState('')
  const [error, setError] = useState({ title: false, body: false });
  const [errorDate, setErrorDate] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorBody, setErrorBody] = useState(false);
  const [errorInterval, setErrorInterval] = useState(false);
  const [errorTime, setErrorTime] = useState(false);
  const [keypath, setKeypath] = useState("All");
  const [target,setTarget]=useState('All')
  const [targetAudeins,setTargetAudeins]=useState('All')
  const [featuredOptions,setFeatureOptions] = useState([]);
  const navigate = useNavigate();
  // const location=useLocation()
  // const {id}=location.state
  const handleSave = async () => {
    try {
      if (
        !startdate ||
        !enddate ||
        !time ||
        !values.title ||
        !values.body ||
        !values.interval
      ) {
        !values.title && setErrorTitle(true);
        !values.body && setErrorBody(true);
        !values.interval && setErrorInterval(true);
        !time && setErrorTime(true);
        !startdate && setErrorDate(true);
        !enddate && setErrorDate(true);
        return message.error("All Field Required");
      } else {
        setLoading(true);
        let obj = {
          title: values.title,
          body: values.body,
          type: select,
          startDate: startdate,
          endDate: enddate,
          status: active,
          intervals: values.interval,
          target:target,
          targetAudience:targetAudeins,
          featuredType:featureType
        };
        let res = await joompaApi.post("/adminCustomPromotions", obj);
        console.log("promtion add post resposnse", res);
        if (res.status === 201) {
          message.success(res?.data?.message);
          setStartDate("");
          setEndDate("");
          setValues({
            title: "",
            body: "",
          });
          // setTitle('')
          // setBody('')
          setActive(false);
          navigate(-1);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Something Went Wrong");
    }
    // console.log(value)
  };
  const handleSelect = (value) => {
    setSelect(value);
  };
  const handleSwitch = (value) => {
    // console.log("handleSwitch value", value);
    setActive(value);
  };


  const isConcerge=true
  const isPremium=true
  const submenuData = [
    {
      label: "All",
      key: "All",
    },
    {
      label: "Gender",
      key: "gender",
      children: [
        {
          label: "Male",
          key: "male",
        },
        {
          label: "Female",
          key: "female",
        },
      ],
    },
    {
      label: "Age",
      key: "age",
      children: [
        {
          label: "18-24",
          key: "18-24",
        },
        {
          label: "25-35",
          key: "25-35",
        },
        {
          label: "36-50",
          key: "36-50",
        },
        {
          label: "51-61",
          key: "51-61",
        },
        {
          label: "+62",
          key: "+62",
        },
      ],
    },
    {
      label: "Marital Status",
      key: "maritalStatus",
      children: [
        {
          label: "Not Married",
          key: "NotMarried",
        },
        {
          label: "Separated",
          key: "Separated",
        },
        {
          label: "Widowed",
          key: "Widowed",
        },
        {
          label: "Divorced",
          key: "Divorced",
        },
      ],
    },
    {
      label: "Country",
      key: "country",
      children: [
        {
          label: "Singapore",
          key: "Singapore",
        },
        {
          label: "Malaysia",
          key: "Malaysia",
        },
        {
          label: "Indonesia",
          key: "Indonesia",
        },
        {
          label: "Asian Countries",
          key: "asianCountries",
        },
        {
          label: "European Countries",
          key: "europeanCountries ",
        },
        {
          label: "Oceaania Countries",
          key: "oceaaniaCountries",
        },
        {
          label: "Africa Countries",
          key: "africaCountries",
        },
        {
          label: "North & South America",
          key: "northAndSouthAmerica",
        },
      ],
    },
    {
      label: "Premium",
      key: "isPremium",
      children: [
        {
          label: "True",
          key: isPremium,
        },
      ],
    },
    {
      label: "Concierge",
      key: "isConcerge",
      children: [
        {
          label: "True",
          key: isConcerge,
        },
      ],
    },
  ];

  useEffect(()=>{
     joompaApi.get("/featured-types").then((res)=>{
      setFeatureOptions(res.data.map((item)=>{
        return{
          ...item,
          label:item.featureType,
          value:item.featureType
        }
      }))
      
    })
    
  },[])

  const handledropdownclick = async ({ key, keyPath }) => {
    setKeypath(key);
    setTarget(keyPath[1]!=="All"?keyPath[1]:null)
    setTargetAudeins(keyPath[0]!=="All"?keyPath[0]:null)
    console.log("target",keyPath[1])
    console.log("targetAudeins",keyPath[0])
  };
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    name === "title" && value != "" && setErrorTitle(false);
    name === "body" && value != "" && setErrorBody(false);
    name === "interval" && value != "" && setErrorInterval(false);
    // console.log(name, value);
    if(name==="interval"){
      value = Math.max(0, value);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleDatePicker = (date, dateString) => {
    setErrorDate(false)
    if(dateString[0] ===""){
      setErrorDate(true);
      return;
    }
    else if(dateString[1] === ""){
      setErrorDate(true);
      return;
    }
    console.log("datehandle",(moment(dateString[0]).toDate().toISOString()))
    setStartDate(moment(dateString[0]));
    setEndDate(moment(dateString[1]));
    // setStartDate(moment(dateString[0]).toDate().toISOString());
    // setEndDate(moment(dateString[1]).toDate().toISOString());
  };

  const handleTimePicker = (time, timeString) => {
    setErrorTime(false);
    setTime(timeString);
    timeString = time.tz('Asia/Singapore').format("HH:mm:ss");
    // timeString = 
    
    console.log("timeString",timeString)
    const timeValue=timeString.split(":")
    console.log(timeValue)
   startdate.add(parseInt(timeValue[0]),'hours')
   startdate.add(parseInt(timeValue[1]),'minutes')
    startdate.add(parseInt(timeValue[2]),'seconds')
    enddate.add(parseInt(timeValue[0]),'hours')
    enddate.add(parseInt(timeValue[1]),'minutes')
    enddate.add(parseInt(timeValue[2]),'seconds')
    // console.log(new Date(startdate).setHours(parseInt(timeValue[0])))
    // console.log(new Date(startdate).setMinutes(parseInt(timeValue[1])))
    // console.log(new Date(startdate).setSeconds(parseInt(timeValue[2])))
    // console.log(new Date(enddate).setHours(parseInt(timeValue[0])))
    // console.log(new Date(enddate).setMinutes(parseInt(timeValue[1])))
    // console.log(new Date(enddate).setSeconds(parseInt(timeValue[2])))
    // console.log("timeString",combineDateAndTime(startdate,time))
    // console.log("timeString",combineDateAndTime(startdate,time))
    // setStartDate(new Date(startdate).toISOString())
    // setEndDate(new Date(enddate).toISOString())
    console.log("startdate",new Date(startdate).toISOString())
    console.log("enddate",new Date(enddate).toISOString())
    // console.log("startDate",moment(`${startdate} ${timeString}`, "YYYY-MM-DD HH:mm:ss").toISOString())
    // console.log("EndDate",moment(`${startdate} ${timeString}`, "YYYY-MM-DD HH:mm:ss").toISOString())
    setStartDate(
      new Date(startdate).toISOString()
      // moment(`${startdate} ${timeString}`, "YYYY-MM-DD HH:mm:ss").toISOString()
    );
    setEndDate(
      new Date(enddate).toISOString()
      // moment(`${enddate} ${timeString}`, "YYYY-MM-DD HH:mm:ss").toISOString()
    );
    // console.log("timeslect", moment(timeString.toDate().toISOString()));
  };

const combineDateAndTime =(date, time)=>{
  const timeString = time.getHours() + ':' + time.getMinutes() + ':00';

    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);

    return combined;
}

  return (
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
      <div className="white-card">
        <div style={{ marginLeft: "20%", marginBottom: "50px" }}>
          <div>
            <label className="label-text">Notification Title</label>
            <Input
              name="title"
              placeholder="Notification Title"
              style={{ width: "75%" }}
              className="input-box"
              onChange={handleInputChange}
              // onChange={(e)=>setTitle(e.target.value)}
              value={values.title}
              status={errorTitle && "error"}
            />
            <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorTitle && <WarningOutlined />}
            </span>
          </div>
          <div>
            <label className="label-text">Notification Body</label>
            <Input
              name="body"
              placeholder="Notification Body"
              style={{ width: "75%" }}
              className="input-box"
              onChange={handleInputChange}
              // onChange={(e)=>setBody(e.target.value)}
              value={values.body}
              status={errorBody && "error"}
            />
            <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorBody && <WarningOutlined />}
            </span>
          </div>
          <div>
            <label className="label-text">Notification Type</label>
            <Select
              defaultValue="offer"
              bordered={false}
              style={{
                width: 200,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              onChange={handleSelect}
              options={[
                {
                  label: "Offer",
                  value: "offer",
                },
                {
                  label: "News",
                  value: "news",
                },
                {
                  label: "Promotion",
                  value: "promotion",
                },
                {
                  label: "Greetings",
                  value: "greetings",
                },
              ]}
            />
          </div>
          <div>
            <label className="label-text">Feature Type (Optional)</label>
            <Select
              placeholder="Feature Type"
              bordered={false}
              allowClear
              style={{
                width: 200,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              onChange={(e)=>setFeatureType(e)}
              options={featuredOptions}
            />
          </div>
          <div>
            <label className="label-text">Notification Interval (Days)</label>
            <Input
              type="number"
              name="interval"
              placeholder="Notification Interval"
              style={{ width: "18%" }}
              className="input-box"
              onChange={handleInputChange}
              // onChange={(e)=>setTitle(e.target.value)}
              value={values.interval}
              status={errorInterval && "error"}
            />
            <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorInterval && <WarningOutlined />}
            </span>
          </div>
          <div>
            <label className="label-text">Target Audience</label>
            <FilterButton items={submenuData} handledropdownclick={handledropdownclick} keypath={keypath} showImg={false} width={"200px"}/>
          </div>
          <div>
            <label className="label-text">Start Date And End Date</label>
            <RangePicker
              onChange={handleDatePicker}
              style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
              status={errorDate && "error"}
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
              // status={error.startdate || error.enddate && "error"}
            />
            <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorDate && <WarningOutlined />}
            </span>
            {/* <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{error.startdate || error.enddate && <WarningOutlined />}</span> */}
          </div>
          <div>
            <label className="label-text">Time</label>
            <TimePicker
              onChange={handleTimePicker}
              style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
              defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
              // defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
              status={errorTime && "error"}
              // use12Hours={true}
            />
            <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {errorTime && <WarningOutlined />}
            </span>
            {/* <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{error.startdate || error.enddate && <WarningOutlined />}</span> */}
          </div>
          <div>
            <label className="label-text">Status(Active/Inactive)</label>
            <Switch checked={active} onChange={handleSwitch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewNotification;
