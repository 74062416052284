import React from "react";
import { DatePicker } from "antd";
import StackedBarChart from "../../Components/Common/StackedBarChart";
import { LoadingOutlined } from "@ant-design/icons";

const UserCardOfStackedBar = ({
  title,
  bottomTitle,
  data,
  dataKey1,
  dataKey2,
  dataKeyX,
  setYearState,
  handleClick,
  loading
}) => {
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setYearState(dateString);
  };
  return (
    <div className="white-card" style={{ height: "441px" }}>
      <div className="middleContainer">
        <div className="card-heading">{title}</div>
        <div style={{ padding: "24px" }}>
        {loading && <LoadingOutlined style={{marginRight:"10px"}}/>}
          <DatePicker
          //  inputReadOnly={true}
            style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
            picker="year"
            onChange={onChange}
          />
          {/* <input readonly="" placeholder="Select year" title="" size="12" autocomplete="off" value=""></input> */}
        </div>
      </div>
      <div className="line"></div>
      <StackedBarChart
        data={data}
        dataKey1={dataKey1}
        dataKey2={dataKey2}
        dataKeyX={dataKeyX}
        handleClick={handleClick}
      />
      <div
        style={{
          fontWeight: 600,
          fontSize: "14px",
          textAlign: "center",
          marginTop: "-18px",
          color: "#1B75BC",
        }}
      >
        {bottomTitle}
      </div>
    </div>
  );
};

export default UserCardOfStackedBar;
