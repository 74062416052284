import React, { useState,useEffect } from "react";
import { Input, Select, DatePicker, Switch, message,TimePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined } from "@ant-design/icons";
import FilterButton from "../../Components/Common/FilterButton";
const { RangePicker } = DatePicker;

const ViewNotification = () => {
  const [select, setSelect] = useState("offer");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [interval, setInterval] = useState("");
  const [time, setTime] = useState("");
  const [keypath, setKeypath] = useState("");
  const [target,setTarget]=useState('')
  const [targetAudeins,setTargetAudeins]=useState('')
  const [featuredOptions,setFeatureOptions] = useState([]);
  const [featureType,setFeatureType] = useState("");
  const navigate = useNavigate();
  const location=useLocation()
  const {id}=location.state

    useEffect(() => {
       
        getNotificationData()
        // console.log("time",time)
    }, []);

const getNotificationData=async()=>{
    try{
        const res=await joompaApi.get(`/adminCustomPromotions/${id}`)
        const data=res?.data?.data
        setSelect(data.type)
        setStartDate(data.startDate)
        setEndDate(data.endDate)
        setActive(data.status)
        setBody(data.body)
        setTitle(data.title)
        setInterval(data?.intervals)
        setStartDate(data?.startDate)
        setFeatureType(data?.featuredType)
        setTarget(data?.target)
        setTargetAudeins(data?.targetAudience)
        const keyPathSet=data?.targetAudience==""?"All":data?.targetAudience
        setKeypath(keyPathSet)
        const getTime=new Date(data?.startDate)
        setTime(getTime.toLocaleTimeString('it-IT'))
        // setTime(data?.startDate)
        // console.log("time",time)
    }catch(err){
        message.error("Something Went Wrong")
    }   
}
const handledropdownclick = async ({ key, keyPath }) => {
  setKeypath(key);
  setTarget(keyPath[1]!=="All"?keyPath[1]:null)
  setTargetAudeins(keyPath[0]!=="All"?keyPath[0]:null)
  console.log("target",keyPath[1])
  console.log("targetAudeins",keyPath[0])
};

useEffect(()=>{
   joompaApi.get("/featured-types").then((res)=>{
    setFeatureOptions(res.data.map((item)=>{
      return{
        ...item,
        label:item.featureType,
        value:item.featureType
      }
    }))
    
  })

},[])
  const handleSave = async () => {
    try {
      if (!startdate || !enddate || !title || !body) {
        return message.error("All Field Required");
      } else {
        if(new Date(enddate)<new Date() || new Date(startdate)<new Date()){
          message.error("This notification has completed its cycle please change start and end date to save this notification.");
          return;
        }
        setLoading(true);
        let obj = {
          title: title,
          body: body,
          type: select,
          startDate: startdate,
          endDate: enddate,
          status: active,
          intervals:interval,
          target:target,
          targetAudience:targetAudeins,
          featuredType:featureType?featureType:""
        };
        let res = await joompaApi.put(`/adminCustomPromotions/${id}`, obj);
        // console.log("resposnse", res);
        if (res.status === 201) {
          message.success(res?.data?.message);
          navigate(-1)
        //   setStartDate("");
        //   setEndDate("");
        //   setTitle("");
        //   setBody("");
          setActive(false);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Something Went Wrong");
    }
    // console.log(value)
  };
  const handleSelect = (value) => {
    setSelect(value);
  };
  const handleSwitch = (value) => {
    // console.log("handleSwitch value", value);
    setActive(value);
  };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name,value)
  //   setValue({
  //     ...value,
  //     [name]: value,
  //   });
  // };


  const isConcerge=true
  const isPremium=true
  const submenuData = [
    {
      label: "All",
      key: "All",
    },
    {
      label: "Gender",
      key: "gender",
      children: [
        {
          label: "Male",
          key: "male",
        },
        {
          label: "Female",
          key: "female",
        },
      ],
    },
    {
      label: "Age",
      key: "age",
      children: [
        {
          label: "18-24",
          key: "18-24",
        },
        {
          label: "25-35",
          key: "25-35",
        },
        {
          label: "36-50",
          key: "36-50",
        },
        {
          label: "51-61",
          key: "51-61",
        },
        {
          label: "+62",
          key: "+62",
        },
      ],
    },
    {
      label: "Marital Status",
      key: "maritalStatus",
      children: [
        {
          label: "Not Married",
          key: "NotMarried",
        },
        {
          label: "Separated",
          key: "Separated",
        },
        {
          label: "Widowed",
          key: "Widowed",
        },
        {
          label: "Divorced",
          key: "Divorced",
        },
      ],
    },
    {
      label: "Country",
      key: "country",
      children: [
        {
          label: "Singapore",
          key: "Singapore",
        },
        {
          label: "Malaysia",
          key: "Malaysia",
        },
        {
          label: "Indonesia",
          key: "Indonesia",
        },
        {
          label: "Asian Countries",
          key: "asianCountries",
        },
        {
          label: "European Countries",
          key: "europeanCountries ",
        },
        {
          label: "Oceaania Countries",
          key: "oceaaniaCountries",
        },
        {
          label: "Africa Countries",
          key: "africaCountries",
        },
        {
          label: "North & South America",
          key: "northAndSouthAmerica",
        },
      ],
    },
    {
      label: "Premium",
      key: "isPremium",
      children: [
        {
          label: "True",
          key: isPremium,
        },
      ],
    },
    {
      label: "Concierge",
      key: "isConcerge",
      children: [
        {
          label: "True",
          key: isConcerge,
        },
      ],
    },
  ];
  const handleDatePicker = (date, dateString) => {
    setStartDate(moment(dateString[0]).toDate().toISOString());
    setEndDate(moment(dateString[1]).toDate().toISOString());
  };
  const handleTimePicker = (time,timeString) => {
    setTime(timeString)
    // timeString = time.tz('Asia/Singapore').format("HH:mm:ss");
    timeString = time.tz('Asia/Kolkata').format("HH:mm:ss");
    setStartDate( moment(`${startdate} ${timeString}`, 'YYYY-MM-DD HH:mm:ss').format())
    setEndDate( moment(`${enddate} ${timeString}`, 'YYYY-MM-DD HH:mm:ss').format())
    // console.log("timeslect", moment(timeString.toDate().toISOString()));
  };
  return (
    <div style={{ padding: "2px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        <button className="back-button" onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className="save-button"
          style={{ background: "#1B75BC" }}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
      <div className="white-card">
        <div style={{ marginLeft: "20%",marginBottom:"50px" }}>
          <div>
            <label className="label-text">Notification Title</label>
            <Input
              name="title"
              placeholder="Notification Title"
              style={{ width: "75%" }}
              className="input-box"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div>
            <label className="label-text">Notification Body</label>
            <Input
              name="body"
              placeholder="Notification Body"
              style={{ width: "75%" }}
              className="input-box"
              onChange={(e) => setBody(e.target.value)}
              value={body}
            />
          </div>
          <div>
            <label className="label-text">Notification Type</label>
            <Select
              defaultValue="offer"
              bordered={false}
              style={{
                width: 200,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              value={select}
              onChange={handleSelect}
              options={[
                {
                  label: "Offer",
                  value: "offer",
                },
                {
                  label: "News",
                  value: "news",
                },
                {
                  label: "Promotion",
                  value: "promotion",
                },
                {
                  label: "Greetings",
                  value: "greetings",
                },
              ]}
            />
          </div>
          <div>
            <label className="label-text">Feature Type (Optional)</label>
            <Select
              placeholder="Feature Type"
              bordered={false}
              value={featureType}
              allowClear
              style={{
                width: 200,
                border: "1px solid #E6EBF1",
                borderRadius: "8px",
              }}
              onChange={(e)=>setFeatureType(e)}
              options={featuredOptions}
            />
          </div>
          <div>
            <label className="label-text">Notification Interval (Days)</label>
            <Input
              type="number"
              name="interval"
              placeholder="Notification Interval"
              style={{ width: "18%",textAlign:"center" }}
              className="input-box"
              onChange={(e) => {
                const newValue = Math.max(0, e.target.value); // Ensure non-negative value
                setInterval(newValue); // Assuming setInterval is your state updater function
              }}
              
              // onChange={(e)=>setTitle(e.target.value)}
              value={interval}
              // status={error.interval && "error"}
            />
            {/* <span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {error.interval && <WarningOutlined />}
            </span> */}
          </div>
          <div>
            <label className="label-text">Target Audience</label>
            <FilterButton items={submenuData} handledropdownclick={handledropdownclick} keypath={keypath} showImg={false} width={"200px"}/>
          </div>
          <div>
            <label className="label-text">Start Date And End Date</label>
            <RangePicker
            disabledDate={(startDate) => {
              return startDate && startDate < moment().startOf("day");
            }}
              onChange={handleDatePicker}
              style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
              value={[moment(startdate),moment(enddate)]}
            />
          </div>
          <div>
            <label className="label-text">Time</label>
            <TimePicker
              onChange={handleTimePicker}
              style={{ border: "1px solid #E6EBF1", borderRadius: "8px" }}
              // defaultValue={time}
              value={moment(`${time}`, "HH:mm:ss")}
              // defaultValue={moment(`${time}`, "HH:mm:ss")}
              // value={time}
            />
            {/* <span style={{marginLeft:"10px",color:"#ff9900",fontSize:"20px"}}>{error.startdate || error.enddate && <WarningOutlined />}</span> */}
          </div>
          <div>
            <label className="label-text">Status (Active / Inactive)</label>
            <Switch checked={active} onChange={handleSwitch} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewNotification;
