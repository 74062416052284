import React, { useEffect, useState ,useRef} from "react";
import "./usermanagement.css";
import locationImg from "../../Assets/Images/transactions/location.png";
import userImage from "../../Assets/Images/userImage.png";
import location from "../../Assets/Images/transactions/location.png";
import age from "../../Assets/Images/transactions/age.png";
import country from "../../Assets/Images/transactions/country.png";
import education from "../../Assets/Images/transactions/education.png";
import height from "../../Assets/Images/transactions/height.png";
import religion from "../../Assets/Images/transactions/religion.png";
import weight from "../../Assets/Images/transactions/weight.png";
import { Input, message ,Select,Card} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { TRANSACTIONS } from "../../utils/Urls";
const { TextArea } = Input;

const initialValues = {
  number: "",
  userId: "",
};

const AddSuggestionForm = () => {
  const [values, setValues] = useState();
  const [cardAppearence,SetCardAppearence] = useState(false);
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestion,setSuggestion] = useState("")
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userDeatils, PersonalityInfo } = location.state;
  const ID = userDeatils._id;
  const [search,setSearch] = useState(true);
  sessionStorage.setItem("userId",JSON.stringify(ID));
  // const handleInputChange = (e) => {
  //   console.log(e.target)
  //   const { name, value } = e.target;
  //   values.name != "" && setError(false);
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  // };
  const interestArray = [
    // ...userDeatils?.interests?.basicQuestion?.answer ??[],
    ...(userDeatils?.interests?.sportsAndFitness ?? []),
    ...(userDeatils?.interests?.outdoorFun ?? []),
    ...(userDeatils?.interests?.hobbies ?? []),
    ...(userDeatils?.interests?.artsAndCulture ?? []),
    ...(userDeatils?.interests?.technology ?? []),
  ];
  const handleSave = async () => {
    if (!values) {
      setError(true);
      message.error("All Field Required", 5);
    } else {
      try {
        setLoading(true);
        const res = await joompaApi.post("createMatchesByAdmin", {
          number:values,
          userId: ID,
        });
        // console.log("post api", res);
        if (res.status === 200) {
          setLoading(false);
          message.success(res?.data?.message, 5);
          setValues({ number: "" });
         navigate(TRANSACTIONS,{
            state:{
              userId:ID,
              conceirge:true,
              // concierge:true,
            }
           });
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        if(error.response.status===301){
          setLoading(false);
          message.error("User has no concierge limit")
          return;
        }
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const functionForSuggestion = (name,number)=>{
    SetCardAppearence(false)
    setSuggestion(name);
    setValues(number);
  }

  const getPackageData = async (searchQuery) => {
    SetCardAppearence(true)
  
    let res = await joompaApi.get(`/userListForConcierge?userId=${ID}&search=${searchQuery||""}`);
    res = res.data.message;
     setData(res);
   
    
  };

  // useEffect(()=>{
  //   getPackageData();
  // },[])

  // const data = [{
  //   label: "AUDIO CALL",
  //   value: "AUDIO CALL",
  // },
  // {
  //   label: "VIDEO CALL",
  //   value: "VIDEO CALL",
  // },
  // {
  //   label: "CONCIERGE",
  //   value: "CONCIERGE",
  // },
  // {
  //   label: "PREMIUM",
  //   value: "PREMIUM",
  // },
  // {
  //   label: "CHAT",
  //   value: "CHAT",
  // },
  // {
  //   label: "REFER",
  //   value: "REFER",
  // },
  // {
  //   label: "TOPUP",
  //   value: "TOPUP",
  // },
  // {
  //   label: "REVOKE PROFILE",
  //   value: "REVOKE PROFILE",
  // },
  // {
  //   label: "PROFILE SUGGESTIONS",
  //   value: "PROFILE SUGGESTIONS",
  // },
  // {
  //   label: "INCOGNITO",
  //   value: "INCOGNITO",
  // },
  // {
  //   label: "PASSPORT",
  //   value: "PASSPORT",
  // }]

  let timeOutId
  const functionCardAppearence = (e)=>{
      setSuggestion(e.target.value)
      clearTimeout(timeOutId);
      timeOutId=setTimeout(()=>{
        const {value,name}=e.target
        getPackageData(value);
        // searchFunction(value)
      },1000)
    
    }

    const functionAtStart = ()=>{
      if(search){
        getPackageData();
        setSearch(false);
      }
    }

    const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          SetCardAppearence(false)
          setSearch(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <div className="add-suggestion-container">
        <div className="left-profile white-card" >
          <div className="profile-img">
            <img
              width="104px"
              // className="ant-image-img"
              height="104px"
              style={{ borderRadius: "50%", objectFit: "cover" }}
              src={userDeatils?.userImg && userDeatils?.userImg[0]?.photoUrl}
            />
            <span className="title">{`${userDeatils.fname},${userDeatils.age}`}</span>
            <span className="user-info">{`${userDeatils.occupation}`}</span>
            <span className="user-info">{`${userDeatils.maritalStatus}`}</span>
          </div>
          <div>
            <span className="profile-heading">{`About ${userDeatils.fname}`}</span>
            <p className="user-info-text">
              <ul style={{ textAlign: "left", marginLeft: "20px" }}>
                {userDeatils?.introduction &&
                  userDeatils?.introduction.map((obj) => (
                    <div>
                      <li style={{ color: "#2775C0", fontSize: "14px" }}>
                        <span style={{ color: "black" }}>{obj.question}</span>
                      </li>
                      <p style={{ fontSize: "12px" }}>{obj.answer}</p>
                    </div>
                  ))}
              </ul>
            </p>
          </div>
          <div>
            <span className="profile-heading">{`${userDeatils.fname}'s basics`}</span>
            <div className="basics">
              <div className="badge">
                <img src={locationImg} alt="location" />
                {` ${userDeatils?.country}`}
              </div>
              <div className="badge">
                <img src={age} alt="location" />
                {` ${userDeatils?.age}`}
              </div>
              <div className="badge">
                <img src={weight} alt="location" />{" "}
                {`${userDeatils?.weight} KG`}
              </div>
              <div className="badge">
                <img src={height} alt="location" />{" "}
                {`${userDeatils?.height} cm`}
              </div>
              <div className="badge">
                <img src={religion} alt="location" />{" "}
                {`${userDeatils?.religion}`}
              </div>
              <div className="badge">
                <img src={country} alt="location" />{" "}
                {`${userDeatils?.ethnicity}`}
              </div>
              <div className="badge">
                <img src={education} alt="location" />{" "}
                {`${userDeatils?.education}`}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            {userDeatils?.userImg?.[1] && <img src={userDeatils?.userImg[1]} />}
          </div>
          <div>
            <span className="profile-heading">Personality information</span>
            <div style={{ marginTop: "15px" }} className="basics">
              {PersonalityInfo &&
                PersonalityInfo?.map((obj) => (
                  <span className="badge">{obj}</span>
                ))}
            </div>
          </div>

          <div style={{ marginTop: "15px" }}>
            {userDeatils?.userImg?.[2] && <img src={userDeatils?.userImg[2]} />}
          </div>
          <div>
            <span className="profile-heading">My Interests</span>
            <div style={{ marginTop: "15px" }} className="basics">
              {interestArray &&
                interestArray.map((obj) => (
                  <span className="badge">{obj}</span>
                ))}
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            {userDeatils?.userImg?.[3] && <img src={userDeatils?.userImg[3]} />}
          </div>
        </div>
        <div className="form-container white-card" style={{height:"200px"}}>
          <div className="form-heading">Add New Suggestion</div>
          <div  ref={wrapperRef} style={{width:"40vw"}}>
            <label className="label-text">User Data</label>
            <Input 
              name="number"
              value={suggestion}
              onChange={functionCardAppearence}
              onClick={functionAtStart}
              placeholder="User Name"
              className="input-box"
              autoComplete="off"
              status={error && "error"}
            />
                
        {cardAppearence && <div 
          style={{
            width: "40vw",
            backgroundColor:"white",
            border:"1px solid #E6EBF1",
            borderRadius: "8px",
            marginTop:"10px",
            maxHeight:"300px",
            position:"absolute",
            overflowY:"scroll",
            paddingLeft:"10px",
            paddingTop:"20px",
            paddingRight:"10px "
          }}
        >
        {
        data.length===0 ? <div style={{padding:"20px",textAlign:"center",marginBottom:"20px"}}>"No Result Found"</div> : data?.map((obj)=>{
           return <div onClick={()=>functionForSuggestion(obj.fname,obj.phoneNumber)} style={{ display: "flex",marginBottom:"2px",marginTop:"2px" ,padding:"15px",borderRadius:"10px",border:"1px solid #E6EBF1",cursor:"pointer"}}>
           <img src={obj?.photoUrl} width="40px" height="40px" style={{borderRadius:"50%",objectFit:"cover"}}/>
           <div style={{ marginLeft: "20px" }}>
             <div
               style={{
                 fontWeight: "700",
                 fontSize: "14px",
                 lineHeight: "17px",
               
               }}
             >
               
                <p>{`${obj?.fname}`}</p>
               
             </div>
             <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                    marginTop:"-10px"
                  }}
                >
                  {obj.phoneNumber}
                </div>
               
           </div>
          
         </div>
         
          })
        }

         
        </div>
      
} 
<span
              style={{ marginLeft: "10px", color: "#ff9900", fontSize: "20px" }}
            >
              {error && <WarningOutlined />}
            </span> 

          </div>
          {/* <div>
            <label className="label-text">User Id</label>
            <Input
              name="userId"
              value={values.userid}
              onChange={handleInputChange}
              placeholder="User Id"
              style={{ width: "70%" }}
              className="input-box"
            />
          </div> */}
        </div>
      </div>
      <div className="save-back-button" style={{ marginTop: "-14.5%",marginRight:"1%" }}>
        <button
          disabled={loading}
          className="back-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <button disabled={loading} className="save-button" style={{ background: "#1B75BC",}} onClick={handleSave}>
          {loading ? <LoadingOutlined /> : "Save"}
        </button>
      </div>
    </>
  );
};

export default AddSuggestionForm;
