import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import RemarksIcon from "../../Assets/Images/messagetext1.png"
import { MessageOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

function ReadMore({visible,setVisibile,readMore,remarks}) {
  return (
    <div>
        <Modal centered icon={<MessageOutlined/>} open={visible} footer={false} onCancel={()=>setVisibile(false)} title={remarks}>
            {readMore}
        </Modal>
    </div>
  )
}

export default ReadMore