import React, { useState } from "react";
import { Spin, Table } from "antd";

const TableComponent = ({ columns, data, rowKey, transaction, number,count,setPage,setCount,page,pages }) => {
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);

  const onChange = (tableData) => {
    setPageSize(tableData.pageSize);
    setCurrent(tableData.current);
    setPage(tableData.current)
    setCount(tableData.pageSize)
  };
  const generateRowKey = (record, index) => {
    return `row-${index}`;
  };

  const functionTab = (transaction) => {
    if (transaction) {
      return { x: "max-content" };
    } else {
      return "";
    }
  };

  columns = [
    {
      title: "S No",
      width: "5%",
      render: (value, item, index) =>{
        if(pages){
            return (page-1)*pageSize+(index+1)
        }else{

          return current === 1 ? index + 1 : (current - 1) * pageSize + (index + 1)
        }
      }
    },
  ].concat(columns);
  // console.log(data)
  // data = data.length!==0 && data.map((item,index)=>{
  //     return{count:index+1,...item}
  // })
  console.log(page)
  return (
    <div className="user-container">
      <div className="white-card table-container">
        <Table
          scroll={functionTab}
          rowKey={generateRowKey}
          size="small"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={{
            showSizeChanger: true,
            current: pages ? Number(page) : undefined,
            pageSizeOptions: ["5", "10", "20", "50", "100"],
            total: count?count:data?.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </div>
  );
};

export default TableComponent;
