import React,{useState,useEffect} from "react";
import eventImg1 from "../../Assets/Images/event/eventImg1.png";
import eventImg2 from "../../Assets/Images/event/eventImg2.png";
import eventImg3 from "../../Assets/Images/event/eventImg3.png";
import reject from "../../Assets/Images/reject.png";
import TextArea from "antd/lib/input/TextArea";
import accept from "../../Assets/Images/accept.png";
import {
  MoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Popover, Modal, Image, message, Spin } from "antd";
import { useLocation,useNavigate } from "react-router-dom";
import joompaApi from "../../utils/apis/joompaApi";
import { formatDate } from "../../utils/Constant";


const eventData = {
  id: "#123",
  eventName: "Creative Crafts",
  eventLocation: "52 Bedok Reservoir Cres Singapore 479226",
  dateAndTime: "16 Jan 2023 10:00 Am - 12:00 Pm",
  createdBy: "Sara M",
  createdOn: "1 Jan 2023",
  eventCaption: "Loreum ipsum is dummy text",
  eventDetails:
    "Loreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy text Loreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy textLoreum ispum is dummy text. Loreum ispum is dummy text",
  eventPictures: [eventImg1, eventImg2, eventImg3,eventImg1, eventImg2, eventImg3],
};
const EventsDetails = () => {
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectConfirmModal, setRejectConfirmModal] = useState(false);
  const [acceptConfirmModal, setAcceptConfirmModal] = useState(false);
  const [rejectLoading, setRejectLoading]=useState(false)
  const [approveLoading, setApproveLoading]=useState(false)
  const [loading,setLoading] = useState(false)
  const [remark, setRemark] = useState("");
  const [alreadyMark,setAlreadyMark]=useState(true)
  const [eventData,setEventData]=useState({})
  const location=useLocation()
  // let eventId;
  // if(location.state.userId){
  //   eventId=location.state.eventId
  // }
  // else{
  //   eventId=location.state.eventId
  // }
  const {eventId}= location.state
  // const {userId} = location.state
  // const {eventId}=location.state
  const navigate=useNavigate()
const handleReject=async()=>{
  if(!remark){
    message.destroy()
    return message.error("Remark Required !")
  }
  let obj={
    eventId:eventId,
    type: "REJECTED",
    rejectRemark: remark
}

  try {
    setRejectLoading(true);
    // console.log("Evnet ID",eventId)
    let res = await joompaApi.post(`/eventApproveOrReject`,obj);
    // console.log("Reject Response",res)
    if(res?.status===201){
      message.success(res?.data?.message)
      setRejectModal(false)
      setRejectConfirmModal(true)
      navigate(-1)
      getContent()
    }
    setRejectLoading(false);
  } catch (error) {
    setRejectLoading(false);
    message.error("something went wrong")
  }
}
const handleAccept=async()=>{

  let obj={
    eventId:eventId,
    type: "APPROVED",
}
  try {
    setApproveLoading(true);
    // console.log("Evnet ID",eventId)
    let res = await joompaApi.post(`/eventApproveOrReject`,obj);
    if(res?.status===201){
      message.success(res?.data?.message)
      setAcceptModal(false)
      setAcceptConfirmModal(true)
      navigate(-1)
      getContent()
    }
    setApproveLoading(false);
  } catch (error) {
    setApproveLoading(false);
    message.error("something went wrong")
  }
}

useEffect(() => {
  getContent()
  
}, []);
const getContent = async () => {
  try {
    setLoading(true);
    // console.log("Evnet ID",eventId)
    let res = await joompaApi.get(`/eventDetails?eventId=${eventId}`);
    let data = res?.data?.data;
    setEventData(data)
    if(data?.eventStatus==="PENDING"){
      setAlreadyMark(false)
    }
    setLoading(false);
  } catch (error) {
    setLoading(false);
    message.error("something went wrong")
  }
};
  return (
    <div style={{ padding: "0px 20px 0px 20px" }}>
      <div style={{ position: "absolute", top: "75px", right: "26px" }}>
        {
          alreadyMark ?
          <div>
            {/* color:"#00AB6F" for approved */}
              <p style={{fontWeight:800,textDecorationLine:"underline",textAlign:"right",color:eventData?.eventStatus==="REJECTED"?"#FF3E3E":"#00AB6F"}}>{eventData?.eventStatus}</p>
              <p style={{marginTop:"-12px",fontSize:"14px",fontWeight:800}}>{formatDate(eventData?.eventStatusChangeDate)}</p>
          </div>:
          <div>
          <button
      className="back-button"
      style={{
        background: "#FF3E3E",
        color:"#FFF",
        borderRadius: "8px",
      }}
      onClick={()=>setRejectModal(true)}
    >
      Reject
    </button>
    <button
      className="save-button"
      style={{
        background: "#00AB6F",
        borderRadius: "8px",
      }}
      onClick={()=>setAcceptModal(true)}
    >
      Approve
    </button>
      </div>
        }
      </div>
      <Spin spinning={loading} tip="Loading..." size="large">
      <div className="form-container white-card">
        <div>
          <label className="label-text">Event ID</label>
          <p>{eventData?._id}</p>
        </div>
        <div>
          <label className="label-text">Event Name</label>
          <p>{eventData?.eventName}</p>
        </div>
        <div>
          <label className="label-text">Event Caption</label>
          <p>{eventData?.eventCaption}</p>
        </div>
        <div>
          <label className="label-text">Event Date & Time</label>
          <p>{`${formatDate(eventData?.eventDate)} Start From ${new Date(eventData?.startTime).toLocaleTimeString([], { timeStyle: "short" })} To ${new Date(eventData?.endTime).toLocaleTimeString([], { timeStyle: "short" })}`}</p>
        </div>
        <div>
          <label className="label-text">Event Address</label>
          <p>{eventData?.address}</p>
        </div>
        <div>
          <label className="label-text">Created By</label>
          <p>{eventData?.eventCreaterFName+" "+eventData?.eventCreaterLName}</p>
        </div>
        <div>
          <label className="label-text">Created On</label>
          <p>{formatDate(eventData?.eventCereatedOn)}</p>
        </div>
        <div>
          <label className="label-text">Event Details</label>
          <p style={{width:"80%",textAlign:"justify"}}>{eventData?.aboutEvent}</p>
        </div>
        <div>
          <label className="label-text">Event Pictures</label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              flexWrap:"wrap"
            }}
          >
            {eventData?.eventPhoto?.map((el) => (
              <img
                src={el.photoUrl}
                style={{
                  borderRadius: "4px",
                  objectFit: "cover",
                  width: "245px",
                  height: "238px",
                }}
                alt="event-pic"
              />
            ))}
          </div>
        </div>
        {
          eventData?.rejectRemark &&  <div>
          <label className="label-text">Event Reject Reason</label>
          <p>{eventData?.rejectRemark}</p>
        </div>
        }
      </div>
      </Spin>

      {/* reject Modal */}
      <Modal
        wrapClassName="accept-modal"
        centered
        visible={rejectModal}
        footer={null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setRejectModal(false)}
      >
        <div className="accept-modal">
          <img src={reject} alt="accept-icon" />
          <p className="modal-masg">
            {
              `Please enter remark before rejecting 
              event"${eventData?.eventName}"`
            }
          
          </p>
          <TextArea
            rows={3}
            placeholder="Type Here..."
            onChange={(e) => setRemark(e.target.value)}
          />
          <div className="modal-buttons">
          <button
              className="back-button"
              onClick={() => setRejectModal(false)}
            >
              Back
            </button>
            <button className="save-button" onClick={handleReject}>
                  {rejectLoading?<LoadingOutlined/>:"Reject"} 
            </button>
           
          </div>
        </div>
      </Modal>
      <Modal
    wrapClassName="accept-modal"
    centered
    visible={rejectConfirmModal}
    footer={null}
    // cancelButtonProps={{ style: { display: 'none' } }}
    // okButtonProps={{ style: { display: 'none' } }}
    onCancel={() => setRejectConfirmModal(false)}
  >
    <div className="accept-modal">
      <img src={accept} alt="accept-icon" />
      <p className="modal-masg">{`Event ${eventData?.eventName} created by
${eventData?.eventCreaterFName} ${eventData?.eventCreaterLName} rejected successfully!`}</p>
      {/* <p className="modal-user-info">{`User Id ${userID}, ${fullName} is verified successfully.`}</p> */}
    </div>
  </Modal>

  {/* acccept modal */}
  <Modal
        wrapClassName="accept-modal"
        centered
        visible={acceptModal}
        footer={null}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setAcceptModal(false)}
      >
        <div className="accept-modal">
          <img src={reject} alt="accept-icon" />
          <p className="modal-masg" style={{lineHeight:"25px"}}>
            {
              `Are you sure, you want to approve 
              event “${eventData?.eventName}” ?`
            }
          
          </p>
          <div className="modal-buttons" style={{marginTop:"50px"}}>
          <button
              className="back-button"
              onClick={() => setAcceptModal(false)}
            >
              Back
            </button>
            <button className="save-button" onClick={handleAccept}>
                  {approveLoading?<LoadingOutlined/>:"Yes Approve"} 
            </button>
           
          </div>
        </div>
      </Modal>
      <Modal
    wrapClassName="accept-modal"
    centered
    visible={acceptConfirmModal}
    footer={null}
    // cancelButtonProps={{ style: { display: 'none' } }}
    // okButtonProps={{ style: { display: 'none' } }}
    onCancel={() => setAcceptConfirmModal(false)}
  >
    <div className="accept-modal">
      <img src={accept} alt="accept-icon" />
      <p className="modal-masg">{`Event ${eventData?.eventName} created by
${eventData?.eventCreaterFName} ${eventData?.eventCreaterLName}approved successfully!`}</p>
      {/* <p className="modal-user-info">{`User Id ${userID}, ${fullName} is verified successfully.`}</p> */}
    </div>
  </Modal>
    </div>
  );
};

export default EventsDetails;
