import React, { useState, useEffect } from "react";
import "./notification.css";
import Initials from "../../Assets/Images/Initials.png";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import joompaApi from "../../utils/apis/joompaApi";
import Loading from "./Loading";
import { formatDate } from "../../utils/Constant";
// const notificationsData = [
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:42 AM",
//   },
// ];
// const extraNotificationData = [
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:01 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:02 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:03 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:04 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:05 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:06 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:07 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:08 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:09 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:10 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:11 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:12 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:13 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:14 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:15 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:16 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:17 AM",
//   },
//   {
//     id: Math.random(),
//     icon: Initials,
//     title:
//       "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.",
//     time: "Today at 9:18 AM",
//   },
// ];


const limit=10
const NotificationCard = ({setShowDot,wrappRef,onHandleClick}) => {
  const [notificationData,setNotificationData]=useState([])
  const [loading,setLoading]=useState(false)
  const [markReadLoading,setMarkReadLoading]=useState(false)
  const [hasMorePage,setHasMorePage]=useState(true)
  const [notificationRead,setNotificationRead] = useState(0);
  const [page,setPage]=useState(0)
  // useEffect(() => {
  //   console.log("here")
  //   fetchData()
  // }, [])

  useEffect(()=>{
    if(page===0){
      fetchData();
    }
  },[page])

const markAllAsRead=async()=>{
    setMarkReadLoading(true)
    let res =await joompaApi.put("/markAllRead");
    // console.log("mark all as read")
    setPage(0)
    setNotificationData([])   
    setMarkReadLoading(false)
  
  }

  const fetchData=async ()=>{
    try {
      // console.log(page)
      setLoading(true)
      let res = await joompaApi.get(`/getAllNotificationForAdmin?limit=${limit}&page=${page}`);
      let data = res?.data;
      setNotificationRead(data.unreadCount);
      if( res?.data?.data.length===0){
        // console.log("i am false")
        setHasMorePage(false);
        return;
      }
      data = res?.data?.data;
      // console.log("page",page,data)
      let flatdata = [...notificationData, data]
      setNotificationData(flatdata.flat());
      setShowDot(res?.data.unreadCount)
      // data.docs.length>0 && setShowDot(true)
      // console.log("notifcation Data", data)
     
      // setNotificationData(notificationData=>[...notificationData,data]);
      setPage(page=>page+1)
      // console.log(page);
      // if(page>=)
      // console.log("hasMorePage",hasMorePage)
      setLoading(false)
    } catch (error) {
      // console.log(error)
    }
  }
  return (
    <div className="noti-card" ref={wrappRef}>
      <div className="noti-heading">
        <div className="noti-title">
          Notifications({notificationRead})
        </div>
        <div className="noti-clear">
          <button style={{
            border:"none",
            background:"none",
            cursor:"pointer"
          }} onClick={markAllAsRead}>{markReadLoading ? <LoadingOutlined/>:<>Mark all as read <CheckCircleTwoTone /></>}</button>

        </div>
      </div>
      <div  className="noti-list" id="scrollableDiv">
        {notificationData.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>No New Notifcation</h3>
        ) : (
          <InfiniteScroll
            dataLength={notificationData.length}
            next={fetchData}
            hasMore={hasMorePage}
            // inverse={true}
            scrollableTarget="scrollableDiv"
            // loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
            loader={loading && <Loading/>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >{
              notificationData.map((el,i) => {
                // console.log(el)
                return (
                  <div onClick={()=>onHandleClick(el)}>
                  {el.isRead?
                  (
                    <div key={el._id} className="single-noti" style={{marginBottom:"3px",padding:"16px",paddingTop:"8px"}}>
                    <div style={{ padding: "0px 10px" }} >
                      {/* <img src={el.icon} /> */}
                    </div>
                    <div>
                      <p className="noti-desc">{el.description}</p>
                      <p className="noti-time">{formatDate(el.dateAndTime)}</p>
                    </div>
                  </div>
                  ):(
              <div key={el._id} className="single-noti" style={{backgroundColor:"#E5FDFF",marginBottom:"3px",padding:"16px",paddingTop:"8px"}}>
                    <div style={{ padding: "0px 10px" }} >
                      {/* <img src={el.icon} /> */}
                    </div>
                    <div>
                      <p className="noti-desc" style={{fontWeight:600}}>{el.title}</p>
                      <p className="noti-desc">{el.description}</p>
                      <p className="noti-time">{formatDate(el.dateAndTime)}</p>
                    </div>
                  </div>
                  )}
                  
                  </div>
                )
              })
            }
          </InfiniteScroll>

        )}
      </div>

    </div>
  );
};

export default NotificationCard;
