import React, { useEffect } from 'react'
import { formatDate } from "../../utils/Constant";
import { FileOutlined ,VideoCameraOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import { Image } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

function chatData({chatData,userProfileData,messagesEndRef,hasMorePage,pageNum,setPageNum,loading}) {

  return (
    <div    >
         <InfiniteScroll
            dataLength={chatData.length}
            next={()=>setPageNum(pageNum=>pageNum+10)}
            hasMore={hasMorePage}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}    
            inverse={true} 
            scrollableTarget="scrollableDiv"
            loader={loading&&<Loading/>}
          //          endMessage={
          //   <p style={{ textAlign: 'center',marginTop:"15px",textAlign:"center" }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
          >
          {
              chatData.map((el,i) =>
              el.from === "admin" ? (
                <div key={el._id}>
                        <div class="message text-only">
                          <div class="response">
                          {!el.attachment? 
                           (<p class="text">{el.message}</p>):
                         
                           ( 
                            <>
                            {el.type==="image"&&
                          <Image  style={{maxWidth:"300px",minWidth:"60px"}} src={el.attachment}/>
                            }
                            {
                        el.type==="video"&& <p class="text">
                        <a style={{color:"#E6EBF1"}} href={el.attachment}><VideoCameraOutlined /> Click to View</a></p>
                      }
                            {
                              el.type==="doc"&&  <p class="text">
                                  
                                 <a style={{color:"#E6EBF1"}} href={el.attachment}><FileOutlined/> Click to View</a>
                              </p>
                            }
                          </>
                          )}
                          </div>
                        </div>
                  <p class="response-time time">{formatDate(el?.date,true)}</p>
                </div> 
              ) : (
                <div key={el._id}>
                  <div class="message">
                    <div class="photo">
                      <Image src={userProfileData?.profilePhoto} width="45px" height="45px" style={{borderRadius:"100px"}}/>
                      <div class="online"></div>
                    </div>
                    {!el.attachment? 
                    (<p class="text">{el.message}</p>):(
              
                      <>
                      {el.type==="image"&&
                    <Image  style={{maxWidth:"300px",minWidth:"60px"}} src={el.attachment}/>
                      }
                      {
                        el.type==="video"&& <p class="text">
                        <a style={{textDecoration:"none",color:"#272C35"}} href={el.attachment}><VideoCameraOutlined /> Click to View</a></p>
                      }
                      {
                        el.type==="doc"&&  <p class="text">
                            
                           <a style={{textDecoration:"none",color:"#272C35"}} href={el.attachment}><FileOutlined/> Click to View</a>
                        </p>
                      }
                    </>
                    )}
                  </div>
                  <p class="time"> {formatDate(el?.date,true)}</p>
                </div>
              )
            )
            }
            </InfiniteScroll>
            {/* <div ref={messagesEndRef} /> */}
    </div>
  )
}

export default chatData;